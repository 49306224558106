import React from 'react';

import { Box, EmptyStateWithIcon } from 'components';

type Props = {
  title: string;
};

const EmptyState = ({ title }: Props) => {
  return (
    <Box className="mt-4">
      <EmptyStateWithIcon inBox={false} iconName="edit_note" title={title} />
    </Box>
  );
};

export default EmptyState;
