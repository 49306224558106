import React from 'react';

import { useArrayMutation } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Button } from 'components';

import { FormBudgetItem } from '../../types';
import { generateNewItem } from '../helpers/generateNewItem';
import BudgetItemLine from './BudgetItemLine';

type Props = {
  budgetItems: Array<FormBudgetItem>;
  onChange: (value: Array<FormBudgetItem>) => void;
  showAddButton?: boolean;
};

const BudgetItemsList = ({
  budgetItems,
  onChange,
  showAddButton = true,
}: Props) => {
  const { createItem, updateItem, deleteItem } = useArrayMutation(
    budgetItems,
    onChange
  );

  return (
    <>
      {budgetItems.map((budgetItem, index) => (
        <BudgetItemLine
          key={budgetItem.id || `i${index}`}
          budgetItem={budgetItem}
          onChange={updateItem(index)}
          onDelete={deleteItem(index)}
        />
      ))}
      {showAddButton && (
        <Button
          color="secondary"
          testClassName="test-define-funding-source-button"
          onClick={createItem(generateNewItem())}
        >
          <span>{__('Add a funding source')}</span>
        </Button>
      )}
    </>
  );
};

export default BudgetItemsList;
