import React from 'react';

import { ReviewCycle } from 'models';

import { StepInfo, StepName } from '../../helpers/steps';
import { UpdateReviewCycle } from '../index';
import Step1Name from './Step1Name';
import Step2Template from './Step2Template';
import Step3Participants from './Step3Participants';
import Step4Options from './Step4Options';
import Step5Emails from './Step5Emails';
import Step6Summary from './Step6Summary';

type Props = {
  currentStepInfo: StepInfo;
  reviewCycle: ReviewCycle;
  goToNextStep: () => void;
  goToStep: (name: StepName) => void;
  updateReviewCycle: UpdateReviewCycle;
  launchReviewCycle: () => Promise<void>;
};

export default function CurrentStep({
  reviewCycle,
  currentStepInfo,
  goToNextStep,
  goToStep,
  updateReviewCycle,
  launchReviewCycle,
}: Props) {
  switch (currentStepInfo.name) {
    case 'name':
      return (
        <Step1Name
          name={reviewCycle.name}
          interactionType={reviewCycle.interactionType}
          updateReviewCycle={updateReviewCycle}
          goToNextStep={goToNextStep}
        />
      );
    case 'template':
      return (
        <Step2Template
          reviewCycle={reviewCycle}
          updateReviewCycle={updateReviewCycle}
          goToNextStep={goToNextStep}
        />
      );
    case 'participants':
      return (
        <Step3Participants
          reviewCycle={reviewCycle}
          updateReviewCycle={updateReviewCycle}
          goToNextStep={goToNextStep}
        />
      );
    case 'options':
      return (
        <Step4Options
          reviewCycle={reviewCycle}
          updateReviewCycle={updateReviewCycle}
          goToNextStep={goToNextStep}
        />
      );
    case 'emails':
      return (
        <Step5Emails reviewCycle={reviewCycle} goToNextStep={goToNextStep} />
      );
    case 'finalize':
      return (
        <Step6Summary
          reviewCycle={reviewCycle}
          launchReviewCycle={launchReviewCycle}
          goToStep={goToStep}
        />
      );
    default:
      return null;
  }
}
