import React, { useState } from 'react';

import type { User } from 'models';
import type { ResponsibleRole } from 'models/UserReview/UserReview';

import { useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { DesignSystem, Icon, Radio, Tooltip } from 'components';

import FeaturePromptModal from 'scenes/components/FeaturePromptModal';
import UserPicker from 'scenes/components/UserPicker';
import delegateToAnyoneSvg from 'scenes/components/svg/delegateToAnyoneSvg';

type Props = {
  reviewee?: User;
  responsible: User | undefined | null;
  reviewCycleResponsible?: User | undefined | null;
  responsibleRole: ResponsibleRole | undefined | null;
  onChange: (responsible: {
    responsible: User | undefined | null;
    responsibleRole: ResponsibleRole | undefined | null;
  }) => Promise<void>;
  inModal?: boolean;
};

const ResponsiblePicker = ({
  reviewee,
  responsible,
  responsibleRole,
  onChange,
  inModal,
  reviewCycleResponsible,
}: Props) => {
  const { delegateToAnyoneEnabled } = useOrganizationPlan();
  const [showFeaturePromptModal, setShowFeaturePromptModal] = useState(false);

  let managerLabel = __('Direct manager of participant');
  let managerName = '';
  let managerHelper = __(
    'If a participant’s manager is changed, the new manager will automatically be the new responsible.'
  );
  let adminLabel = __('An admin');
  let anyoneLabel: React.ReactNode = __('A specific user');
  let shouldDisplayReviewCycleDefaultResponsibleHelper = false;

  if (!!reviewee) {
    managerLabel = __('Direct manager of %1:', reviewee.firstName);
    adminLabel = __('Admin of %1', reviewee.firstName);

    if (!!reviewee.manager) {
      managerName = reviewee.manager.fullName;
    } else {
      managerName = __('No manager associated');
      shouldDisplayReviewCycleDefaultResponsibleHelper = true;
    }

    managerHelper = __(
      'If %1’s manager is changed, the new manager will automatically be assigned the responsible role.',
      reviewee.firstName
    );
  }

  const displayReviewCycleDefaultResponsibleHelper =
    !!reviewCycleResponsible &&
    responsibleRole === 'manager' &&
    shouldDisplayReviewCycleDefaultResponsibleHelper;

  if (!delegateToAnyoneEnabled) {
    anyoneLabel = (
      <span>
        {anyoneLabel} <Icon name="lock" />
      </span>
    );
  }

  return (
    <DesignSystem version={2}>
      <div className="test-responsible-picker">
        <Radio
          label={
            <div style={{ display: 'inline-block' }}>
              <span>
                {managerLabel} <b>{managerName}</b>
              </span>
              {displayReviewCycleDefaultResponsibleHelper && (
                <>
                  <br />
                  <span>
                    {__(
                      'This review will be delegated by default to %1',
                      <b>{reviewCycleResponsible?.fullName}</b>
                    )}
                  </span>
                </>
              )}
              <Tooltip
                content={managerHelper}
                style={{ maxWidth: 340 }}
                isLight
              >
                <Icon style={{ marginLeft: 8 }} name="help" />
              </Tooltip>
            </div>
          }
          isChecked={responsibleRole === 'manager'}
          onChange={() =>
            onChange({ responsible: null, responsibleRole: 'manager' })
          }
          style={{ marginBottom: 8 }}
        />

        <Radio
          label={adminLabel}
          isChecked={responsibleRole === 'admin'}
          onChange={() =>
            onChange({ responsible: null, responsibleRole: 'admin' })
          }
          style={{ marginBottom: 8 }}
          testClassName="test-admin-radio"
        >
          <UserPicker
            value={responsible}
            onChange={responsible =>
              onChange({
                responsible: responsible as User,
                responsibleRole: 'admin',
              })
            }
            style={{ marginLeft: 8, marginTop: 8, marginRight: 8 }}
            inModal={inModal}
            fetchEndpoint="users/admins"
            placeholder={__('Select an admin')}
            className="test-admin-picker"
          />
        </Radio>

        <Radio
          label={anyoneLabel}
          isChecked={responsibleRole === 'anyone'}
          onChange={async () => {
            if (delegateToAnyoneEnabled) {
              await onChange({
                responsible: null,
                responsibleRole: 'anyone',
              });
            } else {
              setShowFeaturePromptModal(true);
            }
          }}
        >
          <UserPicker
            value={responsible || undefined}
            onChange={async responsible => {
              invariant(
                !Array.isArray(responsible),
                "There shouldn't be more than one responsible."
              );

              await onChange({
                responsible: responsible,
                responsibleRole: 'anyone',
              });
            }}
            style={{ marginLeft: 8, marginTop: 8, marginRight: 8 }}
            inModal={inModal}
          />
        </Radio>
      </div>

      <FeaturePromptModal
        title={__('Enable delegation to a specific person')}
        description={__(
          'Do you want to delegate a review to a different manager, a functional manager or any other user?\n' +
            'With this feature it is possible to delegate a review to any user in your Elevo space.'
        )}
        svg={delegateToAnyoneSvg}
        prefilledSubjectToSupport={__(
          'More info on delegating review to a specific person'
        )}
        mainButtonText={__('Contact an Elevo expert to enable this feature')}
        isActive={showFeaturePromptModal}
        onClose={() => setShowFeaturePromptModal(false)}
      />
    </DesignSystem>
  );
};

export default ResponsiblePicker;
