import React, { useState } from 'react';

import {
  SurveyAnswer,
  SurveyCampaign,
  SurveyQuestionStats,
  SurveyQuestionType,
} from 'models';

import { __ } from 'helpers/i18n';

import {
  BoxListItem,
  Button,
  Column,
  Columns,
  Date,
  FeatureFlagged,
  Text,
} from 'components';

import MessagingChatRoomModal from '../components/messaging/ChatRoomModal';
import AnswerScore from './AnswerScore';

type Props = {
  answer: SurveyAnswer;
  questionType: SurveyQuestionType;
  questionStats: SurveyQuestionStats;
  campaign: SurveyCampaign;
  showAnswerScore: boolean;
  testClassName?: string;
};

export default function AnswerItem({
  answer,
  questionStats,
  questionType,
  campaign,
  showAnswerScore,
  testClassName,
}: Props) {
  const [displayMessagingModal, setDisplayMessagingModal] = useState(false);
  const [chatRoom, setChatRoom] = useState(answer.messagingChatRoom);

  return (
    <>
      <BoxListItem className="px-4" testClassName={testClassName}>
        <Columns>
          <Column>
            {answer.text ? (
              <Text preset="14s6" testClassName="test-survey-answer-text">
                {answer.text}
              </Text>
            ) : (
              <Text
                preset="14s6"
                color="light"
                transformation="italic"
                testClassName="test-survey-answer-empty-text"
              >
                {__('Did not leave a comment')}
              </Text>
            )}
            <FeatureFlagged flag="surveyMessagingChatRooms">
              <div>
                <Text color="light" style={{ verticalAlign: 'sub' }}>
                  <Date
                    format="LL"
                    value={answer.submittedAt}
                    className="mt-1"
                  />
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => setDisplayMessagingModal(true)}
                    testClassName="test-survey-answer-conversation"
                    className="ml-2"
                  >
                    {chatRoom
                      ? __('Open the conversation')
                      : __('Start a conversation')}
                  </Button>
                </Text>
              </div>
            </FeatureFlagged>
          </Column>
          {showAnswerScore && (
            <AnswerScore answer={answer} questionType={questionType} />
          )}
        </Columns>
      </BoxListItem>
      {displayMessagingModal && (
        <MessagingChatRoomModal
          answer={answer}
          conversationIsAnonymous={
            chatRoom ? !!chatRoom.anonymousParticipantId : campaign.anonymous
          }
          messagingChatRoom={chatRoom}
          questionName={
            chatRoom?.questionName ||
            `${questionStats.questionNumber} - ${questionStats.question.title}`
          }
          campaignName={chatRoom?.campaignName || campaign.name}
          onClose={messagingChatRoom => {
            setChatRoom(messagingChatRoom);
            setDisplayMessagingModal(false);
          }}
          initialParticipantDisplayName={answer.participantFullName}
          initialParticipantAvatarUrl={answer.participantAvatarUrl}
        />
      )}
    </>
  );
}
