import * as React from 'react';

import type { Period } from './index';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

import { Icon, Link, Tooltip } from 'components';

type Props = {
  periods: Array<Period>;
  onChange: (id: string) => void;
};

export default function PeriodList({ periods, onChange }: Props) {
  return (
    <>
      {periods.map(period => (
        <div
          key={period.id}
          style={{ marginBottom: 4 }}
          className="flex justify-between items-center"
        >
          <div>
            <Tooltip content={period.tooltipMessage}>
              <Link
                disabled={period.disabled}
                className={classNames(
                  'period-list-item',
                  'test-period-list-item',
                  {
                    disabled: period.disabled,
                    'text-text-main': !period.disabled,
                  }
                )}
                onClick={() => {
                  invariant(
                    period.id,
                    'Every selectable period should have an id'
                  );

                  onChange(period.id);
                }}
              >
                <span data-testable-periodid={period.id}>{period.name}</span>
              </Link>
            </Tooltip>
          </div>
          {period.disabled && <Icon name="block" className="text-text-light" />}
        </div>
      ))}
    </>
  );
}
