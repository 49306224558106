import React from 'react';

import type {
  ReviewBlockUpdateParams,
  TrainingHistoryBlock,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  BlueInfoBox,
  Box,
  BoxSeparator,
  Flex,
  Text,
  WithSavingStatusRecorder,
} from 'components';

import BlockActions from './components/BlockActions';
import BlockContent from './components/BlockContent';

type Props = {
  block: TrainingHistoryBlock;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
};

const TrainingHistoryBlockComponent = ({
  block,
  onChange,
  ...actionProps
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const isRevampTrainingRequestHistoryEnabled = featureFlags.includes(
    'revampTrainingProfileAndReview'
  );

  return (
    <Box testClassName="test-training-request-history-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Text preset="14bs6" transformation="uppercase">
          {isRevampTrainingRequestHistoryEnabled
            ? __('History of training requests and sessions')
            : __('History of training requests')}
        </Text>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'training_history_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={block.content}
            withRichText={block.richTextEnabled}
          />
        )}
      />
      <BlueInfoBox>
        {isRevampTrainingRequestHistoryEnabled
          ? __(
              "The reviewee and the reviewer will see the last 5 training requests and completed or upcoming training sessions, along with a link to the employee's profile to access the full history."
            )
          : __(
              'The reviewee and the reviewer will see the last 5 training requests made for the reviewee (all statuses combined) as well as a link to all requests made for the reviewee.'
            )}
      </BlueInfoBox>
    </Box>
  );
};

export default TrainingHistoryBlockComponent;
