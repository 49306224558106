import type { QueryParams } from './types';
import type { PaginatedQueryParams } from 'helpers/hooks/usePaginatedQuery';

import { castHashValuesToBoolean } from 'helpers/hash';

import { hasShape, stringType } from 'types/predicates/WithShape';

const parseQueryParams = (queryParams: unknown) => {
  const sanitizedQueryParams: QueryParams = { search: '' };

  if (!queryParams || typeof queryParams !== 'object') {
    return sanitizedQueryParams;
  }

  // Default query params
  if (hasShape(queryParams, { search: stringType })) {
    sanitizedQueryParams.search = queryParams.search;
  }

  if (hasShape(queryParams, { userFilters: {} })) {
    sanitizedQueryParams.userFilters = queryParams.userFilters;
  }

  if (hasShape(queryParams, { sort: {} })) {
    sanitizedQueryParams.sort = queryParams.sort;
  }

  if (hasShape(queryParams, { filter: {} })) {
    sanitizedQueryParams.filter = castHashValuesToBoolean(queryParams.filter);
  }

  // Other query params
  if (hasShape(queryParams, { tagLabels: [] })) {
    sanitizedQueryParams.tagLabels = queryParams.tagLabels;
  }

  if (hasShape(queryParams, { periodSlug: stringType })) {
    sanitizedQueryParams.periodSlug = queryParams.periodSlug;
  }

  return sanitizedQueryParams;
};

const parsePaginatedQueryParams = (
  params: Partial<PaginatedQueryParams>
): Partial<PaginatedQueryParams> => {
  const parsedParams: Partial<PaginatedQueryParams> = { ...params };

  if (params.page) {
    parsedParams.page = parseInt(params.page as unknown as string, 10);
  }

  if (params.countPerPage) {
    parsedParams.countPerPage = parseInt(
      params.countPerPage as unknown as string,
      10
    );
  }

  const sanitizedQueryParams = parseQueryParams(params.queryParams);

  if (Object.keys(sanitizedQueryParams).length > 0) {
    parsedParams.queryParams = {
      ...parsedParams.queryParams,
      ...sanitizedQueryParams,
    };
  }

  return parsedParams;
};

export default parsePaginatedQueryParams;
