import React, { Fragment, useContext } from 'react';

import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { Text } from 'components';

import { BudgetFormContext } from '../Form';
import { FormBudgetItem } from '../types';

type Props = {
  budgetItems: Array<FormBudgetItem>;
};

const BudgetSummary = ({ budgetItems }: Props) => {
  const {
    sumBudgetItems,
    trainingPeriod: { mainCurrency },
  } = useContext(BudgetFormContext);

  const totalAmountCents = sumBudgetItems(budgetItems, mainCurrency);

  return (
    <Fragment>
      <Text preset="18bs5" className="block mb-4">
        {__('Overall budget')}
      </Text>
      <Text style={{ display: 'block' }} color="info" weight="normal">
        {__('The overall budget is calculated based on funding sources')}
      </Text>
      <Text
        preset="16bs5.5"
        style={{ display: 'block' }}
        testClassName="test-training-global-budget-amount"
      >
        {formatMoney(totalAmountCents, mainCurrency)}
      </Text>
    </Fragment>
  );
};

export default BudgetSummary;
