import React, { useState } from 'react';
import { compose } from 'redux';

import type { ReviewTemplate } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToTemplatePreview, pathToTemplates } from 'helpers/navigation';

import { newDataLoader } from 'lib/dataLoader';
import { del, get, post } from 'redux/actions/api';

import {
  Button,
  Column,
  Columns,
  DeprecatedContainerElevo,
  DesignSystem,
  FeatureFlagged,
  FetchContainer,
  Icon,
  PageHeader,
  SavingStatusLabel,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import ShareModal from '../components/ShareModal';
import NotEditableInBuilderHeaderMessage from './NotEditableInBuilderHeaderMessage';
import Settings from './Settings';
import Structure from './Structure';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type Props = AfterMatchParamsProps;

type AfterConnectProps = {
  reviewTemplate: ReviewTemplate;
  isFetching: boolean;
  hasError: boolean;
} & Props;

function TemplateEdit({
  reviewTemplate,
  isFetching,
  hasError,
}: AfterConnectProps) {
  const dispatch = useAppDispatch();
  const [modalIsActive, setModalIsActive] = useState(false);

  const createReaderRoles = (userIds: string[]) =>
    dispatch(
      post(`/review_templates/${reviewTemplate.id}/create_reader_roles`, {
        userIds,
      })
    );

  const deleteReaderRole = (userId: string) =>
    dispatch(
      del(`/review_templates/${reviewTemplate.id}/remove_reader_role`, {
        userId,
      })
    );

  return (
    <DesignSystem version={2}>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <>
            <PageHeader
              title={reviewTemplate.name}
              withBackButton
              backButtonProps={{
                target: pathToTemplates(reviewTemplate.interactionType),
                children: __('Back to templates'),
              }}
              statusInfo={
                <SavingStatusLabel
                  failedText={_count => __('The template could not be saved')}
                />
              }
              actions={[
                <Button
                  color="secondary"
                  to={pathToTemplatePreview(reviewTemplate.id, 'reviewee')}
                  openInNewTab
                >
                  <Icon style={{ marginRight: 8 }} name="visibility" />
                  {__('Preview template')}
                </Button>,
                <FeatureFlagged flag="shareTemplatePreview">
                  <Button
                    color="secondary"
                    onClick={() => setModalIsActive(true)}
                  >
                    <Icon style={{ marginRight: 8 }} name="share" />
                    {__('Share')}
                  </Button>
                </FeatureFlagged>,
              ]}
            />
            <DeprecatedContainerElevo className="mt-5">
              <Columns className="template-builder">
                <Column size={8}>
                  {reviewTemplate.isEditableInBuilder ? (
                    <Structure
                      templateId={reviewTemplate.id}
                      structure={reviewTemplate.reviewStructure}
                      hasSelfEvaluationEnabled={
                        reviewTemplate.hasSelfEvaluationEnabled
                      }
                    />
                  ) : (
                    <NotEditableInBuilderHeaderMessage
                      interactionType={reviewTemplate?.interactionType}
                    />
                  )}
                </Column>

                <Column size={4}>
                  <Settings
                    settingsDisabled={!reviewTemplate.isEditableInBuilder}
                    template={reviewTemplate}
                  />
                </Column>
              </Columns>
            </DeprecatedContainerElevo>
          </>
        )}
      />
      {modalIsActive && (
        <ShareModal
          users={reviewTemplate.readers || []}
          reviewTemplateName={reviewTemplate.name}
          onSubmit={createReaderRoles}
          onClose={() => setModalIsActive(false)}
          onDelete={userId => deleteReaderRole(userId)}
        />
      )}
    </DesignSystem>
  );
}

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: Props) => get(`review_templates/${match.id}`),
    hydrate: {
      reviewTemplate: {
        reviewStructure: {
          reviewBlocks: {
            feedbackOption: {},
            revieweeFeedbackOptions: {},
            reviewerFeedbackOptions: {},
            revieweeRatingOptions: {},
            reviewerRatingOptions: {},
            ratingOptions: {},
            ratingCriteria: {},
            abilities: {},
          },
        },
        readers: {},
      },
    },
  })
)(TemplateEdit);
