import React, { useContext, useState } from 'react';

import { FundingSource } from 'models';

import { __ } from 'helpers/i18n';

import { Select } from 'components';

import { FundingSourcesContext } from '..';

type Props = {
  fundingSource: FundingSource | undefined | null;
  isDisabled?: boolean;
  onChange: (fundingSource: FundingSource) => void;
};

type Option = {
  label: string;
  value: FundingSource;
};

const FundingSourceSelect = ({
  fundingSource,
  isDisabled,
  onChange,
}: Props) => {
  const { availableFundingSources } = useContext(FundingSourcesContext);

  const fundingSourceToOption = (fundingSource: FundingSource) => ({
    label: fundingSource.title,
    value: fundingSource,
  });
  const options = availableFundingSources.map(fundingSource =>
    fundingSourceToOption(fundingSource)
  );
  const [selectedOption, setSelectedOption] = useState(
    fundingSource ? fundingSourceToOption(fundingSource) : undefined
  );

  const handleOnChange = (option: Option | null | undefined) => {
    setSelectedOption(option || undefined);
    if (option) onChange(option.value);
  };

  const handleOnCreate = (title: string) => {
    const fundingSource = { id: '', title, createdAt: '' };
    setSelectedOption({ label: title, value: fundingSource });
    onChange(fundingSource);
  };

  return (
    <Select<Option>
      value={selectedOption}
      options={options}
      placeholder={__('Search funding sources or create new')}
      noOptionsMessage={__('No funding source available')}
      isDisabled={isDisabled}
      isCreatable
      inModal
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      formatCreateLabel={(newLabel: string) => __('Create "%1"', newLabel)}
    />
  );
};

export default FundingSourceSelect;
