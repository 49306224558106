import React from 'react';
import { compose } from 'redux';

import { TrainingOrganism } from 'models/TrainingOrganism';

import { __, date } from 'helpers/i18n';
import { pathToTrainingOrganisms } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer, FetchContainer, PageHeader, Text } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import EditOrganismForm from './EditOrganismForm';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    organism: TrainingOrganism;
  };

const Organism = ({ organism, isFetching, hasError }: AfterDataLoaderProps) => {
  const lastUpdateInformation = () => {
    return (
      <Text preset="13s7" color="soften" className="mb-4">
        {__('Updated on %1', date(organism.updatedAt, 'LL'))}
      </Text>
    );
  };

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <>
            <PageHeader
              title={organism.name}
              withBackButton
              backButtonProps={{
                target: pathToTrainingOrganisms(),
                children: __('Back to training organizations'),
              }}
              description={lastUpdateInformation()}
            />
            <ContentContainer>
              <EditOrganismForm organism={organism} />
            </ContentContainer>
          </>
        );
      }}
    />
  );
};

export default compose(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) => {
      return get(`training/organisms/${match.id}`);
    },
    hydrate: { organism: {} },
  })
)(Organism) as React.ComponentType;
