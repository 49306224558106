import React from 'react';

import type { ReviewBlockContent, ReviewBlockUpdateParams } from 'models';

import { __ } from 'helpers/i18n';

import { SubTitle, Title } from 'components';

import reviewTemplateVariables from 'scenes/admin/reviewTemplates/Template/Edit/Structure/reviewTemplateVariables';

type Props = {
  content: ReviewBlockContent;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  autoFocus?: boolean;
  withoutTitle?: boolean;
  withRichText?: boolean;
};

const BlockContent = ({
  content,
  onChange,
  autoFocus,
  withoutTitle,
  withRichText,
}: Props) => {
  const { title, description } = content;

  const mergeTags = [{ trigger: '[', tags: reviewTemplateVariables() }];

  return (
    <>
      {!withoutTitle && (
        <Title
          size={5}
          className="is-spaced"
          testClassName="test-block-title-field"
          weight="semibold"
          isEditable
          style={{ marginBottom: 8 }}
          editableOptions={{
            type: 'multilineText',
            autoFocus: autoFocus,
            onChange: title => onChange({ title }),
            value: title,
            placeholder: __('Add a title'),
          }}
        />
      )}

      <div className="test-block-description-field" style={{ marginBottom: 8 }}>
        <SubTitle
          size={6}
          className="is-spaced"
          isEditable
          editableOptions={{
            type: 'multilineText',
            autoFocus: autoFocus && withoutTitle,
            onChange: description => onChange({ description }),
            value: description,
            placeholder: __('Add a description'),
            fieldProps: {
              mergeTags: mergeTags,
              richText: withRichText,
              hideSaveButton: true,
            },
          }}
        />
      </div>
    </>
  );
};

export default BlockContent;
