import React, { Dispatch, SetStateAction } from 'react';

import { __ } from 'helpers/i18n';

import { Flex, Label, SavingStatusLabel, Text } from 'components';

import BudgetList from './BudgetList';

type Props = {
  periodSlug: string;
  setFundingSourcesCurrencies: Dispatch<SetStateAction<string[]>>;
};

const FundingSourcesList = ({
  periodSlug,
  setFundingSourcesCurrencies,
}: Props) => {
  return (
    <>
      <Flex>
        <Label>{__('Define your funding sources')}</Label>
        <div className="ml-auto">
          <SavingStatusLabel
            failedText={() => __('The budget could not be saved')}
          />
        </div>
      </Flex>
      <Text color="info" weight="normal" className="block">
        {__(
          'It will allow you to add different funding sources for a training session.'
        )}
      </Text>

      <BudgetList
        setFundingSourcesCurrencies={setFundingSourcesCurrencies}
        periodSlug={periodSlug}
      />
    </>
  );
};

export default FundingSourcesList;
