import React, { useState } from 'react';

import can from 'helpers/can';
import { useAppDispatch, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import { PageHeader } from 'components/appLayout';
import { ContentContainer } from 'components/containers';

import ExportModal from 'scenes/admin/components/ExportModal';

import BandsList from './BandsList';
import ExportButton from './ExportButton';

const Bands = () => {
  const organization = useCurrentOrganization();
  const [exportModalIsActive, setExportModalIsActive] = useState(false);
  const dispatch = useAppDispatch();

  const exportCompensationBands = async () => {
    await dispatch(post(`compensation/bands/prepare_bulk_export`));

    setExportModalIsActive(true);
  };

  return (
    <>
      <PageHeader
        title={__('Compensation bands')}
        actions={
          <>
            {can({
              perform: 'export_compensation_bands',
              on: organization,
            }) && (
              <>
                <ExportButton
                  exportCompensationBands={exportCompensationBands}
                />
                <ExportModal
                  isActive={exportModalIsActive}
                  onClose={() => setExportModalIsActive(false)}
                />
              </>
            )}
          </>
        }
      />
      <ContentContainer>
        <BandsList />
      </ContentContainer>
    </>
  );
};

export default Bands;
