import React from 'react';

import { BaseTrainingCourse } from 'models';

import { pathToTrainingsCatalogCourse } from 'helpers/paths';

import SuggestionsAccordionCourseRow from './CourseRow';

type SuggestionsAccordionCoursesProps = {
  existingCourses: BaseTrainingCourse[];
  newAutoCourses: BaseTrainingCourse[];
  selectedCourses?: Record<string, boolean>;
  onCheckboxClick?: (courseId: string) => void;
  onCourseDeleteClick?: (courseId: string) => void;
};

const SuggestionsAccordionCourses = ({
  existingCourses,
  newAutoCourses,
  selectedCourses,
  onCheckboxClick,
  onCourseDeleteClick,
}: SuggestionsAccordionCoursesProps) => {
  const allCourses = [
    { isAuto: false, courses: existingCourses },
    { isAuto: true, courses: newAutoCourses },
  ];

  return (
    <>
      {allCourses.flatMap(({ isAuto, courses }) =>
        courses.map(course => (
          <SuggestionsAccordionCourseRow
            key={course.id}
            logoUrl={course.organism.logoUrl}
            logoAlt={`${course.organism.name} logo`}
            title={course.name}
            link={pathToTrainingsCatalogCourse(course.id)}
            isAuto={isAuto}
            openInNewTab={!!selectedCourses}
            isChecked={selectedCourses ? selectedCourses[course.id] : undefined}
            onCheckboxClick={
              onCheckboxClick ? () => onCheckboxClick(course.id) : undefined
            }
            onDeleteClick={
              onCourseDeleteClick
                ? () => onCourseDeleteClick(course.id)
                : undefined
            }
          />
        ))
      )}
    </>
  );
};

export default SuggestionsAccordionCourses;
