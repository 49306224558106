import React from 'react';

import { OrganizationSyncWithStats } from 'models';

import { useAppDispatch, useSession } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { pathToUsers } from 'helpers/paths';

import { post } from 'redux/actions/api';

import {
  Box,
  BoxList,
  ContentContainer,
  Notification,
  PageHeader,
  Title,
} from 'components';

import ExportButton from './ExportButton';
import UserSyncListItem from './UserSyncListItem';

type Props = {
  organizationSync: OrganizationSyncWithStats;
};

function OrganizationSyncDetails({ organizationSync }: Props) {
  const {
    userSyncsWithErrors,
    userSyncsWithWarnings,
    successesCount,
    creationsCount,
    updatesCount,
    suspensionsCount,
    reactivationsCount,
    notChangedCount,
    syncWarnings,
    syncErrors,
    lastPendingExportAction,
  } = organizationSync;
  const { isImpersonated } = useSession();
  const dispatch = useAppDispatch();

  if (organizationSync.status === 'failed') {
    return (
      <Notification kind="danger" withContact>
        <b>{__('A fatal error occurred during synchronization.')}</b>
        <ul>
          {syncErrors.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </Notification>
    );
  }

  const exportOrganizationSyncReport = async () =>
    await dispatch(
      post(`organization_syncs/${organizationSync.id}/async_export`)
    );

  const hasFailedUserSyncs =
    userSyncsWithErrors && userSyncsWithErrors.length > 0;
  const hasWarnedUserSyncs =
    userSyncsWithWarnings && userSyncsWithWarnings.length > 0;

  return (
    <div>
      <PageHeader
        title={organizationSync.name}
        withBackButton
        backButtonProps={{ target: pathToUsers() }}
        actions={
          isImpersonated && (
            <ExportButton
              exportAction={lastPendingExportAction}
              exportOrganizationSyncReport={exportOrganizationSyncReport}
            />
          )
        }
      />
      <ContentContainer>
        {successesCount > 0 && (
          <Notification kind="success">
            <b>
              {n__(
                'One employee was synchronized successfully.',
                '%1 employees were synchronized successfully.',
                successesCount
              )}
            </b>
            <p>
              {creationsCount > 0 && (
                <span>
                  • {n__('%1 created', '%1 created', creationsCount)}
                  <br />
                </span>
              )}
              {updatesCount > 0 && (
                <span>
                  • {n__('%1 updated', '%1 updated', updatesCount)}
                  <br />
                </span>
              )}
              {suspensionsCount > 0 && (
                <span>
                  • {n__('%1 suspended', '%1 suspended', suspensionsCount)}
                  <br />
                </span>
              )}
              {reactivationsCount > 0 && (
                <span>
                  •{' '}
                  {n__('%1 reactivated', '%1 reactivated', reactivationsCount)}
                  <br />
                </span>
              )}
              {notChangedCount > 0 && (
                <span>
                  •{' '}
                  {n__('%1 had no change', '%1 had no change', notChangedCount)}
                </span>
              )}
            </p>
          </Notification>
        )}

        {syncWarnings.length > 0 && (
          <Notification kind="warning" withContact>
            <b>
              {n__(
                'One warning during synchronization.',
                '%1 warnings during synchronization.',
                syncWarnings.length
              )}
            </b>
            <ul>
              {syncWarnings.map(warning => (
                <li key={warning}>{warning}</li>
              ))}
            </ul>
          </Notification>
        )}

        {(hasFailedUserSyncs || hasWarnedUserSyncs) && (
          <>
            <hr
              style={{ margin: '40px 30%' }}
              className="bg-ui-separator-on-background"
            />
            <Title size={5} style={{ marginBottom: 20 }}>
              {__('User details')}
            </Title>
          </>
        )}

        {userSyncsWithErrors && userSyncsWithErrors.length > 0 && (
          <>
            <Notification kind="danger">
              <b>
                {n__(
                  'One employee could not be synchronized properly.',
                  '%1 employees could not be synchronized properly.',
                  userSyncsWithErrors.length
                )}
              </b>
              <p>{__('Please see each error below for more details.')}</p>
            </Notification>

            <Box>
              <BoxList>
                {userSyncsWithErrors.map(userSync => (
                  <UserSyncListItem
                    key={userSync.id}
                    userSync={userSync}
                    integrationType={organizationSync.integrationType}
                    type="error"
                  />
                ))}
              </BoxList>
            </Box>
          </>
        )}

        {userSyncsWithWarnings && userSyncsWithWarnings.length > 0 && (
          <>
            <Notification kind="warning">
              <b>
                {n__(
                  'One employee raised a warning during synchronization.',
                  '%1 employees raised warnings during synchronization.',
                  userSyncsWithWarnings.length
                )}
              </b>
              <p>{__('Please see each warnings below for more details.')}</p>
            </Notification>

            <Box>
              <BoxList>
                {userSyncsWithWarnings.map(userSync => (
                  <UserSyncListItem
                    key={userSync.id}
                    userSync={userSync}
                    integrationType={organizationSync.integrationType}
                    type="warning"
                  />
                ))}
              </BoxList>
            </Box>
          </>
        )}
      </ContentContainer>
    </div>
  );
}

export default OrganizationSyncDetails;
