import React, { ReactElement } from 'react';

import type { ObjectiveCollection } from 'models';

import can from 'helpers/can';
import { __, n__ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';
import { zendeskURL } from 'helpers/zendesk';

import { Button, EmptyStateWithIcon, Icon, Link, Text } from 'components';

type Props<T> = {
  publishedItems: T[];
  draftItems: T[];
  objectiveCollection: ObjectiveCollection;
  renderItems: (items: T[]) => ReactElement;
  revieweeFullName: string;
  onPublishClick?: () => Promise<unknown>;
  fromReview?: boolean;
  definesObjectives?: boolean;
};

const PersonalObjectiveSections = <T,>({
  publishedItems,
  draftItems,
  objectiveCollection,
  renderItems,
  onPublishClick,
  revieweeFullName,
  fromReview,
  definesObjectives,
}: Props<T>) => {
  const canManageDraftObjectives = can({
    perform: 'manage_draft_objectives',
    on: objectiveCollection,
  });

  if (!canManageDraftObjectives) {
    return renderItems(publishedItems);
  }

  const onConfirmPublishClick = () =>
    confirmAsync(
      n__('Publish %1 objective', 'Publish %1 objectives', draftItems.length),
      <p>
        {n__(
          'Once published, this objective will be visible by %2.',
          'Once published, these objectives will be visible by %2.',
          draftItems.length,
          revieweeFullName
        )}
      </p>,
      {
        confirmLabel: __('Publish'),
        isLarge: true,
        onConfirm: onPublishClick,
      }
    );

  const documentationURL = zendeskURL({
    fr: '4405019727761-Compléter-et-évaluer-un-objectif-pour-un-membre-de-votre-équipe',
    en: '4405019727761-Completing-and-evaluating-a-objective-for-a-team-member',
  });

  const publishedSectionTitle = () => {
    const objectiveCount = publishedItems.length;
    if (!fromReview) return __('Published objectives (%1)', objectiveCount);
    return definesObjectives
      ? __('Published objectives for the upcoming period (%1)', objectiveCount)
      : __('Published objectives for the current period (%1)', objectiveCount);
  };

  const emptyPublishedStateTitle = () => {
    if (!fromReview)
      return __('%1 has no published objective yet', revieweeFullName);
    return definesObjectives
      ? __(
          '%1 has no published objective in the upcoming period',
          revieweeFullName
        )
      : __(
          '%1 has no published objective in the current period',
          revieweeFullName
        );
  };

  const draftSectionTitle = () => {
    if (!fromReview) return __('Draft objectives (%1)', draftItems.length);
    return definesObjectives
      ? __('Draft objectives for the upcoming period (%1)', draftItems.length)
      : __('Draft objectives for the current period (%1)', draftItems.length);
  };

  const emptyDraftStateTitle = () => {
    if (!fromReview) return __('Create an objective for %1', revieweeFullName);
    return definesObjectives
      ? __(
          'Create an objective for %1 in the upcoming period',
          revieweeFullName
        )
      : __(
          'Create an objective for %1 in the current period',
          revieweeFullName
        );
  };

  return (
    <>
      {(publishedItems.length > 0 || draftItems.length > 0) && (
        <>
          <div className="pt-4 mb-2">
            <Text preset="18bs5">{publishedSectionTitle()}</Text>
          </div>

          <div className="mb-4">
            <Text>
              {__(
                'Published objectives are visible to %1 and their direct and indirect managers as well as the reviewers of the related reviews.',
                revieweeFullName
              )}
            </Text>
          </div>

          {publishedItems.length === 0 ? (
            <EmptyStateWithIcon
              iconName="track_changes"
              title={emptyPublishedStateTitle()}
              description={__(
                'Publish the drafts so that %1 can access the objectives',
                revieweeFullName
              )}
              className="mb-4"
            />
          ) : (
            renderItems(publishedItems)
          )}
        </>
      )}

      <div className="flex items-center justify-between mb-1 pt-4">
        <Text preset="18bs5">{draftSectionTitle()}</Text>

        <Button
          testClassName="test-bulk-publish-button"
          color="secondary"
          onClick={() => onConfirmPublishClick()}
          disabled={draftItems.length === 0}
        >
          <Icon name="check" />
          <span className="ml-2">{__('Publish objectives')}</span>
        </Button>
      </div>

      <div className="mb-4">
        <Text>
          {__(
            'Draft objectives are not visible to %1 but are accessible to their direct and indirect managers as well as the reviewers of the related reviews.',
            revieweeFullName
          )}
        </Text>
      </div>

      <div className="mb-4">
        <Text>
          {__(
            'Read %1 to find out more about draft and published objectives and how to complete them.',
            <Link
              additionalClassName="text-[var(--primary)]"
              to={documentationURL}
              openInNewTab
            >
              {__('this article')}
            </Link>
          )}
        </Text>
      </div>

      {draftItems.length === 0 ? (
        <EmptyStateWithIcon
          iconName="track_changes"
          title={emptyDraftStateTitle()}
          description={__(
            'Click on "Add" to create a draft objective, which you can then share with %1',
            revieweeFullName
          )}
          className="mb-4"
        />
      ) : (
        renderItems(draftItems)
      )}
    </>
  );
};

export default PersonalObjectiveSections;
