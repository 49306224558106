import { sortBy } from 'lodash';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { compose } from 'redux';

import type { ObjectivePeriod } from 'models';
import type { AppDispatch } from 'redux/actions';

import invariant from 'helpers/invariant';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Column, Columns, FetchContainer, Testable } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import TeamSideList from 'scenes/objectives/team/components/TeamSideList';

import ObjectivePeriodPicker from './ObjectivePeriodPicker';

type AfterMatchParamsProps = {
  match: {
    objectivePeriodSlug: string;
    teamSlug: string;
  };
};

type AfterConnectProps = AfterMatchParamsProps & {
  isFetching: boolean;
  hasError: boolean;
  objectivePeriod: ObjectivePeriod;
};

const ObjectivesTeam = ({
  isFetching,
  hasError,
  objectivePeriod,
  match,
}: AfterConnectProps) => {
  const { teamSlug, objectivePeriodSlug } = match;

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const { teamObjectivePeriods } = objectivePeriod;

        const sortedTeamObjectivePeriods = sortBy(
          teamObjectivePeriods,
          top => top.team.name
        );

        return (
          <>
            <Testable name="test-objective-period-picker">
              <ObjectivePeriodPicker
                selectedObjectivePeriodSlug={objectivePeriodSlug}
                teamSlug={teamSlug}
              />
            </Testable>
            <Columns>
              <Column className={'is-7'}>
                <Outlet
                  context={{
                    isFetching,
                    hasError,
                    objectivePeriod,
                    sortedTeamObjectivePeriods,
                  }}
                />
              </Column>
              {sortedTeamObjectivePeriods.length > 0 && (
                <Column className={'is-4 is-offset-1'}>
                  <TeamSideList
                    teamObjectivePeriods={sortedTeamObjectivePeriods}
                    objectivePeriod={objectivePeriod}
                    teamSlug={teamSlug}
                  />
                </Column>
              )}
            </Columns>
          </>
        );
      }}
    />
  );
};
export default compose(
  withMatchParams,
  newDataLoader({
    fetch:
      ({ match: { objectivePeriodSlug } }: AfterMatchParamsProps) =>
      async (dispatch: AppDispatch) => {
        invariant(objectivePeriodSlug, 'Objective period should be there.');
        return dispatch(
          get(`organization_objective_periods/${objectivePeriodSlug}`)
        );
      },
    hydrate: {
      objectivePeriod: {
        teamObjectivePeriods: {
          team: {},
          objectives: {
            abilities: {},
          },
        },
      },
    },
    cacheKey: ({ match }: AfterMatchParamsProps) => {
      return JSON.stringify(match.objectivePeriodSlug);
    },
  })
)(ObjectivesTeam) as React.ComponentType<{}>;
