import React from 'react';

import { __, n__ } from 'helpers/i18n';

import {
  FilterBar,
  Level,
  LevelLeft,
  LevelRight,
  SearchBar,
  Text,
} from 'components';

type Props = {
  search?: string;
  onSearchChange: (search: string) => void;
  filters?: Array<{ param: string; label: string }>;
  activeFilter?: string | null;
  onFilterChange?: (filter: string) => void;
  skillCount: number;
};

const Header = ({
  search,
  onSearchChange,
  filters,
  activeFilter,
  onFilterChange,
  skillCount,
}: Props) => {
  return (
    <>
      <Level>
        <LevelLeft>
          <Text size={6}>
            {n__('%1 skill selected', '%1 skills selected', skillCount)}
          </Text>
        </LevelLeft>
        <LevelRight>
          {filters && onFilterChange && (
            <FilterBar
              display="link"
              filters={filters}
              activeFilter={activeFilter}
              onFilterChange={onFilterChange}
            />
          )}
        </LevelRight>
      </Level>
      <SearchBar
        className="mt-3 mb-3"
        value={search}
        placeholder={__('Search for a skill')}
        onChange={onSearchChange}
      />
    </>
  );
};

export default Header;
