import moment from 'moment';
import React, { useState } from 'react';

import type { SortChangeMethod } from 'lib/dataLoader/pagination/types';

import { MessagingChatRoom } from 'models';

import { __ } from 'helpers/i18n';

import { Avatar, SimpleTable, Text } from 'components';

import ChatRoomModal from '../Campaign/components/messaging/ChatRoomModal';

type Props = {
  messagingChatRooms: Array<MessagingChatRoom>;
  onChatRoomUpdate: () => void;
  displayConversationOwner?: boolean;
  displayCampaignTitle?: boolean;
  postAsAnonymous?: boolean;
  sort?: { [key: string]: 'asc' | 'desc' };
  onSortChange: SortChangeMethod;
};

const chatRoomDescription = (
  chatRoom: MessagingChatRoom,
  displayConversationOwner: boolean,
  displayCampaignTitle: boolean
) => (
  <Text preset="14s6">
    <Text preset="16s5.5">
      {displayConversationOwner && (
        <>
          {chatRoom.firstMessage.user.fullName}
          {' • '}
        </>
      )}
      {displayCampaignTitle && (
        <>
          {chatRoom.campaignName}
          {' - '}
        </>
      )}
      {chatRoom.questionName}
    </Text>
    <br />
    <Text>
      {__(
        '%1: %2',
        chatRoom.lastMessage.user.fullName,
        chatRoom.lastMessage.content
      )}
    </Text>
  </Text>
);

const ConversationsList = ({
  messagingChatRooms,
  displayConversationOwner,
  displayCampaignTitle,
  onChatRoomUpdate,
  postAsAnonymous,
  sort,
  onSortChange,
}: Props) => {
  const [selectedChatRoom, setSelectedChatRoom] =
    useState<MessagingChatRoom | null>(null);
  const handleOnClose = (shouldRefresh: boolean) => {
    setSelectedChatRoom(null);
    if (shouldRefresh) {
      onChatRoomUpdate();
    }
  };
  return (
    <>
      <SimpleTable
        columns={[
          {
            header: __('Message'),
            cell: chatRoom => (
              <div className="is-text-overflow-ellipsis">
                <Text
                  onLinkClick={() => setSelectedChatRoom(chatRoom)}
                  testClassName="test-my-conversations-conversation-link"
                >
                  {displayConversationOwner ? (
                    <Avatar
                      url={chatRoom.firstMessage.user.avatarUrl}
                      avatarChildrenClassName="ml-4"
                    >
                      {chatRoomDescription(
                        chatRoom,
                        !!displayConversationOwner,
                        !!displayCampaignTitle
                      )}
                    </Avatar>
                  ) : (
                    chatRoomDescription(
                      chatRoom,
                      !!displayConversationOwner,
                      !!displayCampaignTitle
                    )
                  )}
                </Text>
              </div>
            ),
            withoutTooltipOnEllipsis: true,
          },
          {
            header: __('Last message'),
            cell: chatRoom => (
              <Text preset="13s7" className="text-text-light">
                {moment(chatRoom.lastMessageAt).format('DD MMMM YYYY')}
              </Text>
            ),
            activeSort: sort && sort['lastMessageAt'],
            onSort: () => onSortChange('lastMessageAt'),
            isNarrow: true,
          },
        ]}
        rows={messagingChatRooms}
        keyFn={chatRoom => chatRoom.id}
        className="mt-2 mb-4"
      />
      {selectedChatRoom && (
        <ChatRoomModal
          answer={selectedChatRoom.messagingSource}
          conversationIsAnonymous={!!selectedChatRoom.anonymousParticipantId}
          messagingChatRoom={selectedChatRoom}
          postAsAnonymous={
            postAsAnonymous && !!selectedChatRoom.anonymousParticipantId
          }
          onClose={(_chatRoom, shouldRefresh) => handleOnClose(shouldRefresh)}
        />
      )}
    </>
  );
};

export default ConversationsList;
