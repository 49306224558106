import React from 'react';

import {
  ChoiceScore,
  ChoiceSpecificStats,
  SurveyChoiceQuestion,
} from 'models/surveys';

import { __ } from 'helpers/i18n';

import { Flex, HorizontalBarChart, Text } from 'components';

import QuestionScore from '../../components/QuestionScore';

type Props = {
  stats: ChoiceSpecificStats;
  score: ChoiceScore;
  question: SurveyChoiceQuestion;
};

const ChoiceStats = ({ stats, score, question }: Props) => {
  const title =
    question.questionType === 'single_choice'
      ? __('Single choice answer')
      : __('Multiple choice answer');
  return (
    <div className="grow">
      <Text preset="18bs5">{__(title)}</Text>
      <Flex className="mt-4">
        <QuestionScore score={score} question={question} />
        <div className="ml-16 grow">
          <HorizontalBarChart data={stats} />
        </div>
      </Flex>
    </div>
  );
};

export default ChoiceStats;
