import moment from 'moment';
import * as React from 'react';

import type { Meeting, UserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import transformProps from 'helpers/transformProps';

import { Button, Centered, Helper, Icon, Title } from 'components';

import ScheduleMeetingModal from 'scenes/components/userReview/ScheduleMeetingModal';

type Props = {
  userReview: UserReview;
};

type AfterTransformProps = {
  meeting: Meeting;
  userReviewId: string;
  canUpdateMeeting: boolean;
};

function InPersonMeetingPart({
  meeting,
  userReviewId,
  canUpdateMeeting,
}: AfterTransformProps) {
  const [modalIsActive, setModalActive] = React.useState(false);

  return (
    <>
      <Title className="side-bar-title" size={5} weight="bold">
        {__('In-person meeting')}
      </Title>

      <div style={{ marginBottom: 16 }}>
        {meeting.isScheduled ? (
          <Title size={6} className="has-side-bar-link-color">
            <Icon
              style={{ verticalAlign: 'text-bottom' }}
              name="event_available"
            />{' '}
            {moment(meeting.startsAt).format('LLL')}
          </Title>
        ) : (
          <Helper style={{ marginBottom: 0 }}>
            <p>{__("An in-person meeting hasn't been scheduled yet")}</p>
          </Helper>
        )}
      </div>

      <Centered>
        <Button
          color="secondary"
          onClick={() => setModalActive(!modalIsActive)}
          disabled={!canUpdateMeeting}
          disabledExplanation={__(
            "You don't have the permission to edit this meeting"
          )}
        >
          <Icon
            style={{ marginRight: 5 }}
            name={meeting.isScheduled ? 'event_available' : 'calendar_today'}
          />
          {meeting.isScheduled ? __('Edit meeting') : __('Schedule meeting')}
        </Button>
      </Centered>

      <ScheduleMeetingModal
        isActive={modalIsActive}
        onClose={() => setModalActive(false)}
        meeting={meeting}
        userReviewId={userReviewId}
      />
    </>
  );
}

function propsTransformer({ userReview }: Props) {
  return {
    meeting: userReview.meeting,
    userReviewId: userReview.id,
    canUpdateMeeting: can({
      perform: 'schedule_meeting',
      on: userReview,
    }),
  };
}

export default transformProps(propsTransformer)(
  InPersonMeetingPart
) as React.ComponentType<Props>;
