import React, { useState } from 'react';

import { ParticipantFundingItem } from 'models/FundingItem';

import { handleFormErrors } from 'helpers/api';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { hydrateFromStore } from 'lib/dataLoader';
import { post } from 'redux/actions/api';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import FundingSection from '../../../components/FundingSection';
import getDefaultFundingItems from './getDefaultFundingItems';

type Props = {
  sessionId: string;
  periodSlug: string;
  participantIds: Array<string>;
  onClose: () => void;
  onAfterUpdate: () => Promise<unknown>;
  defaultCurrency: string;
};

const BulkUpdateParticipantFundingItems = ({
  sessionId,
  periodSlug,
  participantIds,
  onClose,
  onAfterUpdate,
  defaultCurrency,
}: Props) => {
  const participants = useAppSelector(state =>
    participantIds.map(participantId =>
      hydrateFromStore(
        state.data,
        { resourceType: 'trainingParticipant', id: participantId },
        {
          trainingParticipant: {
            fundingItems: { fundingSource: {} },
            user: {},
          },
        }
      )
    )
  );

  const [errors, setErrors] = useState({});
  const [fundingItems, setFundingItems] = useState<
    Array<ParticipantFundingItem>
  >(getDefaultFundingItems(participants));

  const dispatch = useAppDispatch();
  const bulkUpdateRequests = () =>
    handleFormErrors(
      async () => {
        await dispatch(
          post(
            `training/sessions/${sessionId}/participants/bulk_update_funding_items`,
            {
              participantIds,
              fundingItemsAttributes: fundingItems
                .filter(item => !item._destroy)
                .map(item => ({
                  ...item,
                  fundingSourceId: item.fundingSource.id,
                })),
            }
          )
        );
        onClose();
        return onAfterUpdate();
      },
      setErrors,
      true
    );

  return (
    <ModalCard isActive isLarge refreshContentOnOpening onClose={onClose}>
      <ModalCardHead>
        <ModalCardTitle>
          {participantIds.length === 1
            ? __('Change the funding for %1', participants[0].user.fullName)
            : n__(
                'Change the funding for %1 participant',
                'Change the funding for %1 participants',
                participantIds.length
              )}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <FundingSection
          periodSlug={periodSlug}
          fundingItems={fundingItems}
          errors={errors}
          onChange={setFundingItems}
          defaultCurrency={defaultCurrency}
        />
      </ModalCardBody>
      <ModalCardFooter>
        <Button onClick={onClose} color="secondary">
          {__('Cancel')}
        </Button>
        <Button onClick={bulkUpdateRequests} color="primary">
          {__('Change funding')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default BulkUpdateParticipantFundingItems;
