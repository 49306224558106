import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginatedCollection,
  TrainingSession,
  TrainingSessionSnapshot,
  User,
} from 'models';

import { __ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';
import {
  pathToTrainingSessionDetails,
  pathToUserTrainingSessions,
} from 'helpers/navigation';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, Can, FetchContainer, Link, SimpleTable, Text } from 'components';

import StatusTag from 'scenes/components/StatusTag';
import TrainingSessionDatesTableCell from 'scenes/components/TrainingSessionDatesTableCell';

import EmptyState from './EmptyState';

type Props = {
  userReviewId: string;
  reviewee: User;
  shouldFetchSnapshots: boolean;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    trainingSessionCollection: PaginatedCollection<
      TrainingSession | TrainingSessionSnapshot
    >;
  };

const SessionTable = ({
  trainingSessionCollection,
  reviewee,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <>
      <Text preset="18bs5" className="block mb-2">
        {__('History of past and upcoming training sessions')}
      </Text>
      {!!trainingSessionCollection &&
      trainingSessionCollection.items.length === 0 ? (
        <EmptyState revieweeFullName={reviewee.fullName} />
      ) : (
        <Box className="block-content-wrapper py-2 px-0">
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => (
              <SimpleTable
                useSmallerYPadding
                rows={trainingSessionCollection?.items || []}
                keyFn={session => session.id}
                rowClassName="test-training-session-row"
                columns={[
                  {
                    header: __('Training name'),
                    cell: session => {
                      const name = session.name || __('Untitled session');

                      return (
                        <div className="test-name">
                          {session.type === 'trainingSessionSnapshot' ? (
                            name
                          ) : (
                            <Can
                              perform={'update'}
                              on={session}
                              alternativeChildren={<Text>{name}</Text>}
                            >
                              <Link
                                to={pathToTrainingSessionDetails(session.id)}
                                openInNewTab
                                withOpenInNewTabIcon={false}
                              >
                                {name}
                              </Link>
                            </Can>
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    header: __('Status'),
                    cell: session => {
                      const status = assertDefined(
                        session.status,
                        'status must be defined'
                      );
                      return (
                        <StatusTag
                          status={
                            ['in_preparation', 'prepared'].includes(status)
                              ? 'to_come'
                              : status
                          }
                        />
                      );
                    },
                  },
                  {
                    header: __('Training dates'),
                    cell: session => (
                      <TrainingSessionDatesTableCell session={session} />
                    ),
                  },
                ]}
                className="mb-2"
              />
            )}
          />
          <Can perform="show_training_requests_and_sessions" on={reviewee}>
            <Link
              className="ml-4 mt-2"
              to={pathToUserTrainingSessions(reviewee.id)}
              openInNewTab
              withOpenInNewTabIcon
            >
              {__("View all %1's trainings", reviewee.firstName)}
            </Link>
          </Can>
        </Box>
      )}
    </>
  );
};

export default newDataLoader({
  fetch: ({ userReviewId, shouldFetchSnapshots }: Props) => {
    const endpoint = `user_reviews/${userReviewId}/${
      shouldFetchSnapshots ? 'training_session_snapshots' : 'training_sessions'
    }`;

    // If you change the ordering or limit, you need to also update it in the app/models/training_request_history_module.rb when snapshots are taken
    return get(endpoint, {
      countPerPage: 5,
      sort: { startDate: 'desc' },
      filter: { validatedNotCanceledNotRescheduled: true },
      forReviewHistoryBlock: true,
    });
  },
  hydrate: {
    trainingSessionCollection: {
      items: {},
    },
  },
  cacheKey: ({ userReviewId }: Props) => userReviewId,
})(SessionTable) as React.ComponentType<Props>;
