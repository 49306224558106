import { useCallback, useMemo } from 'react';

import { ExchangeRate } from 'models/ExchangeRate';

import { nonDestroyed } from 'helpers/hooks/mutation/useRemoteDestroy';
import { toStandardDecimal } from 'helpers/locale';

import { FormBudgetItem } from '../types';

const useBudgetItemSum = (exchangeRates: Array<ExchangeRate>) => {
  /**
   * @example
   * {
   *   'USD': [
   *     { currency: 'EUR', rate: 0.85 },
   *     { currency: 'GBP', rate: 0.75 },
   *   ],
   *   'EUR': [
   *     { currency: 'USD', rate: 1.18 },
   *     { currency: 'GBP', rate: 0.89 },
   *   ],
   *   ...
   * }
   */
  const graph = useMemo(() => {
    const graph: Record<string, Array<{ currency: string; rate: number }>> = {};
    exchangeRates.forEach(
      ({ baseCurrency, targetCurrency, targetToBaseRate }) => {
        if (!graph[targetCurrency]) graph[targetCurrency] = [];
        if (!graph[baseCurrency]) graph[baseCurrency] = [];

        graph[targetCurrency].push({
          currency: baseCurrency,
          rate: parseFloat(toStandardDecimal(targetToBaseRate)),
        });
        graph[baseCurrency].push({
          currency: targetCurrency,
          rate: 1 / parseFloat(toStandardDecimal(targetToBaseRate)),
        });
      }
    );
    return graph;
  }, [exchangeRates]);

  /**
   * Breadth-first search
   * Finds the shortest path between exchange rates to determine the rate value
   */
  const findRate = useMemo(() => {
    const cache: Record<string, Array<{ currency: string; rate: number }>> = {};

    return (fromCurrency: string, toCurrency: string) => {
      // Find from cache
      if (cache[fromCurrency]) {
        const rate = cache[fromCurrency].find(
          ({ currency }) => currency === toCurrency
        );
        if (rate) return rate.rate;
      }

      const queue = [{ currency: fromCurrency, value: 1 }];
      const visited = new Set();

      while (queue.length > 0) {
        const current = queue.shift();
        const { currency, value } = current as NonNullable<typeof current>;

        if (currency === toCurrency) {
          cache[fromCurrency] = cache[fromCurrency] || [];
          cache[fromCurrency].push({ currency: toCurrency, rate: value });
          return value;
        }

        if (visited.has(currency)) continue;
        visited.add(currency);

        for (const { currency: nextCurrency, rate } of graph[currency] || []) {
          if (!visited.has(nextCurrency)) {
            queue.push({ currency: nextCurrency, value: value * rate });
          }
        }
      }

      return 1;
    };
  }, [graph]);

  const sumBudgetItems = useCallback(
    (budgetItems: Array<FormBudgetItem>, currency: string) =>
      budgetItems.filter(nonDestroyed).reduce((acc, item) => {
        const rate = findRate(item.provisionedAmountCurrency, currency);

        const provisionedAmountCents =
          (item.provisionedAmountCents || 0) * rate;

        return acc + provisionedAmountCents;
      }, 0),
    [findRate]
  );

  return sumBudgetItems;
};

export default useBudgetItemSum;
