import React from 'react';
import { compose } from 'redux';

import type { DeprecatedPaginationProps } from 'lib/dataLoader/pagination/types';
import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';

import { UserWithCareerLevelCollection } from 'models/User';
import { SkillsMatrix } from 'models/skills';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import {
  DataLoaderProvidedProps,
  newDataLoader,
  withDeprecatedPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Button,
  Column,
  Columns,
  DatatableWrapper,
  Divider,
  FetchContainer,
  Icon,
  Text,
  Tooltip,
} from 'components';

import EmployeeListItem from 'scenes/skills/Index/MatrixEmployeesList/EmployeeListItem';

import useAssignationEnabled from '../../helpers/userAssignation/useAssignationEnabled';
import useGetAssignationCriterionLabel from '../../helpers/userAssignation/useGetAssignationCriterionLabel';
import AutoAssignationModal from './AutoAssignationModal';

type MatrixWithUsers = {
  matrix: SkillsMatrix;
  userCollection: UserWithCareerLevelCollection;
};
type Props = WithDeprecatedPaginationProps & {
  matrixId: string;
};

type MatchPaginationProps = DeprecatedPaginationProps & {
  matrixId: string;
};

type AfterDataLoaderProps = Props &
  DeprecatedPaginationProps &
  DataLoaderProvidedProps & {
    matrixWithUsers: MatrixWithUsers;
  };

function EmployeesPaginatedList({
  matrixWithUsers,
  ...otherProps
}: AfterDataLoaderProps) {
  const { isFetching, hasError, refetchData } = otherProps;
  const assignationEnabled = useAssignationEnabled();
  const assignationCriterionLabel = useGetAssignationCriterionLabel();

  const [isAutoAssignationModalActive, setIsAutoAssignationModalActive] =
    React.useState(false);

  const assignationColumnContent = () => (
    <Text preset="14s6" color="light">
      {__('Assignation criterion: %1', assignationCriterionLabel)}
      <Tooltip
        content={__(
          'Determines the automatic employees assignation to their skills matrix depending on their profile field: %1',
          <b>{assignationCriterionLabel}</b>
        )}
        triggerClassName="ml-2"
      >
        <Icon name="info" />
      </Tooltip>
    </Text>
  );

  return (
    <div>
      {assignationEnabled && (
        <div className="float-right mt-6 mr-6">
          <Button
            color="secondary"
            onClick={() => setIsAutoAssignationModalActive(true)}
          >
            {__('Set up automatic assignment')}
          </Button>
          {isAutoAssignationModalActive && !!matrixWithUsers?.matrix && (
            <AutoAssignationModal
              onClose={() => setIsAutoAssignationModalActive(false)}
              afterSave={() => {
                setIsAutoAssignationModalActive(false);
                refetchData();
              }}
              matrix={matrixWithUsers.matrix}
            />
          )}
        </div>
      )}
      <Box>
        <DatatableWrapper
          collectionInfo={matrixWithUsers?.userCollection}
          {...otherProps}
          filter={undefined}
          onUserFilterChange={undefined}
          totalCountRenderer={count =>
            n__('%1 associated employee', '%1 associated employees', count || 0)
          }
        >
          <BoxList>
            <BoxListItem>
              <Columns>
                <Column size={assignationEnabled ? 3 : 4}>
                  <Text preset="14bs6" color="light">
                    {__('Employee')}
                  </Text>
                </Column>
                <Column size={assignationEnabled ? 3 : 4}>
                  <Text preset="14bs6" color="light">
                    {__('Manager')}
                  </Text>
                </Column>
                <Column size={assignationEnabled ? 2 : 4}>
                  <Text preset="14bs6" color="light">
                    {__('Level')}
                  </Text>
                </Column>
                {assignationEnabled && (
                  <Column size={4}>{assignationColumnContent()}</Column>
                )}
              </Columns>
            </BoxListItem>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <FetchContainer
              isFetching={isFetching}
              hasError={hasError}
              loadingStyle="overlay"
              render={() => (
                <>
                  {!!matrixWithUsers?.userCollection &&
                    matrixWithUsers.userCollection.items.map(user => (
                      <EmployeeListItem
                        key={user.id}
                        user={user}
                        matrix={matrixWithUsers.matrix}
                        refetchData={refetchData}
                      />
                    ))}
                </>
              )}
            />
          </BoxList>
        </DatatableWrapper>
      </Box>
    </div>
  );
}

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({ matrixId, search, page, countPerPage }: MatchPaginationProps) =>
      get(`/skills/matrices/${matrixId}/associated_users`, {
        search,
        page,
        countPerPage,
      }),
    hydrate: {
      matrixWithUsers: {
        matrix: { levels: {} },
        userCollection: {
          items: { manager: {}, currentCareerLevel: { skillsLevel: {} } },
        },
      },
    },
    cacheKey: ({ matrixId, page, countPerPage, search }) =>
      compositeKey({ matrixId, page, countPerPage, search }),
  })
)(EmployeesPaginatedList);
