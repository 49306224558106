import { AdminRole } from 'models';

import { __ } from 'helpers/i18n';

export const roleDescription = (role: AdminRole['name']): string => {
  switch (role) {
    case 'admin':
      return __(
        'Manages users, products, and administrator rights. Can access all content.'
      );
    case 'compensation_admin':
      return __(
        'Manages campaigns and can see the content of compensation reviews. Manages compensation bands and user compensation.'
      );
    case 'performance_admin':
      return __(
        'Manages campaigns and can see the content of reviews and 360 feedback. Manages skills matrices.'
      );
    case 'campaigns_responsible':
      return __(
        'Manages campaigns without seeing reviews and 360 feedback content.'
      );
    case 'training_admin':
      return __('Manages training requests, plan and catalog.');
    case 'people_review_admin':
      return __('Manages campaigns and can see the content of people reviews.');
    case 'people_review_responsible':
      return __('Manages campaigns without seeing people reviews content.');
    default:
      throw new Error('Unknown role');
  }
};
