import React, { useContext } from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Button,
  DesignSystem,
  FeatureFlagged,
  Icon,
  Tooltip,
} from 'components';

import { DataContext } from '../..';
import ActionModal from './ActionModal';
import LockedField from './LockedField';

type Props = {
  lockedFieldValue: string | null | undefined;
  unlockedField?: React.ReactNode;
  fieldLabel: string;
  fieldSlug: string;
};

export default function SyncedField({
  lockedFieldValue,
  unlockedField,
  fieldSlug,
  fieldLabel,
}: Props) {
  const { user } = useContext(DataContext);

  const organization = useCurrentOrganization();
  const [showActionModal, setShowActionModal] = React.useState(false);

  // Only manager_uniq_identifier is overridable for now.
  const syncedFieldOverrideType =
    fieldSlug === 'manager_uniq_identifier' ? fieldSlug : null;

  const overrideFeatureFlagDisabled = !organization.featureFlags.includes(
    'overrideSyncedFields'
  );
  const nonOverridable = !syncedFieldOverrideType;
  const attributeOverride = user.attributeOverrides.find(
    attributeOverride =>
      attributeOverride.overrideType === syncedFieldOverrideType
  );
  const overriden = !nonOverridable && !!attributeOverride;
  const locked = overrideFeatureFlagDisabled || nonOverridable || !overriden;

  // Texts

  const integrationName = organization.syncIntegrationInfo.name;

  const generateTooltipContent = () => {
    const overridenTooltipContent = __(
      'Disable %1 synchronization for this field',
      integrationName
    );
    const nonOverridableTooltipContent = __(
      'The %1 synchronization cannot be disabled for this field.',
      integrationName
    );
    const unlockedTooltipContent = __(
      'Enable %1 synchronization for this field.',
      integrationName
    );
    const lockedTooltipContent = nonOverridable
      ? nonOverridableTooltipContent
      : overridenTooltipContent;

    return locked ? lockedTooltipContent : unlockedTooltipContent;
  };

  return (
    <>
      <div className="flex">
        <div className="flex-grow">
          {locked ? <LockedField value={lockedFieldValue} /> : unlockedField}
        </div>
        <FeatureFlagged flag="overrideSyncedFields">
          <Tooltip content={generateTooltipContent()}>
            <DesignSystem version={2}>
              <Button
                isRounded
                className="is-right ml-2.5 mt-1"
                disabled={nonOverridable}
                onClick={() => setShowActionModal(true)}
                color="secondary"
                hasIconOnly
                size="small"
              >
                <Icon size="tiny" name={locked ? 'sync' : 'sync_disabled'} />
              </Button>
            </DesignSystem>
          </Tooltip>
        </FeatureFlagged>
      </div>
      {showActionModal && (
        <ActionModal
          fieldLabel={fieldLabel}
          fieldSlug={fieldSlug}
          locked={locked}
          onClose={() => setShowActionModal(false)}
          lastSyncedFormattedValue={attributeOverride?.lastSyncedFormattedValue}
        />
      )}
    </>
  );
}
