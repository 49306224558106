import React from 'react';

import type {
  OneOnOneUserReview,
  TrainingHistoryBlockContent as TrainingHistoryBlockContentType,
  User,
} from 'models';

import can from 'helpers/can';
import { useAppSelector, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { hydrateFromStore } from 'lib/dataLoader';

import BlockDetails from 'scenes/components/review/BlockDetails';
import { default as SimbelTrainingRequestList } from 'scenes/components/simbel/TrainingRequestList';

import ReviewMessages from '../../ReviewMessages';
import PromptTrainingRequestCreationTableFooter from '../DeprecatedTrainingRequestHistoryBlockContent/PromptTrainingRequestCreationTableFooter';
import TrainingRequestHistoryBox from './TrainingRequestHistoryBox';
import TrainingSessionHistoryBox from './TrainingSessionHistoryBox';

type Props = {
  content: TrainingHistoryBlockContentType;
  reviewee: User;
  userReviewId?: string;
  withoutSectionSeparator?: boolean;
  isPreview?: boolean;
};

const TrainingHistoryBlockContent = ({
  content,
  reviewee,
  userReviewId,
  withoutSectionSeparator,
  isPreview = false,
}: Props) => {
  const { title, description, id, messages, richTextEnabled } = useAppSelector(
    state =>
      hydrateFromStore(
        state.data,
        { resourceType: 'formContentBlock', id: content.id },
        {
          block: {
            messages: {},
          },
        }
      )
  );

  const { isSimbelIntegrationActive } = useCurrentOrganization();

  const canCreateTrainingRequest = can({
    perform: 'create_training_request',
    on: reviewee,
  });
  const userReview = useAppSelector(
    state =>
      hydrateFromStore(
        state.data,
        { resourceType: 'userReview', id: userReviewId },
        {}
      ) as OneOnOneUserReview
  );
  const shouldFetchSnapshots = !isPreview && userReview.isReleased;

  return (
    <div className="contents test-training-history-block">
      <BlockDetails
        id={id}
        section={__('Trainings')}
        title={title}
        description={description}
        withoutSectionSeparator={withoutSectionSeparator}
        withRichText={richTextEnabled}
        withReadMore
      />
      <ReviewMessages style={{ marginBottom: 8 }} messages={messages} />
      {isSimbelIntegrationActive ? (
        <SimbelTrainingRequestList
          emptyStateTitle={__(
            'No training requests have been created for %1',
            reviewee.fullName
          )}
          userId={reviewee.id}
          userReviewId={userReviewId}
          footer={
            canCreateTrainingRequest && (
              <PromptTrainingRequestCreationTableFooter reviewee={reviewee} />
            )
          }
          isPreview={isPreview}
        />
      ) : (
        <div className="block-content-wrapper">
          <TrainingSessionHistoryBox
            userReviewId={userReviewId}
            reviewee={reviewee}
            isPreview={isPreview}
            shouldFetchSnapshots={shouldFetchSnapshots}
          />
          <TrainingRequestHistoryBox
            userReviewId={userReviewId}
            reviewee={reviewee}
            isPreview={isPreview}
            shouldFetchSnapshots={shouldFetchSnapshots}
          />
        </div>
      )}
    </div>
  );
};

export default TrainingHistoryBlockContent;
