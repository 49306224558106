import React, { useEffect } from 'react';
import { compose } from 'redux';

import { BaseTrainingCourse, PaginatedCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { pathToTrainingsCatalogCourse } from 'helpers/paths';

import {
  DataLoaderProvidedProps,
  DeprecatedPaginationProps,
  newDataLoader,
} from 'lib/dataLoader';
import withDeprecatedStatePagination from 'lib/dataLoader/pagination/DeprecatedStatePaginationFactory';
import { get } from 'redux/actions/api';

import { EmptyStateWithIcon, InfiniteFetchContainer, Tabs } from 'components';
import GridCardLayout from 'components/layout/GridCardLayout';

import CourseCard from './CourseCard';

type Props = {
  search: string;
  tagLabels: string[];
  selectedTab: string;
  onTabChange: (tab: 'suggestions' | 'trainings') => void;
};

type AfterPaginateProps = Props & DeprecatedPaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    trainingCourseCollection: PaginatedCollection<BaseTrainingCourse>;
  };

const CourseList = ({
  trainingCourseCollection,
  search,
  tagLabels,
  getNextPage,
  isFetching,
  hasError,
  selectedTab,
  onTabChange,
}: AfterDataLoaderProps) => {
  const emptyStateDescription = __(
    'The training catalog is currently empty. In the meantime, you can submit an off-catalog request for a specific training.'
  );

  const tabs = [
    { key: 'suggestions' as const, label: __('Recommended for you') },
    { key: 'trainings' as const, label: __('All training courses') },
  ];

  const [showTabs, setShowTabs] = React.useState<boolean | null>(null);

  // Show suggestions tab if there are suggested training courses
  useEffect(() => {
    if (isFetching || showTabs !== null || selectedTab === 'trainings') return;

    if (trainingCourseCollection?.items?.length === 0) {
      setShowTabs(false);
      onTabChange('trainings');
    } else setShowTabs(true);
  }, [
    trainingCourseCollection?.items?.length,
    isFetching,
    showTabs,
    onTabChange,
    selectedTab,
  ]);

  return (
    <>
      {showTabs && (
        <Tabs
          items={tabs.map(tab => ({
            label: tab.label,
            onClick: () => onTabChange(tab.key),
            isActive: selectedTab === tab.key,
          }))}
          className="mt-2 mb-0"
        />
      )}
      <div className="mt-6">
        <InfiniteFetchContainer
          collection={trainingCourseCollection}
          getNextPage={getNextPage}
          isFetching={isFetching}
          hasError={hasError}
          emptyState={
            search || tagLabels.length > 0 ? (
              <EmptyStateWithIcon
                iconName="search"
                title={__('No trainings match your search.')}
              />
            ) : (
              <EmptyStateWithIcon
                iconName="school"
                title={__('Training catalog')}
                description={emptyStateDescription}
              />
            )
          }
          render={items => (
            <GridCardLayout minCardWidth={260}>
              {items.map(item => (
                <CourseCard
                  key={item.id}
                  name={item.name}
                  costPerParticipantCents={item.costPerParticipantCents}
                  costPerParticipantCurrency={item.costPerParticipantCurrency}
                  durationInHours={item.durationInHours}
                  organism={item.organism}
                  url={pathToTrainingsCatalogCourse(item.id)}
                />
              ))}
            </GridCardLayout>
          )}
        />
      </div>
    </>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedStatePagination({
    resetPageFor: ({ search, selectedTab, tagLabels }: Props) => [
      search,
      selectedTab,
      tagLabels,
    ],
  }),
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      tagLabels,
      selectedTab,
    }: AfterPaginateProps) =>
      get(`training/courses`, {
        page,
        countPerPage,
        search,
        filter: { published: true, suggested: selectedTab === 'suggestions' },
        'tagLabels[]': tagLabels,
      }),
    hydrate: {
      trainingCourseCollection: {
        items: {
          organism: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      tagLabels,
      selectedTab,
    }: AfterPaginateProps) =>
      compositeKey({
        type: 'trainingCourses',
        page,
        countPerPage,
        search,
        tagLabels,
        suggested: selectedTab,
      }),
  })
)(CourseList);
