import React from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader, PageTitle } from 'components';

import PeopleReviewCycleList from './PeopleReviewCycleList';

const PeopleReviewsToComplete = () => (
  <>
    <PageTitle title={__('People reviews to complete')} />
    <PageHeader title={__('People reviews to complete')} />

    <ContentContainer>
      <PeopleReviewCycleList />
    </ContentContainer>
  </>
);

export default PeopleReviewsToComplete;
