import { kebabCase } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';

import {
  Question as QuestionType,
  SurveyQuestion,
  SurveyQuestionStats,
  UserFilterSegment,
} from 'models';

import classNames from 'helpers/classNames';
import compositeKey from 'helpers/compositeKey';
import { useCurrentOrganization } from 'helpers/hooks';
import { n__ } from 'helpers/i18n';
import { pathToSurveyCampaignResults } from 'helpers/paths';
import withLoadingOnViewportVisibility from 'helpers/withLoadingOnViewportVisibility';

import { newDataLoader } from 'lib/dataLoader';
import withDeprecatedStatePagination from 'lib/dataLoader/pagination/DeprecatedStatePaginationFactory';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxVerticalSeparator,
  Icon,
  Link,
  Loading,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import QuestionScore from '../../components/QuestionScore';
import { getStepContentStyles } from '../../helpers/steps';

type Props = {
  campaignId: string;
  periodId: string | null | undefined;
  questionNumber: number;
  question: SurveyQuestion;
  userFilters: ActiveFilters | '';
  propToResetLoadingOnChange: ActiveFilters | '';
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    questionStats?: SurveyQuestionStats;
  };

const Question = ({
  campaignId,
  periodId,
  questionNumber,
  isFetching,
  question,
  questionStats,
}: AfterDataLoaderProps) => {
  const organization = useCurrentOrganization();

  const { questionType } = question;
  const { iconName } = getStepContentStyles()[questionType];

  const typeClass = `survey-box-question--${kebabCase(questionType)}`;
  const displayMockData = isFetching || !questionStats;

  return (
    <Link
      to={pathToSurveyCampaignResults(campaignId, question.id, periodId)}
      additionalClassName="no-underline"
    >
      <Box
        className={classNames('survey-box-question', typeClass, 'flex mb-4')}
        testClassName="test-survey-question-box"
      >
        <div className="flex flex-grow items-center">
          <Icon
            name={iconName}
            className="survey-box-question-icon text-xl shrink-0"
          />
          <div className="flex-grow">
            <Text preset="16bs5.5">
              {questionNumber} - {question.title}
            </Text>
            <div className="text-text-light">
              <>
                {n__(
                  '%1 answer',
                  '%1 answers',
                  displayMockData ? 0 : questionStats.answersCount
                )}
                {questionStats && questionStats.commentsCount !== null && (
                  <>
                    {' · '}
                    {n__(
                      '%1 comment',
                      '%1 comments',
                      displayMockData ? 0 : questionStats.commentsCount
                    )}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
        {(questionType !== 'text' ||
          (organization.aiFeaturesEnabled &&
            question.textSentimentEnabled)) && (
          <>
            <BoxVerticalSeparator />

            {displayMockData && (
              <Loading containerClassName="mr-8 inline-flex items-center justify-center min-w-40" />
            )}
            {!displayMockData && (
              <QuestionScore
                question={question}
                score={questionStats.score}
                sentimentCategory={questionStats.sentimentCategory}
                className="mr-8 inline-flex items-center justify-center min-w-40"
              />
            )}
          </>
        )}
      </Box>
    </Link>
  );
};

const MockRenderer = (originalProps: Props) => {
  return (
    <Question
      {...originalProps}
      isFetching
      hasError={false}
      noContent
      refetchData={() => Promise.resolve()}
    />
  );
};

export default compose<React.ComponentType<Props>>(
  withLoadingOnViewportVisibility<Props>({
    delay: 500,
    mockClass: MockRenderer,
  }),
  withDeprecatedStatePagination<{
    segment: UserFilterSegment;
    campaignId: string;
    question: QuestionType;
  }>({
    resetPageFor: ({ segment, campaignId, question }) => [
      segment,
      campaignId,
      question.id,
    ],
  }),
  newDataLoader({
    fetch: ({
      campaignId,
      periodId,
      question,
      userFilters,
    }: AfterDataLoaderProps) =>
      get(`survey/campaigns/${campaignId}/questions/${question.id}/stats`, {
        userFilters,
        periodId,
      }),
    hydrate: {
      questionStats: {
        question: {},
      },
    },
    cacheKey: ({
      userFilters,
      campaignId,
      periodId,
      question,
    }: AfterDataLoaderProps) =>
      compositeKey({
        userFilters,
        campaignId,
        periodId,
        questionId: question.id,
      }),
  })
)(Question);
