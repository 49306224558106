import React from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

import EmptyState from './EmptyState';

type Props = {
  revieweeFullName: string;
};

const PreviewState = ({ revieweeFullName }: Props) => {
  return (
    <>
      <Text preset="18bs5">{__('History of training requests')}</Text>
      <EmptyState revieweeFullName={revieweeFullName} />
    </>
  );
};

export default PreviewState;
