import React from 'react';
import { Navigate, Route, useParams } from 'react-router-dom';

import { pathToTemplatePreview } from 'helpers/paths';

const OldTemplatePreview = () => {
  const { id } = useParams();
  if (!id) return null;

  return (
    <Route element={<Navigate to={pathToTemplatePreview(id, 'reviewee')} />} />
  );
};

export default OldTemplatePreview;
