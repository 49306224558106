import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BudgetDetails from './Details';
import BudgetEdit from './Edit';

const BudgetRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<BudgetDetails />} />
      <Route path="edit" element={<BudgetEdit />} />
    </Routes>
  );
};

export default BudgetRoutes;
