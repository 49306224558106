import { omit, pick } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { DeprecatedPaginationProps } from 'lib/dataLoader/pagination/types';
import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';
import type { ReviewCycleCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, date, n__ } from 'helpers/i18n/index';
import { pathToReviewCycle, pathToReviewCycleEdit } from 'helpers/paths';

import { withDeprecatedPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  FetchContainer,
  Link,
  SimpleTable,
  Tag,
  Testable,
  Text,
} from 'components';

import StatusTag from 'scenes/components/StatusTag';
import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState/index';

type Props = WithDeprecatedPaginationProps & {
  cycleType: string | null;
  tagLabels: string[];
};

type AfterPaginateProps = Props & DeprecatedPaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  reviewCycleCollection: ReviewCycleCollection;
  isFetching: boolean;
  hasError: boolean;
};

const ReviewCycleListWithPagination = ({
  search,
  sort,
  page,
  filter,
  countPerPage,
  reviewCycleCollection,
  isFetching,
  hasError,
  onSearchChange,
  onSortChange,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
}: AfterConnectProps) => {
  const reviewCycles = reviewCycleCollection
    ? reviewCycleCollection.reviewCycles
    : [];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          reviewCycleCollection
            ? omit(reviewCycleCollection, 'reviewCycles')
            : null
        }
        totalCountRenderer={(totalCount: number | undefined | null) =>
          n__('%1 review campaign', '%1 review campaigns', totalCount || 0)
        }
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        filters={[
          { param: 'ongoing', label: __('Active or draft') },
          { param: 'archived', label: __('Archived') },
          { param: 'all', label: __('ReviewCycle|All') },
        ]}
        filter={filter}
        searchPlaceholder={__('Search review campaigns')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <ReviewEmptyState
            title={__('No review campaign matches your search')}
            inBoxList
          />
        )}
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              columns={[
                {
                  header: __('Name'),
                  activeSort: sort && sort['name'],
                  onSort: () => onSortChange && onSortChange('name'),
                  cell: reviewCycle => {
                    const linkTo = ['draft', 'failed'].includes(
                      reviewCycle.status
                    )
                      ? pathToReviewCycleEdit(reviewCycle.id)
                      : pathToReviewCycle(reviewCycle.id);

                    return (
                      <Testable name="test-review-cycle-link">
                        <Link to={linkTo}>{reviewCycle.name}</Link>
                      </Testable>
                    );
                  },
                },
                {
                  header: __('Status'),
                  cell: reviewCycle => (
                    <StatusTag status={reviewCycle.status} />
                  ),
                },
                {
                  header: __('Tags'),
                  cell: reviewCycle => (
                    <>
                      {reviewCycle.tags.map(tag => (
                        <Tag key={tag.id} className="my-1 mr-1 max-w-full">
                          <span className="is-text-overflow-ellipsis">
                            {tag.label}
                          </span>
                        </Tag>
                      ))}
                    </>
                  ),
                },
                {
                  header: __('Date'),
                  activeSort: sort && sort['date'],
                  onSort: () => onSortChange && onSortChange('date'),
                  cell: reviewCycle =>
                    ['draft', 'failed'].includes(reviewCycle.status) ? (
                      <>
                        <Text color="info">
                          {__('ReviewCycle|Created on:')}
                        </Text>{' '}
                        {date(reviewCycle.createdAt)}
                      </>
                    ) : (
                      <>
                        <Text color="info">
                          {__('ReviewCycle|Launched on:')}
                        </Text>{' '}
                        {date(reviewCycle.launchedAt)}
                      </>
                    ),
                  isNarrow: true,
                },
              ]}
              rows={reviewCycles}
              keyFn={reviewCycle => reviewCycle.id}
              className="mb-4"
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      sort,
      filter,
      tagLabels,
      cycleType,
    }: AfterPaginateProps) => {
      // Avoid sending request when cycleType not available
      if (!cycleType) {
        return Promise.resolve;
      }

      return get('review_cycles', {
        page,
        countPerPage,
        search,
        sort,
        filter: cycleType
          ? {
              ...pick(filter, ['ongoing', 'archived', 'all']),
              [cycleType]: true,
            }
          : filter,
        'tagLabels[]': tagLabels,
      });
    },
    hydrate: {
      reviewCycleCollection: {
        reviewCycles: { tags: {} },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      sort,
      filter,
      cycleType,
      tagLabels,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        sort,
        filter,
        cycleType,
        tagLabels,
      }),
  })
)(ReviewCycleListWithPagination);
