import React, { useContext, useEffect, useState } from 'react';

import type { TrainingPlanBudgetStats } from 'models';
import type { ComponentType } from 'react';

import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { useCurrentOrganization } from 'helpers/hooks';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, FetchContainer } from 'components';

import { DataContext } from '../..';
import BudgetDetailsModal from './BudgetDetailsModal';
import BudgetManagementModal from './BudgetManagementModal';
import BudgetStatsCard from './BudgetStatsCard';
import CreationCard from './CreationCard';

type Props = {
  periodSlug: string;
  isValidatedPlan: boolean;
  setMissingCurrencies: (missingCurrencies: Array<string>) => void;
  budgetManagementModalActive: boolean;
  setBudgetManagementModalActive: (active: boolean) => void;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    stats: TrainingPlanBudgetStats;
  };

const BudgetCard = ({
  periodSlug,
  stats,
  isFetching,
  hasError,
  isValidatedPlan,
  refetchData,
  setMissingCurrencies,
  budgetManagementModalActive,
  setBudgetManagementModalActive,
}: AfterConnectProps) => {
  const { shouldRefetchStats, setShouldRefetchStats } = useContext(DataContext);
  const organization = useCurrentOrganization();

  const [detailsModalActive, setDetailsModalActive] = useState(false);

  useEffect(() => {
    if (shouldRefetchStats) {
      refetchData();
      setShouldRefetchStats(false);
    }
  }, [shouldRefetchStats, setShouldRefetchStats, refetchData]);

  useEffect(() => {
    if (stats?.missingCurrencies) {
      setMissingCurrencies(stats.missingCurrencies);
    }
  }, [stats?.missingCurrencies, setMissingCurrencies]);

  const canManageBudget = can({
    perform: 'manage_budget',
    on: organization,
  });

  const undefinedBudget = stats?.budgetItemCount === 0;
  const canCreateBudget = undefinedBudget && canManageBudget;

  return (
    <>
      {(!undefinedBudget || canManageBudget) && (
        <Box className="px-5 py-3 w-[50%] max-w-[460px] mb-0">
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => {
              if (!stats) return null;

              if (canCreateBudget) {
                return (
                  <CreationCard
                    onClick={() => setBudgetManagementModalActive(true)}
                    periodSlug={periodSlug}
                  />
                );
              }

              return (
                <BudgetStatsCard
                  stats={stats}
                  isValidatedPlan={isValidatedPlan}
                  periodSlug={periodSlug}
                  openDetailsModal={() => setDetailsModalActive(true)}
                />
              );
            }}
          />
        </Box>
      )}
      {budgetManagementModalActive && (
        <BudgetManagementModal
          periodSlug={periodSlug}
          onClose={() => {
            setBudgetManagementModalActive(false);
            setShouldRefetchStats(true);
          }}
        />
      )}
      {detailsModalActive && (
        <BudgetDetailsModal
          key={
            'budget-details-modal' +
            periodSlug +
            `${isValidatedPlan ? 'validated' : 'forecast'}`
          }
          canManageBudget={canManageBudget}
          openBudgetManagementModal={() => setBudgetManagementModalActive(true)}
          isValidatedPlan={isValidatedPlan}
          stats={stats}
          periodSlug={periodSlug}
          onClose={() => {
            setDetailsModalActive(false);
            setShouldRefetchStats(true);
          }}
        />
      )}
    </>
  );
};

export default newDataLoader({
  fetch: ({ periodSlug, isValidatedPlan }: Props) =>
    get(
      `training/periods/${periodSlug}/plans/${
        isValidatedPlan ? 'validated' : 'forecast'
      }/budget_stats`
    ),
  hydrate: {
    stats: {},
  },
  cacheKey: ({ periodSlug, isValidatedPlan }: Props) =>
    compositeKey({
      periodSlug,
      viewId: 'trainingPlansBudgetStats',
      plan: isValidatedPlan ? 'validated' : 'forecast',
    }),
})(BudgetCard) as ComponentType<Props>;
