import React from 'react';
import { compose } from 'redux';

import { PeopleReviewDimensionShow } from 'models';

import { __ } from 'helpers/i18n';
import { pathToPeopleReviewDimensions } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  BlueInfoBox,
  Box,
  FetchContainer,
  PageHeader,
  PageTitle,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import DimensionForm from './Form';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type Props = AfterMatchParamsProps;

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    peopleReviewDimension: PeopleReviewDimensionShow;
  };

const Dimension = ({
  peopleReviewDimension,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const { usedInCycle, label } = peopleReviewDimension;
        return (
          <>
            <PageHeader
              title={label}
              withBackButton
              backButtonProps={{
                children: __('Back to dimensions'),
                target: pathToPeopleReviewDimensions(),
              }}
            />
            <PageTitle title={label} />
            <div className="m-4 md:m-8">
              <Box>
                {usedInCycle && (
                  <BlueInfoBox
                    text={__(
                      'These changes will be taken into account <b>in future</b> People Review campaigns. Current and completed campaigns will not be affected.'
                    )}
                    className="mb-4"
                  />
                )}
                <DimensionForm peopleReviewDimension={peopleReviewDimension} />
              </Box>
            </div>
          </>
        );
      }}
    />
  );
};

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: Props) => get(`people_review_dimensions/${match.id}`),
    hydrate: {
      peopleReviewDimension: { peopleReviewRatingOptions: {} },
    },
  })
)(Dimension);
