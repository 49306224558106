import { last } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useArrayMutation } from 'helpers/hooks';

import { BudgetFormContext } from '../../Form';
import { FormBudgetEnvelope } from '../../types';
import { generateNewEnvelope } from './generateNewEnvelope';

type Props = {
  budgetByEnvelope: boolean;
  budgetEnvelopes: Array<FormBudgetEnvelope>;
  onChange: (envelopes: Array<FormBudgetEnvelope>) => void;
};

/**
 * When switching from split by envelopes to single budget:
 * - Envelopes data are kept, but only their items are displayed
 * - A dummy envelope is created to welcome new budget items
 *
 * When switching from single budget to split by envelopes:
 * - The dummy envelope is removed is it contains no items
 */
const useDummyEnvelope = ({
  budgetByEnvelope,
  budgetEnvelopes,
  onChange,
}: Props) => {
  const { trainingPeriod } = useContext(BudgetFormContext);

  // Dummy is pre-created by the form init, to avoid triggerring a "user change"
  // that would make the form dirty immediately
  const dummyInitialState = () =>
    budgetByEnvelope ? null : last(budgetEnvelopes) || null;

  const [dummyEnvelope, setDummyEnvelope] = useState<FormBudgetEnvelope | null>(
    dummyInitialState
  );

  const { createItem, deleteItem } = useArrayMutation(
    budgetEnvelopes,
    onChange
  );

  const dummyIndex = budgetEnvelopes.findIndex(
    envelope => envelope.name === dummyEnvelope?.name
  );

  const action = useMemo(() => {
    if (budgetByEnvelope) {
      if (dummyEnvelope) {
        return dummyIndex !== -1 &&
          budgetEnvelopes[dummyIndex].budgetItems.length === 0
          ? 'deleteDummy'
          : 'leaveDummy';
      }
    } else {
      return dummyEnvelope ? null : 'createDummy';
    }
    return null;
  }, [budgetByEnvelope, dummyEnvelope, dummyIndex, budgetEnvelopes]);

  useEffect(() => {
    if (action === 'createDummy') {
      const newDummyEnvelope = generateNewEnvelope(
        trainingPeriod,
        budgetEnvelopes
      );
      setDummyEnvelope(newDummyEnvelope);
      createItem(newDummyEnvelope)();
    } else if (action === 'deleteDummy') {
      deleteItem(dummyIndex)();
      setDummyEnvelope(null);
    } else if (action === 'leaveDummy') {
      setDummyEnvelope(null);
    }
  }, [
    action,
    budgetEnvelopes,
    createItem,
    deleteItem,
    dummyIndex,
    trainingPeriod,
  ]);

  const isStateReady = !action;

  return isStateReady;
};

export default useDummyEnvelope;
