import React from 'react';
import { Navigate } from 'react-router-dom';
import { compose } from 'redux';

import type { ObjectivePeriod } from 'models';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  Can,
  EmptyStateWithIcon,
  FetchContainer,
  Testable,
  Toggleable,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import CreationModal from '../components/CreateOrganizationPeriodModal';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterConnectProps = {
  currentObjectivePeriod: ObjectivePeriod;
  createObjectivePeriod: (a: string) => Promise<void>;
  notifySuccess: (message: string) => void;
  notifyError: (message: string) => void;
  isFetching: boolean;
  hasError: boolean;
} & AfterMatchParamsProps;

function OrganizationPeriodCreator({
  currentObjectivePeriod,
  isFetching,
  hasError,
}: AfterConnectProps) {
  if (currentObjectivePeriod) {
    return (
      <Navigate
        to={`${currentObjectivePeriod.slug}`}
        state={{ objectivePeriod: currentObjectivePeriod }}
        replace
      />
    );
  }

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <Can
          perform="manage_team_objectives"
          on="organization"
          alternativeChildren={
            <EmptyStateWithIcon
              title=""
              iconName="track_changes"
              description={__(
                "Team objectives haven't yet been activated by your organization."
              )}
            />
          }
        >
          <Toggleable
            render={(isModalVisible, toggleModalVisibility) => (
              <>
                <Testable name="test-emptystate-objective-period-creation">
                  <Button color="primary" onClick={toggleModalVisibility}>
                    {__('Create a period')}
                  </Button>
                </Testable>
                <EmptyStateWithIcon
                  title=""
                  iconName="track_changes"
                  className="mt-4"
                  description={__(
                    'Create your first organization period to start using team objectives.'
                  )}
                />

                {isModalVisible && (
                  <CreationModal onClose={toggleModalVisibility} />
                )}
              </>
            )}
          />
        </Can>
      )}
    />
  );
}

export default compose<React.ComponentType<{}>>(
  withMatchParams,
  newDataLoader({
    fetch: () => get('organization_objective_periods/current'),
    hydrate: {
      currentObjectivePeriod: {},
    },
  })
)(OrganizationPeriodCreator);
