import type {
  Evaluation,
  ThreeSixtyUserReview,
  User,
  UserReview,
} from 'models';

export const getPendingEvaluations = (
  evaluations: Array<Evaluation>
): Array<Evaluation> =>
  evaluations.filter(evaluation => evaluation.status !== 'shared');

export const evaluationSharedOrSigned = (evaluation: Evaluation) =>
  ['shared', 'signed'].includes(evaluation.status);

export const getSelectedPeers = (evaluations: Array<Evaluation>): Array<User> =>
  evaluations
    .filter(evaluation => evaluation.reviewerRole !== 'reviewee')
    .map(evaluation => evaluation.reviewer);

export const getRevieweeEvaluation = (userReview: UserReview) => {
  let evaluations;
  if (userReview.evaluations) {
    evaluations = userReview.evaluations;
  } else {
    return;
  }
  return evaluations.find(evaluation => evaluation.reviewerRole === 'reviewee');
};

export const getPeersEvaluationsNotShared = (
  userReview: ThreeSixtyUserReview
): Array<Evaluation> | null | undefined => {
  let evaluations;
  if (userReview.evaluations) {
    evaluations = userReview.evaluations;
  } else {
    return null;
  }
  return evaluations.filter(
    evaluation =>
      evaluation.reviewerRole !== 'reviewee' && evaluation.status !== 'shared'
  );
};
