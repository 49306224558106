import React from 'react';
import { Navigate, Route, useParams } from 'react-router-dom';

import type { Organization, User } from '../../models';

import { useActiveUser, useCurrentOrganization } from 'helpers/hooks';

type RouteAliasProps = {
  from: string;
  to: string;
};

export function RouteAlias({ from, to }: RouteAliasProps) {
  return <Route path={from} element={<Navigate to={to} />} />;
}

type OnlyLoggedOutRouteProps = {
  element: React.ComponentType<any>;
  path: string;
  activeUser: User | null;
  organization: Organization | null;
};

export const OnlyLoggedOutRoute = ({
  element: Component,
  ...rest
}: OnlyLoggedOutRouteProps) => {
  const activeUser = useActiveUser();
  const organization = useCurrentOrganization();

  return (
    <Route
      {...rest}
      element={props =>
        !(activeUser && organization) ? (
          <Component {...props} />
        ) : (
          <Navigate to="/" />
        )
      }
    />
  );
};

export const NavigateFromParams = ({
  getPath,
}: {
  getPath: (params) => string;
}) => {
  const params = useParams<string>();
  if (!params) return null;

  const to = getPath(params);
  if (!to) return null;

  return <Navigate to={to} replace />;
};
