import React from 'react';
import { Route, Routes } from 'react-router-dom';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';

import Course from './Course';
import CreateCourseForm from './Course/CreateCourseForm';
import Courses from './Courses';
import Organism from './Organism';
import CreateOrganismForm from './Organism/CreateOrganismForm';
import Organisms from './Organisms';
import Suggestions from './Suggestions/Routes';

const CatalogManagementRoutes = () => {
  const organization = useCurrentOrganization();
  const canShowEnabledSugestionsTab = can({
    perform: 'show_enabled_training_suggestions_management_tab',
    on: organization,
  });

  return (
    <Routes>
      <Route index path="courses" element={<Courses />} />
      <Route path="courses/new" element={<CreateCourseForm />} />
      <Route path="courses/:id" element={<Course />} />

      <Route path="organisms" element={<Organisms />} />
      <Route path="organisms/new" element={<CreateOrganismForm />} />
      <Route path="organisms/:id" element={<Organism />} />

      {canShowEnabledSugestionsTab && (
        <Route path="suggestions/*" element={<Suggestions />} />
      )}
    </Routes>
  );
};

export default CatalogManagementRoutes;
