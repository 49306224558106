import React, { Dispatch, SetStateAction } from 'react';

import { __ } from 'helpers/i18n';

import { Field, Icon, Input, Link, Text, Tooltip } from 'components';

type RatingOptionsInput = {
  label: string;
  value: string;
};

type Props = {
  ratingOptions: RatingOptionsInput[];
  setRatingOptions: Dispatch<SetStateAction<RatingOptionsInput[]>>;
  usedInCycle: boolean;
  setIsDirty: (isDirty: boolean) => void;
};

const MAX_RATING_OPTIONS = 5;
const MIN_RATING_OPTIONS = 2;

const RatingOptionInputs = ({
  ratingOptions,
  setRatingOptions,
  usedInCycle,
  setIsDirty,
}: Props) => {
  const addRatingOption = (): void => {
    setRatingOptions(
      prevOptions =>
        [
          ...prevOptions.map(option => ({
            ...option,
            value: (parseInt(option.value) + 1).toString(),
          })),
          {
            label: '',
            value: '1',
          },
        ] as RatingOptionsInput[]
    );
    setIsDirty(true);
  };

  const removeRatingOption = (indexToRemove: number): void => {
    setRatingOptions(prevOptions => {
      const filteredOptions = prevOptions.filter(
        (_option, i) => i !== indexToRemove
      );
      const updatedOptions = filteredOptions.map((option, i) => {
        if (i < indexToRemove) {
          return {
            ...option,
            value: (parseInt(option.value) - 1).toString(),
          };
        }
        return option;
      });
      return updatedOptions as RatingOptionsInput[];
    });
    setIsDirty(true);
  };

  const handleInputChange = (index, event) => {
    const newRatingOptions = ratingOptions.map((option, i) =>
      i === index ? { ...option, label: event } : option
    );
    setRatingOptions(newRatingOptions);
    setIsDirty(true);
  };

  const optionInteractivityClassName = enabled =>
    enabled ? 'cursor-pointer' : 'cursor-not-allowed hover:text-text-light';

  const canAddOptions =
    !usedInCycle && ratingOptions.length < MAX_RATING_OPTIONS;
  const canRemoveOptions =
    !usedInCycle && ratingOptions.length > MIN_RATING_OPTIONS;

  return (
    <>
      <div className={`${usedInCycle ? 'text-text-light' : ''}`}>
        {ratingOptions.map((option, index) => (
          <div key={index} className="flex items-center">
            <Text className={`mb-4 mr-2 w-20`}>
              {__('Value: %1', `${option.value}/${ratingOptions.length}`)}
            </Text>
            <Field className="flex-auto">
              <Tooltip content={option.label} enabled={usedInCycle}>
                <Input
                  key={index}
                  onChange={value => handleInputChange(index, value)}
                  value={option.label}
                  disabled={usedInCycle}
                />
              </Tooltip>
            </Field>
            <div className="mb-4">
              {!usedInCycle && (
                <Tooltip
                  content={__(
                    'A dimension cannot have less than %1 rating options.',
                    MIN_RATING_OPTIONS
                  )}
                  enabled={ratingOptions.length <= MIN_RATING_OPTIONS}
                >
                  <Link
                    disabled={!canRemoveOptions}
                    additionalClassName={`ml-2 ${optionInteractivityClassName(
                      canRemoveOptions
                    )}`}
                    onClick={() => removeRatingOption(index)}
                  >
                    <Icon name="delete" />
                  </Link>
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </div>
      {!usedInCycle && (
        <Tooltip
          content={__(
            'A dimension cannot have more than %1 rating options.',
            MAX_RATING_OPTIONS
          )}
          enabled={ratingOptions.length >= MAX_RATING_OPTIONS}
        >
          <Link
            additionalClassName={`text-text-light ${optionInteractivityClassName(
              canAddOptions
            )}`}
            disabled={!canAddOptions}
            onClick={addRatingOption}
          >
            {__('Add a rating option')}
          </Link>
        </Tooltip>
      )}
    </>
  );
};

export default RatingOptionInputs;
