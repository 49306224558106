import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { TrainingPeriodBudget } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { nonDestroyed } from 'helpers/hooks/mutation/useRemoteDestroy';
import { useForm } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';
import { pathToTrainingBudget } from 'helpers/paths';

import { put } from 'redux/actions/api';

import { Box, Button, UnsavedModificationsGuard } from 'components';

import Breakdown from './Breakdown';
import BudgetSummary from './BudgetSummary';
import ExchangeRates from './ExchangeRates';
import FundingSourcesBlock from './FundingSources';
import { formatFormToParams, formatPeriodToForm } from './helpers/formatForm';
import useAutoExchangeRate from './helpers/useAutoExchangeRate';
import useBudgetItemSum from './helpers/useBudgetItemSum';
import { BudgetForm } from './types';

export const BudgetFormContext = createContext(
  {} as {
    trainingPeriod: TrainingPeriodBudget;
    sumBudgetItems: ReturnType<typeof useBudgetItemSum>;
  }
);

type Props = {
  trainingPeriod: TrainingPeriodBudget;
};

const Form = ({ trainingPeriod }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    values: form,
    handleInputChange,
    handleSubmit: confirmChanges,
    isDirty,
  } = useForm<BudgetForm>(
    () => formatPeriodToForm(trainingPeriod),
    async form => {
      await dispatch(
        put(`training/periods/${trainingPeriod.slug}/budget`, {
          trainingPeriod: formatFormToParams(form),
        })
      );

      navigate(pathToTrainingBudget(trainingPeriod.slug));
    }
  );

  const updateAttribute =
    <Key extends keyof BudgetForm>(name: Key) =>
    (value: BudgetForm[Key]) =>
      handleInputChange({ value, name });

  const sumBudgetItems = useBudgetItemSum(
    form.exchangeRates.filter(nonDestroyed)
  );

  const discardChanges = () => {
    navigate(pathToTrainingBudget(trainingPeriod.slug));
  };

  const { usedCurrencies, definedCurrencies } = useAutoExchangeRate(
    trainingPeriod.mainCurrency,
    form,
    updateAttribute('exchangeRates')
  );

  const canUpdateFullBudget = can({
    perform: 'update_full_budget',
    on: trainingPeriod,
  });

  return (
    <BudgetFormContext.Provider
      value={{
        trainingPeriod,
        sumBudgetItems,
      }}
    >
      {canUpdateFullBudget && (
        <Box>
          <Breakdown
            byEnvelope={form.budgetByEnvelope}
            onChange={updateAttribute('budgetByEnvelope')}
          />
        </Box>
      )}
      <Box>
        <FundingSourcesBlock
          budgetByEnvelope={form.budgetByEnvelope}
          budgetEnvelopes={form.budgetEnvelopes}
          onChange={updateAttribute('budgetEnvelopes')}
          canUpdateFullBudget={canUpdateFullBudget}
        />
      </Box>
      {canUpdateFullBudget && (
        <Box>
          <ExchangeRates
            exchangeRates={form.exchangeRates}
            onChange={updateAttribute('exchangeRates')}
            usedCurrencies={usedCurrencies}
            definedCurrencies={definedCurrencies}
          />
        </Box>
      )}
      <Box>
        <BudgetSummary
          budgetItems={form.budgetEnvelopes.flatMap(
            envelope => envelope.budgetItems
          )}
        />
      </Box>
      <div className="flex gap-4 justify-center">
        <Button onClick={discardChanges} color="secondary">
          {__('Cancel')}
        </Button>
        <Button onClick={confirmChanges} disabled={!isDirty} color="primary">
          {__('Save changes')}
        </Button>
      </div>
      <UnsavedModificationsGuard isDirty={isDirty} onSubmit={confirmChanges} />
    </BudgetFormContext.Provider>
  );
};

export default Form;
