import React from 'react';
import { compose } from 'redux';

import type { SurveyCampaign } from 'models';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { withMatchParams } from 'components/HOCs/withMatchParams';

import SurveyContent from './SurveyContent';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    campaign: SurveyCampaign;
  };

const Survey = ({ isFetching, hasError, campaign }: AfterDataLoaderProps) => {
  return (
    <SurveyContent
      isFetching={isFetching}
      hasError={hasError}
      campaign={campaign}
    />
  );
};

export default compose<React.ComponentType<{}>>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`survey/campaigns/${match.id}/my_ongoing_survey`),
    hydrate: {
      campaign: {
        currentPeriod: {
          currentUserParticipation: { answers: { choices: {} } },
          form: { steps: { question: {}, instruction: {} } },
        },
      },
    },
  })
)(Survey);
