import { DeleteItemFunction } from './useArrayMutation';
import { UpdateAttributeFunction } from './useObjectMutation';

type ObjectState = Record<string, unknown> & {
  id: string;
  _destroy?: boolean;
};

/**
 * Hook for destroyable nested_attributes
 * If record is persisted, it sets `_destroyed` to true
 * Otherwise, record is asked to be deleted from the array
 *
 * @example
 * const { record, onChange, onDelete } = props;
 *
 * const updateAttribute = useObjectMutation(record, onChange);
 * const { destroyItem, isSoftDestroyed } = useRemoteDestroy(
 *   record,
 *   updateAttribute,
 *   onDelete
 * );
 */
const useRemoteDestroy = <TObjectState extends ObjectState>(
  obj: TObjectState,
  updateAttribute: UpdateAttributeFunction<TObjectState>,
  deleteItem: ReturnType<DeleteItemFunction>
) => {
  const destroyItem = () => {
    if (obj.id) {
      updateAttribute('_destroy')(true);
    } else {
      deleteItem();
    }
  };

  const isSoftDestroyed = obj._destroy;

  return {
    destroyItem,
    isSoftDestroyed,
  };
};

/**
 * @example
 * items.filter(nonDestroyed).map();
 */
export const nonDestroyed = (item: { _destroy?: boolean }) => !item._destroy;

export default useRemoteDestroy;
