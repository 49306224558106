import React, { CSSProperties } from 'react';

import type { EvaluationStatus, ReviewerTag, User } from 'models';

import { __, date } from 'helpers/i18n';

import { Avatar, Flex, Icon, Indicator, Text } from 'components';
import { AvatarSubTitle, AvatarTitle } from 'components/Avatar';

type StatusModifiers = {
  isDanger?: boolean;
  isInfo?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
};
const getStatusModifiers = (status: EvaluationStatus): StatusModifiers => {
  switch (status) {
    case 'canceled':
    case 'pending_peer_validation':
    case 'not_started':
    case 'not_completed':
      return { isDanger: true };
    case 'in_progress':
      return { isInfo: true };
    case 'blocked':
      return { isWarning: true };
    case 'outdated':
      return { isInfo: true };
    case 'shared':
    case 'signed':
      return { isSuccess: true };
    default:
      return {};
  }
};
const getStatusLabel = (status: EvaluationStatus): string => {
  switch (status) {
    case 'canceled':
      return __('Canceled');
    case 'pending_peer_validation':
      return __('Pending validation');
    case 'not_started':
      return __('Not started');
    case 'in_progress':
      return __('In Progress');
    case 'shared':
      return __('Shared');
    case 'signed':
      return __('Signed');
    case 'not_completed':
      return __('Not completed');
    case 'blocked':
      return __('Blocked');
    case 'outdated':
      return __('Outdated');
    default:
      return __('Invalid status: %1', status);
  }
};

const getColor = (modifier: StatusModifiers) => {
  if (modifier.isDanger) return 'danger';
  if (modifier.isInfo) return 'info';
  if (modifier.isWarning) return 'warning';
  if (modifier.isSuccess) return 'success';
  return undefined;
};

type UserStatusProps = {
  status: EvaluationStatus;
  testClassName?: string;
};
const UserStatus = ({ status, testClassName }: UserStatusProps) => (
  <Text
    color={getColor(getStatusModifiers(status))}
    testClassName={testClassName}
  >
    {getStatusLabel(status)}
  </Text>
);

type AuthorTagProps = { tag: ReviewerTag; style?: CSSProperties };
const AuthorTag = ({ tag, style }: AuthorTagProps) => {
  let authorIcon: 'account_circle' | 'grade' | undefined;
  let authorTitle: string | undefined;
  switch (tag) {
    case 'reviewee':
      authorIcon = 'account_circle';
      authorTitle = __('Reviewee');
      break;
    case 'manager':
      authorIcon = 'grade';
      authorTitle = __('Manager');
      break;
    default:
      return null;
  }

  return (
    <Text size={6} style={{ fontWeight: '600' }}>
      <Icon
        size="small"
        style={{
          width: '0.7rem',
          marginRight: 5,
          verticalAlign: 'middle',
          ...style,
        }}
        name={authorIcon}
      />
      <span style={{ verticalAlign: 'middle' }}>{authorTitle}</span>
    </Text>
  );
};

type AvatarWithStatusProps = {
  user: User;
  status?: EvaluationStatus;
  tag?: ReviewerTag;
  link?: string;
  testClassName?: string;
  className?: string;
  avatarImageClassName?: string;
  dueDate?: string | null;
  additionDate?: string;
  statusFallback?: string;
};

export const AvatarWithStatus = ({
  user,
  status,
  tag,
  link,
  testClassName,
  className,
  avatarImageClassName,
  dueDate,
  additionDate,
  statusFallback,
}: AvatarWithStatusProps) => {
  return (
    <Avatar
      url={user.avatarUrl}
      testClassName={testClassName}
      className={className}
      avatarImageClassName={avatarImageClassName}
    >
      <AvatarTitle>
        <Text
          linkTo={link}
          testClassName={link && 'text-link test-avatar-link'}
        >
          <div className="test-avatar-full-name">{user.fullName}</div>
          {!!tag && tag !== 'peer' && (
            <small style={{ fontWeight: 600 }}>
              &nbsp;(
              <AuthorTag tag={tag} style={{ height: '1rem', marginLeft: 2 }} />
              &nbsp;)
            </small>
          )}
        </Text>
      </AvatarTitle>

      <AvatarSubTitle>
        {status ? (
          <UserStatus status={status} testClassName="test-avatar-status" />
        ) : (
          statusFallback || ''
        )}
      </AvatarSubTitle>

      {(dueDate || additionDate) && (
        <Flex direction="column" style={{ marginTop: 8 }}>
          {status && dueDate && (
            <AvatarSubTitle
              textProps={{
                color: 'grey',
                transformation: 'italic',
                style: { paddingRight: 8 },
              }}
            >
              {__('Due date: %1', date(dueDate))}
            </AvatarSubTitle>
          )}

          {additionDate && (
            <AvatarSubTitle
              textProps={{
                color: 'grey',
                transformation: 'italic',
                style: { paddingRight: 8 },
              }}
            >
              {__('Added on: %1', date(additionDate))}
            </AvatarSubTitle>
          )}
        </Flex>
      )}
    </Avatar>
  );
};

type AvatarWithStatusIndicatorProps = {
  user: User;
  status: EvaluationStatus;
  avatarImageClassName?: string;
};
export const AvatarWithStatusIndicator = ({
  user,
  status,
  avatarImageClassName,
}: AvatarWithStatusIndicatorProps) => (
  <Avatar
    url={user.avatarUrl}
    decoration={<Indicator isSmall {...getStatusModifiers(status)} />}
    avatarImageClassName={avatarImageClassName}
  />
);
