import React from 'react';

import {
  ChoiceScore,
  ChoiceSpecificStats,
  NPSScore,
  NPSSpecificStats,
  ScaleScore,
  ScaleSpecificStats,
  SurveyAnswerSentimentCategory,
  SurveyChoiceQuestion,
  SurveyNPSQuestion,
  SurveyQuestionStats,
  SurveyScaleQuestion,
  SurveyTextQuestion,
  TextScore,
  TextSpecificStats,
} from 'models/surveys';

import { useCurrentOrganization } from 'helpers/hooks';

import { Box, BoxVerticalSeparator, Flex } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import QuestionCompletionStats from '../components/QuestionCompletionStats';
import Evolution from './Evolution';
import ChoiceStats from './stats/ChoiceStats';
import NPSStats from './stats/NPSStats';
import ScaleStats from './stats/ScaleStats';
import TextStats from './stats/TextStats';

type Props = {
  questionStats: SurveyQuestionStats;
  campaignId: string;
  selectedPeriodId: string;
  userFilters: ActiveFilters | '';
  withEvolution: boolean;
};

const QuestionStats = ({
  questionStats,
  campaignId,
  selectedPeriodId,
  userFilters,
  withEvolution,
}: Props) => {
  const {
    question: { questionType },
    specificStats,
    score,
  } = questionStats;

  const evolutionYAxisProps = () => {
    const { minValue, maxValue } =
      questionStats.question as SurveyScaleQuestion;
    switch (questionType) {
      case 'nps':
        return {
          domain: [-100, 100],
        };
      case 'scale':
        return {
          domain: [minValue, maxValue],
        };
      default:
        return { domain: [minValue, maxValue] };
    }
  };

  const MainStatsBox = () => {
    const { aiFeaturesEnabled } = useCurrentOrganization();

    switch (questionType) {
      case 'nps':
        return (
          <>
            <NPSStats
              stats={specificStats as NPSSpecificStats}
              score={score as NPSScore}
              question={questionStats.question as SurveyNPSQuestion}
            />
            <BoxVerticalSeparator />
          </>
        );
      case 'scale':
        return (
          <>
            <ScaleStats
              question={questionStats.question as SurveyScaleQuestion}
              stats={specificStats as ScaleSpecificStats}
              score={score as ScaleScore}
            />
            <BoxVerticalSeparator />
          </>
        );
      case 'text':
        if (!aiFeaturesEnabled || !specificStats) {
          return null;
        }
        return (
          <>
            <TextStats
              stats={specificStats as TextSpecificStats}
              score={score as TextScore}
              sentimentCategory={
                questionStats.sentimentCategory as SurveyAnswerSentimentCategory
              }
              question={questionStats.question as SurveyTextQuestion}
            />
            <BoxVerticalSeparator />
          </>
        );

      case 'single_choice':
      case 'multiple_choice':
        return (
          <>
            <ChoiceStats
              stats={specificStats as ChoiceSpecificStats}
              score={score as ChoiceScore}
              question={questionStats.question as SurveyChoiceQuestion}
            />
            <BoxVerticalSeparator />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Box className="mt-4" testClassName="test-survey-question-stats">
        <Flex className="justify-between">
          <MainStatsBox />
          <QuestionCompletionStats questionStats={questionStats} />
        </Flex>
      </Box>

      {withEvolution &&
        (questionType === 'nps' || questionType === 'scale') && (
          <Evolution
            campaignId={campaignId}
            selectedPeriodId={selectedPeriodId}
            questionId={questionStats.question.id}
            yAxisProps={evolutionYAxisProps()}
            userFilters={userFilters}
          />
        )}
    </>
  );
};

export default QuestionStats;
