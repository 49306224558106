import React from 'react';

import type { BaseTrainingCourse, User } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';

import SimbelTrainingRequestModal from 'scenes/components/simbel/TrainingRequestModal';

import OriginalTrainingRequestModal from './TrainingRequestModal';

type Props = {
  onClose: () => void;
  currentTrainingPeriodSlug?: string | null;
  concernedUser?: User | null;
  createdFromReview?: boolean;
  onConfirmationClose?: () => void;
  onAfterSubmit?: () => void;
  fetchCurrentPeriodOnOpening?: boolean;
  trainingCourse?: BaseTrainingCourse | null;
};

export default function TrainingRequestModal(props: Props) {
  const { isSimbelIntegrationActive } = useCurrentOrganization();

  if (isSimbelIntegrationActive) {
    return <SimbelTrainingRequestModal {...props} />;
  } else {
    return <OriginalTrainingRequestModal {...props} />;
  }
}
