import React from 'react';

import type { OneOnOneUserReview } from 'models';

import { __ } from 'helpers/i18n';
import { getPendingEvaluations } from 'helpers/models/evaluation';

import { Helper, Notification, Text } from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';
import SubmitFormModal from 'scenes/review/components/SubmitFormModal';

type Props = {
  isActive: boolean;
  onShare: () => Promise<void>;
  onClose: () => void;
  userReview: OneOnOneUserReview;
};

export function successMessage(userReview: OneOnOneUserReview) {
  if (userReview.visibleForReviewee) {
    return __(
      '%1 can now access the results of this review. An e-mail notification was sent to %2.',
      userReview.user.fullName,
      userReview.user.firstName
    );
  } else {
    return __('Your comments have been shared.');
  }
}

function description(userReview: OneOnOneUserReview) {
  const pendingEvaluations = getPendingEvaluations(userReview.evaluations);
  const revieweeFirstName =
    userReview && userReview.user && userReview.user.firstName;

  return (
    <div>
      {pendingEvaluations.length > 0 ? (
        <>
          <Notification kind="warning" icon="warning">
            <Text>
              {__('Sharing results now will close all incomplete feedback.')}
            </Text>
          </Notification>
          <p style={{ marginBottom: 8 }}>
            <Text weight="bold">{__('Incomplete feedback:')}</Text>
          </p>
          <ul>
            {pendingEvaluations.map(evaluation => (
              <li key={evaluation.id} style={{ marginBottom: 16 }}>
                <AvatarWithStatus
                  status={evaluation.status}
                  user={evaluation.reviewer}
                />
              </li>
            ))}
          </ul>
        </>
      ) : null}
      <Helper>
        {__(
          'An email will be sent to %1 to notify them of the results availability.',
          revieweeFirstName
        )}
      </Helper>
    </div>
  );
}

export default function ReleaseReviewModal({
  isActive,
  onShare,
  onClose,
  userReview,
}: Props) {
  if (!userReview) return null;

  return (
    <SubmitFormModal
      isActive={isActive}
      onShare={onShare}
      onClose={onClose}
      title={__('Share review results?')}
      description={description(userReview)}
      successTitle={__('Review results have been shared')}
      successDescription={
        <Text align="centered">{successMessage(userReview)}</Text>
      }
      confirmLabel={__('Share review')}
      style={{ width: 448 }}
    />
  );
}
