import React from 'react';

import { SurveyCampaign } from 'models/surveys';

import { __, date, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import Icon from 'components/Icon';

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
const calculateDifferenceBetweenDates = (date1: Date, date2: Date) =>
  Math.ceil((date1.getTime() - date2.getTime()) / ONE_DAY_IN_MS);

export const pollingPeriodLaunchInfo = ({
  status,
  lastPeriodStartedAt,
}: SurveyCampaign) => {
  if (status !== 'in_progress') return null;

  invariant(lastPeriodStartedAt, 'lastPeriodEndDate is required');

  const differenceBetweenDates = calculateDifferenceBetweenDates(
    new Date(),
    new Date(lastPeriodStartedAt)
  );
  return n__(
    'Survey period launched on: %2 (%3 day)',
    'Survey period launched on: %2 (%3 days)',
    differenceBetweenDates,
    date(lastPeriodStartedAt),
    differenceBetweenDates
  );
};

export const pollingPeriodNextLaunchInfo = ({
  status,
  nextPeriodAt,
}: SurveyCampaign) => {
  if (status !== 'scheduled') return null;

  invariant(nextPeriodAt, 'nextPeriodAt is required');

  const differenceBetweenDates = calculateDifferenceBetweenDates(
    new Date(nextPeriodAt),
    new Date()
  );
  return n__(
    'Launch of the next survey period scheduled for: %2 (%3 day)',
    'Launch of the next survey period scheduled for: %2 (%3 days)',
    differenceBetweenDates,
    date(nextPeriodAt),
    differenceBetweenDates
  );
};

export const pollingPeriodParticipationCreationInfo = ({
  status,
  anyInProgressParticipationCreation,
}: SurveyCampaign) => {
  if (status !== 'in_progress' || !anyInProgressParticipationCreation)
    return null;

  return (
    <>
      <Icon
        name="sync"
        className="text-xl mr-2 mt-[-3px] animate-[spin_2s_linear_reverse_infinite]"
      />
      {__(
        'Participants are being added, remember to refresh the page to update the data'
      )}
    </>
  );
};
