import React, { useState } from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Button, Flex, Icon, Text, Textarea } from 'components';

type Props = {
  onSubmit: (messageContent: string) => Promise<unknown>;
  postAsAnonymous: boolean;
  conversationIsAnonymous: boolean;
  testClassName?: string;
};

const ChatRoomInput = ({
  onSubmit,
  postAsAnonymous,
  conversationIsAnonymous,
  testClassName,
}: Props) => {
  const [messageContent, setMessageContent] = useState('');

  const submit = async () => {
    await onSubmit(messageContent);
    setMessageContent('');
  };

  const conversationDescriptionText = conversationIsAnonymous
    ? postAsAnonymous
      ? __('This conversation is %1.', <b>{__('anonymous')}</b>)
      : __('The employee is %1.', <b>{__('anonymous')}</b>)
    : __('This conversation is %1.', <b>{__('not anonymous')}</b>);

  return (
    <div
      className={classNames(
        'flex flex-col gap-4 w-full items-center',
        testClassName
      )}
    >
      <div className="w-full">
        <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
          <Text preset="16bs5.5">{__('Answer')}</Text>
          <Text preset="14s6" style={{ fontWeight: 'normal' }}>
            <Icon
              className="mr-1"
              name={conversationIsAnonymous ? 'visibility_off' : 'visibility'}
            />
            {conversationDescriptionText}
          </Text>
        </Flex>
        <div className="my-4">
          <Textarea
            placeholder={
              postAsAnonymous ? __('Answer anonymously...') : __('Answer...')
            }
            onChange={messageContent => setMessageContent(messageContent)}
            value={messageContent}
            testClassName="test-conversation-input-textarea"
          />
        </div>
      </div>
      <Button
        color={'primary'}
        onClick={submit}
        testClassName="test-confirm-button"
        disabled={!messageContent}
      >
        {__('Send')}
      </Button>
    </div>
  );
};

export default ChatRoomInput;
