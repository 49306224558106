import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useCurrentOrganization } from 'helpers/hooks';
import { pathToValidatedTrainingPlan } from 'helpers/paths';

import { NavigateFromParams } from 'components/navigation/Routes';

import SimbelTrainingRoutes from 'scenes/components/simbel/SimbelTrainingRoutes';

import BudgetRoutes from './Budget/Routes';
import Landing from './Landing';
import PeriodCreator from './PeriodCreator';
import PeriodRedirector from './PeriodRedirector';
import PeriodRequests from './PeriodRequests';
import Plan from './Plan';
import Session from './Session';

const PlanManagementRoutes = () => {
  const organization = useCurrentOrganization();

  if (organization.isSimbelIntegrationActive) {
    return <SimbelTrainingRoutes />;
  }

  const budgetEnvelopesEnabled =
    organization.featureFlags.includes('trainingCurrencies') &&
    organization.featureFlags.includes('budgetEnvelopes');

  return (
    <Routes>
      <Route index path="/" element={<PeriodRedirector />} />
      <Route path="sessions/:id/*" element={<Session />} />
      <Route path="periods/new" element={<PeriodCreator />} />
      <Route path="landing" element={<Landing />} />
      <Route path=":periodSlug/requests" element={<PeriodRequests />} />
      {budgetEnvelopesEnabled && (
        <Route path=":periodSlug/budget/*" element={<BudgetRoutes />} />
      )}
      <Route
        path=":periodSlug/forecast"
        element={<Plan isValidatedPlan={false} />}
      />
      <Route path=":periodSlug/validated" element={<Plan isValidatedPlan />} />
      <Route
        path=":periodSlug/sessions"
        element={
          <NavigateFromParams
            getPath={({ periodSlug }) =>
              pathToValidatedTrainingPlan(periodSlug!)
            }
          />
        }
      />
    </Routes>
  );
};

export default PlanManagementRoutes;
