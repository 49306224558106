import React from 'react';

import type { GroupedAnswers } from './index';
import type { BlockAnswer } from 'models';

import RatingChart from './Chart';
import { ChartColor } from './Chart/colors';
import RatingTable from './Table';

const MAX_DISPLAYABLE_RATING_OPTIONS = 6;

type Props = {
  ratings: Array<string>;
  answers: Array<BlockAnswer>;
  colorIterator: () => ChartColor;
  groupedAnswers: GroupedAnswers;
};

export default function RatingChartWithTableFallback({
  groupedAnswers,
  colorIterator,
  ratings,
  answers,
}: Props) {
  const shouldDisplayGraph = (ratings: Array<string>) =>
    ratings.length <= MAX_DISPLAYABLE_RATING_OPTIONS;

  if (!shouldDisplayGraph(ratings))
    return <RatingTable groupedAnswers={groupedAnswers} ratings={ratings} />;

  return (
    <RatingChart
      groupedAnswers={groupedAnswers}
      colorIterator={colorIterator}
      ratings={ratings}
      answers={answers}
    />
  );
}
