import React, { useState } from 'react';

import type { BaseTrainingCourse, User } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { assert } from 'helpers/invariant';

import { hydrateFromResponse } from 'lib/dataLoader';
import { post } from 'redux/actions/api';

import { ModalCard } from 'components';

import CreateContent from './CreateContent';
import SuccessContent from './SuccessContent';
import {
  CreateTrainingRequestFormObject,
  CreateTrainingRequestParams,
} from './types';

type Props = {
  currentTrainingPeriodSlug?: string | null;
  concernedUser?: User | null;
  createdFromReview?: boolean;
  onClose: () => void;
  onConfirmationClose?: () => void;
  onAfterSubmit?: () => void;
  trainingCourse?: BaseTrainingCourse | null;
};

const TrainingRequestModal = ({
  currentTrainingPeriodSlug,
  concernedUser,
  createdFromReview = false,
  onClose,
  onConfirmationClose,
  onAfterSubmit,
  trainingCourse,
}: Props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [trainee, setTrainee] = useState(null);
  const [trainingRequestsCount, setTrainingRequestsCount] = useState(0);

  const dispatch = useAppDispatch();

  const onSubmit = async ({
    title,
    description,
    priority,
    trainee,
    periodSlug,
    traineeIds,
    collaboratorsChoice,
    trainingCourseId,
  }: CreateTrainingRequestFormObject) =>
    dispatch(async (dispatch, getState) => {
      const params: CreateTrainingRequestParams =
        collaboratorsChoice === 'single'
          ? {
              title,
              description,
              priority,
              createdFromReview,
              traineeId: assert(trainee, 'Trainee exists on single choice').id,
              trainingCourseId,
            }
          : {
              title,
              description,
              priority,
              createdFromReview,
              traineeIds: Array.from(traineeIds),
              trainingCourseId,
            };

      const { response } = await dispatch(
        post(`training/periods/${periodSlug}/requests`, params)
      );

      return response.body.data.type === 'trainingRequest'
        ? hydrateFromResponse(
            getState().data,
            response.body,
            { trainingRequest: { trainee: {} } },
            response.body.data.id
          )
        : hydrateFromResponse(
            getState().data,
            response.body,
            { trainingGroup: {} },
            response.body.data.id
          );
    });

  const handleSubmit = async (
    trainingRequest: CreateTrainingRequestFormObject
  ) => {
    const trainingRequestFromResponse = await onSubmit(trainingRequest);
    !!onAfterSubmit && onAfterSubmit();

    setIsSuccess(true);
    if (trainingRequestFromResponse.type === 'trainingRequest') {
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      setTrainee(trainingRequestFromResponse.trainee);
    }

    if (trainingRequestFromResponse.type === 'trainingGroup') {
      setTrainingRequestsCount(
        trainingRequestFromResponse.trainingRequestsCount
      );
    }
  };

  const handleClose = () => {
    const onCloseFunction = onConfirmationClose || onClose;
    onCloseFunction();
    setIsSuccess(false);
  };

  return (
    <ModalCard
      isBig={!isSuccess}
      isActive
      refreshContentOnOpening
      onClose={onClose}
    >
      {isSuccess ? (
        <SuccessContent
          trainee={trainee}
          onClose={handleClose}
          trainingRequestsCount={trainingRequestsCount}
        />
      ) : (
        <CreateContent
          currentTrainingPeriodSlug={currentTrainingPeriodSlug || null}
          concernedUser={concernedUser}
          trainingCourse={trainingCourse}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      )}
    </ModalCard>
  );
};

export default TrainingRequestModal;
