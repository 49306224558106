import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { Evaluation } from 'models';

import can from 'helpers/can';
import invariant, { assert } from 'helpers/invariant';
import { evaluationSharedOrSigned } from 'helpers/models/evaluation';
import { pathToReviewResults } from 'helpers/navigation';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import { ScrollingContext } from '../ScrollingContext';
import FormContent from '../components/FormContent';
import ReviewLayout from '../components/ReviewLayout';
import ReviewSeenTracker from '../components/ReviewSeenTracker';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import UserPanel, { shouldShowUserPanel } from '../components/UserPanel';
import ShareAction from './ShareAction';

type AfterMatchParamsProps = {
  match: {
    evaluationId: string;
  };
};

type Props = AfterMatchParamsProps;

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    evaluation: Evaluation;
  };

function EvaluationComponent({
  hasError,
  isFetching,
  evaluation,
  refetchData,
}: AfterDataLoaderProps) {
  const [shouldScrollToSignSection, setShouldScrollToSignSection] =
    useState(false);

  return (
    <ScrollingContext.Provider
      value={{
        shouldScrollToSignSection,
        setShouldScrollToSignSection,
      }}
    >
      <ShareAction
        evaluation={evaluation}
        refreshReview={refetchData}
        render={shareAction => (
          <ReviewLayout
            userAvatarUrl={evaluation?.userReview?.user?.avatarUrl}
            sidebar={
              <FetchContainer
                hasError={hasError}
                isFetching={isFetching}
                render={() => (
                  <Sidebar
                    content={assert(
                      evaluation.formContent,
                      'formContent must be defined once loaded'
                    )}
                    userReview={assert(
                      evaluation.userReview,
                      'userReview must be available on evaluation'
                    )}
                    shareAction={shareAction}
                  />
                )}
              />
            }
            content={
              <FetchContainer
                hasError={hasError}
                isFetching={isFetching}
                render={() => {
                  const { formContent, userReview } = evaluation;

                  invariant(
                    formContent && userReview,
                    'Both content and review should be attached to evaluation'
                  );

                  const shouldRedirect =
                    !shareAction.isModalActive &&
                    evaluationSharedOrSigned(evaluation) &&
                    can({
                      perform: 'view_results',
                      on: userReview,
                    });

                  // Redirect if results available and scroll to sign section if needed
                  if (shouldRedirect) {
                    return (
                      <Navigate
                        to={pathToReviewResults(userReview)}
                        state={{ shouldScrollToSignSection }}
                      />
                    );
                  }

                  return (
                    <>
                      <ReviewSeenTracker
                        userReview={userReview}
                        additionalProperties={{ step: 'evaluation' }}
                      />
                      <FormContent
                        content={formContent}
                        shareAction={shareAction}
                        reviewee={userReview.user}
                        userReviewId={userReview.id}
                      />
                    </>
                  );
                }}
              />
            }
            topbar={(() => {
              const review = evaluation && evaluation.userReview;
              return (
                <Topbar
                  reviewee={review && review.user}
                  reviewCycle={review && review.reviewCycle}
                  review={review}
                  refreshReview={refetchData}
                  shareAction={shareAction}
                  showSavingStatus
                />
              );
            })()}
            userPanel={(() => {
              const review = evaluation && evaluation.userReview;
              if (!review) return null;
              if (!shouldShowUserPanel(review)) return null;

              return <UserPanel userReview={review} />;
            })()}
          />
        )}
      />
    </ScrollingContext.Provider>
  );
}

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: Props) => get(`evaluations/${match.evaluationId}`),
    hydrate: {
      evaluation: {
        abilities: {},
        userReview: {
          abilities: {},
          user: {
            abilities: {},
          },
          responsible: {},
          reviewer: {},
          reviewCycle: {},
          evaluations: {
            reviewer: {},
          },
          meeting: { participants: {} },
          additionalReviewer: {},
        },
        reviewer: {},
        formContent: {
          messages: {},
          blocks: {
            messages: {},
            feedbackableItems: {
              item: {},
              answers: {
                author: {},
              },
            },
          },
        },
      },
    },
  })
)(EvaluationComponent);
