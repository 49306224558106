import React from 'react';
import { compose } from 'redux';

import type { TrainingPlanBudgetStats } from 'models/TrainingPlan';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, Text } from 'components';
import FetchContainer from 'components/FetchContainer';

import BudgetVisualizer from '../../components/BudgetVisualizer';

type Props = {
  periodSlug: string;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    stats: TrainingPlanBudgetStats;
  };

const GlobalBudget = ({ stats, isFetching, hasError }: AfterConnectProps) => {
  return (
    <Box className="mb-0">
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <BudgetVisualizer
            title={<Text preset="14bs6">{__('Sum of funding sources')}</Text>}
            justifyLegend={false}
            testClassName="test-total-budget-visualizer"
            validatedAmountCents={stats.validatedAmountCents}
            forecastAmountCents={stats.forecastAmountCents}
            provisionedAmountCents={stats.provisionedAmountCents}
            currency={stats.currency}
          />
        )}
      />
    </Box>
  );
};

export default compose(
  newDataLoader({
    fetch: ({ periodSlug }: Props) =>
      get(`training/periods/${periodSlug}/plans/forecast/budget_stats`),
    hydrate: {
      stats: {},
    },
    cacheKey: ({ periodSlug }: Props) =>
      compositeKey({
        periodSlug,
        viewId: 'trainingPlansGlobalBudgetStats',
      }),
  })
)(GlobalBudget);
