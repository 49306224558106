import React from 'react';

import { __ } from 'helpers/i18n';

import { BigSelectableButton, Text } from 'components';

type Props = {
  byEnvelope: boolean;
  onChange: (byEnvelope: boolean) => void;
};

const Breakdown = ({ byEnvelope, onChange }: Props) => {
  const setBudgetByEnvelope = (value: boolean) => {
    if (value !== byEnvelope) {
      onChange(value);
    }
  };

  return (
    <>
      <Text preset="18bs5" className="block mb-4">
        {__('Budget breakdown method')}
      </Text>
      <div className="flex gap-4">
        <BigSelectableButton
          emoji="✏️"
          title={__('Single budget')}
          isActive={!byEnvelope}
          onClick={() => setBudgetByEnvelope(false)}
        />
        <BigSelectableButton
          emoji="✂️"
          title={__('Breakdown by envelope')}
          isActive={byEnvelope}
          onClick={() => setBudgetByEnvelope(true)}
        />
      </div>
    </>
  );
};

export default Breakdown;
