import { isEqual } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { FormContent, TrainingPeriod, UserReview } from 'models';
import type { ReleaseActionInfo } from 'scenes/review/Review/ReleaseAction';

import can from 'helpers/can';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import AttachFilesSection from './AttachFilesSection';
import ScheduleMeetingSection from './ScheduleMeetingSection';
import TableOfContentsSection from './TableOfContentsSection';
import TrainingRequestCreationSection from './TrainingRequestCreationSection';

type Props = {
  content: FormContent;
  userReview: UserReview;
  isPreview?: boolean;
  shareAction: ReleaseActionInfo;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    currentTrainingPeriod: TrainingPeriod;
  };

const Sidebar = ({
  content,
  userReview,
  isPreview,
  shareAction,
  currentTrainingPeriod,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const blocksForTable = content.blocks.filter(
    block =>
      [
        'header_message',
        'title',
        'objectives_module',
        'final_comment',
        'share_evaluation',
        'signature',
        'training_request_history', // old one to remove with deprecation about EA-8158
        'training_history',
        'skills_career_level',
      ].includes(block.blockType) ||
      ('release_review' === block.blockType && shareAction.isShown)
  );

  const displayScheduleMeetingSection = !isPreview;
  const displayTrainingRequestCreationSection =
    !isPreview &&
    !!currentTrainingPeriod &&
    can({
      perform: 'show_create_training_request_button',
      on: userReview,
    });
  const trainingHistoryBlockPresent = !!blocksForTable.find(
    block =>
      ['training_request_history', 'training_history'].includes(block.blockType) // Only use training_history EA-8158
  );

  const displayAttachFilesSection =
    !isPreview && userReview.interactionType === 'one_on_one';

  return (
    <div className="side-bar">
      <TableOfContentsSection blocks={blocksForTable} />
      {displayAttachFilesSection && (
        <>
          <hr />
          <AttachFilesSection userReview={userReview} />
        </>
      )}
      {displayScheduleMeetingSection && (
        <>
          <hr />
          <ScheduleMeetingSection userReview={userReview} />
        </>
      )}

      {!isFetching && !hasError && displayTrainingRequestCreationSection && (
        <>
          <hr />
          <TrainingRequestCreationSection
            currentTrainingPeriod={currentTrainingPeriod}
            reviewee={userReview.user}
            onConfirm={() => {
              !userReview.isReleased &&
                trainingHistoryBlockPresent &&
                window.location.reload();
            }}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(
  compose(
    newDataLoader({
      fetch: () => get('training/periods/current'),
      hydrate: {
        currentTrainingPeriod: {},
      },
    })
  )(Sidebar),
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
) as React.ComponentType<Props>;
