import React from 'react';

import tailwindTheme from 'config/tailwindTheme';

import { Box, Column, Columns } from 'components';

import logo from './elevo_logo_400x1088.png';

const Logo = () => <img alt="presentation" src={logo} width="200" />;

const cardOffset = 120;
const imagePadding = 30;

const styles = {
  background: {
    backgroundColor: tailwindTheme.colors['light-blue'],
    height: '100vh',
  },
  topImage: {
    paddingTop: imagePadding,
    paddingBottom: imagePadding + cardOffset,
  },
  bottomFrameContainer: {
    marginTop: -cardOffset,
    paddingTop: 0,
  },
  box: {
    padding: '2.5rem',
  },
};

type Props = {
  children: React.ReactNode;
};

const Splash = ({ children }: Props) => (
  <div style={styles.background}>
    <div className="has-text-centered" style={styles.topImage}>
      <Logo />
    </div>
    <div className="container-elevo" style={styles.bottomFrameContainer}>
      <Columns>
        <Column size={6} offset={3}>
          <Box style={styles.box}>{children}</Box>
        </Column>
      </Columns>
    </div>
  </div>
);

export default Splash;
