import { isEqual } from 'lodash';

import type { ParticipantFundingItem, TrainingParticipant } from 'models';

const groupFundingItemsBySource = (
  fundingItems: Array<ParticipantFundingItem>
) =>
  fundingItems.reduce((itemsBySource, fundingItem) => {
    itemsBySource[fundingItem.fundingSource.id] = fundingItem.amountCents;
    return itemsBySource;
  }, {});

const areAllFundingItemsIdentical = (
  allFundingItems: Array<Array<ParticipantFundingItem>>
) => {
  const firstFundingItemsBySource = groupFundingItemsBySource(
    allFundingItems[0]
  );

  return allFundingItems.every(fundingItems =>
    isEqual(firstFundingItemsBySource, groupFundingItemsBySource(fundingItems))
  );
};

const getDefaultFundingItems = (participants: Array<TrainingParticipant>) =>
  areAllFundingItemsIdentical(
    participants.map(participant => participant.fundingItems)
  )
    ? participants[0].fundingItems
    : [];

export default getDefaultFundingItems;
