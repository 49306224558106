import React from 'react';

import { __ } from 'helpers/i18n';

import IntegrationSettingField from './IntegrationSettingField';

type Props = {
  settings: {
    apiKey: string;
    subdomain: string;
  };
  onChange: (key: string, value: string) => void;
};

export function BambooHRIntegrationSettings({ settings, onChange }: Props) {
  return (
    <>
      <IntegrationSettingField
        name="apiKey"
        label={__('API Key:')}
        value={settings.apiKey}
        cleanOnFocus
        onUpdate={(value: string) => onChange('apiKey', value)}
      />
      <IntegrationSettingField
        name="subdomain"
        label={__('Subdomain:')}
        value={settings.subdomain}
        onUpdate={(value: string) => onChange('subdomain', value)}
        placeholder={__('yoursubdomain')}
      />
    </>
  );
}

export const BambooHRIntegrationDefaultSettings = {
  apiKey: '',
  subdomain: '',
};
