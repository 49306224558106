import React from 'react';

import { __ } from 'helpers/i18n';

import { DefaultErrorNotification } from 'components';

type Props = {
  hasLoginError: boolean;
  hasInvitationTokenError: boolean;
};

export default function BadRequestErrorMessage({
  hasLoginError,
  hasInvitationTokenError,
}: Props) {
  return (
    <>
      {hasLoginError && (
        <DefaultErrorNotification
          message={__(
            'Your login is missing from the URL, please retry or contact your administrator.'
          )}
        />
      )}
      {hasInvitationTokenError && (
        <DefaultErrorNotification
          message={__(
            'Your invitation token is missing from the URL, please retry or contact your administrator.'
          )}
        />
      )}
    </>
  );
}
