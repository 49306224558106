import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SurveyCampaign, SurveyQuestionStats } from 'models';

import compositeKey from 'helpers/compositeKey';
import { pathToSurveyCampaignResults } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import { surveyIsRecurrent } from 'scenes/surveys/helpers/survey.helpers';

import Header from '../components/Header';
import NotEnoughParticipantsEmptyState from '../components/NotEnoughParticipantEmptyState';
import AnswersList from './AnswersList';
import QuestionSlider from './QuestionSlider';
import QuestionStats from './QuestionStats';

type Props = {
  campaign: SurveyCampaign;
  selectedPeriodId: string;
  userFilters: ActiveFilters | '';
  onUserFiltersChange: (newQueryParams: '' | ActiveFilters) => void | undefined;
  questionId?: string | null;
  correlationSlug?: string | null;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    questionStats: SurveyQuestionStats;
  };

const QuestionPicker = ({
  campaign,
  selectedPeriodId,
  userFilters,
  onUserFiltersChange,
  questionId,
  correlationSlug,
  questionStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const navigate = useNavigate();
  const selectedPeriod = campaign.pollingPeriods.find(
    period => period.id === selectedPeriodId
  );

  const handleSelectedPeriodChange = periodId => {
    const {
      question: { correlationSlug },
    } = questionStats;
    navigate(
      pathToSurveyCampaignResults(
        campaign.id,
        correlationSlug ? null : questionId,
        periodId,
        correlationSlug
      )
    );
  };
  const surveyRecurrentWithPeriod =
    surveyIsRecurrent(campaign) && !!selectedPeriod;
  const displayMissingParticipants =
    campaign.anonymous && questionStats?.participantsCount < 3;

  return (
    <>
      <Header
        campaign={campaign}
        selectedPeriod={selectedPeriod}
        handleSelectedPeriodChange={handleSelectedPeriodChange}
        questionStats={questionStats}
        userFilters={userFilters}
        onUserFiltersChange={onUserFiltersChange}
      />
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <>
            <QuestionSlider
              questionStats={questionStats}
              campaignId={campaign.id}
              selectedPeriodId={selectedPeriodId}
            />
            {!!questionStats && (
              <>
                {displayMissingParticipants && (
                  <NotEnoughParticipantsEmptyState />
                )}
                {!displayMissingParticipants && (
                  <>
                    <QuestionStats
                      questionStats={questionStats}
                      campaignId={campaign.id}
                      withEvolution={surveyRecurrentWithPeriod}
                      selectedPeriodId={selectedPeriodId}
                      userFilters={userFilters}
                    />
                    {questionStats.commentEnabled && (
                      <AnswersList
                        campaign={campaign}
                        selectedPeriodId={selectedPeriodId}
                        userFilters={userFilters}
                        questionStats={questionStats}
                        question={questionStats.question}
                        correlationSlug={correlationSlug}
                        paginationType="state"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      />
    </>
  );
};

export default newDataLoader({
  fetch: ({
    campaign,
    questionId,
    selectedPeriodId,
    userFilters,
    correlationSlug,
  }: Props) =>
    get(`survey/campaigns/${campaign.id}/questions/question_stats`, {
      correlationSlug,
      questionId,
      userFilters,
      periodId: selectedPeriodId,
    }),
  hydrate: {
    questionStats: {
      question: {},
      correlatedPollingPeriods: { abilities: {} },
    },
  },
  cacheKey: ({
    campaign,
    questionId,
    selectedPeriodId,
    userFilters,
    correlationSlug,
  }: Props) =>
    compositeKey({
      view: 'stats',
      campaign,
      questionId,
      selectedPeriodId,
      userFilters,
      correlationSlug,
    }),
})(QuestionPicker) as React.ComponentType<Props>;
