import React from 'react';
import { connect } from 'react-redux';

import type { ReviewCycle } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';

import { get } from 'redux/actions/api';

import { LoadingOverlay, Text, Title4 } from 'components';
import DisabledLayer from 'components/DisabledLayer';
import { Poller } from 'components/behaviors';

type Props = {
  reviewCycle: ReviewCycle;
};

type AfterConnectProps = Props & {
  refreshReviewCycle: () => Promise<void>;
};

const LaunchingCycle = ({
  reviewCycle,
  refreshReviewCycle,
}: AfterConnectProps) => {
  if (reviewCycle.status === 'failed') {
    return <DisabledLayer active>Oops, something went wrong</DisabledLayer>;
  }

  return (
    <>
      <Poller
        poll={() => refreshReviewCycle()}
        initialDelayMs={2000}
        everyMs={3000}
      />

      <LoadingOverlay>
        <Title4>{__('We are launching your campaign.')}</Title4>
        <p>
          <Text>
            {__(
              'Your campaign is being launched. You will be able to manage it shortly'
            )}
          </Text>
        </p>
        <p>
          <Text color="info">
            {__(
              'This may take up to 2 minutes. You can wait, or you can leave this page and come back later 🙂.'
            )}
          </Text>
        </p>
      </LoadingOverlay>
    </>
  );
};

// @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
export default connect(null, (dispatch: AppDispatch, props: Props) => ({
  refreshReviewCycle: () =>
    dispatch(get(`review_cycles/${props.reviewCycle.id}`)),
}))(LaunchingCycle);
