import { NavigateFunction } from 'react-router-dom';

import type { ReduxStore } from '../../reducers';
import type { AppDispatch } from '../types';
import type {
  OneOnOneUserReview,
  ThreeSixtyUserReview,
  User,
  UserReviewContent,
  UserReviewStatus,
} from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToMyReviews } from 'helpers/navigation';

import { APIActionError } from 'lib/api/types';

import { getResourceById } from '../../reducers';
import { get, post, put } from '../api/index';
import { errorNotice, successNotice } from '../application';

type UserReviewUpdateParams = Partial<
  OneOnOneUserReview &
    UserReviewContent & {
      previousPeriodObjectiveIds: string[];
      mainReviewerStatus?: UserReviewStatus;
      reviewersStatuses?: UserReviewStatus;
    }
>;

export const fetchUserReviewsForUser = (reviewee: User, params: any) =>
  get(`users/${reviewee.id}/reviews`, params);

export const fetchPeerFeedbacksSharedByUser = (user: User) =>
  get(`users/${user.id}/peer_feedbacks/shared`);

export type ReminderType =
  | 'one_on_one_reviewee_reminder_to_share'
  | 'one_on_one_reviewee_reminder_to_sign'
  | 'one_on_one_reviewer_reminder_to_share'
  | 'one_on_one_reviewer_reminder_to_sign'
  | 'three_sixty_reviewee_reminder_to_nominate_peers'
  | 'three_sixty_reviewee_reminder_to_fill_self_assessment'
  | 'three_sixty_reminder_for_peer_feedback';

export type OneOnOneStatusUpdateRole =
  | 'reviewee'
  | 'additional_reviewer'
  | 'reviewer'
  | 'reviewers';

export const releaseUserReview = ({ userReviewId }: { userReviewId: string }) =>
  post(`user_reviews/${userReviewId}/release`);

export const releaseUserReviewWithNotice =
  (userReview: ThreeSixtyUserReview) =>
  async (dispatch: AppDispatch, getState: () => ReduxStore) => {
    await dispatch(releaseUserReview({ userReviewId: userReview.id }));
    const reviewee = getResourceById(
      getState().data,
      'user',
      userReview.userId
    ) as unknown as User;

    reviewee &&
      (await dispatch(
        successNotice(__('You shared this feedback with %1', reviewee.fullName))
      ));
  };

export const sendReminder =
  (userReviewId: string, reminderType: ReminderType) =>
  async (dispatch: AppDispatch) => {
    await dispatch(
      post(`user_reviews/${userReviewId}/reminders/${reminderType}`)
    );
    await dispatch(successNotice(__('The email is on its way! 📧')));
  };

export const updateUserReviewStatus =
  (
    userReview: OneOnOneUserReview,
    {
      role,
      status,
    }: {
      role: OneOnOneStatusUpdateRole;
      status: UserReviewStatus;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    invariant(
      role === 'reviewee' ||
        role === 'reviewer' ||
        userReview.additionalReviewer,
      "Can't update additional reviewer status: This review has no additional reviewer"
    );

    const userReviewUpdate: UserReviewUpdateParams = {};

    if (role === 'reviewee') {
      userReviewUpdate.revieweeStatus = status;
    } else if (role === 'additional_reviewer') {
      userReviewUpdate.additionalReviewerStatus = status;
    } else if (role === 'reviewer') {
      userReviewUpdate.mainReviewerStatus = status;
    } else if (role === 'reviewers') {
      userReviewUpdate.reviewersStatuses = status;
    }

    try {
      await dispatch(updateUserReview(userReview.id, userReviewUpdate));

      let successText = __('Status updated');

      if (status === 'shared') {
        if (role === 'reviewers') {
          successText = __(
            '%1 and %2’s comments have been shared. Status updated.',
            userReview.reviewer.fullName,
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            userReview.additionalReviewer.fullName
          );
        } else {
          let username: string;
          if (role === 'reviewee') {
            username = userReview.user.fullName;
          } else if (role === 'reviewer') {
            username = userReview.reviewer.fullName;
          } else if (role === 'additional_reviewer') {
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            username = userReview.additionalReviewer.fullName;
          }
          successText = __(
            '%1’s comments have been shared. Status updated.',
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            username
          );
        }
      }

      await dispatch(successNotice(successText));
    } catch (error) {
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      if (!Object.prototype.hasOwnProperty.call(error, 'isHandled')) {
        const apiError = error as APIActionError<{ text: string }>;
        await dispatch(
          errorNotice(
            __(
              'Something wrong happened while updating the status: %1',
              apiError.response.text
            )
          )
        );
      }
    }
  };

export const submitReview = (
  userReviewId: string,
  navigate: NavigateFunction
) => {
  /**
   * For some weird reason, using the form
   *  export const submitReview = (uId) => async (dispatch) => { // logic }
   * made Jest crash.
   *
   * It seems Jest is not using Babel (or not the same version as Babel?) as us yet,
   * so for now I work around by using this more verbose form.
   */
  return async function submitReviewDispatcher(dispatch: AppDispatch) {
    try {
      await dispatch(put(`user_reviews/${userReviewId}/share_for_active_user`));
      navigate(pathToMyReviews('one_on_one'));
      dispatch(successNotice(__('Your feedback has been shared')));
    } catch (e) {
      dispatch(errorNotice(__('Failed to submit feedback')));
    }
  };
};

type AddPeersParams = {
  userReview: ThreeSixtyUserReview;
  users: User[];
};
export const nominatePeers = ({ userReview, users }: AddPeersParams) =>
  put(`user_reviews/${userReview.id}/nominate_peers`, {
    userIds: users.map(u => u.id),
  });

export const validatePeers = ({ userReview, users }: AddPeersParams) =>
  put(`user_reviews/${userReview.id}/validate_peers`, {
    userIds: users.map(u => u.id),
  });

export const updateUserReview = (
  userReviewId: string,
  userReview: UserReviewUpdateParams
) => put(`user_reviews/${userReviewId}`, { userReview: userReview });

export const signUserReview = (userReviewId: string) =>
  post(`user_reviews/${userReviewId}/sign`);
