import React from 'react';
import { compose } from 'redux';

import { MessagingChatRoom, PaginatedCollection, SurveyCampaign } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { PaginationProps, WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyStateWithIcon,
  FetchContainer,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import ConversationsList from '../../components/ConversationsList';

type Props = {
  campaign: SurveyCampaign;
  periodId: string | null | undefined;
  userFilters: ActiveFilters | '';
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    messagingChatRoomsCollection: PaginatedCollection<MessagingChatRoom>;
  };

const List = ({
  messagingChatRoomsCollection,
  isFetching,
  hasError,
  page,
  countPerPage,
  queryParams: { search, sort },
  setPreviousPageParams,
  setNextPageParams,
  setQueryParams,
  onSortChange,
}: AfterDataLoaderProps) => {
  return (
    <Box className={'survey-question-messaging-chat-rooms survey-box-question'}>
      <DatatableWrapper
        collectionInfo={messagingChatRoomsCollection}
        isFetching={isFetching}
        hasError={hasError}
        showTotalRecordCount
        page={page}
        countPerPage={countPerPage}
        search={search}
        getNextPage={setNextPageParams}
        getPreviousPage={setPreviousPageParams}
        onQueryParamsChange={setQueryParams}
        renderNoResult={() => (
          <EmptyStateWithIcon
            iconName="comment"
            title={__(
              "You don't have any follow-up conversations yet for this survey."
            )}
          />
        )}
        totalCountRenderer={totalCount =>
          n__('%1 message', '%1 messages', totalCount || 0)
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <ConversationsList
              messagingChatRooms={messagingChatRoomsCollection?.items || []}
              onChatRoomUpdate={() => setQueryParams({})}
              displayConversationOwner
              displayCampaignTitle={false}
              sort={sort}
              onSortChange={onSortChange}
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      queryParams: { sort },
      countPerPage,
      campaign,
      periodId,
      userFilters,
    }: AfterPaginateProps) =>
      get(`survey/campaigns/${campaign.id}/messaging_chat_rooms`, {
        page,
        sort,
        countPerPage,
        userFilters,
        periodId: periodId,
      }),
    hydrate: {
      messagingChatRoomsCollection: {
        items: {
          messagingSource: {},
          firstMessage: { user: {} },
          lastMessage: { user: {} },
        },
      },
    },
    cacheKey: ({
      page,
      queryParams: { sort },
      countPerPage,
      campaign,
      periodId,
      userFilters,
    }: AfterPaginateProps) =>
      compositeKey({
        view: 'survey-campaign-messaging-chat-rooms-list',
        page,
        sort,
        countPerPage,
        campaign,
        periodId,
        userFilters,
      }),
  })
)(List) as React.ComponentType<Props>;
