const aiLogo = `<svg width="121" height="35" viewBox="0 0 121 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.81293 26.1196C7.04171 26.1196 5.49728 25.7308 4.17966 24.9532C2.86204 24.154 1.83603 23.0632 1.10162 21.6807C0.367205 20.2767 0 18.6783 0 16.8855C0 15.0711 0.356405 13.4726 1.06922 12.0902C1.80363 10.7078 2.81884 9.62779 4.11486 8.85018C5.43248 8.05097 6.9553 7.65137 8.68333 7.65137C10.4762 7.65137 12.0206 8.07257 13.3166 8.91498C14.6342 9.75739 15.6062 10.9022 16.2326 12.3494C16.8807 13.7966 17.0859 15.4275 16.8483 17.2419H2.91604C2.98084 19.2291 3.54245 20.7951 4.60087 21.9399C5.65928 23.0632 7.06331 23.6248 8.81293 23.6248C10.1738 23.6248 11.4541 23.3008 12.3181 22.6528C13.2038 22.0048 13.8605 21.1623 14.0549 20.1255H16.919C16.8483 21.4125 15.8066 23.3116 14.4242 24.4348C13.0418 25.558 11.0594 26.1196 8.81293 26.1196ZM3.07805 14.9415H13.9322C13.9106 13.4726 13.4138 12.3062 12.4418 11.4422C11.4914 10.5566 10.2494 10.1138 8.71573 10.1138C7.22531 10.1138 5.98329 10.535 4.98967 11.3774C4.01766 12.2198 3.38045 13.4078 3.07805 14.9415Z" fill="#101010"/>
<path d="M40.0961 26.1196C38.3249 26.1196 36.7805 25.7308 35.4629 24.9532C34.1452 24.154 33.1192 23.0632 32.3848 21.6807C31.6504 20.2767 31.2832 18.6783 31.2832 16.8855C31.2832 15.0711 31.6396 13.4726 32.3524 12.0902C33.0868 10.7078 34.102 9.62779 35.3981 8.85018C36.7157 8.05097 38.2385 7.65137 39.9665 7.65137C41.7594 7.65137 43.3038 8.07257 44.5998 8.91498C45.9174 9.75739 46.8894 10.9022 47.5158 12.3494C48.1639 13.7966 48.3691 15.4275 48.1315 17.2419H34.1992C34.264 19.2291 34.8257 20.7951 35.8841 21.9399C36.9425 23.0632 38.3465 23.6248 40.0961 23.6248C41.457 23.6248 42.7373 23.3008 43.6013 22.6528C44.487 22.0048 45.1437 21.1623 45.3382 20.1255H48.2022C48.1315 21.4125 47.0898 23.3116 45.7074 24.4348C44.325 25.558 42.3426 26.1196 40.0961 26.1196ZM34.3612 14.9415H45.2154C45.1938 13.4726 44.697 12.3062 43.725 11.4422C42.7746 10.5566 41.5326 10.1138 39.9989 10.1138C38.5085 10.1138 37.2665 10.535 36.2729 11.3774C35.3009 12.2198 34.6637 13.4078 34.3612 14.9415Z" fill="#101010"/>
<path d="M55.8033 26.1193L48.9668 7.68359H52.0772L57.3585 22.4256L62.6074 7.68359H65.6206L58.7517 26.1193H55.8033Z" fill="#101010"/>
<path d="M19.1094 26.1063V23.7086H22.7058V5.36523H19.8086V3H25.5895V23.7086H29.1211V26.1063H19.1094Z" fill="#101010"/>
<path d="M75.1705 26.1196C73.4857 26.1196 71.9737 25.7308 70.6345 24.9532C69.2952 24.1756 68.2368 23.0956 67.4592 21.7131C66.7032 20.3091 66.3252 18.6999 66.3252 16.8855C66.3252 15.0495 66.7032 13.4402 67.4592 12.0578C68.2368 10.6754 69.2952 9.59539 70.6345 8.81778C71.9737 8.04017 73.4857 7.65137 75.1705 7.65137C76.877 7.65137 78.3998 8.04017 79.739 8.81778C81.0782 9.59539 82.1258 10.6754 82.8818 12.0578C83.6595 13.4402 84.0483 15.0495 84.0483 16.8855C84.0483 18.6999 83.6595 20.3091 82.8818 21.7131C82.1258 23.0956 81.0782 24.1756 79.739 24.9532C78.3998 25.7308 76.877 26.1196 75.1705 26.1196ZM75.1705 23.5924C76.337 23.5924 77.363 23.3116 78.2486 22.75C79.1558 22.1668 79.8578 21.3783 80.3546 20.3847C80.873 19.3695 81.1322 18.2031 81.1322 16.8855C81.1322 15.5679 80.873 14.4015 80.3546 13.3862C79.8578 12.371 79.1558 11.5826 78.2486 11.021C77.363 10.4378 76.337 10.1462 75.1705 10.1462C74.0257 10.1462 73.0105 10.4378 72.1249 11.021C71.2393 11.5826 70.5373 12.371 70.0189 13.3862C69.522 14.4015 69.2736 15.5679 69.2736 16.8855C69.2736 18.2031 69.522 19.3695 70.0189 20.3847C70.5373 21.3783 71.2393 22.1668 72.1249 22.75C73.0105 23.3116 74.0257 23.5924 75.1705 23.5924Z" fill="#101010"/>
<path d="M120.547 17.455C120.547 25.2108 114.26 31.4981 106.504 31.4981C98.7481 31.4981 92.4607 25.2108 92.4607 17.455C92.4607 9.69922 98.7481 3.41191 106.504 3.41191C114.26 3.41191 120.547 9.69922 120.547 17.455Z" stroke="#101010" stroke-width="0.823822"/>
<path d="M111.949 24.2471V10.2646H113.835V24.2471H111.949Z" fill="#101010"/>
<path d="M97.2109 24.2471L102.931 10.2646H105.083L110.783 24.2471H108.774L107.297 20.6516H100.635L99.1585 24.2471H97.2109ZM101.25 19.0736H106.703L103.976 12.362L101.25 19.0736Z" fill="#101010"/>
</svg>`;
export default aiLogo;
