import { p__ } from 'helpers/i18n';

const requestTableFilters = () => [
  {
    param: 'waiting_for_approval',
    label: p__('TrainingRequestStatuses', 'Waiting for approval'),
  },
  {
    param: 'approved',
    label: p__('TrainingRequestStatuses', 'Approved'),
  },
  {
    param: 'refused',
    label: p__('TrainingRequestStatuses', 'Refused'),
  },
  { param: 'all', label: p__('TrainingRequestStatuses', 'All') },
];

export default requestTableFilters;
