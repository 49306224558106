import React, { useContext, useState } from 'react';

import { BudgetItem, FundingSource } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import { CurrentTrainingPeriodContext } from '../../..';
import BudgetSummary from './BudgetSummary';
import ExchangeRates from './ExchangeRates';
import FundingSourcesList from './FundingSourcesList';

/** budgetItem is serialized by DeprecatedFundingSourceSerializer */
export type DeprecatedFundingSource = FundingSource & {
  budgetItem: BudgetItem;
};

type Props = {
  periodSlug: string;
  onClose: () => void;
};

const BudgetManagementModal = ({ onClose, periodSlug }: Props) => {
  const organization = useCurrentOrganization();
  const trainingCurrenciesEnabled =
    organization.featureFlags.includes('trainingCurrencies');
  const trainingPeriod = useContext(CurrentTrainingPeriodContext);

  const [fundingSourcesCurrencies, setFundingSourcesCurrencies] = useState<
    string[]
  >([]);

  return (
    <ModalCard isActive refreshContentOnOpening onClose={onClose} isLarge>
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('Configure the budget')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody style={{ paddingBottom: 16 }}>
        {trainingCurrenciesEnabled ? (
          <>
            <FundingSourcesList
              setFundingSourcesCurrencies={setFundingSourcesCurrencies}
              periodSlug={periodSlug}
            />
            <hr />
            {trainingPeriod && (
              <ExchangeRates
                fundingSourcesCurrencies={fundingSourcesCurrencies}
                trainingPeriod={trainingPeriod}
              />
            )}
            <hr />
            <BudgetSummary periodSlug={periodSlug} />
          </>
        ) : (
          <>
            <BudgetSummary periodSlug={periodSlug} />
            <hr />
            <FundingSourcesList
              setFundingSourcesCurrencies={setFundingSourcesCurrencies}
              periodSlug={periodSlug}
            />
          </>
        )}
      </ModalCardBody>
      <ModalCardFooter>
        <Button onClick={onClose} color="secondary">
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default BudgetManagementModal;
