import React, { ReactNode } from 'react';

import {
  MeasurablePosition,
  Submittable,
  Toggleable,
  ValueManager,
} from 'components/behaviors';

import Outline from './Outline';

export type RenderEditedParams<T> = {
  submitIfChanged: (value: T | null | undefined) => Promise<any>;
  isSubmitting: boolean;
  hasError: boolean;
  hasWarning: boolean;
  toggle: () => void;
  onChange: (value: T | null | undefined) => void;
  value: T | null | undefined;
  targetPosition: MeasurablePosition;
};

type Props<T> = {
  value: T | null | undefined;
  onSubmit: (newValue: T | null | undefined) => Promise<unknown> | unknown;
  renderEdited: (targetPosition: RenderEditedParams<T>) => ReactNode;
  renderNotEdited: () => ReactNode;
  autoFocus?: boolean;
  withEditIcon?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  onBlur?: () => any;
  onFocus?: () => any;
};

export default function InlineEditableWrapper<T>({
  value,
  onSubmit,
  renderEdited,
  renderNotEdited,
  autoFocus,
  withEditIcon = false,
  hasError = false,
  hasWarning = false,
  onBlur,
  onFocus,
  isDisabled,
}: Props<T>) {
  return (
    <div className="inline-editable-wrapper">
      {isDisabled && (
        <div className="test-inline-editable-field">{renderNotEdited()}</div>
      )}
      {!isDisabled && (
        <div className="inline-editable test-inline-editable-field">
          <Toggleable
            initiallyToggled={autoFocus}
            onToggle={isToggled => {
              !isToggled && onBlur && onBlur();
              isToggled && onFocus && onFocus();
            }}
            render={(isToggled, toggle) => (
              <Outline
                onClick={toggle}
                isEdited={isToggled}
                withEditIcon={withEditIcon}
                hasError={hasError}
                hasWarning={hasWarning}
                renderEdited={targetPosition => {
                  return (
                    <Submittable<T | null | undefined>
                      doSubmit={async value => {
                        if (onSubmit) await onSubmit(value);
                        if (isToggled) toggle();
                      }}
                      doCancel={toggle}
                      originalValue={value}
                      render={(submitIfChanged, isSubmitting, hasError) => (
                        <ValueManager
                          initialValue={value}
                          render={(onChange, value) =>
                            renderEdited({
                              submitIfChanged,
                              isSubmitting,
                              hasError,
                              hasWarning,
                              toggle,
                              onChange,
                              value,
                              targetPosition,
                            })
                          }
                        />
                      )}
                    />
                  );
                }}
                renderNotEdited={renderNotEdited}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
