import React, { useMemo } from 'react';

import { ScaleScore, ScaleSpecificStats, SurveyScaleQuestion } from 'models';

import { __ } from 'helpers/i18n';

import { FlatPieChart, Flex, Text } from 'components';

import QuestionScore from '../../components/QuestionScore';
import { statsToChartData } from '../helpers/scaleStatsHelper';

type Props = {
  question: SurveyScaleQuestion;
  score: ScaleScore;
  stats: ScaleSpecificStats;
};

const ScaleStats = ({ question, score, stats }: Props) => {
  const data = useMemo(
    () => statsToChartData(question, stats),
    [question, stats]
  );

  return (
    <div className="grow">
      <Text preset="18bs5">{__('Average rating')}</Text>
      <Flex className="mt-4">
        <QuestionScore score={score} question={question} />
        <div className="ml-16 grow">
          <FlatPieChart data={data} valuePositionInLegend="hidden" />
        </div>
      </Flex>
    </div>
  );
};

export default ScaleStats;
