import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { PageTitle } from 'components';

import AreaEditor from './Index/AreaEditor';
import AreaList from './Index/AreaList';
import AreaMatrixList from './Index/AreaMatrixList';
import EmployeesOverview from './Index/EmployeesOverview';
import MatrixEmployeesList from './Index/MatrixEmployeesList';
import MatrixExplorer from './Index/MatrixExplorer';
import MatrixList from './Index/MatrixList';
import MatrixVisualizer from './Index/MatrixVisualizer';

export default function SkillsRoutes() {
  const organization = useCurrentOrganization();

  const canAccessToMatrixEdition = can({
    perform: 'show_matrix_edition_menu',
    on: organization,
  });

  return (
    <>
      <PageTitle title={[__('Matrices and skills'), __('Admin')]} />

      <Routes>
        <Route index path="matrices/explorer" element={<MatrixExplorer />} />
        <Route path="matrices/:id" element={<MatrixVisualizer />} />
        {canAccessToMatrixEdition && (
          <>
            <Route path="matrices" element={<MatrixList />} />
            <Route
              path="matrices/:id/edit"
              element={<MatrixVisualizer editionMode />}
            />
            <Route
              path="matrices/:id/employees"
              element={<MatrixEmployeesList />}
            />
            <Route path="areas" element={<AreaList />} />
            <Route path="areas/:id/edit" element={<AreaEditor />} />
            <Route path="areas/:id/matrices" element={<AreaMatrixList />} />
            <Route path="employees" element={<EmployeesOverview />} />
          </>
        )}
        <Route path="*" element={<Navigate to="matrices" />} />
      </Routes>
    </>
  );
}
