import React, { useState } from 'react';

import type { PeopleUserReview, PeopleUserReviewStatus } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToPeopleUserReview } from 'helpers/navigation';
import confirmAsync from 'helpers/react/confirmAsync';

import { del, put } from 'redux/actions/api';

import {
  BoxListItem,
  Column,
  Columns,
  HamburgerMenu,
  MenuItem,
  MenuList,
  PullRight,
  Text,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import PeopleUserReviewStatusTag from 'scenes/components/peopleUserReview/PeopleUserReviewStatusTag';

import PeopleGroupTagList from '../components/PeopleGroupTagList';
import ChangeReviewerModal from './ChangeReviewerModal';

type Props = {
  peopleUserReview: PeopleUserReview;
  refetchData: () => Promise<void>;
  peopleReviewCycleName: string;
};

const PeopleUserReviewListItem = ({
  peopleUserReview,
  refetchData,
  peopleReviewCycleName,
}: Props) => {
  const dispatch = useAppDispatch();
  const { status, user, reviewer } = peopleUserReview;
  invariant(reviewer, 'There should be a reviewer for this people review.');

  const [changeReviewerModalActive, setChangeReviewerModalActive] =
    useState<boolean>(false);

  const confirmReviewDeletion = async () => {
    await confirmAsync(
      __('Remove %1 from cycle?', user.firstName),
      __(
        'If you remove %1 from the cycle their people review comments will be removed.',
        user.fullName
      ),
      {
        sideEffectsLabel: __('Please confirm the action:'),
        sideEffects: [__('Remove comments from people review')],
        confirmLabel: __('Remove %1 from cycle', user.firstName),
        onConfirm: async () => {
          await dispatch(del(`people_user_reviews/${peopleUserReview.id}`));
          return refetchData();
        },
      }
    );
  };

  const updateStatus = async (status: PeopleUserReviewStatus) =>
    dispatch(put(`people_user_reviews/${peopleUserReview.id}`, { status }));

  const canDestroy = can({
    perform: 'destroy',
    on: peopleUserReview,
  });
  const canUpdateReviewer = can({
    perform: 'update_reviewer',
    on: peopleUserReview,
  });
  const canSetStatusToToDo = can({
    perform: 'set_status_to_todo',
    on: peopleUserReview,
  });
  const canSetStatusToCompleted = can({
    perform: 'set_status_to_completed',
    on: peopleUserReview,
  });
  const canSetStatusToValidated = can({
    perform: 'set_status_to_validated',
    on: peopleUserReview,
  });
  const canPerformSomeActions = [
    canDestroy,
    canUpdateReviewer,
    canSetStatusToToDo,
    canSetStatusToCompleted,
    canSetStatusToValidated,
  ].includes(true);

  return (
    <BoxListItem
      testClassName={`test-people-user-review-item test-people-user-review-item-${peopleUserReview.id}`}
    >
      <Columns contentVerticallyAligned>
        <Column size={3}>
          <UserAvatar
            user={user}
            link={
              can({ perform: 'show_content', on: peopleUserReview })
                ? pathToPeopleUserReview(peopleUserReview.id)
                : undefined
            }
            withJobTitle
          />
        </Column>
        <Column size={3}>
          <UserAvatar user={reviewer} withJobTitle />
        </Column>
        <Column size={3}>
          {peopleUserReview.status === 'to_do' ? (
            <Text color="grey">{'-'}</Text>
          ) : can({ perform: 'show_people_groups', on: peopleUserReview }) ? (
            <PeopleGroupTagList
              peopleGroups={peopleUserReview.peopleGroups || []}
            />
          ) : (
            <Text transformation="italic" color="grey">
              {__(
                'You do not have the permission to view associated groups of employees'
              )}
            </Text>
          )}
        </Column>
        <Column size={2}>
          <PeopleUserReviewStatusTag
            isLocked={peopleUserReview.isLocked}
            status={status}
          />
        </Column>
        {canPerformSomeActions && (
          <Column size={1}>
            <PullRight>
              <HamburgerMenu>
                <MenuList>
                  {canUpdateReviewer && (
                    <MenuItem
                      onClick={() => setChangeReviewerModalActive(true)}
                    >
                      <Text>{__('Change reviewer')}</Text>
                    </MenuItem>
                  )}
                  {canSetStatusToToDo && (
                    <MenuItem onClick={() => updateStatus('to_do')}>
                      <Text>{__('Change status to "to do"')}</Text>
                    </MenuItem>
                  )}
                  {canSetStatusToCompleted && (
                    <MenuItem onClick={() => updateStatus('completed')}>
                      <Text>{__('Change status to "completed"')}</Text>
                    </MenuItem>
                  )}
                  {canSetStatusToValidated && (
                    <MenuItem onClick={() => updateStatus('validated')}>
                      <Text>{__('Change status to "validated"')}</Text>
                    </MenuItem>
                  )}
                  {canDestroy && (
                    <MenuItem
                      key="delete"
                      isDanger
                      onClick={confirmReviewDeletion}
                    >
                      <Text>{__('Remove from cycle')}</Text>
                    </MenuItem>
                  )}
                </MenuList>
              </HamburgerMenu>
            </PullRight>
          </Column>
        )}
      </Columns>
      {canUpdateReviewer && changeReviewerModalActive && (
        <ChangeReviewerModal
          peopleUserReviewId={peopleUserReview.id}
          reviewee={user}
          reviewer={reviewer}
          onClose={() => setChangeReviewerModalActive(false)}
          refetchData={refetchData}
          peopleReviewCycleName={peopleReviewCycleName}
        />
      )}
    </BoxListItem>
  );
};

export default PeopleUserReviewListItem;
