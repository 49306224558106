import React, { useEffect, useState } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { ReviewedDimension } from 'models';

import useUrlQueryParams from 'helpers/hooks/useUrlQueryParams';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { hasNoNullableValues } from 'types/predicates/NoNullValues';

import { EmptyStateWithIcon, FetchContainer, Text } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserMultiFilters from 'scenes/components/UserMultiFilters';

import DimensionPicker from './DimensionPicker';
import PeopleReviewCycleHeatmap from './PeopleReviewCycleHeatmap';

type Props = {
  cycleId: string;
};

export type SelectedDimensionIds = {
  x: null | string;
  y: null | string;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    reviewedDimensions: Array<ReviewedDimension>;
  };

const PeopleReviewCycleMapping = ({
  cycleId,
  reviewedDimensions,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  const { urlQueryParams, replaceHistory } = useUrlQueryParams();
  const [selectedDimensionIds, setSelectedDimensionIds] =
    useState<SelectedDimensionIds>({
      // @ts-expect-error TSFIXME x does't exist in PaginationSearchParams
      x: urlQueryParams().x || null,
      // @ts-expect-error TSFIXME y does't exist in PaginationSearchParams
      y: urlQueryParams().y || null,
    });

  useEffect(() => {
    replaceHistory({
      ...urlQueryParams(),
      x: selectedDimensionIds.x,
      y: selectedDimensionIds.y,
    });
  }, [
    urlQueryParams,
    replaceHistory,
    selectedDimensionIds.x,
    selectedDimensionIds.y,
  ]);

  const [userFilters, setUserFilters] = useState<ActiveFilters | null>(
    urlQueryParams().userFilters || null
  );

  const onUserFiltersChange = userFilters => {
    setUserFilters(userFilters);
    replaceHistory({ ...urlQueryParams(), userFilters });
  };

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <>
          <Text>
            {__(
              'View the position of your employees by selecting two dimensions:'
            )}
          </Text>
          <div className="flex items-end justify-between mt-4">
            <DimensionPicker
              reviewedDimensions={reviewedDimensions}
              selectedDimensionIds={selectedDimensionIds}
              setSelectedDimensionIds={setSelectedDimensionIds}
            />

            <UserMultiFilters
              onChange={({ userFilters }) => onUserFiltersChange(userFilters)}
              userFilters={userFilters}
            />
          </div>
          <div className="mt-4">
            {hasNoNullableValues(selectedDimensionIds) ? (
              <PeopleReviewCycleHeatmap
                cycleId={cycleId}
                selectedDimensionIds={selectedDimensionIds}
                userFilters={userFilters}
              ></PeopleReviewCycleHeatmap>
            ) : (
              <EmptyStateWithIcon
                title={__('Start analyzing your people reviews')}
                iconName="equalizer"
                description={__(
                  'Select two dimensions using the drop-down lists above'
                )}
              ></EmptyStateWithIcon>
            )}
          </div>
        </>
      )}
    />
  );
};

export default newDataLoader({
  fetch: ({ cycleId }: Props) =>
    get(`people_review_cycles/${cycleId}/reviewed_dimensions`),
  hydrate: {
    reviewedDimensions: {},
  },
})(PeopleReviewCycleMapping) as React.ComponentType<Props>;
