import React, { ReactNode, createContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { compose } from 'redux';

import { SurveyCampaign } from 'models';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { useAppDispatch } from 'helpers/hooks';
import useUrlQueryParams from 'helpers/hooks/useUrlQueryParams';
import { __ } from 'helpers/i18n';
import {
  pathToSurveyCampaignConversations,
  pathToSurveyCampaignOverview,
  pathToSurveyCampaignPreview,
  pathToSurveyCampaignResults,
  pathToSurveyCampaigns,
} from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { AppDispatch } from 'redux/actions';
import { get, post } from 'redux/actions/api';

import {
  Button,
  ButtonMenu,
  FetchContainer,
  MenuItem,
  MenuList,
  PageHeader,
  PageTitle,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';
import { withMatchParams } from 'components/HOCs/withMatchParams';
import { NavigationItem } from 'components/navigation/Tabs/NavigationList';

import ExportModal from 'scenes/admin/components/ExportModal';
import StatusTag from 'scenes/components/StatusTag';

import ArchiveMenuItem from '../components/ArchiveMenuItem';
import Conversations from './Conversations';
import DetailedResults from './DetailedResults';
import ExportButton from './ExportButton';
import Overview from './Overview';
import {
  pollingPeriodLaunchInfo,
  pollingPeriodNextLaunchInfo,
  pollingPeriodParticipationCreationInfo,
} from './helpers/pollingPeriodLaunchInfo';
import useUpdateCampaignStatus from './hooks/useUpdateCampaignStatus';

const TitleWithStatusAndPeriodInfo = ({
  surveyCampaign,
}: {
  surveyCampaign: SurveyCampaign;
}) => {
  let periodInfo: ReactNode;
  if (surveyCampaign.status === 'in_progress') {
    if (surveyCampaign.anyInProgressParticipationCreation) {
      periodInfo = pollingPeriodParticipationCreationInfo(surveyCampaign);
    } else {
      periodInfo = pollingPeriodLaunchInfo(surveyCampaign);
    }
  } else {
    periodInfo = pollingPeriodNextLaunchInfo(surveyCampaign);
  }

  return (
    <div className="flex flex-col">
      <div className="flex">
        <Text preset="24bs2" className="mr-4">
          {surveyCampaign.name}
        </Text>

        <StatusTag status={surveyCampaign.status} />
      </div>

      {(surveyCampaign.status === 'in_progress' ||
        surveyCampaign.status === 'scheduled') && (
        <Text preset="13s7" className="mt-2">
          {periodInfo}
        </Text>
      )}
    </div>
  );
};

const OtherActionsItems = ({
  surveyCampaign,
  dispatch,
}: {
  surveyCampaign: SurveyCampaign;
  dispatch: AppDispatch;
}) => {
  const handleSendReminder = async () => {
    await confirmAsync(
      __('Send a reminder'),
      __(
        'Send a reminder email to participants who have not sent their answers?'
      ),
      {
        confirmLabel: __('Send a reminder'),
        onConfirm: async () => {
          await dispatch(
            post(
              `survey/campaigns/${surveyCampaign.id}/send_reminder`,
              undefined,
              {
                successMessage: __('Emails are on their way! 📧'),
              }
            )
          );
        },
      }
    );
  };

  const otherActionsItems: ReactNode[] = [];

  if (can({ perform: 'send_reminder', on: surveyCampaign })) {
    otherActionsItems.push(
      <MenuItem key="reminder" onClick={handleSendReminder}>
        <Text>{__('Send reminder')}</Text>
      </MenuItem>
    );
  }

  if (can({ perform: 'archive', on: surveyCampaign })) {
    otherActionsItems.push(
      <ArchiveMenuItem
        key="archive"
        campaign={surveyCampaign}
        redirectTo={pathToSurveyCampaigns()}
      />
    );
  }

  if (can({ perform: 'preview', on: surveyCampaign })) {
    otherActionsItems.push(
      <MenuItem
        key="preview"
        to={pathToSurveyCampaignPreview(surveyCampaign.id)}
        openInNewTab
      >
        <Text>{__('Preview form')}</Text>
      </MenuItem>
    );
  }

  if (otherActionsItems.length === 0) return null;

  return (
    <ButtonMenu text={__('Other actions')}>
      <MenuList>{otherActionsItems}</MenuList>
    </ButtonMenu>
  );
};

type DataContextType = {
  maxFiltersCount: number;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};
type Props = AfterMatchParamsProps;
type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    surveyCampaign: SurveyCampaign;
  };

const Campaign = ({
  match: { id: campaignId },
  isFetching,
  hasError,
  surveyCampaign,
  refetchData,
}: AfterDataLoaderProps) => {
  const { urlQueryParams } = useUrlQueryParams();
  // @ts-expect-error: TSFIXME questionId doesn't exist in Partial<PaginationSearchParams>
  const questionId = urlQueryParams().questionId;
  // @ts-expect-error: TSFIXME periodId doesn't exist in Partial<PaginationSearchParams>
  const periodId = urlQueryParams().periodId || surveyCampaign?.lastPeriodId;
  // @ts-expect-error: TSFIXME correlationSlug doesn't exist in Partial<PaginationSearchParams>
  const correlationSlug = urlQueryParams().correlationSlug;
  const organization = useCurrentOrganization();

  const tabItems: NavigationItem[] = [
    {
      label: __('Overview'),
      to: pathToSurveyCampaignOverview(campaignId, periodId),
    },
    {
      label: __('Detailed results'),
      to: pathToSurveyCampaignResults(campaignId, questionId, periodId),
    },
  ];
  if (organization.featureFlags.includes('surveyMessagingChatRooms')) {
    tabItems.push({
      label: __('Conversations'),
      to: pathToSurveyCampaignConversations(campaignId, periodId),
    });
  }
  const dispatch = useAppDispatch();
  const [exportModalIsActive, setExportModalIsActive] = useState(false);

  const [userFilters, setUserFilters] = useState<ActiveFilters | ''>('');

  const { confirmLaunch, launchCampaign, handleSubmitErrors } =
    useUpdateCampaignStatus(campaignId);

  const handleLaunchCampaignButton = async () => {
    try {
      if (!(await confirmLaunch())) return;
      await launchCampaign();
      await refetchData();
    } catch (error: unknown) {
      handleSubmitErrors(error);
    }
  };

  const exportSurveyCampaign = async () => {
    await dispatch(post(`survey/campaigns/${surveyCampaign.id}/export`));

    setExportModalIsActive(true);
  };

  return (
    <DataContext.Provider
      value={{ maxFiltersCount: surveyCampaign?.maxFiltersCount }}
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        renderFetching={() => (
          <PageHeader
            title={__('Loading…')}
            tabItems={tabItems}
            withBackButton
          />
        )}
        render={() => (
          <>
            <PageTitle title={[__('Surveys'), surveyCampaign.name]} />
            <PageHeader
              tabItems={tabItems}
              withBackButton
              backButtonProps={{
                children: __('Back to campaigns'),
                target: pathToSurveyCampaigns(),
              }}
              actions={
                <>
                  <OtherActionsItems
                    surveyCampaign={surveyCampaign}
                    dispatch={dispatch}
                  />

                  {can({ perform: 'export', on: surveyCampaign }) && (
                    <ExportButton
                      lastPendingExportAction={
                        surveyCampaign.lastPendingExportAction
                      }
                      exportSurveyCampaign={exportSurveyCampaign}
                    />
                  )}

                  {can({ perform: 'launch', on: surveyCampaign }) && (
                    <Button
                      color="primary"
                      onClick={handleLaunchCampaignButton}
                      testClassName="test-survey-launch-campaign-button"
                    >
                      {__('Launch campaign')}
                    </Button>
                  )}
                </>
              }
            >
              <TitleWithStatusAndPeriodInfo surveyCampaign={surveyCampaign} />
            </PageHeader>

            <div className="m-4 md:m-6">
              <Routes>
                <Route
                  path="overview"
                  element={
                    <Overview
                      surveyCampaign={surveyCampaign}
                      periodId={periodId}
                      userFilters={userFilters}
                      onUserFiltersChange={setUserFilters}
                    />
                  }
                />
                <Route
                  path="results"
                  element={
                    <DetailedResults
                      questionId={questionId}
                      selectedPeriodId={periodId}
                      userFilters={userFilters}
                      onUserFiltersChange={setUserFilters}
                      correlationSlug={correlationSlug}
                      campaign={surveyCampaign}
                    />
                  }
                />
                <Route
                  path="conversations"
                  element={
                    <Conversations
                      selectedPeriodId={periodId}
                      userFilters={userFilters}
                      onUserFiltersChange={setUserFilters}
                      campaign={surveyCampaign}
                    />
                  }
                />
              </Routes>
            </div>
          </>
        )}
      />

      {exportModalIsActive && (
        <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
      )}
    </DataContext.Provider>
  );
};

export default compose(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`survey/campaigns/${match.id}`),
    hydrate: {
      surveyCampaign: {
        abilities: {},
        lastPendingExportAction: {},
        pollingPeriods: { abilities: {} },
      },
    },
  })
)(Campaign) as React.ComponentType<{}>;
