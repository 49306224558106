import React, { useState } from 'react';

import {
  SurveyMultipleChoiceQuestion,
  SurveyQuestionChoice,
  SurveyQuestionFormStep,
  SurveySingleChoiceQuestion,
} from 'models';

import store from 'config/store';

import { FormErrors, handleFormErrors } from 'helpers/api';
import resolveRelationships from 'helpers/data/resolveRelationships';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del, post, put } from 'redux/actions/api';

import { DragAndDropContainer, FieldError, Text } from 'components';

import QuestionChoice from './QuestionChoice';

type Props = {
  formStep: SurveyQuestionFormStep<
    SurveyMultipleChoiceQuestion | SurveySingleChoiceQuestion
  >;
};

const QuestionChoices = ({ formStep }: Props) => {
  const question = resolveRelationships(
    store.getState().data,
    formStep.question,
    {
      choices: {},
    }
  );

  const dispatch = useAppDispatch();
  const [addErrors, setAddErrors] = useState<FormErrors>({});

  const onLabelUpdate = (choice: SurveyQuestionChoice, value: string) =>
    dispatch(
      put(`survey/form_steps/${formStep.id}/question_choices/${choice.id}`, {
        label: value,
      })
    );

  const onPositionUpdate = (choiceId: string, newIndex: number) =>
    dispatch(
      put(`survey/form_steps/${formStep.id}/question_choices/${choiceId}`, {
        position: newIndex,
      })
    );

  const onDelete = (choice: SurveyQuestionChoice) =>
    dispatch(
      del(`survey/form_steps/${formStep.id}/question_choices/${choice.id}`)
    );

  const addChoice = () =>
    handleFormErrors(async () => {
      await dispatch(
        post(`survey/form_steps/${formStep.id}/question_choices/`)
      );
      setAddErrors({});
    }, setAddErrors);

  return (
    <>
      <div>
        <div className="mb-2">
          <Text preset="14bs6">{__('Answer choices:')}</Text>
        </div>
        <DragAndDropContainer onChangePosition={onPositionUpdate}>
          <div className="flex flex-col gap-2 ml-6">
            {question.choices.map(choice => (
              <QuestionChoice
                key={choice.id}
                choice={choice}
                deletable={question.choices.length > 1}
                onLabelUpdate={label => onLabelUpdate(choice, label)}
                onDelete={() => onDelete(choice)}
                testClassName="test-survey-edition-choice"
              />
            ))}
          </div>
        </DragAndDropContainer>
        {question.choices.length < 20 && (
          <div className="mt-2 pl-12">
            <Text preset="13s7" color="light" onLinkClick={addChoice}>
              {__('Add an answer choice')}
            </Text>
            <FieldError>{addErrors['base']}</FieldError>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionChoices;
