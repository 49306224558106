/**
 * Clips an Array at `count` elements
 *
 * This is made for usage when you want to have "Something, some other thing, and X others"-type
 * wordings or UI.
 *
 * @note
 * As such, if the remainder is of size 1, it will instead return an empty remainder,
 * and move the item to the "clipped" array
 *
 * @returns Both the clipped array and the remainder.
 */
export const clipArray = <T>(
  array: Array<T>,
  count: number
): [Array<T>, Array<T>] => {
  if (array.length <= count + 1) {
    return [array, []];
  }

  const clippedArray = array.slice(0, count);
  const remainder = array.slice(count);

  return [clippedArray, remainder];
};
