import React from 'react';

import { TrainingPeriod } from 'models/TrainingPeriod';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { Text } from 'components';
import { EmptyStateWithIcon } from 'components/emptyStates';

import BudgetEnvelopes from './BudgetEnvelopes';
import ExchangeRates from './ExchangeRates';
import FundingSources from './FundingSources';
import OverallBudget from './OverallBudget';

type Props = {
  period: TrainingPeriod;
};

const BudgetDetailsBody = ({ period }: Props) => {
  const budgetByEnvelope = period?.budgetByEnvelope;

  const canManageBudget = can({
    perform: 'update_budget',
    on: period,
  });

  if (!period.hasBudgetItems && !canManageBudget) {
    return (
      <EmptyStateWithIcon
        title={__('No budget envelope')}
        description={__(
          'You are not currently responsible for any budget envelope'
        )}
        iconName="equalizer"
      />
    );
  } else if (!period.hasBudgetItems) {
    return (
      <EmptyStateWithIcon
        title={__('Start defining your budget')}
        description={__(
          'Choose your budget allocation method and add your funding sources.'
        )}
        iconName="equalizer"
      />
    );
  }
  return (
    <>
      <Text preset="18bs5">{__('Overall budget')}</Text>
      <OverallBudget periodSlug={period.slug} />
      {budgetByEnvelope ? (
        <>
          <Text preset="18bs5">{__('Budget envelopes')}</Text>
          <BudgetEnvelopes periodSlug={period.slug} />
        </>
      ) : (
        <>
          <Text preset="18bs5">{__('Funding sources')}</Text>
          <FundingSources periodSlug={period.slug} />
        </>
      )}

      <>
        <Text preset="18bs5">{__('Exchange Rates')}</Text>
        <ExchangeRates period={period} />
      </>
    </>
  );
};

export default BudgetDetailsBody;
