import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { LoggedError, Playground, UncaughtError, Up, Version } from './index';

const ServiceRoutes = () => {
  return (
    <Routes>
      <Route path="version" element={<Version />} />
      <Route path="uncaught_error" element={<UncaughtError />} />
      <Route path="logged_error" element={<LoggedError />} />
      <Route path="up" element={<Up />} />
      <Route path="playground" element={<Playground />} />
    </Routes>
  );
};

export default ServiceRoutes;
