import React, { type ReactNode } from 'react';

import type { Props as BackButtonProps } from 'components/HOCs/withBackButton';
import type { NavigationItem } from 'components/navigation/Tabs/NavigationList';

import classNames from 'helpers/classNames';
import { useIsMobile } from 'helpers/hooks';

import { BackButton, Tabs, Text } from 'components';

import MobilePageHeader from './MobilePageHeader';

type Props = {
  title?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  withBackButton?: boolean;
  backButtonProps?: BackButtonProps;
  statusInfo?: ReactNode;
  tabItems?: NavigationItem[];
  transparentBackground?: boolean;
  children?: ReactNode;
};

const PageHeader = ({
  title,
  description,
  actions,
  withBackButton,
  backButtonProps,
  statusInfo,
  tabItems,
  transparentBackground,
  children,
}: Props) => {
  const isMobile = useIsMobile();
  const tabItemsWithPreset = tabItems?.map(item => ({
    ...item,
    labelPreset: '16bs5.5' as const,
  }));

  if (isMobile)
    return (
      <MobilePageHeader
        title={title}
        description={description}
        actions={actions}
        withBackButton={withBackButton}
        backButtonProps={backButtonProps}
        statusInfo={statusInfo}
        tabItems={tabItems}
        transparentBackground={transparentBackground}
        children={children}
      />
    );

  return (
    <div
      className={classNames('page-header', {
        'page-header--transparent-background': transparentBackground,
      })}
    >
      {withBackButton && (
        <div className="page-header__back-button-container">
          <BackButton {...backButtonProps} />
        </div>
      )}

      <div
        className={classNames('page-header__title-and-actions-container', {
          'pb-6': !description && !tabItemsWithPreset,
        })}
      >
        {!!children ? (
          children
        ) : (
          <Text
            className="page-header__title-and-actions-container__title"
            preset="24bs2"
            testClassName="test-page-header__title-and-actions-container__title"
          >
            {title}
          </Text>
        )}
        <div className="flex flex-col items-end gap-3">
          {statusInfo && (
            <div className="page-header__status-container">{statusInfo}</div>
          )}
          {!!actions && (
            <div className="flex items-end justify-between">
              <div className="page-header__title-and-actions-container__actions">
                {actions}
              </div>
            </div>
          )}
        </div>
      </div>
      {description && (
        <div className="page-header__description">
          {typeof description === 'string' ? (
            <Text preset="13s7">{description}</Text>
          ) : (
            description
          )}
        </div>
      )}

      {!!tabItemsWithPreset && tabItemsWithPreset.length > 1 && (
        <Tabs tabsPosition="left" items={tabItemsWithPreset} />
      )}
    </div>
  );
};

export default PageHeader;
