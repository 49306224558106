import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import type { AppDispatch } from '../types';
import type { TeamObjectivePeriod } from 'models';

import { __ } from 'helpers/i18n';
import { pathToOrganizationObjectivePeriod } from 'helpers/navigation';
import confirmAsync from 'helpers/react/confirmAsync';

import { del } from '../api';
import { getDeleteTeamObjectivePeriodSideEffects } from './sideEffects';

export const deleteTeamObjectivePeriod =
  (teamObjectivePeriod: TeamObjectivePeriod, navigate: NavigateFunction) =>
  (dispatch: AppDispatch) => {
    const { team } = teamObjectivePeriod;

    return confirmAsync(
      __('Remove %1 from period', team.name),
      teamObjectivePeriod.objectives.length > 0
        ? __(
            'Removing team %1 from the displayed period will also delete the following items.',
            <b>{team.name}</b>
          )
        : __(
            'Are you sure you want to remove team %1 from period %2?',
            <b>{team.name}</b>,
            <b>{teamObjectivePeriod.name}</b>
          ),
      {
        onConfirm: async () => {
          await dispatch(
            del(
              `teams/${teamObjectivePeriod.team.slug}/objective_periods/${teamObjectivePeriod.slug}`
            )
          );
          navigate(pathToOrganizationObjectivePeriod(teamObjectivePeriod.slug));
        },
        confirmLabel: __('Remove %1 from period', team.name),
        sideEffects:
          getDeleteTeamObjectivePeriodSideEffects(teamObjectivePeriod),
        isDanger: true,
      }
    );
  };
