import React from 'react';
import { Route, Routes } from 'react-router-dom';

import can from 'helpers/can';
import {
  useActiveUser,
  useCurrentOrganization,
  useSession,
} from 'helpers/hooks';

import { FeatureFlagged } from 'components';

import EntityIndex from './EntityIndex';
import Integrations from './Integrations';
import NotificationsSettings from './NotificationsSettings';
import Rights from './Rights';
import SettingsRoutes from './Settings/SettingsRoutes';
import SyncDetails from './SyncDetails';
import UserList from './UserList';
import CycleRoutes from './reviewCycles/Routes';
import TemplateRoutes from './reviewTemplates/Routes';

const AdminRoutes = () => {
  const { isImpersonated } = useSession();
  const user = useActiveUser();
  const organization = useCurrentOrganization();

  const showReviewCampaignMenu = can({
    perform: 'show_review_campaign_menu',
    on: organization,
  });
  const showReviewTemplateMenu = can({
    perform: 'show_review_template_menu',
    on: organization,
  });
  const showUserDirectoryMenu = can({
    perform: 'show_user_directory_menu',
    on: organization,
  });

  return (
    <FeatureFlagged
      flag="adminRights"
      render={adminRightsEnabled => (
        <>
          <Routes>
            {showReviewCampaignMenu && (
              <Route path="cycles/*" element={<CycleRoutes />} />
            )}
            {showReviewTemplateMenu && (
              <Route path="templates/*" element={<TemplateRoutes />} />
            )}
            {showUserDirectoryMenu && (
              <Route path="users" element={<UserList />} />
            )}

            {adminRightsEnabled && user.isOrganizationAdmin && (
              <Route path="rights" element={<Rights />} />
            )}
            {isImpersonated && user.isOrganizationAdmin && (
              <Route path="entities" element={<EntityIndex />} />
            )}
            {user.isOrganizationAdmin && (
              <Route path="settings/*" element={<SettingsRoutes />} />
            )}
            {user.isOrganizationAdmin && (
              <Route path="sync/:id" element={<SyncDetails />} />
            )}
            {isImpersonated && user.isOrganizationAdmin && (
              <Route path="integrations" element={<Integrations />} />
            )}
            {user.isOrganizationAdmin && (
              <Route path="notifications" element={<NotificationsSettings />} />
            )}
          </Routes>
        </>
      )}
    />
  );
};

export default AdminRoutes;
