import React from 'react';

import type { PeopleReviewCycle, PeopleUserReview } from 'models';

import store from 'config/store';

import { useActiveUser, useAppDispatch, useShowMore } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { wait } from 'helpers/time';

import { hydrateFromResponse, hydrateFromStore } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { BoxFooter, Icon } from 'components';

import PeopleUserReviewListItem from './PeopleUserReviewListItem';

type Props = {
  peopleReviewCycle: PeopleReviewCycle;
  initialQueryMsDelay: number;
};

const peopleUserReviewHydration = {
  user: {},
};

const PeopleUserReviewList = ({
  peopleReviewCycle,
  initialQueryMsDelay,
}: Props) => {
  const activeUser = useActiveUser();

  const dispatch = useAppDispatch();
  const loadPeopleUserReviews = async (page: number) => {
    const { response } = await dispatch(
      get(`/people_review_cycles/${peopleReviewCycle.id}/reviews`, {
        page,
        reviewer_id: activeUser.id,
      })
    );

    const hydrate = {
      peopleUserReviewCollection: {
        items: peopleUserReviewHydration,
      },
      totalRecordCount: {},
    };

    return hydrateFromResponse(
      store.getState().data,
      response.body,
      hydrate,
      response.body.data.id
    );
  };

  const [
    isFetching,
    paginationInfo,
    peopleUserReviewIds,
    fetchPeopleUserReviews,
  ] = useShowMore({
    loadItems: loadPeopleUserReviews,
  });

  const peopleUserReviews: Array<PeopleUserReview> = hydrateFromStore(
    store.getState().data,
    {
      resourceType: 'peopleUserReview',
      filter: (item: PeopleUserReview) => peopleUserReviewIds.includes(item.id),
    },
    {
      userReview: peopleUserReviewHydration,
    }
  );

  React.useEffect(() => {
    wait(initialQueryMsDelay).then(() => {
      fetchPeopleUserReviews(paginationInfo.page);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {peopleUserReviews.map(peopleUserReview => (
        <PeopleUserReviewListItem
          key={peopleUserReview.id}
          peopleUserReview={peopleUserReview}
        />
      ))}

      {paginationInfo.hasNext && !isFetching && (
        <div style={{ position: 'relative' }}>
          <BoxFooter
            onClick={() => {
              fetchPeopleUserReviews(paginationInfo.page + 1);
            }}
            pagination={n__(
              '%1/%2 people review',
              '%1/%2 people reviews',
              peopleUserReviews.length,
              paginationInfo.totalRecordCount
            )}
          >
            <Icon
              size="tiny"
              style={{ verticalAlign: 'text-bottom' }}
              name="keyboard_arrow_down"
            />

            {__(
              'Show %1 more',
              Math.min(
                paginationInfo.totalRecordCount - peopleUserReviews.length,
                10
              )
            )}
          </BoxFooter>
        </div>
      )}
    </>
  );
};

export default PeopleUserReviewList;
