import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { Objective } from 'models';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader } from 'components';
import RightSidebar from 'components/RightSidebar';

import ObjectiveVersions from '../ObjectiveVersions';
import { ObjectiveContext } from '../objectiveContext';
import ObjectivesTeam from './ObjectivesTeam';
import OrganizationPeriodCreator from './OrganizationPeriodCreator';
import TeamList from './TeamList';
import TeamPage from './TeamPage';

const TeamRoutes = () => {
  const [objective, setObjective] = React.useState<Objective | null>(null);

  return (
    <>
      <PageHeader title={__('Team objectives')} />

      <ObjectiveContext.Provider value={{ objective, setObjective }}>
        <ContentContainer>
          <Routes>
            <Route index element={<OrganizationPeriodCreator />} />
            <Route path=":objectivePeriodSlug/*" element={<ObjectivesTeam />}>
              <Route index element={<TeamList />} />
              <Route path=":teamSlug" element={<TeamPage />} />
            </Route>
          </Routes>
          <Outlet />
        </ContentContainer>

        <RightSidebar
          title={objective?.title || ''}
          isOpen={!!objective}
          onClose={() => setObjective(null)}
        >
          {!!objective && <ObjectiveVersions objective={objective} />}
        </RightSidebar>
      </ObjectiveContext.Provider>
    </>
  );
};

export default TeamRoutes;
