import React from 'react';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { Select } from 'components';

import { DeprecatedFundingSource } from '.';

type Props = {
  fundingSource: DeprecatedFundingSource | undefined | null;
  fundingSources: Array<DeprecatedFundingSource>;
  onCreate: (title: string) => Promise<void>;
  onUpdate: (fundingSourceId: string) => Promise<void>;
};

type Option = {
  label: string;
  value: string;
};

const FundingSourceSelect = ({
  fundingSource,
  fundingSources,
  onCreate,
  onUpdate,
}: Props) => {
  const fundingSourceToOption = (fundingSource: DeprecatedFundingSource) => ({
    label: fundingSource.title,
    value: fundingSource.id,
  });
  const options = fundingSources.map(fundingSource =>
    fundingSourceToOption(fundingSource)
  );
  const [selectedOption, setSelectedOption] = React.useState(
    fundingSource ? fundingSourceToOption(fundingSource) : undefined
  );

  const handleOnChange = (option: Option | null | undefined) => {
    setSelectedOption(option || undefined);
    if (option) onUpdate(option.value);
  };

  const handleOnCreate = (title: string) => {
    setSelectedOption({ label: title, value: title });
    onCreate(title);
  };

  return (
    <Select<Option>
      value={selectedOption}
      options={options}
      placeholder={__('Search funding sources or create new')}
      noOptionsMessage={__('No funding source available')}
      isDisabled={
        fundingSource?.budgetItem &&
        !can({ perform: 'update', on: fundingSource.budgetItem })
      }
      isCreatable
      inModal
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      formatCreateLabel={(newLabel: string) => __('Create "%1"', newLabel)}
    />
  );
};

export default FundingSourceSelect;
