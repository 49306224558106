import React, { useContext } from 'react';

import can from 'helpers/can';
import { useObjectMutation } from 'helpers/hooks';
import useRemoteDestroy from 'helpers/hooks/mutation/useRemoteDestroy';
import { __ } from 'helpers/i18n';

import MoneyInput from 'components/formElements/advancedElements/MoneyInput';

import { BudgetFormContext } from '../../Form';
import { FormBudgetItem } from '../../types';
import BudgetItemDeletionButton from './BudgetItemDeletionButton';
import FundingSourceSelect from './FundingSourceSelect';

type Props = {
  budgetItem: FormBudgetItem;
  onChange: (value: FormBudgetItem) => void;
  onDelete: () => void;
};

const BudgetItemLine = ({ budgetItem, onChange, onDelete }: Props) => {
  const updateAttribute = useObjectMutation(budgetItem, onChange);
  const { destroyItem, isSoftDestroyed } = useRemoteDestroy(
    budgetItem,
    updateAttribute,
    onDelete
  );

  const { trainingPeriod } = useContext(BudgetFormContext);

  if (isSoftDestroyed) return null;

  return (
    <div className="flex justify-between items-center mb-4 gap-4">
      <div className="w-[500px]">
        <FundingSourceSelect
          fundingSource={budgetItem.fundingSource}
          isDisabled={!can({ perform: 'update', on: budgetItem })}
          onChange={updateAttribute('fundingSource')}
        />
      </div>
      <div className="flex w-[300px] min-w-[200px] justify-end">
        {budgetItem.fundingSource && (
          <MoneyInput
            testClassName="test-budget-item-money-input"
            placeholder={__('Amount')}
            amount={budgetItem.provisionedAmountCents}
            currency={
              budgetItem.provisionedAmountCurrency ||
              trainingPeriod.mainCurrency
            }
            saveOnlyOnBlur
            onAmountChange={updateAttribute('provisionedAmountCents')}
            onCurrencyChange={updateAttribute('provisionedAmountCurrency')}
          />
        )}
        {budgetItem && (
          <BudgetItemDeletionButton
            isDisabled={!can({ perform: 'update', on: budgetItem })}
            onClick={destroyItem}
          />
        )}
      </div>
    </div>
  );
};

export default BudgetItemLine;
