import React from 'react';
import { useParams } from 'react-router-dom';

import PreviewComponent from 'scenes/review/Preview';

type RouteContext = {
  id: string;
  role: 'reviewee' | 'reviewer';
};

const TemplatePreview = () => {
  const { role, id } = useParams() as RouteContext;

  return <PreviewComponent role={role} templateId={id} />;
};

export default TemplatePreview;
