import React from 'react';
import { compose } from 'redux';

import { CompensationBandContent } from 'models/Compensation';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer, FetchContainer } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import BandContent from './BandContent';
import BandHeader from './BandHeader';

type AfterMatchParamsProps = {
  match: { id: string };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    compensationBand: CompensationBandContent;
  };

const Band = ({
  compensationBand,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <>
            <BandHeader compensationBand={compensationBand} />

            <ContentContainer>
              <BandContent compensationBand={compensationBand} />
            </ContentContainer>
          </>
        );
      }}
    />
  );
};

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`compensation/grids/${match.id}`),
    hydrate: {
      compensationBand: {
        levels: {
          remunerations: { remunerationType: {} },
        },
      },
    },
  })
)(Band);
