import React from 'react';

import type { TrainingSession } from 'models';

import { __, n__ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';
import confirmAsync from 'helpers/react/confirmAsync';

import { Icon } from 'components';

import SessionSummary from 'scenes/trainings/PlanManagement/components/SessionSummary';

const sendInvitationsModal = (
  session: TrainingSession,
  sendCalendarInvitations: () => Promise<void>
) => {
  const unscopedParticipantCount = assertDefined(
    session.unscopedParticipantCount,
    'unscopedParticipantCount is required for sendInvitationsModal'
  );

  return confirmAsync(
    __('Send calendar invitation'),
    <>
      {n__(
        'Invite the participant to this training session by sending a calendar invitation.',
        'Invite the %1 participants to this training session by sending them a calendar invitation.',
        unscopedParticipantCount
      )}
      <br />
      <br />
      <SessionSummary session={session} />
      <br />
      <b>
        {n__(
          'Do you want to send a calendar invitation to the participant of the training session ?',
          'Do you want to send a calendar invitation to the participants of the training session ?',
          unscopedParticipantCount
        )}
      </b>
    </>,
    {
      inlineButtonIcon: <Icon style={{ marginRight: 5 }} name="send" />,
      confirmLabel: __('Send invitation'),
      onConfirm: sendCalendarInvitations,
    }
  );
};

export default sendInvitationsModal;
