import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TrainingSessionValidationActionSummary } from 'models/TrainingSession';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToValidatedTrainingPlan } from 'helpers/navigation';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import {
  Button,
  FetchContainer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import useSessionValidationSummaryContent from '../hooks/useSessionValidationSummaryContent';

type Props = {
  onClose: () => void;
  periodSlug: string;
};

type AfterDataLoaderProps = DataLoaderProvidedProps &
  Props & { summary: TrainingSessionValidationActionSummary };

const BulkValidateSessionsModal = ({
  isFetching,
  hasError,
  onClose,
  summary,
  periodSlug,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    renderTitleText,
    renderContent,
    renderValidateButtonText,
    checkedOptions,
  } = useSessionValidationSummaryContent(summary);

  const bulkValidateSessions = async () => {
    await dispatch(
      post(
        `training/periods/${periodSlug}/sessions/bulk_validate`,
        checkedOptions
      )
    );

    onClose();
    navigate(pathToValidatedTrainingPlan(periodSlug));
  };

  return (
    <ModalCard isActive isLarge onClose={onClose}>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <>
            <ModalCardHead onClose={onClose}>
              <ModalCardTitle>{renderTitleText()}</ModalCardTitle>
            </ModalCardHead>
            <ModalCardBody>{renderContent()}</ModalCardBody>
            <ModalCardFooter>
              <Button color="primary" onClick={bulkValidateSessions}>
                {renderValidateButtonText()}
              </Button>
              <Button color="secondary" onClick={onClose}>
                {__('Cancel')}
              </Button>
            </ModalCardFooter>
          </>
        )}
      />
    </ModalCard>
  );
};

export default newDataLoader({
  fetch: ({ periodSlug }: Props) =>
    get(`training/periods/${periodSlug}/sessions/bulk_validation_summary`),
  hydrate: { summary: {} },
})(BulkValidateSessionsModal);
