import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import PeopleReviewDimensions from './Dimensions';
import PeopleReviewDimensionEdit from './Dimensions/Edit';
import PeopleReviewsToComplete from './PeopleReviewsToComplete';
import PeopleUserReview from './PeopleUserReview';
import PeopleReviewCycleIndex from './cycles/Index';
import PeopleReviewCycleManage from './cycles/Manage';
import PeopleReviewActionIndex from './plannedActions';

const PeopleReviewRoutes = () => {
  return (
    <Routes>
      <Route index path="cycles" element={<PeopleReviewCycleIndex />} />
      <Route path="cycles/:id/*" element={<PeopleReviewCycleManage />} />

      <Route path="to-complete" element={<PeopleReviewsToComplete />} />

      <Route path="dimensions" element={<PeopleReviewDimensions />} />
      <Route path="dimensions/:id" element={<PeopleReviewDimensionEdit />} />

      <Route path="actions" element={<PeopleReviewActionIndex />} />

      <Route path="reviews/:id" element={<PeopleUserReview />} />

      <Route
        path="my-people-reviews"
        element={<Navigate to="to-complete" replace />}
      />
      <Route path="*" element={<Navigate to="cycles" />} />
    </Routes>
  );
};

export default PeopleReviewRoutes;
