import React from 'react';

import type {
  FeedbackableItem,
  ObjectiveCollection,
  PersonalObjective,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import transformProps from 'helpers/transformProps';

import { genericFetch } from 'lib/api/genericFetch';
import { post } from 'redux/actions/api';
import { receivedResourcesAndRelationships } from 'redux/actions/api/serialization';

import { Title } from 'components';

import { PersonalObjectiveCard } from 'scenes/components/objectives';

import { refreshEvaluationShareability } from '../../../EvaluationShareability';
import FeedbackZone from '../components/FeedbackZone';

type Props = {
  feedbackableObjective: FeedbackableItem<PersonalObjective>;
  objectiveCollection: ObjectiveCollection;
  revieweeFullName: string;
  afterUpdate?: () => Promise<void>;
  afterDestroy?: () => Promise<unknown>;
};

type AfterConnectProps = Props & {
  persistFeedbackAction: (
    evaluationId: string | null | undefined,
    feedbackableId: string | null | undefined,
    feedbackableType: string | null | undefined,
    text: string,
    rating: string | null | undefined
  ) => (dispatch: AppDispatch) => Promise<void>;
};

function FeedbackableObjective({
  feedbackableObjective,
  objectiveCollection,
  persistFeedbackAction,
  revieweeFullName,
  afterUpdate,
  afterDestroy,
}: AfterConnectProps) {
  const objective = feedbackableObjective.item;
  const dispatch = useAppDispatch();
  const { answers } = feedbackableObjective;

  const getFeedbackCount = async (): Promise<number> => {
    const {
      body: { feedbackCount },
    } = await genericFetch({
      method: 'GET',
      url: `${objectiveCollection.collectionPath}/${objective.id}/feedbacks/count`,
    });
    return feedbackCount;
  };

  const clearAnswersOnUnpublish = () =>
    dispatch(
      receivedResourcesAndRelationships(
        {
          answer: feedbackableObjective.answers.reduce((map, answer) => {
            map[answer.id] = { text: null, rating: null, isAnswered: false };
            return map;
          }, {}),
        },
        {}
      )
    );

  return (
    <PersonalObjectiveCard
      key={objective.id}
      objective={objective}
      objectiveCollection={objectiveCollection}
      afterUpdate={afterUpdate}
      afterUnpublish={clearAnswersOnUnpublish}
      afterDestroy={afterDestroy}
      collapsibleKeyResults={true}
      revieweeFullName={revieweeFullName}
      getFeedbackCount={getFeedbackCount}
    >
      {objective.published && answers.length > 0 && (
        <>
          <div className="content-separator" />

          <Title size={5} className="block-title">
            {__('Comments')}
          </Title>

          <FeedbackZone
            feedbackableItem={feedbackableObjective}
            persistAction={persistFeedbackAction}
          />
        </>
      )}
    </PersonalObjectiveCard>
  );
}

function propsTransformer() {
  return {
    persistFeedbackAction:
      (evaluationId, feedbackableId, feedbackableType, text, rating) =>
      async dispatch => {
        await dispatch(
          post('feedbacks', {
            feedback: {
              evaluationId,
              feedbackableId,
              feedbackableType,
              text,
              rating,
            },
          })
        );

        await dispatch(refreshEvaluationShareability(evaluationId));
      },
  };
}

export default transformProps(propsTransformer)(
  FeedbackableObjective
) as React.ComponentType<Props>;
