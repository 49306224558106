import React, { useEffect, useState } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/pagination/types';
import type { PaginatedCollection, TrainingRequest, User } from 'models';

import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __, dateRelative, n__ } from 'helpers/i18n';
import { approvalStatusLabelWithIcon } from 'helpers/models/trainingRequest';

import { newDataLoader } from 'lib/dataLoader';
import withPagination from 'lib/pagination/withPagination';
import { del, get } from 'redux/actions/api';

import {
  Can,
  DatatableWrapper,
  FetchContainer,
  HamburgerMenu,
  Link,
  MenuItem,
  MenuList,
  SimpleTable,
  Text,
  Tooltip,
} from 'components';

import { FAKE_ALL_PERIODS_SLUG } from 'scenes/components/TrainingPeriodPicker';
import RequestManagementModal from 'scenes/components/TrainingRequestManagementModal';
import TrainingSessionNameTableCell from 'scenes/components/TrainingSessionNameTableCell';
import deleteRequestWithConfirmation from 'scenes/trainings/helpers/deleteRequestWithConfirmation';
import disabledDeletionTooltipText from 'scenes/trainings/helpers/disabledRequestDeletionTooltipText';
import requestTableFilters from 'scenes/trainings/helpers/requestTableFilters';

import NoRecordState from './NoRecordState';
import NoResultState from './NoResultsState';

type Props = {
  user: User;
  shouldRefetchRequests: boolean;
  setShouldRefetchRequests: (value: boolean) => void;
} & WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    trainingRequestCollection: PaginatedCollection<TrainingRequest>;
  };

const RequestTable = ({
  user,
  shouldRefetchRequests,
  setShouldRefetchRequests,
  refetchData,
  page,
  countPerPage,
  queryParams: { search, filter, sort, periodSlug },
  setPreviousPageParams,
  setNextPageParams,
  setQueryParams,
  trainingRequestCollection,
  onSortChange,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const [foregroundRequest, setForegroundRequest] =
    useState<TrainingRequest | null>(null);

  useEffect(() => {
    if (shouldRefetchRequests) {
      refetchData();
      setShouldRefetchRequests(false);
    }
  }, [refetchData, shouldRefetchRequests, setShouldRefetchRequests]);

  const deleteTrainingRequest = async (request: TrainingRequest) => {
    await dispatch(
      del(
        `training/periods/${request.trainingPeriod.slug}/requests/${request.id}`
      )
    );

    refetchData();
  };

  return (
    <>
      <DatatableWrapper
        collectionInfo={trainingRequestCollection}
        search={search}
        page={page}
        countPerPage={countPerPage}
        getPreviousPage={setPreviousPageParams}
        getNextPage={setNextPageParams}
        onQueryParamsChange={setQueryParams}
        isFetching={isFetching}
        hasError={hasError}
        totalCountRenderer={(count?: number | null) =>
          n__('%1 training request', '%1 training requests', count || 0)
        }
        renderNoRecord={() => (
          <NoRecordState
            userFullName={user.fullName}
            onAllPeriods={periodSlug === FAKE_ALL_PERIODS_SLUG}
          />
        )}
        renderNoResult={() => <NoResultState userFullName={user.fullName} />}
        filter={filter}
        filters={requestTableFilters()}
        withSearch
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              rows={trainingRequestCollection?.items || []}
              keyFn={trainingRequest => trainingRequest.id}
              columns={[
                {
                  header: __('Request title'),
                  activeSort: sort && sort['title'],
                  onSort: () => onSortChange && onSortChange('title'),
                  cell: trainingRequest => (
                    <Can
                      perform={'show_admin_and_superior_only_attributes'}
                      on={trainingRequest}
                      alternativeChildren={<Text>{trainingRequest.title}</Text>}
                    >
                      <Link
                        onClick={() => setForegroundRequest(trainingRequest)}
                      >
                        {trainingRequest.title}
                      </Link>
                    </Can>
                  ),
                },
                {
                  header: __('Status'),
                  cell: request =>
                    approvalStatusLabelWithIcon(request.approvalStatus),
                },
                {
                  header: __('Related training'),
                  cell: trainingRequest => (
                    <TrainingSessionNameTableCell
                      session={trainingRequest.session}
                    />
                  ),
                },
                {
                  header: __('Creation date'),
                  activeSort: sort && sort['requestedAt'],
                  onSort: () => onSortChange && onSortChange('requestedAt'),
                  cell: request => (
                    <Text>
                      {__(
                        'Created by %1 %2',
                        request.creator.fullName,
                        dateRelative(request.requestedAt)
                      )}
                    </Text>
                  ),
                },
                can({
                  perform: 'show_training_delete_action_in_profile',
                  on: user,
                })
                  ? {
                      header: '',
                      isNarrow: true,
                      cell: request => (
                        <HamburgerMenu>
                          <MenuList>
                            <Tooltip
                              content={disabledDeletionTooltipText(request)}
                              enabled={
                                !can({ perform: 'destroy', on: request })
                              }
                            >
                              <MenuItem
                                disabled={
                                  !can({ perform: 'destroy', on: request })
                                }
                                onClick={() =>
                                  deleteRequestWithConfirmation(
                                    request,
                                    deleteTrainingRequest
                                  )
                                }
                                isDanger
                              >
                                {__('Delete')}
                              </MenuItem>
                            </Tooltip>
                          </MenuList>
                        </HamburgerMenu>
                      ),
                    }
                  : null,
              ]}
              className="mb-2"
              rowClassName="test-training-request-row"
            />
          )}
        />
      </DatatableWrapper>
      {!!foregroundRequest && (
        <RequestManagementModal
          trainingRequest={foregroundRequest}
          onClose={params => {
            setForegroundRequest(null);

            if (params?.shouldRefetchData) {
              refetchData();
            }
          }}
          linkedSessionName={foregroundRequest.session?.name || null}
          currentPeriodSlug={foregroundRequest.trainingPeriod.slug}
        />
      )}
    </>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, sort, periodSlug },
      user,
    }: AfterPaginateProps) =>
      get(`users/${user.id}/training_requests`, {
        page,
        countPerPage,
        search,
        filter,
        sort,
        periodSlug: periodSlug === FAKE_ALL_PERIODS_SLUG ? null : periodSlug,
      }),
    hydrate: {
      trainingRequestCollection: {
        items: {
          creator: {},
          trainee: {
            manager: {},
          },
          trainingPeriod: {},
          session: {},
          trainingCourse: {
            organism: {},
          },
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, sort, periodSlug },
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        filter,
        sort,
        periodSlug,
      }),
  })
)(RequestTable);
