import React from 'react';

import { __ } from 'helpers/i18n';

import { Button } from 'components';

type Props = {
  exportCompensationBands: () => Promise<void>;
};

const ExportButton = ({ exportCompensationBands }: Props) => {
  return (
    <Button color="secondary" onClick={exportCompensationBands}>
      {__('Export all bands')}
    </Button>
  );
};

export default ExportButton;
