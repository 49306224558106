import React from 'react';

import type {
  DataLoaderProvidedProps,
  UserFilterChangeMethod,
} from 'lib/dataLoader';
import type { OneOnOneReviewCycleStats, UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Column, Columns, Section } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import StatsContainer from 'scenes/admin/reviewCycles/Manage/Analytics/components/StatsContainer';

import EvaluatedObjectivesStats from './EvaluatedObjectivesStats';
import NewPeriodObjectivesStats from './NewPeriodObjectivesStats';
import ObjectiveCompletionDistributionChart from './ObjectiveCompletionDistributionChart';
import ObjectiveCompletionStats from './ObjectiveCompletionStats';
import OverallProgressChart from './OverallProgressChart';
import ReviewerEvaluationStatusesChart from './ReviewerEvaluationStatusesChart';
import SelfEvaluationStatusesChart from './SelfEvaluationStatusesChart';

type Props = {
  reviewCycleId: string;
  filter: { user: { [key: string]: boolean } };
  onFilterChange: (filter: { user: { [key: string]: boolean } }) => void;
  userFilter?: UserFilterSegment | null;
  onUserFilterChange?: UserFilterChangeMethod;
  userFilters?: ActiveFilters;
  onUserFiltersChange?:
    | ((newQueryParams: '' | ActiveFilters) => void | undefined)
    | undefined;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    stats: OneOnOneReviewCycleStats;
  };

function OneOnOneReviewCycleAnalytics({
  stats,
  isFetching,
  hasError,
  filter,
  onFilterChange,
  userFilter,
  onUserFilterChange,
  userFilters,
  onUserFiltersChange,
}: AfterConnectProps) {
  return (
    <StatsContainer
      isFetching={isFetching}
      hasError={hasError}
      filter={filter}
      onFilterChange={onFilterChange}
      userFilter={userFilter}
      userFilters={userFilters}
      onUserFiltersChange={onUserFiltersChange}
      onUserFilterChange={onUserFilterChange}
      hasStats={!!stats}
      render={() => {
        return (
          <>
            <Columns>
              {stats.selfEvaluationStatuses && (
                <Column size={6}>
                  <SelfEvaluationStatusesChart
                    statuses={stats.selfEvaluationStatuses}
                  />
                </Column>
              )}

              <Column size={6}>
                <ReviewerEvaluationStatusesChart
                  statuses={stats.reviewerEvaluationStatuses}
                />
              </Column>
            </Columns>

            {!!stats.overallProgressSeries && (
              <Section>
                <OverallProgressChart series={stats.overallProgressSeries} />
              </Section>
            )}

            {stats.evaluatedObjectivesStats && stats.objectiveCompletionStats && (
              <Columns>
                <Column size={6}>
                  <EvaluatedObjectivesStats
                    stats={stats.evaluatedObjectivesStats}
                  />
                </Column>

                <Column size={6}>
                  <ObjectiveCompletionStats
                    stats={stats.objectiveCompletionStats}
                  />
                </Column>
              </Columns>
            )}

            {stats.objectiveCompletionDistribution && (
              <Section>
                <ObjectiveCompletionDistributionChart
                  distribution={stats.objectiveCompletionDistribution}
                />
              </Section>
            )}

            {stats.newPeriodObjectivesStats && (
              <Columns>
                <Column size={6}>
                  <NewPeriodObjectivesStats
                    stats={stats.newPeriodObjectivesStats}
                  />
                </Column>
              </Columns>
            )}
          </>
        );
      }}
    />
  );
}

export default newDataLoader({
  fetch: ({ reviewCycleId, userFilter, filter, userFilters }: Props) =>
    get(`review_cycles/${reviewCycleId}/stats`, {
      userFilter,
      filter,
      userFilters,
    }),
  hydrate: {
    stats: {
      selfEvaluationStatuses: {},
      reviewerEvaluationStatuses: {},
      evaluatedObjectivesStats: {},
      objectiveCompletionStats: {},
      newPeriodObjectivesStats: {},
      overallProgressSeries: {
        selfEvaluationSharedProgress: {},
        reviewerEvaluationSharedProgress: {},
        selfEvaluationSignedProgress: {},
        reviewerEvaluationSignedProgress: {},
      },
      objectiveCompletionDistribution: {},
    },
  },
  cacheKey: ({ reviewCycleId, userFilter, filter, userFilters }: Props) =>
    compositeKey({ reviewCycleId, userFilter, filter, userFilters }),
})(OneOnOneReviewCycleAnalytics);
