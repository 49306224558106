import React, { ReactNode, useEffect, useState } from 'react';
import { DirectUploadProvider } from 'react-activestorage-provider';

import { assert } from 'helpers/invariant';

import { targetApi } from 'lib/api/constants';
import { getCredentials } from 'lib/api/credentials';

export { default as DragAndDropFileInput } from './DragAndDropFileInput';

export type FilesUploaderProps = {
  onFilesDrop: (files: File[]) => void;
  documents: Documents;
  removeDocument: (documentId: string) => void;
};

export type Document = {
  id: string;
  state: 'waiting' | 'uploading' | 'error' | 'finished';
  progress?: number;
  file: File;
  signedId: string;
};

export type Documents = {
  [key: string]: Document;
};

type Props = {
  children: (props: FilesUploaderProps) => ReactNode;
  onSuccess?: React.ComponentProps<typeof DirectUploadProvider>['onSuccess'];
};

type LoadingStatesProps = {
  uploads: Document[];
  updateDocumentsUploadingStates: (uploads: Document[]) => void;
};

const LoadingStatesProvider = ({
  uploads,
  updateDocumentsUploadingStates,
}: LoadingStatesProps) => {
  useEffect(() => {
    updateDocumentsUploadingStates(uploads);
  }, [uploads, updateDocumentsUploadingStates]);

  return null;
};

const FilesUploader = ({ children, onSuccess }: Props) => {
  const credentials = getCredentials();
  const authorizationHeader = `Bearer ${assert(
    credentials.token,
    'Credentials required for direct upload'
  )}`;
  const [documents, setDocuments] = useState<Documents>({});

  const updateDocumentsUploadingStates = (uploads: Document[]) => {
    uploads.forEach(upload => {
      if (
        !documents[upload.id] ||
        documents[upload.id].state !== upload.state ||
        documents[upload.id].progress !== upload.progress
      )
        setDocuments({ ...documents, [upload.id]: upload });
    });
  };

  const removeDocument = (documentId: string) => {
    const { [documentId]: deletedDocument, ...otherDocuments } = documents;
    setDocuments(otherDocuments);
  };

  return (
    <DirectUploadProvider
      directUploadsPath="/api/v1/direct_uploads"
      multiple
      onSuccess={onSuccess || (() => {})}
      headers={{ Authorization: authorizationHeader }}
      origin={{
        host: assert(targetApi.hostname, 'hostname must be defined'),
        port: targetApi.port || undefined,
        protocol: targetApi.httpsEnabled ? 'https' : 'http',
      }}
      render={({ handleUpload, uploads }) => {
        const onFilesDrop = (files: File[]) => {
          handleUpload(files);
        };

        return (
          <>
            <LoadingStatesProvider
              uploads={uploads}
              updateDocumentsUploadingStates={updateDocumentsUploadingStates}
            />
            {children({ onFilesDrop, documents, removeDocument })}
          </>
        );
      }}
    />
  );
};

export default FilesUploader;
