import { BudgetEnvelope, TrainingPeriodBudget } from 'models';

import { __ } from 'helpers/i18n';

import { FormBudgetEnvelope } from '../../types';

/**
 * @returns A, B, C, ..., Z, AA, AB, AC, ...
 */
function getLetter(i: number): string {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const letterIndex = i % 26;
  return (
    (i >= 26 ? getLetter(Math.floor(i / 26) - 1) : '') + alphabet[letterIndex]
  );
}

/**
 * @returns A unique name if other envelopes are provided, otherwise an empty string
 */
export const generateNewEnvelope = (
  trainingPeriod: TrainingPeriodBudget,
  budgetEnvelopes?: Array<BudgetEnvelope>
) => {
  const getUniqueName = (index: number = 0): string => {
    if (!budgetEnvelopes) return '';

    const newName = __('Envelope %1', getLetter(index));
    return budgetEnvelopes.some(envelope => envelope.name === newName)
      ? getUniqueName(index + 1)
      : newName;
  };

  return {
    id: '',
    name: getUniqueName(),
    mainCurrency: trainingPeriod.mainCurrency,
    budgetItems: [] as FormBudgetEnvelope['budgetItems'],
    responsibles: [] as FormBudgetEnvelope['responsibles'],
  };
};
