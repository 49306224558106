import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { FundingSourceStats, TrainingPlanBudgetStats } from 'models';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  FetchContainer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import Stats from './Stats';

type Props = {
  periodSlug: string;
  isValidatedPlan: boolean;
  canManageBudget: boolean;
  stats: TrainingPlanBudgetStats;
  onClose: () => void;
  openBudgetManagementModal: () => void;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    fundingSourcesStats: FundingSourceStats;
  };

const BudgetDetailsModal = ({
  canManageBudget,
  stats,
  onClose,
  openBudgetManagementModal,
  fundingSourcesStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <ModalCard
      isActive
      isLarge
      refreshContentOnOpening
      onClose={onClose}
      className="budget-details-modal"
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('My budget')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="alone"
          render={() => {
            if (!fundingSourcesStats) return null;

            return (
              <Stats stats={stats} fundingSourcesStats={fundingSourcesStats} />
            );
          }}
        />
      </ModalCardBody>

      <ModalCardFooter>
        {canManageBudget && (
          <Button
            onClick={() => {
              onClose();
              openBudgetManagementModal();
            }}
            color="secondary"
          >
            {__('Edit')}
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default newDataLoader({
  fetch: ({ periodSlug, isValidatedPlan }: Props) =>
    get(
      `training/periods/${periodSlug}/plans/${
        isValidatedPlan ? 'validated' : 'forecast'
      }/funding_sources_stats`
    ),
  hydrate: {
    fundingSourcesStats: {
      budgetItem: {
        abilities: {},
      },
    },
  },
})(BudgetDetailsModal) as React.ComponentType<Props>;
