import React, { useContext } from 'react';

import type { ReviewCycle } from 'models';

import {
  formatFilterToParamsHash,
  formatParamsHashToFilterName,
} from 'helpers/filter';
import { __ } from 'helpers/i18n';
import useUrlPersistedState from 'helpers/useUrlPersistedState';

import {
  ContentContainer,
  FilterBar,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  PageTitle,
} from 'components';

import ParticipantList from 'scenes/admin/reviewCycles/Manage/Participants';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import { DataContext } from '..';
import ManagerReviewCycleAnalytics from './components/ManagerReviewCycleAnalytics';

type Props = {
  reviewCycle: ReviewCycle;
};

const ManagerCycleAnalytics = ({ reviewCycle }: Props) => {
  const { userFilters, setUserFilters } = useContext(DataContext);

  const filters = [
    { param: 'active', label: __('Active') },
    { param: 'suspended', label: __('Suspended') },
    { param: 'all', label: __('All') },
  ];

  const [filter, setFilter] = useUrlPersistedState<{
    [key: string]: { [key: string]: boolean } | boolean;
  }>('filter', { all: true, user: { active: true } }, true);

  const activeFilter = formatParamsHashToFilterName(
    filter.user as { [key: string]: boolean }
  );

  return (
    <ContentContainer>
      <PageTitle title={[reviewCycle.name, __('Progress')]} />

      <Level style={{ marginBottom: 16 }}>
        <LevelLeft>
          <LevelItem>
            <UserMultiFilters
              userFilters={userFilters}
              onChange={({ userFilters }) => setUserFilters(userFilters)}
              maxFiltersCount={5}
            />
          </LevelItem>
        </LevelLeft>
        <LevelRight style={{ textAlign: 'end' }}>
          <FilterBar
            display="link"
            leftText={__('Show')}
            filters={filters}
            activeFilter={activeFilter}
            onFilterChange={(param: string) => {
              setFilter({ ...filter, user: formatFilterToParamsHash(param) });
            }}
          />
        </LevelRight>
      </Level>

      <ManagerReviewCycleAnalytics
        reviewCycleId={reviewCycle.id}
        reviewCycleType={reviewCycle.interactionType}
        filter={{ user: filter.user }}
        userFilters={userFilters}
      />
      <ParticipantList
        reviewCycle={reviewCycle}
        hideUserFilters
        defaultFilter={{ all: true }}
        additionalFilter={{ user: filter.user }}
        additionalQueryParams={{ userFilters }}
      />
    </ContentContainer>
  );
};

export default ManagerCycleAnalytics;
