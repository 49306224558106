import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const TrainingsRedirectRoutes = () => {
  const location = useLocation();

  const newPath = location.pathname
    .replace('/admin/trainings', '/trainings/plan')
    .replace(/\/(forecast|validated)-plan/, '/$1');

  return (
    <Navigate
      to={`${newPath}${location.search}`}
      state={location.state}
      replace
    />
  );
};

export default TrainingsRedirectRoutes;
