import React from 'react';

import { ExchangeRate, TrainingPeriod } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { toLocaleDecimal } from 'helpers/locale';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, FetchContainer, Text } from 'components';

type Props = {
  period: TrainingPeriod;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    exchangeRates: Array<ExchangeRate>;
  };

const ExchangeRates = ({
  exchangeRates,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  return (
    <Box>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <div className="flex flex-col gap-2">
            {exchangeRates.map(exchangeRate => (
              <Text preset="14s6" key={exchangeRate.id}>
                {__(
                  '%1 %2 is equal to %3 %4',
                  toLocaleDecimal('1.00'),
                  exchangeRate.targetCurrency,
                  toLocaleDecimal(exchangeRate.targetToBaseRate),
                  exchangeRate.baseCurrency
                )}
              </Text>
            ))}
          </div>
        )}
      />
    </Box>
  );
};

export default newDataLoader({
  fetch: ({ period }: Props) =>
    get(`exchange_rates`, {
      resourceId: period.id,
      resourceType: 'TrainingPeriod',
    }),
  hydrate: {
    exchangeRates: {},
  },
  cacheKey: ({ period, fundingSourcesCurrencies }) =>
    compositeKey({ period, fundingSourcesCurrencies }),
})(ExchangeRates);
