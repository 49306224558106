import React from 'react';

import type { User } from 'models';

import invariant from 'helpers/invariant';

import ConnectedState from './ConnectedState';
import PreviewState from './PreviewState';

type Props = {
  userReviewId?: string;
  reviewee: User;
  isPreview: boolean;
  shouldFetchSnapshots: boolean;
};

const TrainingRequestHistoryBox = ({
  userReviewId,
  reviewee,
  isPreview,
  shouldFetchSnapshots,
}: Props) => {
  if (isPreview) {
    return <PreviewState revieweeFullName={reviewee.fullName} />;
  }

  invariant(userReviewId, 'userReviewId should be defined');

  return (
    <ConnectedState
      userReviewId={userReviewId}
      reviewee={reviewee}
      shouldFetchSnapshots={shouldFetchSnapshots}
    />
  );
};

export default TrainingRequestHistoryBox;
