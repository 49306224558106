import React from 'react';

import type { AttributesOnlyOrganization, HomeMessage } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del } from 'redux/actions/api';

import {
  Box,
  Can,
  HamburgerMenu,
  Hoverable,
  MenuItem,
  MenuList,
  PullRight,
  Text,
  WithFadeinFadeoutAnimation,
} from 'components';
import { Editor } from 'components/TrixEditor/TrixEditor';
import RichTextEditor from 'components/formElements/advancedElements/RichTextEditor';

import EmptyState from './EmptyState';
import UpdateHomeMessageModal from './UpdateHomeMessageModal';

type Props = {
  homeMessage: HomeMessage | undefined | null;
  refetchHomeMessage: () => Promise<any>;
  organization: AttributesOnlyOrganization;
};

const HomeMessageBox = ({
  organization,
  homeMessage,
  refetchHomeMessage,
}: Props) => {
  const dispatch = useAppDispatch();
  const [editor, setEditor] = React.useState<Editor | null>(null);
  const [isUpdateHomeMessageModalOpened, setIsUpdateHomeMessageModalOpened] =
    React.useState<boolean>(false);

  const deleteAllHomeMessages = () =>
    confirmAsync(
      __('Confirm deletion'),
      <>
        <p>{__('If you delete the welcome message:')}</p>
        <ul className="!list-disc !ml-6">
          <li>
            <b>{__('it will no longer be visible to your employees')}</b>
          </li>
          <li>
            <b>{__('it will be deleted in all configured languages')}</b>
          </li>
        </ul>
        <p className="mb-4">{__('You can configure a new one later.')}</p>
        <p>{__('Are you sure you want to delete the welcome message?')}</p>
      </>,
      {
        isDanger: true,
        confirmLabel: __('Remove the welcome message'),
        onConfirm: async () => {
          await dispatch(del('home_messages/destroy_all'));
          refetchHomeMessage();
        },
      }
    );

  React.useEffect(() => {
    if (!editor) return;

    // @ts-expect-error: TODO: Better type RichTextEditor
    editor.element.value = homeMessage?.interpolatedMessage;
  }, [homeMessage, editor]);

  return (
    <>
      <Hoverable
        render={isHovered => (
          <Box testClassName="test-home-message-box">
            {!homeMessage ? (
              <EmptyState
                setIsUpdateHomeMessageModalOpened={
                  setIsUpdateHomeMessageModalOpened
                }
              />
            ) : (
              <>
                <div className="flex items-center mb-4 h-8">
                  <Text preset="13buppers7" style={{ width: '100%' }}>
                    {__('Message from the HR team')}
                  </Text>
                  <Can perform="update_home_message" on={organization}>
                    <PullRight>
                      <WithFadeinFadeoutAnimation visible={isHovered}>
                        <HamburgerMenu align="right">
                          <MenuList>
                            <MenuItem
                              onClick={() =>
                                setIsUpdateHomeMessageModalOpened(true)
                              }
                            >
                              {__('Edit')}
                            </MenuItem>
                            <MenuItem onClick={() => deleteAllHomeMessages()}>
                              {__('Remove')}
                            </MenuItem>
                          </MenuList>
                        </HamburgerMenu>
                      </WithFadeinFadeoutAnimation>
                    </PullRight>
                  </Can>
                </div>

                {homeMessage && (
                  <RichTextEditor
                    value={homeMessage?.interpolatedMessage}
                    onEditorReady={setEditor}
                    disabled
                    display
                  />
                )}
              </>
            )}
          </Box>
        )}
      />
      {isUpdateHomeMessageModalOpened && (
        <UpdateHomeMessageModal
          organization={organization}
          onClose={() => {
            setIsUpdateHomeMessageModalOpened(false);
          }}
          refetchHomeMessage={refetchHomeMessage}
        />
      )}
    </>
  );
};

export default HomeMessageBox;
