import React, { useState } from 'react';

import type { PeopleReviewCycleShow } from 'models';

import can from 'helpers/can';
import { useMultiSelect } from 'helpers/hooks';
import queryString from 'helpers/queryString';

import parseQueryParams from 'lib/pagination/parsePaginatedQueryParams';

import { ActiveFilters } from 'components/Filters/types';

import UserMultiFilters from 'scenes/components/UserMultiFilters';

import PeopleGroupList from './PeopleGroupList';
import PeopleUserReviewListWithPagination from './PeopleUserReviewListWithPagination';

type Props = {
  peopleReviewCycle: PeopleReviewCycleShow;
};

const Participants = ({ peopleReviewCycle }: Props) => {
  const urlQueryParams = () =>
    parseQueryParams(queryString.parse(window.location.search));

  const peopleGroupIdsFromParams = urlQueryParams().queryParams?.peopleGroupIds;

  const [selectedPeopleGroupIds, togglePeopleGroupSelection] =
    useMultiSelect<string>(peopleGroupIdsFromParams || []);

  const [userFilters, setUserFilters] = useState<ActiveFilters | ''>(
    urlQueryParams().queryParams?.userFilters || ''
  );

  const [shouldRefetchReviews, setShouldRefetchReviews] = useState(false);

  const canEditPeopleGroups = can({
    perform: 'edit_groups',
    on: peopleReviewCycle,
  });

  return (
    <>
      <div className="mb-4">
        <UserMultiFilters
          onChange={({ userFilters }) => setUserFilters(userFilters)}
          userFilters={userFilters}
        />
      </div>
      <PeopleGroupList
        cycleId={peopleReviewCycle.id}
        filteringEnabled={can({
          perform: 'filter_by_people_group',
          on: peopleReviewCycle,
        })}
        selectedPeopleGroupIds={selectedPeopleGroupIds}
        togglePeopleGroupSelection={togglePeopleGroupSelection}
        userFilters={userFilters}
        canEditPeopleGroups={canEditPeopleGroups}
        setShouldRefetchReviews={setShouldRefetchReviews}
      />
      <PeopleUserReviewListWithPagination
        shouldRefetchReviews={shouldRefetchReviews}
        peopleReviewCycle={peopleReviewCycle}
        setShouldRefetchReviews={setShouldRefetchReviews}
        additionalQueryParams={{
          userFilters: userFilters === '' ? undefined : userFilters,
          peopleGroupIds: selectedPeopleGroupIds,
        }}
      />
    </>
  );
};

export default Participants;
