import React, { ReactNode } from 'react';

import { __ } from '../../helpers/i18n';
import { Button } from '../buttons';
import {
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from './Default';

type Props = {
  onConfirm: () => void | Promise<void>;
  onCancel: () => void;
  title: string;
  refreshContentOnOpening: boolean;
  children: ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  isDanger?: boolean;
  isLarge?: boolean;
  confirmDisabled?: boolean;
  isActive?: boolean;
  hideCancel?: boolean;
  inlineButtonIcon?: ReactNode;
  nonEscapable?: boolean;
  overflowVisible?: boolean;
  onClose?: () => void;
};

type State = {
  isSubmitting: boolean;
};

export default class ConfirmationModal extends React.Component<Props, State> {
  _isMounted: boolean = false;
  state = {
    isSubmitting: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClick = async () => {
    this.setState({
      isSubmitting: true,
    });

    try {
      await this.props.onConfirm();
    } catch (e) {
      this._isMounted &&
        this.setState({
          isSubmitting: false,
        });
    }

    this._isMounted &&
      this.setState({
        isSubmitting: false,
      });
  };

  render() {
    const {
      confirmDisabled,
      isDanger,
      isActive,
      onCancel,
      title,
      children,
      confirmLabel,
      cancelLabel,
      refreshContentOnOpening,
      isLarge,
      hideCancel,
      inlineButtonIcon,
      nonEscapable,
      overflowVisible,
      onClose,
    } = this.props;

    const confirmButton = color => {
      return (
        <Button
          disabled={confirmDisabled}
          isLoading={this.state.isSubmitting}
          color={color}
          onClick={this.onClick}
          testClassName="test-confirm-button"
        >
          {inlineButtonIcon}
          {confirmLabel || __('OK')}
        </Button>
      );
    };
    const cancelButton = color => {
      return (
        <Button color={color} onClick={onCancel}>
          {cancelLabel || __('Cancel')}
        </Button>
      );
    };

    return (
      <ModalCard
        isActive={isActive}
        onClose={onClose || onCancel}
        isLarge={isLarge}
        refreshContentOnOpening={refreshContentOnOpening}
        nonEscapable={nonEscapable}
      >
        <ModalCardHead>
          {title && <ModalCardTitle>{title}</ModalCardTitle>}
        </ModalCardHead>

        <ModalCardBody overflowVisible={overflowVisible}>
          {children}
        </ModalCardBody>

        <ModalCardFooter>
          {/* Product decision to change placement of CTA button whether it is a danger action or not */}
          {isDanger ? (
            <>
              {confirmButton('danger')}
              {!hideCancel && cancelButton('secondary')}
            </>
          ) : (
            <>
              {!hideCancel && cancelButton('secondary')}
              {confirmButton('primary')}
            </>
          )}
        </ModalCardFooter>
      </ModalCard>
    );
  }
}
