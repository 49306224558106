import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToSurveyCampaigns } from 'helpers/navigation';
import confirmAsync from 'helpers/react/confirmAsync';

import { del } from 'redux/actions/api';

import { Button, Icon, Text } from 'components';

type Props = {
  surveyCampaignId: string;
  surveyCampaignName: string;
};

const DestroyButton = ({ surveyCampaignId, surveyCampaignName }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const destroy = async () => {
    await dispatch(
      del(`survey/campaigns/${surveyCampaignId}`, undefined, {
        successMessage: __(
          '<b>The survey campaign %1 has been successfully deleted.</b>',
          surveyCampaignName
        ),
      })
    );
    navigate(pathToSurveyCampaigns());
  };

  const handleDestroy = () => {
    return confirmAsync(
      __('Delete the survey campaign?'),
      <>
        <p>
          {__(
            'This action will delete the survey campaign %1.',
            surveyCampaignName
          )}
        </p>
        <div className="mt-4 mb-2">
          <Text color="danger">
            <Icon color="danger" name="warning" className="mr-4" />

            {__('This action cannot be undone')}
          </Text>
        </div>
      </>,
      {
        confirmLabel: __('Delete the campaign'),
        isDanger: true,
        onConfirm: destroy,
      }
    );
  };

  return (
    <Button color="danger" onClick={handleDestroy}>
      <Icon style={{ marginRight: 8 }} name="delete" />
      {__('Delete')}
    </Button>
  );
};

export default DestroyButton;
