import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { OneOnOneUserReview, User } from 'models';

import { __ } from 'helpers/i18n';
import invariant, { assert } from 'helpers/invariant';
import { evaluationSharedOrSigned } from 'helpers/models/evaluation';
import { pathToEvaluation } from 'helpers/paths';
import { withActiveUser } from 'helpers/withSessionProps';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import FormContentComponent from '../components/FormContent';
import ReviewLayout from '../components/ReviewLayout';
import ReviewSeenTracker from '../components/ReviewSeenTracker';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import UserPanel, { shouldShowUserPanel } from '../components/UserPanel';
import ReleaseAction from './ReleaseAction';
import ReviewCategorization from './ReviewCategorization';
import ReviewSummary from './ReviewSummary';

type AfterMatchParamsProps = {
  match: {
    userReviewId: string;
  };
};

type AfterConnectProps = {
  activeUser: User;
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  AfterConnectProps &
  DataLoaderProvidedProps & {
    userReview: OneOnOneUserReview;
  };

function ReviewComponent({
  userReview,
  activeUser,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [scrollToSignSection, setScrollToSignSection] = useState(
    (location.state as { shouldScrollToSignSection?: boolean })
      ?.shouldScrollToSignSection
  );

  const signSectionBlock = userReview?.formContent?.blocks.find(
    block => block.blockType === 'signature'
  );

  useEffect(() => {
    if (scrollToSignSection && signSectionBlock) {
      const signSectionBlockId = signSectionBlock.id;
      navigate(`#${signSectionBlockId}`);
      const element = window.document.getElementById(signSectionBlockId);
      element?.scrollIntoView();
      setScrollToSignSection(false);
    }
  }, [scrollToSignSection, signSectionBlock, navigate]);

  return (
    <ReleaseAction
      userReview={userReview}
      refreshReview={refetchData}
      render={releaseAction => (
        <ReviewLayout
          userAvatarUrl={userReview?.user?.avatarUrl}
          sidebar={
            <FetchContainer
              hasError={hasError}
              isFetching={isFetching}
              render={() => (
                <Sidebar
                  content={assert(
                    userReview.formContent,
                    'formContent must be defined once loaded'
                  )}
                  userReview={assert(
                    userReview,
                    'userReview must be available on evaluation'
                  )}
                  shareAction={releaseAction}
                />
              )}
            />
          }
          content={
            <FetchContainer
              hasError={hasError}
              isFetching={isFetching}
              render={() => {
                const { formContent } = userReview;

                invariant(
                  formContent && userReview,
                  'Both content and review should be attached to evaluation'
                );

                const currentUserEvaluation = userReview.evaluations.find(
                  evaluation => evaluation.reviewerId === activeUser.id
                );

                if (
                  userReview.interactionType === 'one_on_one' &&
                  !!currentUserEvaluation &&
                  !evaluationSharedOrSigned(currentUserEvaluation)
                ) {
                  navigate(pathToEvaluation(currentUserEvaluation.id));
                  return null;
                }

                const revieweeEvaluation = userReview.evaluations.find(
                  e => e.reviewerRole === 'reviewee'
                );
                const showReviewCategorizations =
                  !!userReview?.reviewCycle?.reviewCategorizationEnabled &&
                  !!revieweeEvaluation?.flaggedByAi &&
                  !!revieweeEvaluation.categorizationReport?.categorizations;
                const showReviewSummarization =
                  !!userReview?.reviewCycle?.reviewSummarizationEnabled;

                return (
                  <>
                    <ReviewSeenTracker
                      userReview={userReview}
                      additionalProperties={{ step: 'results' }}
                    />
                    {showReviewCategorizations && (
                      <ReviewCategorization
                        categorizations={
                          revieweeEvaluation.categorizationReport
                            ?.categorizations
                        }
                      />
                    )}
                    {showReviewSummarization && (
                      <ReviewSummary
                        title={
                          userReview.interactionType === 'one_on_one'
                            ? __("Review's key points")
                            : __('Key points shared by peers')
                        }
                        tooltipContent={
                          userReview.interactionType === 'one_on_one'
                            ? __(
                                'Thanks to Elevo AI, save precious time with a summary of the interview, crafted from the main evaluator’s comments.'
                              )
                            : __(
                                'Thanks to Elevo’s AI, save time with a summary based on peers’ comments.'
                              )
                        }
                        summary={userReview.summary || null}
                      />
                    )}
                    <FormContentComponent
                      content={formContent}
                      shareAction={releaseAction}
                      reviewee={userReview.user}
                      userReviewId={userReview.id}
                    />
                  </>
                );
              }}
            />
          }
          topbar={(() => {
            const review = userReview;
            return (
              <Topbar
                reviewee={review && review.user}
                reviewCycle={review && review.reviewCycle}
                review={review}
                refreshReview={refetchData}
                shareAction={releaseAction}
                showSavingStatus
              />
            );
          })()}
          userPanel={(() => {
            if (!userReview) return null;
            if (!shouldShowUserPanel(userReview)) return null;

            return <UserPanel userReview={userReview} />;
          })()}
        />
      )}
    />
  );
}

export default compose<React.ComponentType>(
  withActiveUser,
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`/user_reviews/${match.userReviewId}/v2/results`),
    hydrate: {
      userReview: {
        abilities: {},
        user: {
          abilities: {},
        },
        reviewCycle: {},
        reviewer: {},
        evaluations: {
          reviewer: {},
          categorizationReport: {
            categorizations: {},
          },
        },
        formContent: {
          messages: {},
          blocks: {
            messages: {},
            feedbackableItems: {
              item: {},
              answers: {
                author: {},
              },
            },
          },
        },
        meeting: { participants: {} },
        additionalReviewer: {},
        summary: {},
      },
    },
  })
)(ReviewComponent);
