import React from 'react';

import type {
  FeedbackableItem,
  SkillsCareerLevelExpectationBlock,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';

import { Link, Text, Tooltip } from 'components';

import ClosedAnswers from './components/ClosedAnswers';
import OpenAnswer from './components/OpenAnswer';

type Props = {
  feedbackableItem: FeedbackableItem<SkillsCareerLevelExpectationBlock>;
  persistAction: (
    evaluationId: string,
    feedbackableId: string,
    feedbackableType: string,
    text: string | undefined,
    rating: string | null | undefined
  ) => (dispatch: AppDispatch) => Promise<void>;
  setExpectationModalProps: React.Dispatch<
    React.SetStateAction<{
      levelTitle: string;
      skillTitle: string;
      expectationContent: string;
    } | null>
  >;
};

const ExpectationFeedbackZone = ({
  feedbackableItem,
  persistAction,
  setExpectationModalProps,
}: Props) => {
  const dispatch = useAppDispatch();

  const ratingOptions = feedbackableItem.answers[0]?.ratings || [];
  const openedAnswer = feedbackableItem.answers.find(
    answer => answer.status === 'opened'
  );
  const closedAnswers = feedbackableItem.answers.filter(
    answer => answer.status === 'shared'
  );
  const ratingEnabled = feedbackableItem.answers.some(
    answer => answer.ratingEnabled
  );

  const skillTitleRow = (
    <>
      <Text
        preset="13bs7"
        className="mb-2 line-clamp-2 lg:col-span-10 lg:mb-0 lg:pr-4"
      >
        <Tooltip
          content={__('Click to view detailed expectations for this skill')}
        >
          <Link
            onClick={() =>
              setExpectationModalProps({
                levelTitle: feedbackableItem.item.levelTitle,
                skillTitle: feedbackableItem.item.areaTitle,
                expectationContent: feedbackableItem.item.expectationContent,
              })
            }
          >
            {feedbackableItem.item.areaTitle}
          </Link>
        </Tooltip>
      </Text>
      <div className="hidden lg:block col-span-2 border-0 border-solid border-l border-nav-border-default pl-6">
        <br />
      </div>
    </>
  );

  const answersRow = !!openedAnswer ? (
    <OpenAnswer
      autoSaveUid={compositeKey({
        type: feedbackableItem.type,
        id: feedbackableItem.id,
        field: 'rating',
      })}
      rating={openedAnswer.rating}
      ratingOptions={openedAnswer.ratings}
      closedAnswers={closedAnswers}
      persistFeedback={(rating: string | null) =>
        dispatch(
          persistAction(
            assert(openedAnswer.evaluationId, 'evaluationId should be defined'),
            feedbackableItem.item.id,
            feedbackableItem.item.type,
            '',
            rating
          )
        )
      }
    />
  ) : (
    <ClosedAnswers
      ratingOptions={ratingOptions}
      closedAnswers={closedAnswers}
    />
  );

  return (
    <>
      {skillTitleRow}
      {ratingEnabled && answersRow}
    </>
  );
};

export default ExpectationFeedbackZone;
