import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MatrixList from './MatrixList';
import MatrixSuggestions from './MatrixSuggestions';

const SuggestionRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<MatrixList />} />
      <Route path="/:id" element={<MatrixSuggestions />} />
    </Routes>
  );
};

export default SuggestionRoutes;
