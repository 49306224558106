import React, { ReactNode } from 'react';

import { Divider, Text } from 'components';

type Item = Record<string, unknown>;

type Props<TItem extends Item> = {
  items: Array<TItem>;
  dateFn: (item: TItem) => string | null;
  noDateLabel?: string;
  renderItem: (item: TItem) => ReactNode;
};

const groupByYear = <TItem extends Item>(
  items: Props<TItem>['items'],
  dateFn: Props<TItem>['dateFn'],
  noDateLabel: Props<TItem>['noDateLabel']
) => {
  const yearsObj = items.reduce((yearsObj, item) => {
    const date = dateFn(item);
    const year = date ? new Date(date).getFullYear().toString() : '9999';

    if (!yearsObj[year]) {
      yearsObj[year] = [];
    }

    yearsObj[year].push(item);

    return yearsObj;
  }, {} as Record<string, TItem[]>);

  return Object.entries(yearsObj)
    .sort()
    .reverse()
    .map(([year, items]) => ({
      year: year === '9999' ? noDateLabel || '-' : year,
      items,
    }));
};

const YearSections = <TItem extends Item>({
  items,
  dateFn,
  noDateLabel,
  renderItem,
}: Props<TItem>) => {
  const groupedByYearItems = groupByYear(items, dateFn, noDateLabel);

  return (
    <div className="mt-4">
      {groupedByYearItems.map(({ year, items }, i) => {
        return (
          <div key={year}>
            {i !== 0 && <Divider />}
            <Text preset="16bs5.5">{year}</Text>
            <div className="mt-2">{items.map(renderItem)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default YearSections;
