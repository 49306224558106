import * as React from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';

import RichTextEditor from 'components/formElements/advancedElements/RichTextEditor';

import UnsafeRawHtml from './UnsafeRawHtml';

type VariableExpanderContext = { reviewee: User };

const userAttributesHumanizedName = key => {
  const mapping = {
    firstName: __('first name'),
    lastName: __('last name'),
    initials: __('initials'),
    fullName: __('full name'),
    teamName: __('team name'),
    workStartDate: __('work start date'),
    email: __('email'),
    jobTitle: __('job title'),
    registrationNumber: __('registration number'),
    level: __('level'),
    service: __('service'),
    department: __('department'),
    region: __('region'),
  };
  return mapping[key];
};

const createVariableExpander = (
  context: VariableExpanderContext | undefined
) => {
  if (!context) return s => s;

  return (content: string) => {
    const { reviewee } = context;

    return content.replace(
      /\[\[reviewee\.(\w+)\]\]/g,
      (_match, key) =>
        reviewee[key] ||
        __(
          '[%1 is not defined for %2]',
          userAttributesHumanizedName(key),
          reviewee.fullName
        )
    );
  };
};

type Props = {
  content: string | null | undefined;
  context?: VariableExpanderContext | undefined;
  withRichText?: boolean;
};

export default function RichTextContent({
  content,
  context,
  withRichText,
}: Props) {
  if (!content) return null;

  const expandVariables = createVariableExpander(context);

  return withRichText ? (
    <RichTextEditor value={content} disabled display />
  ) : (
    <UnsafeRawHtml html={expandVariables(content)} />
  );
}
