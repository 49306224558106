import React from 'react';

import { type InteractionType } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Level,
  LevelLeft,
  LevelRight,
  Text,
  Title5,
  WithSavingStatusRecorder,
} from 'components';

type Props = {
  hasSelfEvaluationEnabled: boolean;
  showRevieweeEvaluationToReviewers: boolean;
  onChange: (attributes: {
    hasSelfEvaluationEnabled?: boolean;
    showRevieweeEvaluationToReviewers?: boolean;
  }) => Promise<void>;
  interactionType: InteractionType;
  disabled: boolean;
  templateId: string;
};

const EnableSelfEvaluation = ({
  onChange,
  interactionType,
  disabled,
  templateId,
  hasSelfEvaluationEnabled,
  showRevieweeEvaluationToReviewers,
}: Props) => {
  return (
    <>
      <Level>
        <LevelLeft>
          <Title5>{__('Self evaluation')}</Title5>
        </LevelLeft>
        <LevelRight>
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              templateId: templateId,
              field: 'self_evaluation_enabled',
            })}
            onChange={(hasSelfEvaluationEnabled: boolean) =>
              onChange({ hasSelfEvaluationEnabled })
            }
            render={autoSavingOnchange => (
              <CheckboxSwitch
                disabled={disabled}
                value={hasSelfEvaluationEnabled}
                onChange={newValue => autoSavingOnchange(newValue)}
                size="small"
              />
            )}
          />
        </LevelRight>
      </Level>
      <div style={{ marginTop: 8 }}>
        <Text color="grey">
          {__('Allows you to enable the employee’s self-assessment')}
        </Text>
      </div>

      {interactionType === '360' && !!hasSelfEvaluationEnabled && (
        <>
          <Level style={{ marginTop: 8 }}>
            <LevelLeft>
              <Text>{__('Visible to peers')}</Text>
            </LevelLeft>
            <LevelRight>
              <WithSavingStatusRecorder
                fieldUid={compositeKey({
                  templateId: templateId,
                  field: 'show_reviewee_evaluation_to_reviewers',
                })}
                onChange={(showRevieweeEvaluationToReviewers: boolean) =>
                  onChange({ showRevieweeEvaluationToReviewers })
                }
                render={autoSavingOnchange => (
                  <CheckboxSwitch
                    disabled={disabled}
                    value={showRevieweeEvaluationToReviewers}
                    onChange={newValue => autoSavingOnchange(newValue)}
                    size="small"
                  />
                )}
              />
            </LevelRight>
          </Level>
          <Text color="grey">
            {__('Grant view access of the self-assessment to peers.')}
          </Text>
        </>
      )}
    </>
  );
};

export default EnableSelfEvaluation;
