import React, { ComponentProps, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { compose } from 'redux';

import { SkillsMatrixWithSuggestions } from 'models';

import { __ } from 'helpers/i18n';
import { pathToTrainingCourseSuggestions } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  ContentContainer,
  FetchContainer,
  PageHeader,
  Tooltip,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import AIIcon from 'scenes/components/AIIcon';

import AddSuggestionsModal from './components/AddSuggestionsModal';
import AutoSuggestModal from './components/AutoSuggestModal';
import DeletionConfirmationModal from './components/DeletionConfirmationModal';
import SuggestionsAccordion from './components/SuggestionsAccordion';
import useEmbeddingsReady from './hooks/useEmbeddingsReady';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    matrix: SkillsMatrixWithSuggestions;
  };

const MatrixSuggestions = ({
  matrix,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const backButton: Partial<ComponentProps<typeof PageHeader>> = {
    withBackButton: true,
    backButtonProps: {
      fallbackTarget: pathToTrainingCourseSuggestions(),
      children: __('Back to training catalog management'),
    },
  };

  const [autoSuggestModalActive, setAutoSuggestModalActive] = useState(false);
  const [addModalProps, setAddModalProps] = useState<{
    areaId: string;
  } | null>(null);
  const [deletionModalProps, setDeletionModalProps] = useState<{
    areaId: string;
    courseId: string;
  } | null>(null);

  const {
    isFetching: embeddingsReadyFetching,
    isReady: embeddingsReady,
    pollerComponent,
  } = useEmbeddingsReady();

  const getArea = (areaId: string) =>
    matrix.areas.find(area => area.id === areaId);

  const getCourse = (areaId: string, courseId: string) =>
    matrix.areas
      .find(area => area.id === areaId)
      ?.trainingCourses.find(course => course.id === courseId);

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      renderFetching={() => (
        <PageHeader title={<Skeleton width={200} />} {...backButton} />
      )}
      render={() => (
        <>
          {pollerComponent}
          {autoSuggestModalActive && (
            <AutoSuggestModal
              matrixId={matrix.id}
              onClose={() => {
                setAutoSuggestModalActive(false);
              }}
            />
          )}
          {!!deletionModalProps && (
            <DeletionConfirmationModal
              courseId={deletionModalProps.courseId}
              courseTitle={
                getCourse(
                  deletionModalProps.areaId,
                  deletionModalProps.courseId
                )?.name || ''
              }
              areaId={deletionModalProps.areaId}
              onClose={() => setDeletionModalProps(null)}
              onConfirm={refetchData}
            />
          )}
          {!!addModalProps && (
            <AddSuggestionsModal
              areaId={addModalProps.areaId}
              unavailableCourseIds={getArea(
                addModalProps.areaId
              )?.trainingCourses.map(course => course.id)}
              onClose={() => setAddModalProps(null)}
              onConfirm={refetchData}
            />
          )}
          <PageHeader
            title={matrix.title}
            actions={[
              <Tooltip
                enabled={!embeddingsReady && !embeddingsReadyFetching}
                content={__(
                  'Automatic suggestions are being prepared, this may take a few minutes.'
                )}
              >
                <Button
                  key="autoSuggest"
                  color="primary"
                  disabled={!embeddingsReady}
                  onClick={() => {
                    setAutoSuggestModalActive(true);
                  }}
                >
                  <AIIcon />
                  &nbsp;{__('Suggest courses')}
                </Button>
              </Tooltip>,
            ]}
            {...backButton}
          />
          <ContentContainer>
            <SuggestionsAccordion
              matrix={matrix}
              design="elevated"
              onCourseDeleteClick={(areaId: string, courseId: string) => {
                setDeletionModalProps({ areaId, courseId });
              }}
              onCourseAddClick={areaId => {
                setAddModalProps({ areaId });
              }}
            />
          </ContentContainer>
        </>
      )}
    />
  );
};

export default compose(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) => {
      return get(`skills/matrices/${match.id}/training_suggestions`);
    },
    hydrate: { matrix: { areas: { trainingCourses: { organism: {} } } } },
  })
)(MatrixSuggestions) as React.ComponentType<{}>;
