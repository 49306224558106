import React, { ComponentProps } from 'react';
import { YAxis } from 'recharts';

import { GraphFormattedTimeSeriesDataPoint, TimeSeries } from 'models';

import tailwindTheme from 'config/tailwindTheme';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { zipSeries } from 'helpers/timeSeries';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, FetchContainer, Text } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import LineChart from './components/LineChart';

type Props = {
  campaignId: string;
  selectedPeriodId: string;
  questionId: string;
  yAxisProps: ComponentProps<typeof YAxis>;
  userFilters: ActiveFilters | '';
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    lineChart: { global: TimeSeries };
  };

const Evolution = ({
  yAxisProps,
  isFetching,
  hasError,
  lineChart,
}: AfterDataLoaderProps) => {
  const linesMetadata = [
    {
      dataKey: 'global' as const,
      name: 'Global',
      color: tailwindTheme.colors['text-main'],
      curveType: 'monotone' as const,
    },
  ];

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const data = zipSeries(lineChart)
          .data as GraphFormattedTimeSeriesDataPoint<'global'>[];

        return (
          <Box>
            <Text preset="18bs5">{__('Trend over recent periods')}</Text>
            <LineChart
              data={data}
              linesMetadata={linesMetadata}
              yAxisProps={yAxisProps}
            />
          </Box>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ campaignId, questionId, selectedPeriodId, userFilters }: Props) =>
    get(`survey/campaigns/${campaignId}/questions/${questionId}/evolution`, {
      periodId: selectedPeriodId,
      userFilters,
    }),
  hydrate: {
    lineChart: { global: {} },
  },
  cacheKey: ({ campaignId, questionId, userFilters }: Props) =>
    compositeKey({ campaignId, questionId, userFilters }),
})(Evolution) as React.ComponentType<Props>;
