import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AuditReport from './AuditReport';
import Audits from './index';

const AuditRoutes = () => {
  return (
    <Routes>
      <Route path="/" index element={<Audits />} />
      <Route path=":id" element={<AuditReport />} />
    </Routes>
  );
};

export default AuditRoutes;
