import React from 'react';

import { __ } from 'helpers/i18n';

import { Button, Icon } from 'components';

type Props = {
  onClick: () => void;
  isDisabled?: boolean;
};

const BudgetItemDeletionButton = ({ onClick, isDisabled }: Props) => {
  return (
    <Button
      className="border-none bg-transparent"
      onClick={onClick}
      disabled={isDisabled}
      disabledExplanation={__(
        'The funding source cannot be edited or deleted as it used in this period.'
      )}
      tooltipProps={{
        isLight: true,
        style: { maxWidth: 340 },
      }}
    >
      <Icon name="close" />
    </Button>
  );
};

export default BudgetItemDeletionButton;
