import React, { Fragment, ReactNode } from 'react';

import classNames from 'helpers/classNames';
import useIsMobile from 'helpers/hooks/useIsMobile';

import {
  Button,
  DesignSystem,
  Icon,
  Text,
  TooltipOnEllipsis,
} from 'components';

type Falsy = null | undefined | false;

const filterDefinedColumns = <T,>(columns: T[]): Exclude<T, Falsy>[] => {
  return columns.filter(column => column) as Exclude<T, Falsy>[];
};

const generateGridTemplateColumns = <TRow,>(
  columns: ColumnDefinition<TRow>[]
) => {
  let template: Array<string> = [];
  for (let i = 0; i < columns.length; i++) {
    // Data column
    template.push('minmax(0px, max-content)');

    // Filler column
    if (columns[i].isNarrow) {
      template.push('0px');
    } else {
      template.push('minmax(0px, 1fr)');
    }
  }
  return template.join(' ');
};

/** `headerKey` must be specified if `header` is null */
type Header =
  | {
      header: NonNullable<ReactNode>;
      headerKey?: string;
    }
  | {
      header: null | undefined;
      headerKey: string;
    };

export type ColumnDefinition<TRow> = Header & {
  cell: (row: TRow, rowIndex: number) => ReactNode;
  onSort?: () => void;
  activeSort?: 'asc' | 'desc' | null;
  isNarrow?: boolean;
  withoutTooltipOnEllipsis?: boolean;
};

type HeaderTextProps = {
  children: ReactNode;
  lineCountPerRow?: number;
};

export type Props<TRow> = {
  rows: Array<TRow>;
  keyFn: (row: TRow, rowIndex: number) => string;
  displayRow?: (row: TRow) => boolean;
  emptyState?: ReactNode;
  footer?: ReactNode;
  className?: string;
  columns: Array<ColumnDefinition<TRow> | Falsy>;
  withBorder?: boolean;
  highlightedRowFn?: (row: TRow) => boolean;
  highlightExplanation?: ReactNode;
  useSmallerYPadding?: boolean;
  lineCountPerRow?: number;
  rowClassName?: string;
};

const SimpleTable = <TRow,>({
  columns,
  rows,
  keyFn,
  displayRow,
  emptyState,
  footer,
  className,
  withBorder,
  highlightedRowFn,
  highlightExplanation,
  useSmallerYPadding,
  lineCountPerRow,
  rowClassName,
}: Props<TRow>) => {
  const isMobile = useIsMobile();
  const filteredColumns = filterDefinedColumns(columns);
  const borderClasses = withBorder
    ? 'border-[1px] border-solid border-text-lightest/60 rounded-lg'
    : null;
  const gridTemplateColumns =
    isMobile || columns.length === 1
      ? '100%'
      : generateGridTemplateColumns(filteredColumns);

  if (rows.length === 0) {
    return (
      <DesignSystem version={2}>
        <div>
          {emptyState}
          {!!footer && footer}
        </div>
      </DesignSystem>
    );
  }

  const anyHighlightedRow =
    !!highlightedRowFn && rows.some(row => highlightedRowFn(row));

  // *2 to skip the 1fr gaps | First grid column is 1
  const gridColumnIndex = (index: number) => 2 * index + 1;

  return (
    <DesignSystem version={2}>
      <div
        className={classNames(
          'simple-table grid items-center',
          borderClasses,
          className
        )}
        style={{
          gridTemplateColumns,
          gridAutoRows: 'min-content',
          justifyItems: 'space-between',
        }}
      >
        <Row isHeader>
          {filteredColumns.map((column, index) => (
            <Fragment
              key={
                typeof column.header === 'string'
                  ? column.header
                  : column.headerKey
              }
            >
              <Cell
                columnIndex={gridColumnIndex(index)}
                useSmallerYPadding={useSmallerYPadding}
              >
                <Fragment>
                  {!column.onSort || typeof column.header !== 'string' ? (
                    typeof column.header === 'string' ? (
                      <HeaderText lineCountPerRow={lineCountPerRow}>
                        {column.header}
                      </HeaderText>
                    ) : (
                      column.header
                    )
                  ) : (
                    <Button
                      hasIconOnly
                      onClick={() => column.onSort && column.onSort()}
                      className="border-none bg-transparent p-0 flex"
                    >
                      <HeaderText lineCountPerRow={lineCountPerRow}>
                        {column.header}
                      </HeaderText>

                      <Icon
                        name={sortIconName(column.activeSort)}
                        color="info"
                      />
                    </Button>
                  )}
                </Fragment>
              </Cell>
              {index !== columns.length - 1 && (
                <FillerCell columnIndex={gridColumnIndex(index) + 1} />
              )}
            </Fragment>
          ))}
        </Row>
        {anyHighlightedRow && (
          <Row isHighlighted isFirstHighlighted className={rowClassName}>
            <Cell fullWidth useSmallerYPadding={useSmallerYPadding}>
              {highlightExplanation}
            </Cell>
          </Row>
        )}
        {rows.map((row, rowIndex) => {
          if (displayRow && !displayRow(row)) return null;

          const isHighlighted = !!highlightedRowFn && highlightedRowFn(row);
          const isLastHighlighted =
            isHighlighted &&
            (rowIndex === rows.length - 1 ||
              !highlightedRowFn(rows[rowIndex + 1]));

          return (
            <Row
              key={keyFn(row, rowIndex)}
              isHighlighted={isHighlighted}
              isLastHighlighted={isLastHighlighted}
              className={rowClassName}
            >
              {filteredColumns.map((column, index) => (
                <Fragment
                  key={
                    typeof column.header === 'string'
                      ? column.header
                      : column.headerKey
                  }
                >
                  <Cell
                    columnIndex={gridColumnIndex(index)}
                    useSmallerYPadding={useSmallerYPadding}
                  >
                    {!!column.withoutTooltipOnEllipsis ? (
                      column.cell(row, rowIndex)
                    ) : (
                      <TooltipOnEllipsis plainText lineClamp={lineCountPerRow}>
                        {column.cell(row, rowIndex)}
                      </TooltipOnEllipsis>
                    )}
                  </Cell>
                  {index !== columns.length - 1 && (
                    <FillerCell columnIndex={gridColumnIndex(index) + 1} />
                  )}
                </Fragment>
              ))}
            </Row>
          );
        })}
        {footer && (
          <Row>
            <Cell fullWidth useSmallerYPadding={useSmallerYPadding}>
              {footer}
            </Cell>
          </Row>
        )}
      </div>
    </DesignSystem>
  );
};

type CellProps =
  | {
      fullWidth?: false;
      columnIndex: number;
      children: ReactNode;
      useSmallerYPadding?: boolean;
    }
  | {
      fullWidth: true;
      children: ReactNode;
      useSmallerYPadding?: boolean;
    };

const Cell = ({ children, useSmallerYPadding, ...props }: CellProps) => {
  const isMobile = useIsMobile();

  let gridColumn: string;
  if (props.fullWidth || isMobile) {
    gridColumn = '1 / -1';
  } else {
    gridColumn = `${props.columnIndex}`;
  }

  return (
    <div
      className={classNames('px-4', {
        'py-3': !isMobile && !useSmallerYPadding,
        'py-2': !isMobile && useSmallerYPadding,
      })}
      style={{ gridColumn }}
    >
      {children}
    </div>
  );
};

const FillerCell = ({ columnIndex }: { columnIndex: number }) => (
  <div style={{ gridColumn: `${columnIndex}` }} />
);

type RowProps = {
  isHeader?: boolean;
  children: ReactNode;
  isHighlighted?: boolean;
  isFirstHighlighted?: boolean;
  isLastHighlighted?: boolean;
  className?: string;
};

const Row = ({
  isHeader,
  children,
  isHighlighted,
  isFirstHighlighted,
  isLastHighlighted,
  className,
}: RowProps) => {
  return (
    <Fragment>
      {!isHeader && !isFirstHighlighted && (
        <RowDelimiter isHighlighted={isHighlighted} />
      )}
      <div
        className={classNames(
          'contents',
          {
            'highlighted-row': isHighlighted,
            'first-highlighted-row': isFirstHighlighted,
            'last-highlighted-row': isLastHighlighted,
          },
          className
        )}
      >
        {children}
      </div>
    </Fragment>
  );
};

const RowDelimiter = ({ isHighlighted }: { isHighlighted?: boolean }) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={classNames('h-[1px]', {
        'my-3': isMobile,
        'bg-ui-body-bg': !isHighlighted,
        'bg-transparent border-x-[1px] border-solid border-0 border-primary-20':
          isHighlighted,
      })}
      style={{
        gridColumn: '1 / -1',
      }}
    />
  );
};

export const HeaderText = ({ children, lineCountPerRow }: HeaderTextProps) => (
  <Text preset="14bs6" color="light" className="min-w-0">
    <TooltipOnEllipsis plainText lineClamp={lineCountPerRow}>
      {children}
    </TooltipOnEllipsis>
  </Text>
);

const sortIconName = (activeSort: 'asc' | 'desc' | null | undefined) => {
  if (activeSort === 'asc') return 'arrow_upward';
  if (activeSort === 'desc') return 'arrow_downward';
  return 'swap_vert';
};

export default SimpleTable;
