import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import type { TrainingSession } from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
import compositeKey from 'helpers/compositeKey';
import { guessTimeZone } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';

import { put } from 'redux/actions/api';

import { Box, WithSavingStatusRecorder } from 'components';

import EditableFields from './EditableFields';

type Props = {
  session: TrainingSession;
};

const Details = ({ session: sessionFromProps }: Props) => {
  const [errors, setErrors] = useState<FormErrors>({});
  const [session, setSession] = useState<TrainingSession>(sessionFromProps);
  const dispatch = useAppDispatch();

  // We want to reset the state each time the session is updated because some relationships aren't updated correctly (tags)
  // The autosave makes everything complicated on this page...
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setSession(sessionFromProps), [sessionFromProps.updatedAt]);

  const updateSession = ({
    period,
    trainingCourse,
    ...session
  }: TrainingSession) => {
    return dispatch(
      put(`training/sessions/${session.id}`, {
        trainingSession: {
          name: session.name,
          location: session.location,
          minAvailableSeats: session.minAvailableSeats,
          maxAvailableSeats: session.maxAvailableSeats,
          trainingFormat: session.trainingFormat,
          trainingType: session.trainingType,
          durationInHours: session.durationInHours,
          periodId: period.id,
          certifying: session.certifying,
          totalCostPerSessionCents: session.totalCostPerSessionCents,
          endDate: session.endDate,
          startDate: session.startDate,
          trainingCourseId: trainingCourse ? trainingCourse.id : null,
          trainingOrganization: session.trainingOrganization,
          timezone: guessTimeZone(),
          caseInsensitiveTagLabels: session?.tags?.map(tag => tag.label),
        },
      })
    );
  };

  const persistSessionChanges = async (newSession?: TrainingSession) => {
    setErrors({});
    if (!!newSession) setSession(newSession);
    await handleFormErrors(
      () => updateSession(newSession || session),
      setErrors,
      true
    );
  };

  const hasChangesOrErrors = (newSession?: TrainingSession): boolean =>
    !isEqual(newSession || session, sessionFromProps) ||
    Object.keys(errors).length > 0;

  return (
    <>
      <Box className="training-session-details-form">
        <WithSavingStatusRecorder
          fieldUid={compositeKey({
            trainingSessionId: session.id,
            type: 'training_session',
          })}
          onChange={persistSessionChanges}
          render={autoSavingOnChange => (
            <>
              <EditableFields
                session={session}
                setSession={setSession}
                onChange={(newSession?: TrainingSession) => {
                  if (hasChangesOrErrors(newSession))
                    return autoSavingOnChange(newSession);
                  return Promise.resolve();
                }}
                errors={errors}
              />
            </>
          )}
        />
      </Box>
    </>
  );
};

export default Details;
