import classNames from 'classnames';
import React from 'react';

import ImagePicker from './ImagePicker';

type AvatarImageProps = {
  url: string | undefined | null;
  canUpload?: boolean;
  onUpload?: (base64Image: string) => Promise<void>;
  onDelete?: () => Promise<void>;
  className?: string;
};

export default function AvatarImage({
  url,
  canUpload,
  onUpload,
  onDelete,
  className,
}: AvatarImageProps) {
  return (
    <>
      <div
        className={classNames('avatar-image', className)}
        style={url ? { backgroundImage: `url(${url})` } : {}}
      >
        {canUpload && onUpload && onDelete && (
          <div style={{ height: '100%', position: 'relative' }}>
            <ImagePicker url={url} onUpload={onUpload} onDelete={onDelete} />
          </div>
        )}
      </div>
    </>
  );
}
