import React, { type ReactNode } from 'react';
import ImageUploading, { ErrorsType, ImageType } from 'react-images-uploading';

// Reference https://www.npmjs.com/package/react-images-upload

type OnChange = (image: ImageType) => any;
type OnError = (errors: ErrorsType, file: File | null) => any;

type Render = (params: {
  isDragging: boolean;
  errors: ErrorsType;
  onImageUpload: (...args: any) => any;
  onImageRemove: (...args: any) => any;
  onImageUpdate: (...args: any) => any;
}) => ReactNode;

type Props = {
  image: ImageType | null | undefined;
  onChange: OnChange;
  onError: OnError;
  render: Render;
};

const MAX_FILE_SIZE = 5 * 10 ** 6;

export default function ImagePicker({
  image,
  onChange,
  onError,
  render,
}: Props) {
  return (
    <ImageUploading
      multiple={false}
      value={image ? [image] : []}
      onChange={images => onChange(images[0])}
      acceptType={['jpg', 'png', 'jpeg', 'gif']}
      maxFileSize={MAX_FILE_SIZE}
      onError={(errors, images) => onError(errors, images?.[0]?.file || null)}
    >
      {render}
    </ImageUploading>
  );
}
