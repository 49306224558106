import React from 'react';
import Truncate from 'react-truncate';

import type { Answer } from 'models';

import { __ } from 'helpers/i18n';

import { BoxListItem, Link, Text, Toggleable } from 'components';

import AnswerModal from './AnswerModal';
import Author from './Author';

function lineBreakToTags(text: string) {
  return text.split('\n').map((line, i, arr) => {
    const spanLine = <span key={i}>{line}</span>;

    if (i === arr.length - 1) {
      return spanLine;
    } else {
      return [spanLine, <br key={i + 'br'} />];
    }
  });
}

type Props = {
  answer: Answer;
};

export default function AnswerListItem({ answer }: Props) {
  return (
    <BoxListItem key={answer.id}>
      <Author answer={answer} />
      <Text
        size={7}
        className="answer"
        testClassName="test-answer-list-item"
        style={answer.text ? {} : { fontStyle: 'italic' }}
      >
        <Truncate
          lines={2}
          ellipsis={
            <Toggleable
              render={(isToggled, toggle) => (
                <>
                  …
                  <Link className={null} onClick={toggle}>
                    {__('see more')}
                  </Link>
                  <AnswerModal isActive={isToggled} onClose={toggle}>
                    <div className="answer-modal">
                      <Author answer={answer} />
                      <div className="answer">{answer.text}</div>
                    </div>
                  </AnswerModal>
                </>
              )}
            />
          }
        >
          {answer.text ? lineBreakToTags(answer.text) : __('No comment')}
        </Truncate>
      </Text>
    </BoxListItem>
  );
}
