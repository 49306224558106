import React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { PullRight, Text } from 'components';

import {
  NominatePeersModal,
  ValidatePeersModal,
} from 'scenes/components/userReview/360/actions/ManagePeersAction';
import PeersList from 'scenes/components/userReview/PeersList';

import Actions from './Actions';

type Props = {
  userReview: ThreeSixtyUserReview;
  reviewCycle: ThreeSixtyReviewCycle;
};

type State = {
  displayManagePeersModal: boolean;
};

export default class ThreeSixtyReviewCycleComponent extends React.Component<
  Props,
  State
> {
  state = {
    displayManagePeersModal: false,
  };

  closeManagePeersModal = () =>
    this.setState({ displayManagePeersModal: false });

  openManagePeersModal = () => this.setState({ displayManagePeersModal: true });

  canAddPeers = (userReview: ThreeSixtyUserReview) => {
    return !userReview.isReleased;
  };

  shouldShowValidateInsteadOfNominate() {
    const { userReview } = this.props;
    const canChoosePeers =
      can({
        perform: 'choose_peers',
        on: userReview,
      }) ||
      can({ perform: 'validate_peers', on: userReview }) ||
      can({
        perform: 'change_peers_after_validation',
        on: userReview,
      });
    const canNominatePeers = can({
      perform: 'nominate_peers',
      on: userReview,
    });

    if (canNominatePeers) return false;
    return canChoosePeers;
  }

  render() {
    const { reviewCycle, userReview } = this.props;

    const peerEvaluations =
      userReview.evaluations &&
      userReview.evaluations.filter(
        evaluation => evaluation.reviewerRole !== 'reviewee'
      );

    const peers =
      peerEvaluations &&
      peerEvaluations
        .map(evaluation => evaluation.reviewer)
        .sort((a, b) => a.fullName.localeCompare(b.fullName));

    return (
      <>
        <div style={{ marginBottom: 16 }}>
          {reviewCycle.nominatePeersStepEnabled &&
            (!peers || peers.length === 0) && (
              <div style={{ textAlign: 'center' }}>
                <Text color="info">
                  {__('Search and select peers to provide you feedback')}
                </Text>
              </div>
            )}
          {peers.length > 0 && <PeersList peers={peers} />}
        </div>
        <PullRight>
          <Actions
            onAddPeers={this.openManagePeersModal}
            canAddPeers={this.canAddPeers(userReview)}
            userReview={userReview}
            reviewCycle={reviewCycle}
          />
        </PullRight>

        {this.state.displayManagePeersModal &&
          (this.shouldShowValidateInsteadOfNominate() ? (
            <ValidatePeersModal
              user={userReview.user}
              isActive
              onClose={this.closeManagePeersModal}
              reviewCycle={reviewCycle}
              userReview={userReview}
              peers={peers}
            />
          ) : (
            <NominatePeersModal
              user={userReview.user}
              isActive
              onClose={this.closeManagePeersModal}
              reviewCycle={reviewCycle}
              userReview={userReview}
              peers={peers}
            />
          ))}
      </>
    );
  }
}
