import { FunctionComponent } from 'react';

import { __ } from 'helpers/i18n';

import { EditableFieldProps } from '../EditionInputs/EditableField';
import DateField from '../EditionInputs/editableFieldTypes/DateField';
import EntityField from '../EditionInputs/editableFieldTypes/EntityField';
import GenderField from '../EditionInputs/editableFieldTypes/GenderField';
import LocaleField from '../EditionInputs/editableFieldTypes/LocaleField';
import ManagerField from '../EditionInputs/editableFieldTypes/ManagerField';
import SSOField from '../EditionInputs/editableFieldTypes/SSOField';
import SelectWithAggregatedValuesField from '../EditionInputs/editableFieldTypes/SelectWithAggregatedValuesField';
import TeamField from '../EditionInputs/editableFieldTypes/TeamField';
import WorkingTimeRatioField from '../EditionInputs/editableFieldTypes/WorkingTimeRatioField';

type FieldMetadata = {
  label: string;
  slug: string;
  isClearableFromControl?: boolean;
  errorFetchingKey?: string;
  syncOptions?: { syncedFieldSlug: string };
  componentClass?: FunctionComponent<EditableFieldProps>;
};

const useFieldMetadata = (field: string) =>
  ({
    // Using Partial because customFieldValues is unused here
    firstName: {
      label: __('First Name'),
      slug: 'first_name',
    },
    lastName: {
      label: __('Last Name'),
      slug: 'last_name',
    },
    email: {
      label: __('E-mail'),
      slug: 'email',
    },
    jobTitle: {
      label: __('Job Title'),
      slug: 'job_title',
      componentClass: SelectWithAggregatedValuesField,
    },
    workingTimeRatio: {
      label: __('Working time ratio'),
      slug: 'working_time_ratio',
      componentClass: WorkingTimeRatioField,
    },
    gender: {
      label: __('Gender'),
      slug: 'gender',
      componentClass: GenderField,
    },
    level: {
      label: __('Level'),
      slug: 'level',
      componentClass: SelectWithAggregatedValuesField,
    },
    region: {
      label: __('Region'),
      slug: 'region',
      componentClass: SelectWithAggregatedValuesField,
    },
    service: {
      label: __('Service'),
      slug: 'service',
      componentClass: SelectWithAggregatedValuesField,
    },
    workStartDate: {
      label: __('Work start date'),
      slug: 'work_start_date',
      componentClass: DateField,
      isClearableFromControl: true,
    },
    manager: {
      label: __('Manager'),
      slug: 'manager',
      errorFetchingKey: 'managerId', // We don't use directly manager in the backend, so we need this to handle error fetching
      componentClass: ManagerField,

      syncOptions: { syncedFieldSlug: 'manager_uniq_identifier' },
    },
    team: {
      label: __('Team name'),
      slug: 'team',
      errorFetchingKey: 'teamId', // We don't use directly team in the backend, so we need this to handle error fetching
      componentClass: TeamField,

      syncOptions: { syncedFieldSlug: 'team_name' },
    },
    entityId: {
      label: __('Entity'),
      slug: 'entity_id',
      componentClass: EntityField,

      syncOptions: { syncedFieldSlug: 'entity' },
    },
    registrationNumber: {
      label: __('Registration Number'),
      slug: 'registration_number',
    },
    department: {
      label: __('Department'),
      slug: 'department',
      componentClass: SelectWithAggregatedValuesField,
    },
    locale: {
      label: __('Language'),
      slug: 'locale',
      componentClass: LocaleField,
    },
    username: {
      label: __('Username'),
      slug: 'username',
    },
    useSso: {
      label: __('The employee logs in via SSO:'),
      slug: 'useSso',
      componentClass: SSOField,
    },
  }[field] as FieldMetadata);

export default useFieldMetadata;
