import React from 'react';

import { SurveyQuestionStats } from 'models';

import tailwindTheme from 'config/tailwindTheme';

import { __, n__ } from 'helpers/i18n';

import { Flex, Icon, SimpleRadialChart, Text } from 'components';

type Props = {
  questionStats: SurveyQuestionStats;
};

const QuestionCompletionStats = ({ questionStats }: Props) => {
  return (
    <div>
      <Text preset="18bs5">{__('Completion')}</Text>
      <div className="flex items-center gap-6 mt-2">
        <div style={{ height: 100, width: 100 }}>
          <SimpleRadialChart
            value={Math.round(100 * questionStats.completion)}
            color={tailwindTheme.colors['text-main']}
          />
        </div>
        <Flex direction="column">
          <Text preset="14s6" color="grey" className="mb-4">
            <Icon name="group" className="mr-2" />
            {n__(
              '%1 participant',
              '%1 participants',
              questionStats.participantsCount
            )}
          </Text>
          <Text preset="14s6" color="grey" className="mb-4">
            <Icon name="assignment_turned_in" className="mr-2" />
            {n__('%1 answer', '%1 answers', questionStats.answersCount)}
          </Text>
          {questionStats.commentsCount !== null && (
            <Text preset="14s6" color="grey">
              <Icon name="comment" className="mr-2" />
              {n__('%1 comment', '%1 comments', questionStats.commentsCount)}
            </Text>
          )}
        </Flex>
      </div>
    </div>
  );
};

export default QuestionCompletionStats;
