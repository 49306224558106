import React from 'react';

import { useObjectMutation } from 'helpers/hooks';
import useRemoteDestroy, {
  nonDestroyed,
} from 'helpers/hooks/mutation/useRemoteDestroy';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { HamburgerMenu, MenuItem, MenuList, Tooltip } from 'components';

import { FormBudgetEnvelope } from '../../types';

type Props = {
  envelope: FormBudgetEnvelope;
  onChange: (value: FormBudgetEnvelope) => void;
  onDelete: () => void;
  onEditClick: () => void;
};

const EnvelopeMenu = ({ envelope, onChange, onDelete, onEditClick }: Props) => {
  const updateAttribute = useObjectMutation(envelope, onChange);
  const { destroyItem, isSoftDestroyed } = useRemoteDestroy(
    envelope,
    updateAttribute,
    onDelete
  );

  invariant(
    !isSoftDestroyed,
    'Soft destroyed envelopes render should be prevented by parent'
  );

  const destroyDisabled = envelope.budgetItems.filter(nonDestroyed).length > 0;

  return (
    <HamburgerMenu align="right">
      <MenuList>
        <MenuItem onClick={onEditClick} key="edit">
          {__('Edit envelope')}
        </MenuItem>
        <Tooltip
          enabled={destroyDisabled}
          content={__(
            'This envelope cannot be deleted because it contains funding sources'
          )}
        >
          <MenuItem
            onClick={destroyItem}
            key="delete"
            isDanger
            disabled={destroyDisabled}
          >
            {__('Delete envelope')}
          </MenuItem>
        </Tooltip>
      </MenuList>
    </HamburgerMenu>
  );
};

export default EnvelopeMenu;
