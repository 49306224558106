import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Course from './Course';
import Courses from './Courses';

const CatalogRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Courses />} />
      <Route path=":id" element={<Course />} />
    </Routes>
  );
};

export default CatalogRoutes;
