import type { Occupation } from 'models';

import { computePercentChange } from 'helpers/computePercentChange';

const addRemunerationProgressPercent = (
  occupations: Array<Occupation>
): Array<Occupation> => {
  return occupations.map((occupation, index) => {
    if (index === 0) {
      return occupation;
    }

    const previousOccupation = occupations[index - 1];

    const remunerations = occupation.remunerations?.map(remuneration => {
      const previousRemuneration = previousOccupation.remunerations?.find(
        previousRemuneration =>
          previousRemuneration.remunerationTypeId ===
          remuneration.remunerationTypeId
      );
      const percentChange =
        previousRemuneration?.valueCurrency === remuneration.valueCurrency
          ? computePercentChange(
              previousRemuneration?.valueCents,
              remuneration.valueCents
            )
          : null;
      return { ...remuneration, percentChange };
    });

    return { ...occupation, remunerations: remunerations };
  });
};

export default addRemunerationProgressPercent;
