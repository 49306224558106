import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ObjectivePeriod, Team } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToTeamObjectivesForObjectivePeriod } from 'helpers/paths';

import { post } from 'redux/actions/api';

import { ConfirmationModal, Text } from 'components';

import TeamPicker from 'scenes/components/TeamPicker';

type Props = {
  onClose: () => void;
  objectivePeriod: ObjectivePeriod;
  disabledTeamIds?: Array<string>;
};

const AddTeamModal = ({
  onClose: closeModal,
  objectivePeriod,
  disabledTeamIds,
}: Props) => {
  const navigate = useNavigate();
  const [selectedTeam, setSelectedTeam] = React.useState<Team | null>(null);
  const dispatch = useAppDispatch();

  const onClose = () => {
    setSelectedTeam(null);
    closeModal();
  };

  const addTeamToPeriod = async () => {
    invariant(selectedTeam, 'A team must be selected to submit.');
    const { slug: teamSlug } = selectedTeam;
    await dispatch(
      post(`teams/${teamSlug}/objective_periods`, {
        periodSlug: objectivePeriod.slug,
      })
    );
    navigate(
      pathToTeamObjectivesForObjectivePeriod(objectivePeriod.slug, teamSlug)
    );
    onClose();
  };

  return (
    <ConfirmationModal
      isActive
      onCancel={onClose}
      refreshContentOnOpening={false}
      title={__('Add a team to %1', objectivePeriod.name)}
      onConfirm={addTeamToPeriod}
      confirmLabel={__('Add team')}
      confirmDisabled={!selectedTeam}
    >
      <div>
        <Text>{__('Create a new team or select an existing one:')}</Text>
      </div>
      <div>
        <TeamPicker
          value={selectedTeam ? selectedTeam.id : null}
          onChange={(_teamId, team) => setSelectedTeam(team)}
          disabledTeamIds={disabledTeamIds}
          inModal
        />
      </div>
    </ConfirmationModal>
  );
};

export default AddTeamModal;
