import moment from 'moment';
import React, { useState } from 'react';

import type { AuditReport as AuditReportModel } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import { Button, PageHeader, Tag, Text } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';

type Props = {
  auditReport: AuditReportModel;
  refreshData: () => void;
  isDataUpdating: boolean;
};

const AuditReportPageHeader = ({
  auditReport,
  refreshData,
  isDataUpdating,
}: Props) => {
  const [isExportModalActive, setIsExportModalActive] = useState(false);
  const dispatch = useAppDispatch();
  const exportData = async () => {
    await dispatch(post(`audit_reports/${auditReport.id}/export`));
    setIsExportModalActive(true);
  };

  return (
    <>
      <PageHeader
        withBackButton
        title={auditReport.title}
        actions={[
          <Button
            key="refresh-btn"
            color="secondary"
            onClick={refreshData}
            // @ts-ignore TSFIXME Prop isFetching does not exist on Button (try isLoading instead)
            isFetching={isDataUpdating}
          >
            {__('Refresh data')}
          </Button>,
          <Button key="export-btn" color="primary" onClick={exportData}>
            {__('Export')}
          </Button>,
        ]}
        description={
          <div className="flex items-center w-full mb-4">
            <div>
              <Text>{__('Audit based on')}</Text>
              {auditReport.tags.map((tag, index) => (
                <Tag key={`tag-${index}`} className="mx-1">
                  {tag.label}
                </Tag>
              ))}
              <Text>
                {n__(
                  'in the last %1 month',
                  'in the last %1 months',
                  auditReport.delayInMonths
                )}
              </Text>
            </div>
            <div className="ml-auto">
              <Text>
                {__(
                  'Data updated %1',
                  moment(auditReport.dataUpdatedAt).calendar()
                )}
              </Text>
            </div>
          </div>
        }
      />
      <ExportModal
        isActive={isExportModalActive}
        onClose={() => setIsExportModalActive(false)}
      />
    </>
  );
};

export default AuditReportPageHeader;
