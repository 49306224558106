import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { ActionProps } from './types';
import type { OneOnOneUserReview, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { viewerRole } from 'helpers/models/userReview';
import confirmAsync from 'helpers/react/confirmAsync';
import { trackAction } from 'helpers/tracking';
import { withActiveUser } from 'helpers/withSessionProps';

import { updateUserReviewStatus } from 'redux/actions/resources';

import { MenuItem } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = ActionProps & {
  onAfterAction?: () => any;
};

type AfterWithActiveUserProps = Props & {
  activeUser: User;
};

type AfterConnectProps = AfterWithActiveUserProps & {
  unshareEvaluation: () => Promise<void>;
};

const getActionPerformText = (target: Props['target']): string => {
  switch (target) {
    case 'reviewee':
      return __('Re-open self assessment');
    case 'reviewer':
      return __('Re-open the evaluation of the main reviewer');
    case 'additional_reviewer':
      return __('Re-open the evaluation of the additional reviewer');
    case 'reviewers':
      return __('Re-open the evaluation of the 2 reviewers');
  }
};

const getConfirmCtaText = (
  target: Exclude<Props['target'], 'reviewee'>
): string => {
  switch (target) {
    case 'reviewer':
      return __('Re-open evaluation of the main reviewer');
    case 'additional_reviewer':
      return __('Re-open evaluation of the additional reviewer');
    case 'reviewers':
      return __('Re-open evaluation of reviewers');
  }
};

const getRetainedDataText = (
  target: Exclude<Props['target'], 'reviewee'>
): string => {
  switch (target) {
    case 'reviewer':
      return __('previously recorded by the main reviewer of this review.');
    case 'additional_reviewer':
      return __(
        'previously recorded by the additional reviewer of this review.'
      );
    case 'reviewers':
      return __('previously recorded by the two reviewers of this review.');
  }
};

function UnshareEvaluation({
  userReview,
  target,
  unshareEvaluation,
}: AfterConnectProps) {
  const targetsAdditionalReviewer =
    target === 'additional_reviewer' || target === 'reviewers';
  const hasAdditionalReviewer = !!userReview.additionalReviewer;

  invariant(
    !targetsAdditionalReviewer || hasAdditionalReviewer,
    'Unshare confirm popup: additionalReviewer must exist when targeted'
  );

  return (
    <MenuItem onClick={unshareEvaluation}>
      {getActionPerformText(target)}
    </MenuItem>
  );
}

function buildSideEffectsNotice(userReview: OneOnOneUserReview) {
  const {
    signatureModuleEnabled,
    trainingHistoryEnabled,
    signatureWithFinalCommentEnabled,
  } = userReview;

  return (
    <>
      <p>
        <b>{__('Good to know:')}</b>
      </p>
      <p>
        {__(
          'If you have changed the objectives after sharing, they will be updated with the latest version when the review is reopened.'
        )}
      </p>
      {trainingHistoryEnabled && (
        <p>
          {__(
            'If any training requests were created or updated after sharing, you will see these information in the training request history when the review is reopened.'
          )}
        </p>
      )}
      {signatureWithFinalCommentEnabled && (
        <p>
          {__('The final comment of the reviewee and reviewer will be kept.')}
        </p>
      )}
      {signatureModuleEnabled && (
        <p>
          {__(
            'Signatures will be removed and the interview will have to be signed again.'
          )}
        </p>
      )}
    </>
  );
}

function buildConfirmBody(
  userReview: OneOnOneUserReview,
  target: 'additional_reviewer' | 'reviewer' | 'reviewers'
) {
  const { additionalReviewer, reviewer } = userReview;

  const reviewers: User[] = [];
  const targetsReviewer = target === 'reviewer' || target === 'reviewers';
  const targetsAdditionalReviewer =
    target === 'additional_reviewer' || target === 'reviewers';

  if (targetsReviewer) reviewers.push(reviewer);
  // @ts-ignore TSFIXME: Fix strictNullChecks error
  if (targetsAdditionalReviewer) reviewers.push(additionalReviewer);

  return (
    <>
      <p>
        <b>{__('The reopening will retain the data')} </b>
        {getRetainedDataText(target)}
      </p>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {reviewers.map(user => (
          <UserAvatar
            user={user}
            withJobTitle
            style={{ marginTop: 8, marginBottom: 8 }}
          />
        ))}
      </div>
      {targetsReviewer && buildSideEffectsNotice(userReview)}
    </>
  );
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { activeUser, userReview, target, onAfterAction }: AfterWithActiveUserProps
) {
  const performUnshare = async () => {
    trackAction(`Evaluation forced unshared`, {
      reviewerRole: target,
      activeUserRole: viewerRole(activeUser, userReview),
    });
    await dispatch(
      updateUserReviewStatus(userReview, {
        role: target,
        status: 'in_progress',
      })
    );
    if (onAfterAction) {
      await Promise.resolve(onAfterAction());
    }
  };

  return {
    unshareEvaluation: async () => {
      if (target !== 'reviewee') {
        const title = getActionPerformText(target);
        const body = buildConfirmBody(userReview, target);
        const ctaText = getConfirmCtaText(target);

        await confirmAsync(title, body, {
          onConfirm: async () => {
            await performUnshare();
          },
          confirmLabel: ctaText,
          isDanger: true,
          isLarge: true,
        });
      } else {
        performUnshare();
      }
    },
  };
}

export default compose<ComponentType<Props>>(
  withActiveUser,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(UnshareEvaluation);
