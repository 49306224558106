import { omit } from 'lodash';
import React, { useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { compose } from 'redux';

import type {
  DataLoaderProvidedProps,
  FilterChangeMethod,
  UserFilterChangeMethod,
} from 'lib/dataLoader';
import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/pagination/types';
import type {
  OneOnOneUserReview,
  ReviewCycle,
  ThreeSixtyUserReview,
  UserFilterSegment,
  UserReviewCollection,
} from 'models';

import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { pathToReviewCycles } from 'helpers/paths';

import { highLevelErrorHandler } from 'lib/api';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Box,
  BreadcrumbAnchor,
  CheckboxSwitch,
  EmptyState as EmptyStateComponent,
  Loading,
  PageTitle,
  Text,
} from 'components';
import { withRouter } from 'components/HOCs/withRouter';
import DatatableWrapper from 'components/datatable/DatatableWrapper';

import AILogo from 'scenes/components/AILogo';

import EmptyState from './EmptyState';
import OneOnOneUserReviewsList from './OneOnOneUserReviewList';
import ThreeSixtyUserReviewsList from './ThreeSixtyUserReviewList';

type Props = {
  reviewCycle: ReviewCycle;
  hideUserFilters?: boolean;
  additionalFilter?: { [key: string]: { [key: string]: boolean } | boolean };
  onFilterChange?: FilterChangeMethod;
  userFilter?: UserFilterSegment | null;
  onUserFilterChange?: UserFilterChangeMethod;
} & WithPaginationProps;

type AfterRouterProps = {
  navigate: NavigateFunction;
};

type AfterPaginateProps = AfterRouterProps &
  Props &
  PaginationProps & {
    flaggedByAi: string;
  };

type AfterConnectProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    userReviewCollection: UserReviewCollection;
  };

function ParticipantList({
  page,
  countPerPage,
  flaggedByAi: flaggedByAiProp,
  queryParams: { search, filter, userFilters },
  userReviewCollection,
  reviewCycle,
  isFetching,
  hasError,
  setParams,
  setQueryParams,
  setPreviousPageParams,
  setNextPageParams,
  refetchData,
  hideUserFilters,
}: AfterConnectProps) {
  const [flaggedByAi, setFlaggedByAi] = useState(flaggedByAiProp === 'true');

  if (!userReviewCollection) {
    return <Loading containerStyle={{ padding: 15 }} />;
  }

  if (reviewCycle.userReviewsCount === 0) {
    return <EmptyState reviewCycle={reviewCycle} />;
  }

  let userReviewList;

  if (reviewCycle.interactionType === '360') {
    const userReviews =
      userReviewCollection.userReviews as Array<ThreeSixtyUserReview>;

    userReviewList = (
      <ThreeSixtyUserReviewsList
        userReviews={userReviews}
        reviewCycle={reviewCycle}
        refetchData={refetchData}
        isFetching={isFetching}
        hasError={hasError}
      />
    );
  } else {
    const userReviews =
      userReviewCollection.userReviews as Array<OneOnOneUserReview>;

    userReviewList = (
      <OneOnOneUserReviewsList
        userReviews={userReviews}
        refetchData={refetchData}
        reviewCycle={reviewCycle}
        isFetching={isFetching}
        hasError={hasError}
      />
    );
  }

  const onAiFilterChange = (value: boolean) => {
    setFlaggedByAi(value);
    setParams(prevState => ({
      ...prevState,
      page: 1,
      flaggedByAi: value.toString(),
    }));
  };

  const collectionInfo = userReviewCollection
    ? omit(userReviewCollection, 'userReviews')
    : null;

  const filters = () => {
    if (reviewCycle.interactionType === '360') {
      return [
        { param: 'all', label: __('All') },
        { param: 'waiting_for_peers', label: __('Waiting for peers') },
        { param: 'not_started', label: __('Not started') },
        { param: 'ongoing', label: __('Ongoing') },
        { param: 'shared', label: __('Shared') },
      ];
    }

    const oneOnOneFilters = [
      { param: 'all', label: __('All') },
      { param: 'not_started', label: __('Not started') },
      { param: 'ongoing', label: __('Ongoing') },
      { param: 'shared', label: __('Shared') },
    ];
    if (reviewCycle.signatureModuleEnabled)
      oneOnOneFilters.push({ param: 'signed', label: __('Signed') });
    return oneOnOneFilters;
  };

  const canSeeReviewCatergozation = can({
    perform: 'see_review_categorization',
    on: reviewCycle,
  });

  return (
    <>
      {canSeeReviewCatergozation && (
        <div className="flex mt-4 items-center">
          <CheckboxSwitch
            value={flaggedByAi}
            size="small"
            onChange={onAiFilterChange}
          />
          <Text preset="14bs6" className="mr-2">
            {__('See only the reviews that need your attention')}
          </Text>

          <AILogo />
        </div>
      )}

      <Box style={{ marginTop: 8 }} testClassName="test-participants-list-box">
        <PageTitle title={[reviewCycle.name, __('Participants')]} />
        <BreadcrumbAnchor name="user_review_list" />
        {reviewCycle.id === userReviewCollection.reviewCycleId ? (
          <DatatableWrapper
            collectionInfo={collectionInfo}
            search={search}
            withSearch
            page={page}
            countPerPage={countPerPage}
            getPreviousPage={setPreviousPageParams}
            getNextPage={setNextPageParams}
            userFilters={userFilters}
            withUserMultiFilters
            searchPlaceholder={__('Search reviewee')}
            isFetching={isFetching}
            hasError={hasError}
            filters={filters()}
            onQueryParamsChange={setQueryParams}
            renderNoResult={() => (
              <EmptyStateComponent
                title={__('No review matches your search')}
                inBoxList
              />
            )}
            filter={filter}
            hideUserFilters={hideUserFilters}
          >
            {userReviewList}
          </DatatableWrapper>
        ) : (
          <Loading containerStyle={{ padding: 15 }} />
        )}
      </Box>
    </>
  );
}

export default compose<React.ComponentType<Props>>(
  withPagination,
  withRouter,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      additionalFilter,
      queryParams: { search, filter, userFilters },
      reviewCycle,
      flaggedByAi,
      navigate,
    }: AfterPaginateProps) => {
      const filters = { ...filter, ...additionalFilter };
      const defaultParams = {
        page,
        countPerPage,
        search,
        filter: filters,
        userFilters,
        flaggedByAi,
      };

      return get(
        `review_cycles/${reviewCycle.id}/user_reviews`,
        reviewCycle.reviewCategorizationEnabled
          ? { ...defaultParams, flaggedByAi }
          : defaultParams,
        {
          customErrorHandler: (error, dispatch) => {
            if (error.response.status === 401) {
              navigate(pathToReviewCycles(reviewCycle.interactionType));
              return;
            }

            highLevelErrorHandler(error, dispatch);
          },
        }
      );
    },
    hydrate: {
      userReviewCollection: {
        userReviews: {
          abilities: {},
          reviewer: {},
          responsible: {},
          user: {},
          evaluations: {
            reviewer: {},
          },
          additionalReviewer: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      additionalFilter,
      queryParams: { search, filter, userFilters },
      reviewCycle,
      flaggedByAi,
    }: AfterPaginateProps) => {
      const { userReviewsCount } = reviewCycle;
      return compositeKey({
        filter,
        additionalFilter,
        userFilters,
        page,
        countPerPage,
        search,
        userReviewsCount,
        flaggedByAi,
      });
    },
  })
)(ParticipantList);
