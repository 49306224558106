import React, { useState } from 'react';

import type { ReviewCycle, Tag as TagType } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Button, Flex, Icon, Tag } from 'components';

import UpdateTagsModal from './UpdateTagsModal';

type Props = {
  reviewCycle: ReviewCycle;
  onFieldChange: (field: { tagLabels: Array<string> }) => Promise<void>;
  readOnly: boolean;
};

const TagsBlock = ({ reviewCycle, onFieldChange, readOnly }: Props) => {
  const tags = reviewCycle.tags;
  invariant(tags, 'Review cycle tags should be defined');
  const [isTagsModalActive, setIsTagsModalActive] = useState(false);

  const removeTag = (tagLabel: string) => {
    onFieldChange({
      tagLabels: tags.map(tag => tag.label).filter(tag => tag !== tagLabel),
    });
  };
  const updateTags = (tags: Array<TagType>) =>
    onFieldChange({
      tagLabels: tags.map(tag => tag.label),
    });

  return (
    <Flex
      style={{ flexDirection: 'column', alignItems: 'start' }}
      className="gap-1"
    >
      {tags.map((tag, index) => (
        <Tag
          key={`tag-${index}`}
          rightIcon={
            readOnly
              ? undefined
              : {
                  name: 'close',
                  onClick: () => removeTag(tag.label),
                }
          }
        >
          {tag.label}
        </Tag>
      ))}
      {!readOnly && (
        <Button
          onClick={() => setIsTagsModalActive(true)}
          size="small"
          color="secondary"
          className="no-underline"
        >
          <Icon className="mr-1" name="add_circle" />
          {__('Add')}
        </Button>
      )}

      {isTagsModalActive && !readOnly && (
        <UpdateTagsModal
          tags={tags}
          updateTags={updateTags}
          onClose={() => setIsTagsModalActive(false)}
          tagContext={
            reviewCycle.interactionType === 'one_on_one'
              ? 'review_cycle'
              : 'three_sixty_review_cycle'
          }
        />
      )}
    </Flex>
  );
};

export default TagsBlock;
