import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { useIsVisible } from 'helpers/hooks';

type OptionProps<TProps> = {
  delay: number;
  mockClass: React.ComponentType<TProps>;
};

const withLoadingOnViewportVisibility = <T,>(options: OptionProps<T>) => {
  return (OriginalComponent: React.ComponentType<T>) => {
    const NewComponent: React.ComponentType<
      T & { propToResetLoadingOnChange?: any }
    > = originalProps => {
      const { ref, isVisible } = useIsVisible();
      const [canDisplay, setCanDisplay] = useState(false);
      const isVisibleRef = useRef(false);
      const timeout = useRef(null);

      const prevResetProp = useRef(originalProps.propToResetLoadingOnChange);

      if (
        !isEqual(
          originalProps.propToResetLoadingOnChange,
          prevResetProp.current
        )
      ) {
        prevResetProp.current = originalProps.propToResetLoadingOnChange;
        setCanDisplay(false);
      }

      useEffect(() => {
        if (canDisplay) {
          return;
        }

        const launchTimer = () => {
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          timeout.current = setTimeout(() => {
            setCanDisplay(isVisibleRef.current);

            timeout.current = null;
          }, options.delay);
        };

        isVisibleRef.current = isVisible;

        if (!timeout.current && isVisible) {
          launchTimer();
        }
      }, [isVisible, canDisplay]);

      const Component = canDisplay ? OriginalComponent : options.mockClass;

      return (
        <div ref={ref}>
          {/** @ts-ignore TSFIXME: Fix strictNullChecks error **/}
          <Component {...originalProps} />
        </div>
      );
    };

    return NewComponent;
  };
};

export default withLoadingOnViewportVisibility;
