import React from 'react';

import type { UserFilterSegment } from 'models';

import { __ } from 'helpers/i18n';

import { Notification } from 'components';
import FetchContainer from 'components/FetchContainer';
import { ActiveFilters } from 'components/Filters/types';

type Props = {
  isFetching: boolean;
  hasError: boolean;
  hasStats: boolean;
  filter: { [key: string]: boolean };
  userFilter: UserFilterSegment | null;
  userFilters?: ActiveFilters;
  render: () => React.ReactNode;
};

export default function StatsContainer({
  render,
  isFetching,
  hasError,
  hasStats,
  filter,
  userFilter,
  userFilters,
}: Props) {
  return (
    <FetchContainer
      loadingStyle={hasStats ? 'overlay' : 'alone'}
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        if (!hasStats && (!userFilter || !userFilters) && !filter) {
          return (
            <Notification kind="info">
              <p>{__('This campaign does not have any participant.')}</p>
            </Notification>
          );
        }

        return (
          <>
            {!hasStats && (
              <Notification kind="info">
                <p>{__('No participant matches your filter')}</p>
              </Notification>
            )}
            {hasStats && render()}
          </>
        );
      }}
    />
  );
}
