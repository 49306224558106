import React from 'react';

import type { FundingItem as FundingItemType, FundingSource } from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Button, Icon } from 'components';
import MoneyInput from 'components/formElements/advancedElements/MoneyInput';

import FundingSourceSelect from './FundingSourceSelect';

type NewFundingItem = {
  amountCents: number | undefined | null;
  amountCurrency: string;
  fundingSource: FundingSource | undefined | null;
};

type Props = {
  fundingItem: FundingItemType | NewFundingItem;
  fundingSources: Array<FundingSource>;
  disabledFundingSources: Array<FundingSource>;
  onUpdate: (params: Partial<FundingItemType>) => void;
  onDelete: () => void;
  disabled?: boolean;
  deletable?: boolean;
};

const FundingItem = ({
  fundingItem,
  fundingSources,
  disabledFundingSources,
  onUpdate,
  onDelete,
  disabled,
  deletable = true,
}: Props) => {
  const handleUpdate = (newFundingItem: FundingItemType | NewFundingItem) => {
    const fundingSource = newFundingItem.fundingSource;

    invariant(
      fundingSource,
      'fundingSource must be defined when updating fundingItem'
    );

    onUpdate({ ...newFundingItem, fundingSource } as Partial<FundingItemType>);
  };

  const onAmountChange = (amountCents: number | null) => {
    const newFundingItem = { ...fundingItem };

    newFundingItem.amountCents = amountCents;

    handleUpdate(newFundingItem);
  };

  const onFundingSourceChange = (fundingSource: FundingSource) => {
    const newFundingItem = { ...fundingItem };

    newFundingItem.fundingSource = fundingSource;

    handleUpdate(newFundingItem);
  };

  return (
    <div className="flex items-center justify-between gap-7 pb-2">
      <div className="flex-1 test-training-funding-source-select">
        <FundingSourceSelect
          fundingSource={fundingItem.fundingSource}
          onChange={onFundingSourceChange}
          fundingSources={fundingSources}
          disabledFundingSources={disabledFundingSources}
          disabled={disabled}
        />
      </div>
      <div className="flex-1 flex">
        <MoneyInput
          testClassName="test-budget-item-money-input"
          amount={fundingItem.amountCents}
          placeholder={__('Amount')}
          currency={fundingItem.amountCurrency}
          onAmountChange={amountCents => {
            onAmountChange(amountCents);
          }}
          isDisabled={disabled || !fundingItem.fundingSource}
          saveOnlyOnBlur
          className="w-full"
        />
        <Button
          className="ml-2 border-none bg-transparent"
          hasIconOnly
          onClick={onDelete}
          disabled={disabled || !deletable}
        >
          <Icon name="delete" />
        </Button>
      </div>
    </div>
  );
};

export default FundingItem;
