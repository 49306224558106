import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { TrainingSession } from 'models';

import { __ } from 'helpers/i18n';
import {
  pathToForecastTrainingPlan,
  pathToTrainingSessionCost,
  pathToTrainingSessionDetails,
  pathToTrainingSessionParticipants,
  pathToValidatedTrainingPlan,
} from 'helpers/paths';
import {
  makeDocumentNonScrollable,
  makeDocumentScrollable,
} from 'helpers/scrollable';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  ContentContainer,
  DesignSystem,
  FetchContainer,
  PageHeader,
  SavingStatusLabel,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import Cost from './Cost';
import Details from './Details';
import HeaderActions from './HeaderActions';
import Participants from './Participants';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterConnectProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    session: TrainingSession;
  };

const Session = ({
  match,
  session,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  useEffect(() => {
    makeDocumentNonScrollable();
    return function cleanup() {
      makeDocumentScrollable();
    };
  });

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const tabItems = [
          {
            label: __('Information'),
            to: pathToTrainingSessionDetails(session.id),
            testName: 'test-details-tab-button',
          },
          {
            label: __('Cost'),
            to: pathToTrainingSessionCost(session.id),
            testName: 'test-cost-tab-button',
          },
          {
            label: __('Participants'),
            to: pathToTrainingSessionParticipants(session.id),
            testName: 'test-participants-tab-button',
          },
        ];

        return (
          <div className="training-session-management">
            <DesignSystem version={2}>
              <PageHeader
                title={session.name || __('Untitled session')}
                withBackButton
                backButtonProps={{
                  target: session.validated
                    ? pathToValidatedTrainingPlan(session.period.slug)
                    : pathToForecastTrainingPlan(session.period.slug),
                }}
                statusInfo={
                  <SavingStatusLabel
                    failedText={() =>
                      __('The training session could not be updated')
                    }
                  />
                }
                tabItems={tabItems}
                actions={<HeaderActions session={session} />}
              />
            </DesignSystem>

            <div className="training-session-management-content">
              <ContentContainer>
                <Routes>
                  <Route
                    path="details"
                    element={<Details session={session} />}
                  />
                  <Route
                    path="participants"
                    element={<Participants session={session} />}
                  />
                  <Route path="cost" element={<Cost session={session} />} />
                  <Route
                    path="*"
                    element={
                      <Navigate to={pathToTrainingSessionDetails(match.id)} />
                    }
                  />
                </Routes>
              </ContentContainer>
            </div>
          </div>
        );
      }}
    />
  );
};

export default compose(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`training/sessions/${match.id}`),
    hydrate: {
      session: {
        fundingItems: {
          fundingSource: {},
        },
        abilities: {},
        period: {},
        creator: {},
        trainingCourse: { organism: {} },
        tags: {},
      },
    },
    cacheKey: ({ match }: AfterMatchParamsProps) => match.id,
  })
)(Session) as React.ComponentType;
