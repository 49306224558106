import React from 'react';

import type { ThreeSixtyUserReview } from 'models/index';

import { __ } from 'helpers/i18n/index';

import { MenuItem, Toggleable } from 'components/index';

import ReOpenPeersFeedbackModal from './ReOpenPeersFeedbackModal';

type Props = {
  userReview: ThreeSixtyUserReview;
  onAfterAction?: () => Promise<void>;
  disabled?: boolean;
};

const ReOpenPeersFeedbackAction = ({
  userReview,
  onAfterAction,
  disabled,
}: Props) => {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <>
          <MenuItem onClick={toggle} disabled={disabled}>
            {__('Re-open peers feedback')}
          </MenuItem>
          <ReOpenPeersFeedbackModal
            isActive={isToggled}
            toggleModal={toggle}
            userReview={userReview}
            onAfterAction={onAfterAction}
          />
        </>
      )}
    />
  );
};

export default ReOpenPeersFeedbackAction;
