import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { post } from 'superagent';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { createUrl } from 'lib/api';
import { getCredentials } from 'lib/api/credentials';
import { get } from 'redux/actions/api';
import { AppDispatch } from 'redux/actions/types';

import { Button } from 'components';

type ImportDataType = {
  eligibleUserIdsCount: number;
  ineligibleUserLoginsAndReasonsCount: number;
  notFoundUserLoginsCount: number;
  unadministeredUserLoginsCount: number;
  filename: string;
};

type Props = {
  reviewCycleId: string;
  refetchData: () => Promise<void>;
  onReport: (report: ReactNode) => void;
  onError: (error: ReactNode) => void;
};

type AfterConnectProps = Props & {
  afterImportAction: () => Promise<void>;
};

const Import = ({
  reviewCycleId,
  onReport,
  onError,
  afterImportAction,
}: AfterConnectProps) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const openFile = () => {
    if (!!fileInputRef.current) fileInputRef.current.click();
  };
  const onInputChange = event => {
    onError(null);
    onReport(null);
    const file = event.target.files[0];
    if (!file) return;
    event.target.value = null;
    const url = createUrl(
      `review_cycles/${reviewCycleId}/planned_participants/import`
    );
    const credentials = getCredentials();
    invariant(credentials?.token, 'credentials token should be defined');
    post(url)
      .set(
        'Authorization',
        `Bearer ${credentials.token ? credentials.token : ''}`
      )
      .attach('file', file)
      .then(res => {
        const report = res.body.data.attributes as ImportDataType;
        const reportContainsErrors =
          report.unadministeredUserLoginsCount > 0 ||
          report.ineligibleUserLoginsAndReasonsCount > 0 ||
          report.notFoundUserLoginsCount > 0;

        onReport(
          <>
            {__(
              '%1 employee(s) selected, %2 employee(s) outside your entities, %3 employee(s) ineligible and %4 employee(s) not found from file named %5.',
              report.eligibleUserIdsCount,
              report.unadministeredUserLoginsCount,
              report.ineligibleUserLoginsAndReasonsCount,
              report.notFoundUserLoginsCount,
              report.filename
            )}
            {reportContainsErrors && (
              <div className="mt-2 font-bold">
                {__('An error report has been sent to you by email.')}
              </div>
            )}
          </>
        );
        afterImportAction();
      })
      .catch(e =>
        onError(e.response.body.error?.message || e.response.body.error)
      );
  };

  return (
    <>
      <Button color="secondary" style={{ marginRight: 16 }} onClick={openFile}>
        {__('Import')}
      </Button>
      <input
        type="file"
        name="participantsFile"
        onChange={onInputChange}
        multiple={false}
        ref={fileInputRef}
        accept=".csv,.xlsx"
        className="hidden"
      />
    </>
  );
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { reviewCycleId, refetchData }: Props
): Omit<AfterConnectProps, keyof Props> => {
  return {
    afterImportAction: async () => {
      await dispatch(get(`review_cycles/${reviewCycleId}`));
      await refetchData();
    },
  };
};

// @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
export default connect(null, mapDispatchToProps)(Import);
