import React, { createContext } from 'react';
import { compose } from 'redux';

import type { SkillsLevel, SkillsMatrix } from 'models';

import can from 'helpers/can';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer, FetchContainer } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import MatrixUnpublishedEmptyState from 'scenes/components/skills/MatrixUnpublishedEmptyState';
import useParentAndChildrenHandlingWithErrors from 'scenes/skills/helpers/useParentAndChildrenHandlingWithErrors';

import Matrix from './Matrix';
import PageHeader from './PageHeader';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type Props = {
  editionMode?: boolean;
};

export type MatrixErrorsType = {
  matrix?: {
    title?: Array<string>;
  };
  levels?: {
    [id: string]: {
      title?: Array<string>;
      description?: Array<string>;
    };
  };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  Props &
  DataLoaderProvidedProps & {
    matrix: SkillsMatrix;
  };

type DataContextType = {
  matrix: SkillsMatrix;
  refetchData: () => Promise<void>;
  editionMode: boolean;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

function MatrixVisualizer({
  matrix,
  isFetching,
  hasError,
  refetchData,
  editionMode,
}: AfterDataLoaderProps) {
  const handlingProps = useParentAndChildrenHandlingWithErrors<
    SkillsMatrix,
    SkillsLevel
  >({
    refetchData,
    parent: matrix,
    parentProps: {
      name: 'matrix',
      endpoint: 'skills/matrices',
      errorField: 'matrix',
    },
    childProps: {
      name: 'level',
      endpoint: 'skills/levels',
      errorField: 'levels',
    },
  });

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        if (!can({ perform: 'show_content', on: matrix })) {
          return <MatrixUnpublishedEmptyState />;
        }

        return (
          <>
            <PageHeader
              {...handlingProps.headerProps}
              editionMode={editionMode}
            />
            <ContentContainer>
              <DataContext.Provider
                value={{
                  matrix,
                  refetchData,
                  editionMode: !!editionMode,
                }}
              >
                <Matrix {...handlingProps.editorProps} />
              </DataContext.Provider>
            </ContentContainer>
          </>
        );
      }}
    />
  );
}

export default compose<React.ComponentType<Props>>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`/skills/matrices/${match.id}`),
    hydrate: {
      matrix: {
        levels: {},
        areaMappings: { mappedExpectations: {}, domain: {} },
        abilities: {},
      },
    },
  })
)(MatrixVisualizer);
