import React from 'react';

import { CompensationBand } from 'models';

import { useOrganizationSettings } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToCompensationBands } from 'helpers/paths';

import { Icon, PageHeader, Text } from 'components';

import StatusTag from 'scenes/components/StatusTag';

type Props = {
  compensationBand: CompensationBand;
};

const BandHeader = ({ compensationBand }: Props) => {
  const { managerCanSeeManageeRemuneration } = useOrganizationSettings();
  const isPublished = compensationBand.status === 'published';
  const { collaboratorsVisibility } = compensationBand;

  const visibilityMessage = ((): string => {
    if (!isPublished) return __('Visible only to admins');

    switch (collaboratorsVisibility) {
      case 'no_visibility':
        return managerCanSeeManageeRemuneration
          ? __('Visible to admins and direct and indirect managers')
          : __('Visible to admins and indirect managers');
      case 'level_visibility':
        return managerCanSeeManageeRemuneration
          ? __(
              'Visible to admins, direct and indirect managers and employees, on their level only'
            )
          : __(
              'Visible to admins, indirect managers and employees, on their level only'
            );
      case 'band_visibility':
        return managerCanSeeManageeRemuneration
          ? __('Visible to admins, direct and indirect managers and employees')
          : __('Visible to admins, indirect managers and employees');
      default:
        throw new Error(`Unknown visibility ${collaboratorsVisibility}`);
    }
  })();

  return (
    <PageHeader
      withBackButton
      title={compensationBand.name}
      description={
        <div className="flex justify-between items-center w-full mb-4">
          <div>
            <Icon name="visibility" className="mr-2" />
            <Text>{visibilityMessage}</Text>
          </div>

          <StatusTag status={compensationBand.status} />
        </div>
      }
      backButtonProps={{
        children: __('Back to compensation bands'),
        fallbackTarget: pathToCompensationBands(),
      }}
    />
  );
};

export default BandHeader;
