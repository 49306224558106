import { AdminRoleName } from 'models/Role';

import { __ } from 'helpers/i18n';

const displayableRoleName = (roleName: AdminRoleName) => {
  switch (roleName) {
    case 'admin':
      return __('Global administrator');
    case 'performance_admin':
      return __('Performance administrator');
    case 'compensation_admin':
      return __('Compensation administrator');
    case 'training_admin':
      return __('Training administrator');
    case 'campaigns_responsible':
      return __('Campaigns responsible');
    case 'people_review_admin':
      return __('People Review administrator');
    case 'people_review_responsible':
      return __('People Review responsible');
    default:
      return roleName;
  }
};

export default displayableRoleName;
