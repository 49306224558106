import React, { useContext } from 'react';

import currencies from 'helpers/currencies';
import { useArrayMutation } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Button, Icon, StrictlySanitizedHtml, Text, Tooltip } from 'components';

import { BudgetFormContext } from '../Form';
import { FormExchangeRate } from '../types';
import ExchangeRateItem from './ExchangeRateItem';

type Props = {
  exchangeRates: Array<FormExchangeRate>;
  onChange: (value: Array<FormExchangeRate>) => void;
  usedCurrencies: string[];
  definedCurrencies: string[];
};

const ExchangeRates = ({
  exchangeRates,
  onChange,
  usedCurrencies,
  definedCurrencies,
}: Props) => {
  const { trainingPeriod } = useContext(BudgetFormContext);
  const { createItem, updateItem, deleteItem } = useArrayMutation(
    exchangeRates,
    onChange
  );

  const availableCurrencies = currencies
    .filter(currency => !definedCurrencies.includes(currency))
    .filter(currency => currency !== trainingPeriod.mainCurrency);

  const addButtonDisabled = availableCurrencies.length === 0;

  return (
    <div>
      <div className="flex items-center">
        <Text preset="18bs5" className="block mb-4">
          {__('Exchange Rates')}
          <Tooltip
            isLight
            placement="right"
            className="max-w-md"
            content={__(
              'In the event that other currencies are used, you will have the option of adding their exchange rates. Otherwise, a rate of 1:1 will be used.'
            )}
          >
            <Icon className="mb-1 ml-2" name="info" color="info" />
          </Tooltip>
        </Text>
      </div>
      <Text color="light" className="block mb-4">
        <StrictlySanitizedHtml
          html={__(
            'Set the exchange rates against your main currency: <b>%1</b>',
            trainingPeriod.mainCurrency
          )}
        />
      </Text>

      <div>
        {exchangeRates.map((exchangeRate, index) => (
          <ExchangeRateItem
            key={exchangeRate.id}
            availableCurrencies={availableCurrencies}
            exchangeRate={exchangeRate}
            locked={usedCurrencies.includes(exchangeRate.baseCurrency)}
            onDelete={deleteItem(index)}
            onChange={updateItem(index)}
          />
        ))}
      </div>

      <Button
        onClick={createItem({
          id: '',
          baseCurrency: availableCurrencies[0],
          targetToBaseRate: '1',
          targetCurrency: trainingPeriod.mainCurrency,
        })}
        color="secondary"
        disabled={addButtonDisabled}
      >
        {__('Add a currency')}
      </Button>
    </div>
  );
};

export default ExchangeRates;
