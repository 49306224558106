import React from 'react';

import { TrainingSession } from 'models';

import GeneralActions from './GeneralActions';
import StatusActions from './StatusActions';

type Props = { session: TrainingSession };

const Actions = ({ session }: Props) => {
  return (
    <div className="flex gap-3">
      <GeneralActions session={session} />
      <StatusActions session={session} />
    </div>
  );
};

export default Actions;
