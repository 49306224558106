import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { Organization } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import {
  pathToOrganizationObjectivePeriod,
  pathToTeamObjectivesForObjectivePeriod,
} from 'helpers/paths';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get, post } from 'redux/actions/api';

import { Button, Can, PeriodPicker, Testable, Text } from 'components';

import CreatePeriodModal from '../components/CreateOrganizationPeriodModal';

type Props = {
  selectedObjectivePeriodSlug: string;
  teamSlug: string | undefined | null;
};

type AfterConnectProps = Props & {
  isFetching: boolean;
  hasError: boolean;
  organization: Organization;
};

const ObjectivePeriodPicker = ({
  selectedObjectivePeriodSlug,
  teamSlug,
  organization,
  isFetching,
}: AfterConnectProps) => {
  const navigate = useNavigate();
  const [showCreatePeriodModal, setShowCreatePeriodModal] =
    React.useState(false);
  const dispatch = useAppDispatch();

  const activatePeriod = (slug: string) =>
    dispatch(post(`organization_objective_periods/${slug}/activate`));

  if (isFetching) {
    return (
      <div>
        <PeriodPicker
          isLoading
          period={undefined}
          periods={[]}
          onChange={() => {}}
        />
      </div>
    );
  }

  const { objectivePeriods } = organization;

  invariant(
    objectivePeriods,
    'Organization should contain the objective periods'
  );

  const currentObjectivePeriod = objectivePeriods.find(
    objectivePeriod => objectivePeriod.active
  );
  const selectedObjectivePeriod = objectivePeriods.find(
    objectivePeriod => objectivePeriod.slug === selectedObjectivePeriodSlug
  );

  invariant(
    selectedObjectivePeriod && currentObjectivePeriod,
    'There should always be one selected and one active objective period'
  );

  const periodsData = objectivePeriods.map(objectivePeriod => ({
    id: objectivePeriod.slug,
    name:
      objectivePeriod.name +
      (objectivePeriod.active ? ` ${__('(current period)')}` : ''),
  }));

  return (
    <>
      <div>
        <div className="flex">
          <PeriodPicker
            period={{
              id: selectedObjectivePeriod.slug,
              name: selectedObjectivePeriod.name,
            }}
            periods={periodsData}
            onChange={slug => {
              navigate(
                teamSlug
                  ? pathToTeamObjectivesForObjectivePeriod(slug, teamSlug)
                  : pathToOrganizationObjectivePeriod(slug)
              );
            }}
            onPeriodCreationLinkClick={
              can({
                perform: 'manage_team_objectives',
                on: organization,
              })
                ? () => setShowCreatePeriodModal(true)
                : undefined
            }
            periodCreationLinkClassName="test-add-period"
          />
          {selectedObjectivePeriod !== currentObjectivePeriod && (
            <Can perform="manage_team_objectives" on="organization">
              <Testable name="test-set-current-period-button">
                <Button
                  className="ml-2"
                  color="secondary"
                  onClick={() => activatePeriod(selectedObjectivePeriodSlug)}
                >
                  {__('Set as current period')}
                </Button>
              </Testable>
            </Can>
          )}
        </div>
        <div>
          {selectedObjectivePeriod === currentObjectivePeriod && (
            <Testable name="test-current-period-text">
              <Text color="info" preset="13s7">
                {__('Current period')}
              </Text>
            </Testable>
          )}
        </div>
      </div>
      {showCreatePeriodModal && (
        <CreatePeriodModal onClose={() => setShowCreatePeriodModal(false)} />
      )}
    </>
  );
};

export default newDataLoader({
  fetch: () => get('organization_objective_periods'),
  hydrate: { organization: { objectivePeriods: {}, abilities: {} } },
})(ObjectivePeriodPicker) as React.ComponentType<Props>;
