import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useActiveUser, useOrganizationSettings } from 'helpers/hooks';

import ObjectiveDashboard from './Dashboard';
import PersonalObjectives from './PersonalObjectives';
import TeamObjectivesRoutes from './team/Routes';

const ObjectivesRoutes = () => {
  const activeUser = useActiveUser();
  const settings = useOrganizationSettings();
  return (
    <Routes>
      <Route path="dashboard" element={<ObjectiveDashboard />} />
      <Route
        path="personal"
        element={<PersonalObjectives user={activeUser} />}
      />
      {settings.areTeamObjectivesEnabled && !activeUser.external && (
        <Route path="teams/*" element={<TeamObjectivesRoutes />} />
      )}
    </Routes>
  );
};

export default ObjectivesRoutes;
