import { capitalize } from 'lodash';
import React, { useState } from 'react';

import { UserHistoryLog } from 'models';

import {
  dateWithoutTimeFromDateString,
  formatDateToLocaleMonthAndYear,
} from 'helpers/date';
import { __ } from 'helpers/i18n';

import { Icon, Link, ProductIcon, Text } from 'components';

import SummaryModal from './SummaryModal';

type Props = {
  log: UserHistoryLog;
  currentReviewId: string;
};

const UserHistoryLogItem = ({ log, currentReviewId }: Props) => {
  const { redirectPath, downloadUrl } = log.item;
  const isCurrentReview =
    log.itemType === 'UserReview' && log.itemId === currentReviewId;
  const isClickable = !isCurrentReview && (redirectPath || downloadUrl);
  const [summaryModalActive, setSummaryModalActive] = useState<boolean>(false);

  return (
    <div className="mt-4 flex items-center">
      <ProductIcon productName={log.item.productName} />
      <div className="ml-4 flex flex-col w-full">
        <div>
          {isClickable ? (
            <Link
              to={redirectPath || downloadUrl}
              openInNewTab
              withOpenInNewTabIcon={false}
              additionalClassName="mb-1"
            >
              <Text
                preset="14bs6"
                color="primary"
                className="line-clamp-2 text-ellipsis"
              >
                {log.item.name}
              </Text>
            </Link>
          ) : (
            <Text
              preset="14bs6"
              className="line-clamp-2 text-ellipsis text-text-main"
            >
              {log.item.name}
            </Text>
          )}
          {log.itemType === 'ImportedReview' && (
            <Text> {__('(Imported)')}</Text>
          )}
        </div>
        <div className="flex items-center justify-between">
          <Text preset="13s7" className="text-text-light flex-1 flex flex-wrap">
            {capitalize(
              formatDateToLocaleMonthAndYear(
                dateWithoutTimeFromDateString(log.itemCreatedAt)
              )
            )}
            {log.item.cycleStatus === 'active' && ' • ' + __('Ongoing')}
            {log.item?.tags?.length > 0 && (
              <>
                {' • '}
                {log.item.tags.map((tag, index) => (
                  <Text key={index} className="whitespace-nowrap mx-1">
                    {tag}
                    {index < log.item.tags.length - 1 ? ' / ' : ''}
                  </Text>
                ))}
              </>
            )}
          </Text>
          {log.item.summary && (
            <Text preset="13s7" className="flex text-text-light">
              <Icon name="description" className="mr-2" />
              <Link
                additionalClassName="flex items-center text-text-light"
                onClick={() => setSummaryModalActive(true)}
              >
                {__('See summary')}
              </Link>
            </Text>
          )}
          {!!log.item.summary && summaryModalActive && (
            <SummaryModal
              summary={log.item.summary}
              onClose={() => setSummaryModalActive(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserHistoryLogItem;
