import React, { useContext } from 'react';

import type { ReviewCycle } from 'models';

import { ContentContainer } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import Results from 'scenes/admin/reviewCycles/Manage/Results';

import { DataContext } from '..';

type Props = {
  reviewCycle: ReviewCycle;
};

const ManagerCycleResults = ({ reviewCycle }: Props) => {
  const { userFilters, setUserFilters } = useContext(DataContext);

  return (
    <ContentContainer>
      <Results
        reviewCycle={reviewCycle}
        userFilters={userFilters as ActiveFilters}
        setUserFilters={setUserFilters}
      />
    </ContentContainer>
  );
};

export default ManagerCycleResults;
