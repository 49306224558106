import { Style, StyleRoot } from 'radium';
import React from 'react';
import { Provider } from 'react-redux';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { generalStyle } from 'styles';

import {
  DesignSystem,
  FlagsContainer,
  GlobalErrorBoundary,
  LoadingOverlay,
  Notice,
  PageTitle,
} from 'components';

import { MainRoutes } from 'scenes/Routes';

import SessionInitializer from './SessionInitializer';
import store from './store';

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<MainRoutes />} />)
);

export default function Root() {
  return (
    <Provider store={store}>
      <GlobalErrorBoundary>
        <StyleRoot>
          <DesignSystem version={2}>
            <Style rules={generalStyle} />
            <PageTitle title="Elevo" />
            <SessionInitializer
              render={(isInitialized, session) => {
                if (!isInitialized) {
                  return <LoadingOverlay />;
                }
                return (
                  <>
                    <FlagsContainer session={session || null} />
                    <Notice />
                    <RouterProvider router={router} />
                  </>
                );
              }}
            />
          </DesignSystem>
        </StyleRoot>
      </GlobalErrorBoundary>
    </Provider>
  );
}
