import React, { ReactElement, ReactNode } from 'react';

import type { OneOnOneUserReview, ReviewCycle } from 'models';

import can from 'helpers/can';
import invariant from 'helpers/invariant';

import { MenuDivider } from 'components';

import { UpdateResponsibleAction } from 'scenes/components/userReview/actions';
import RemoveParticipantAction from 'scenes/components/userReview/common/actions/RemoveParticipantAction';
import {
  SendReminder,
  SendSignatureReminder,
  ShareEvaluation,
  UnshareEvaluation,
  UpdateAdditionalReviewerAction,
} from 'scenes/components/userReview/oneOnOne/actions';

const getAvailableMenuItems = (
  reviewCycle: ReviewCycle,
  userReview: OneOnOneUserReview,
  refetchData: () => Promise<void>
): ReactNode[] => {
  invariant(userReview.abilities, 'abilities must be defined on userReview');

  const itemGroups: {
    remind: ReactElement[];
    unshare: ReactElement[];
    other: ReactElement[];
    remove: ReactElement[];
  } = {
    remind: [],
    unshare: [],
    other: [],
    remove: [],
  };

  /* Remind */
  if (can({ perform: 'remind_self_assessment', on: userReview })) {
    itemGroups.remind.push(
      <SendReminder
        userReview={userReview}
        target="reviewee"
        key="sendReminderToReviewee"
      />
    );
  }
  if (can({ perform: 'remind_reviewer_evaluation', on: userReview })) {
    itemGroups.remind.push(
      <SendReminder
        userReview={userReview}
        target="reviewer"
        key="sendReminderToReviewer"
      />
    );
  }
  if (can({ perform: 'remind_reviewee_signature', on: userReview })) {
    itemGroups.remind.push(
      <SendSignatureReminder
        userReview={userReview}
        target="reviewee"
        key="sendSignatureReminderToReviewee"
      />
    );
  }
  if (can({ perform: 'remind_reviewer_signature', on: userReview })) {
    itemGroups.remind.push(
      <SendSignatureReminder
        userReview={userReview}
        target="reviewer"
        key="sendSignatureReminderToReviewer"
      />
    );
  }
  /* Unshare */
  if (can({ perform: 'unshare_self_assessment', on: userReview })) {
    itemGroups.unshare.push(
      <UnshareEvaluation
        userReview={userReview}
        target="reviewee"
        key="unshareRevieweeEvaluation"
      />
    );
  }
  if (can({ perform: 'unshare_main_reviewer_evaluation', on: userReview })) {
    itemGroups.unshare.push(
      <UnshareEvaluation
        userReview={userReview}
        target="reviewer"
        onAfterAction={refetchData}
        key="unshareReviewerEvaluation"
      />
    );
  }
  if (
    can({ perform: 'unshare_additional_reviewer_evaluation', on: userReview })
  ) {
    itemGroups.unshare.push(
      <UnshareEvaluation
        userReview={userReview}
        target="additional_reviewer"
        key="unshareAdditionalReviewerEvaluation"
      />
    );
  }
  if (can({ perform: 'unshare_reviewers_evaluations', on: userReview })) {
    itemGroups.unshare.push(
      <UnshareEvaluation
        userReview={userReview}
        target="reviewers"
        onAfterAction={refetchData}
        key="unshareAdditionalReviewersEvaluation"
      />
    );
  }
  /* Other */
  if (can({ perform: 'update_additional_reviewer', on: userReview })) {
    itemGroups.other.push(
      <UpdateAdditionalReviewerAction
        userReview={userReview}
        key="updateAdditionalReviewer"
        onAfterAction={refetchData}
      />
    );
  }
  if (can({ perform: 'share_main_reviewer_evaluation', on: userReview })) {
    itemGroups.other.push(
      <ShareEvaluation
        userReview={userReview}
        target="reviewer"
        onAfterAction={refetchData}
        key="shareReviewerEvaluation"
      />
    );
  }
  if (can({ perform: 'share_reviewers_evaluations', on: userReview })) {
    itemGroups.other.push(
      <ShareEvaluation
        userReview={userReview}
        target="reviewers"
        onAfterAction={refetchData}
        key="shareReviewersEvaluation"
      />
    );
  }
  if (can({ perform: 'share_self_assessment', on: userReview })) {
    itemGroups.other.push(
      <ShareEvaluation
        userReview={userReview}
        target="reviewee"
        key="shareRevieweeEvaluation"
      />
    );
  }
  if (can({ perform: 'update_responsible', on: userReview })) {
    itemGroups.other.push(
      <UpdateResponsibleAction
        userReview={userReview}
        reviewCycle={reviewCycle}
        onAfterAction={refetchData}
        key="updateResponsibleAction"
      />
    );
  }
  /* Remove */
  if (can({ perform: 'remove_participant', on: userReview })) {
    itemGroups.remove.push(
      <RemoveParticipantAction
        key="removeParticipantAction"
        reviewCycle={reviewCycle}
        userReview={userReview}
      />
    );
  }

  const elements: Array<ReactNode> = [];
  (Object.keys(itemGroups) as (keyof typeof itemGroups)[]).forEach(key => {
    const items: Array<ReactNode> = itemGroups[key];
    items.forEach(item => elements.push(item));
    if (items.length > 0) elements.push(<MenuDivider key={key} />);
  });

  elements.pop();

  return elements;
};

export default getAvailableMenuItems;
