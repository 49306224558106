import React, { ReactNode } from 'react';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

import { Icon, Measurable, type MeasurablePosition } from 'components';

type Props = {
  onClick: () => void;
  isEdited: boolean;
  renderNotEdited: () => ReactNode;
  renderEdited: (targetPosition: MeasurablePosition) => ReactNode;
  overlayEdited?: boolean;
  disabled?: boolean;
  withEditIcon?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
};

type State = {
  targetPosition: MeasurablePosition | null | undefined;
};

export default class InlineEditOutline extends React.Component<Props, State> {
  state = {
    targetPosition: null,
  };

  setPosition = (position: MeasurablePosition) => {
    this.setState({ targetPosition: position });
  };

  shouldRenderEdited = () => {
    const { isEdited, disabled } = this.props;
    const { targetPosition } = this.state;

    return !disabled && isEdited && !!targetPosition;
  };

  renderEdited = () => {
    const { renderEdited } = this.props;
    const { targetPosition } = this.state;

    invariant(
      targetPosition,
      'targetPosition should be defined for renderEdited to be called'
    );
    return renderEdited(targetPosition);
  };

  renderNotEdited = () => {
    const {
      withEditIcon,
      renderNotEdited,
      onClick,
      disabled,
      hasError,
      hasWarning,
    } = this.props;
    const editIconClassName = classNames('inline-editable-icon', {
      'always-on': !!withEditIcon,
    });

    return (
      <Measurable onPositionChange={this.setPosition}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={!disabled ? onClick : undefined}
          onFocus={!disabled ? onClick : undefined}
          className={classNames(
            'test-inline-editable-outline',
            'inline-editable-outline',
            {
              'is-disabled': !!disabled,
              'has-error': !!hasError,
              'has-warning': !!hasWarning,
            }
          )}
          onKeyDown={e => {
            if (!disabled && e.keyCode === 13) {
              e.preventDefault();
              onClick();
            }
          }}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={disabled ? undefined : 0}
        >
          <>
            {renderNotEdited()}

            {!disabled && (
              <Icon size="tiny" className={editIconClassName} name="edit" />
            )}
          </>
        </div>
      </Measurable>
    );
  };

  render() {
    if (this.shouldRenderEdited()) {
      return this.renderEdited();
    }

    return this.renderNotEdited();
  }
}
