import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function NotSignedInRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.redirectTo) {
      localStorage.setItem('redirect', location.state.redirectTo);
    }

    navigate('/signin');
  }, [location, navigate]);

  return null;
}
