import React from 'react';

import type { TrainingSession, TrainingSessionSnapshot } from 'models';

import { startToEndDateLabel } from 'helpers/date';
import { __ } from 'helpers/i18n';

import { Text } from 'components';

type Props = {
  session: TrainingSession | TrainingSessionSnapshot;
};

const TrainingSessionDatesTableCell = ({ session }: Props) => {
  if (session.startDate === null && session.endDate === null) {
    return (
      <Text color="light" transformation="italic">
        {__('No dates')}
      </Text>
    );
  }

  return (
    <>
      {startToEndDateLabel(session.startDate, session.endDate, {
        fromToLabelsAlwaysVisible: true,
      })}
    </>
  );
};

export default TrainingSessionDatesTableCell;
