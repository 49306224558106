import { isEmpty, sortBy } from 'lodash';
import React, { useState } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PeopleGroup } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  EmptyStateWithIcon,
  FetchContainer,
  Flex,
  Icon,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import PeopleGroupListItemPlaceholder from '../components/PeopleGroupListItemPlaceholder';
import PeopleGroupListItem from './PeopleGroupListItem';
import CreatePeopleGroupModal from './PeopleGroupModal/CreatePeopleGroupModal';
import usePeopleReviewCycleFromParams from './usePeopleReviewCycleFromParams';

type Props = {
  cycleId: string;
  selectedPeopleGroupIds: string[];
  togglePeopleGroupSelection: (value: string) => void;
  userFilters: ActiveFilters;
  filteringEnabled: boolean;
  canEditPeopleGroups: boolean;
  setShouldRefetchReviews: (value: boolean) => void;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    peopleGroups: PeopleGroup[];
  };

const LoadingStateRenderer = () => (
  <Flex className="people-group-list">
    {Array.from({ length: 4 }, (_e, index) => (
      <PeopleGroupListItemPlaceholder key={index} />
    ))}
  </Flex>
);

const EmptyState = ({
  canEditPeopleGroups,
  setShowCreatePeopleGroupModal,
}: {
  canEditPeopleGroups: boolean;
  setShowCreatePeopleGroupModal: (value: boolean) => void;
}) => {
  const peopleReviewCycle = usePeopleReviewCycleFromParams();

  return (
    <>
      {canEditPeopleGroups ? (
        <div className="-mt-12">
          <div className="flex justify-end mb-4">
            <Button
              color="primary"
              onClick={() => setShowCreatePeopleGroupModal(true)}
              disabled={peopleReviewCycle.peopleReviewDimensions.length === 0}
              disabledExplanation={__(
                'Please contact support@elevo.io to add dimensions to your campaign'
              )}
            >
              <Icon name="add" className="mr-2" />
              {__('Create first group')}
            </Button>
          </div>

          <EmptyStateWithIcon
            iconName="equalizer"
            title={__('Start analyzing your people reviews')}
            description={__(
              'Create your own groups of employees simply by clicking on Create first group.'
            )}
          />
        </div>
      ) : (
        <EmptyStateWithIcon
          iconName="equalizer"
          title={__('No groups have been created yet')}
          description={__('Group management is reserved for super admins.')}
        />
      )}
    </>
  );
};

function PeopleGroupList({
  peopleGroups,
  selectedPeopleGroupIds,
  togglePeopleGroupSelection,
  canEditPeopleGroups,
  filteringEnabled,
  isFetching,
  hasError,
  refetchData,
  setShouldRefetchReviews,
}: AfterDataLoaderProps) {
  const [showCreatePeopleGroupModal, setShowCreatePeopleGroupModal] =
    useState(false);

  return (
    <>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        renderFetching={LoadingStateRenderer}
        render={() => {
          if (isEmpty(peopleGroups)) {
            return (
              <EmptyState
                canEditPeopleGroups={canEditPeopleGroups}
                setShowCreatePeopleGroupModal={setShowCreatePeopleGroupModal}
              />
            );
          }

          return (
            <div className="flex">
              <div className="flex people-group-list">
                {sortBy(peopleGroups, 'position').map(peopleGroup => (
                  <PeopleGroupListItem
                    key={peopleGroup.id}
                    peopleGroup={peopleGroup}
                    disabled={!filteringEnabled}
                    isSelected={selectedPeopleGroupIds.includes(peopleGroup.id)}
                    canEditPeopleGroups={canEditPeopleGroups}
                    togglePeopleGroupSelection={() =>
                      togglePeopleGroupSelection(peopleGroup.id)
                    }
                    refetchData={refetchData}
                    setShouldRefetchReviews={setShouldRefetchReviews}
                  />
                ))}
              </div>
              {canEditPeopleGroups && (
                <div className="content-center ml-4">
                  <Button
                    hasIconOnly
                    isRounded
                    color="secondary"
                    onClick={() => {
                      setShowCreatePeopleGroupModal(true);
                    }}
                  >
                    <Icon name="add" />
                  </Button>
                </div>
              )}
            </div>
          );
        }}
      />
      {showCreatePeopleGroupModal && (
        <CreatePeopleGroupModal
          onClose={() => {
            setShowCreatePeopleGroupModal(false);
          }}
          onSubmit={() => {
            setShouldRefetchReviews(true);
            refetchData();
          }}
        />
      )}
    </>
  );
}

export default newDataLoader({
  fetch: ({ cycleId, userFilters }: Props) =>
    get(`/people_review_cycles/${cycleId}/groups`, {
      userFilters,
    }),
  hydrate: {
    peopleGroups: {
      peopleGroupRules: {
        peopleReviewDimension: {},
      },
    },
  },
  cacheKey: ({ cycleId, userFilters }: Props) =>
    compositeKey({
      cycleId,
      userFilters,
    }),
})(PeopleGroupList);
