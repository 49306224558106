import React from 'react';

import { useObjectMutation } from 'helpers/hooks';
import useRemoteDestroy from 'helpers/hooks/mutation/useRemoteDestroy';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import {
  Button,
  Icon,
  Select,
  SimpleFloatInput,
  Text,
  stringToOption,
} from 'components';

import { FormExchangeRate } from '../types';

type Props = {
  exchangeRate: FormExchangeRate;
  onChange: (exchangeRate: FormExchangeRate) => void;
  availableCurrencies: string[];
  locked: boolean;
  onDelete: () => void;
};

const ExchangeRateItem = ({
  availableCurrencies,
  exchangeRate,
  locked,
  onDelete,
  onChange,
}: Props) => {
  const updateAttribute = useObjectMutation(exchangeRate, onChange);
  const { destroyItem, isSoftDestroyed } = useRemoteDestroy(
    exchangeRate,
    updateAttribute,
    onDelete
  );

  if (isSoftDestroyed) return null;

  return (
    <div className="flex items-center my-4">
      <Select
        value={stringToOption(exchangeRate.baseCurrency)}
        options={availableCurrencies.map(stringToOption)}
        onChange={option => {
          invariant(
            option && !Array.isArray(option),
            'Option must exist and cannot be an array'
          );

          updateAttribute('baseCurrency')(option.value);
        }}
        isMulti={false}
        isClearable={false}
        inModal
        className="w-1/3"
        isDisabled={locked}
      />

      <div className="flex-1"></div>

      <div className="flex items-center">
        <Text color="light" className="mr-2">
          {__('1 %1 is equal to: ', exchangeRate.targetCurrency)}
        </Text>

        <SimpleFloatInput
          value={exchangeRate.targetToBaseRate}
          onValueChange={updateAttribute('targetToBaseRate')}
          label={exchangeRate.baseCurrency}
        />
      </div>

      <Button
        onClick={destroyItem}
        className="border-none bg-transparent"
        disabled={locked}
      >
        <Icon name="close" />
      </Button>
    </div>
  );
};

export default ExchangeRateItem;
