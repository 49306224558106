import React from 'react';

import { BudgetEnvelopesStats } from 'models/BudgetEnvelopeStats';

import { __ } from 'helpers/i18n';

import { Icon, Text, Tooltip } from 'components';

import BudgetVisualizer from '../../components/BudgetVisualizer';

const calculateRemainingAmount = (
  provisioned: number,
  validated: number,
  forecast: number
) => {
  return provisioned - validated - forecast;
};

type Props = {
  envelopeStats: BudgetEnvelopesStats;
};

const BudgetEnvelopeItem = ({ envelopeStats }: Props) => {
  const envelopeStatsHasNegativeRemainingAmount =
    calculateRemainingAmount(
      envelopeStats.totalProvisionedAmountCents || 0,
      envelopeStats.totalValidatedAmountCents,
      envelopeStats.totalForecastAmountCents || 0
    ) < 0;

  const displayWarningIcon =
    envelopeStats.fundingSourcesStats.some(source => {
      return (
        calculateRemainingAmount(
          source.provisionedAmountCents || 0,
          source.validatedAmountCents,
          source.forecastAmountCents || 0
        ) < 0
      );
    }) && !envelopeStatsHasNegativeRemainingAmount;

  return (
    <div className="flex items-center gap-0">
      <BudgetVisualizer
        title={<Text preset="14bs6">{envelopeStats.envelopeName}</Text>}
        isInline
        provisionedAmountCents={envelopeStats.totalProvisionedAmountCents}
        validatedAmountCents={envelopeStats.totalValidatedAmountCents}
        forecastAmountCents={envelopeStats.totalForecastAmountCents}
        currency={envelopeStats.mainCurrency}
        className="flex-grow min-w-0"
      />
      {displayWarningIcon && (
        <>
          <Tooltip
            content={
              <Text>{__('One of the funding sources is off-budget.')}</Text>
            }
          >
            <Icon name="warning" color="danger" className="-ml-4" />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default BudgetEnvelopeItem;
