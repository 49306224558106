import React from 'react';

import { ReviewCycle, UserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { Column, DesignSystem, Icon, Text, Tooltip } from 'components';

import ReviewCycleIcon from 'scenes/components/reviewCycle/ListItem/ReviewCycleIcon';

type Props = {
  reviewCycle: ReviewCycle;
  userReview: UserReview;
};

const IconColumn = ({ reviewCycle, userReview }: Props) => {
  const shouldShowReviewCategorizationIcons = can({
    perform: 'see_review_categorization',
    on: reviewCycle,
  });
  const shouldDisplayAiFlaggedWarning = userReview.evaluations.some(
    evaluation => evaluation.flaggedByAi === true
  );

  return (
    <>
      {shouldShowReviewCategorizationIcons ? (
        <DesignSystem version={2}>
          <Column isNarrow isVerticallyCentered className="min-w-14 max-h-14">
            {shouldDisplayAiFlaggedWarning ? (
              <Tooltip
                content={
                  <div className="flex flex-col">
                    <Text preset="13bs7">
                      {__('Review flagged by Elevo AI')}
                    </Text>
                    <Text preset="13s7" className="mt-2">
                      {__(
                        'Elevo AI seeks to identify the reviews that are most likely to need your attention.'
                      )}
                    </Text>
                  </div>
                }
              >
                <Icon name="warning" color="danger" className="text-xl" />
              </Tooltip>
            ) : (
              '-'
            )}
          </Column>
        </DesignSystem>
      ) : (
        <Column isNarrow isVerticallyCentered>
          <ReviewCycleIcon reviewCycle={reviewCycle} />
        </Column>
      )}
    </>
  );
};

export default IconColumn;
