import React from 'react';

import type { UpdatableObjectiveFields } from 'redux/actions/resources';

import { somethingWentWrong } from 'helpers/commonPhrases';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { errorNotice } from 'redux/actions/application';

import {
  Button,
  ButtonPlaceholder,
  HamburgerMenu,
  Icon,
  MenuItem,
  MenuList,
  StrictlySanitizedHtml,
  Testable,
  Text,
  Tooltip,
} from 'components';

type Props = {
  locked: boolean;
  lockedReason: string | null | undefined;
  disabled: boolean;
  onDestroy: () => Promise<unknown>;
  onUpdate: (attributes: Partial<UpdatableObjectiveFields>) => Promise<unknown>;
  showPublishObjective: boolean;
  showUnpublishObjective: boolean;
  objectiveTitle: string;
  revieweeFullName: string;
  getFeedbackCount?: () => Promise<number>;
};

export default function ObjectiveActions({
  locked,
  lockedReason,
  disabled,
  onDestroy,
  showPublishObjective,
  showUnpublishObjective,
  onUpdate,
  objectiveTitle,
  revieweeFullName,
  getFeedbackCount,
}: Props) {
  const dispatch = useAppDispatch();

  if (disabled && !locked) return null;

  const confirmPublishObjective = () =>
    confirmAsync(
      __('Publish the objective "%1"', objectiveTitle),
      <p>
        {__(
          'Once published, this objective will be visible by %1.',
          revieweeFullName
        )}
      </p>,
      {
        confirmLabel: __('Publish'),
        inlineButtonIcon: <Icon style={{ marginRight: 8 }} name="check" />,
        isLarge: true,
        onConfirm: () => onUpdate({ published: true }),
      }
    );

  const confirmUnpublishObjective = async () => {
    let feedbackCount = 0;

    if (!!getFeedbackCount) {
      try {
        feedbackCount = await getFeedbackCount();
      } catch (e) {
        window.logException(e);
        dispatch(errorNotice(somethingWentWrong()));
        return;
      }
    }

    return confirmAsync(
      __('Unpublish the objective "%1"', objectiveTitle),
      <>
        <p>
          {__(
            'Once unpublished, this objective will no longer be visible by %1.',
            revieweeFullName
          )}
        </p>
        {!!getFeedbackCount && feedbackCount > 0 && (
          <>
            <br />
            <p>
              <StrictlySanitizedHtml
                html={n__(
                  'One person has left a comment on this objective.<br/><b>This comment will be deleted</b> if the objective is unpublished.',
                  '%1 people have left a comment on this objective.<br/><b>These comments will be deleted</b> if the objective is unpublished.',
                  feedbackCount
                )}
              />
            </p>
            <Text color="danger">
              <Icon
                style={{ marginBottom: 4, marginRight: 8 }}
                name="warning"
              />
              {__('This action cannot be undone')}
            </Text>
          </>
        )}
      </>,
      {
        confirmLabel: __('Unpublish'),
        inlineButtonIcon: <Icon style={{ marginRight: 8 }} name="edit_note" />,
        isLarge: true,
        onConfirm: () => onUpdate({ published: false }),
      }
    );
  };

  return (
    <Testable name="test-objective-actions">
      {!locked ? (
        <>
          {showPublishObjective && (
            <Button
              testClassName="test-publish-button"
              className="mx-2 my-0"
              color="secondary"
              onClick={() => confirmPublishObjective()}
            >
              {__('Publish')}
            </Button>
          )}
          <HamburgerMenu color="default" align="right">
            <MenuList>
              {showUnpublishObjective && (
                <MenuItem onClick={confirmUnpublishObjective}>
                  {__('Unpublish this objective')}
                </MenuItem>
              )}
              <MenuItem onClick={onDestroy} isDanger>
                {__('Delete this objective')}
              </MenuItem>
            </MenuList>
          </HamburgerMenu>
        </>
      ) : (
        <Tooltip content={lockedReason}>
          <Icon isInfo name="lock" />
        </Tooltip>
      )}
    </Testable>
  );
}

export function ActionsPlaceholder() {
  return <ButtonPlaceholder width={34} />;
}
