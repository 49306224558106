import React from 'react';

import { WithSavingStatusRecorder } from 'components/withSavingStatus';

import Throttler from '../behaviors/Throttler';
import RetryLabel from './RetryLabel';

type OnChange<Value> = (newValue: Value) => unknown;

type Props<Value> = {
  render: (onChange: OnChange<Value>) => React.ReactNode;
  onChange: OnChange<Value>;
  doPersist: () => Promise<void>;
  fieldUid: string;
  withoutRetryLabel?: boolean;
  unprocessableEntityHandled?: boolean;
};

export default function AutoSave<Value>({
  onChange,
  doPersist,
  render,
  fieldUid,
  withoutRetryLabel,
  unprocessableEntityHandled,
}: Props<Value>) {
  return (
    <WithSavingStatusRecorder
      onChange={doPersist}
      fieldUid={fieldUid}
      unprocessableEntityHandled={unprocessableEntityHandled}
      render={(savingStatusOnchange, setSaving) => (
        <>
          <Throttler
            doPersist={savingStatusOnchange}
            onChange={onChange}
            render={render}
            beforeThrottle={setSaving}
          />
          {!withoutRetryLabel && (
            <RetryLabel
              fieldUid={fieldUid}
              onRetryClick={savingStatusOnchange}
            />
          )}
        </>
      )}
    />
  );
}
