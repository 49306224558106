import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useActiveUser } from 'helpers/hooks';
import { useQuery } from 'helpers/navigation';
import { pathToMyReviews } from 'helpers/paths';

import DisplayReview from '../review/Review';
import FeedbackRequests from './FeedbackRequests';
import MyReviews from './MyReviews';
import MyTeamReviews from './MyTeamReviews';
import OtherActions from './OtherActions';

const ReviewsRoutes = () => {
  const activeUser = useActiveUser();
  const query = useQuery();
  const type = query.get('type') || 'one_on_one';

  return (
    <Routes>
      <Route index element={<Navigate to={pathToMyReviews('one_on_one')} />} />
      <Route path="my-reviews" element={<MyReviews />} />
      {activeUser.isManager && (
        <Route path="my-team-reviews" element={<MyTeamReviews type={type} />} />
      )}
      <Route path="delegated-reviews" element={<OtherActions type={type} />} />
      <Route path="feedback-requests" element={<FeedbackRequests />} />
      <Route path=":userReviewId" element={<DisplayReview />} />
    </Routes>
  );
};

export default ReviewsRoutes;
