import React from 'react';

import { TrainingCourse } from 'models/TrainingCourse';

import { useAppDispatch } from 'helpers/hooks';
import { useForm } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import UnsavedModificationsGuard from 'components/UnsavedModificationsGuard';

import CourseDetailsForm from './Form/CourseFormDetails';

type Props = {
  course: TrainingCourse;
};

const EditCourseForm = ({ course }: Props) => {
  const dispatch = useAppDispatch();

  const { values, errors, handleInputChange, handleSubmit, isDirty } = useForm<
    Partial<TrainingCourse>
  >(course, async values => {
    const { organism, tags, ...rest } = values;
    const params = {
      ...rest,
      organism_id: organism?.id || null,
      case_insensitive_tag_labels: tags?.map(tag => tag.label) || [],
    };

    await dispatch(
      put(`training/courses/${course.id}`, params, {
        successMessage: __('The training course was successfully updated.'),
      })
    );
  });

  return (
    <>
      <CourseDetailsForm
        trainingCourse={values}
        handleInputChange={handleInputChange}
        saveButtonLabel={__('Update')}
        isDirty={isDirty}
        errors={errors}
        onSave={handleSubmit}
      />
      <UnsavedModificationsGuard isDirty={isDirty} onSubmit={handleSubmit} />
    </>
  );
};

export default EditCourseForm;
