import React from 'react';

import { ReviewCycle } from 'models/ReviewCycle';

import { Column, Icon } from 'components';

type Props = {
  reviewCycle: ReviewCycle;
};

const IconColumn = ({ reviewCycle }: Props) => {
  const shouldShowReviewCategorizationIcons =
    reviewCycle.reviewCategorizationEnabled;

  return (
    <>
      {shouldShowReviewCategorizationIcons ? (
        <Column isNarrow isVerticallyCentered className="min-w-14"></Column>
      ) : (
        <Column isNarrow className="min-w-14 text-center">
          <Icon isInfo name="do_not_disturb_on" />
        </Column>
      )}
    </>
  );
};

export default IconColumn;
