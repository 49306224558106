import { useMemo } from 'react';

type ArrayState = Array<unknown>;

type CreateItemFunction<TValue> = (value: TValue) => () => void;
type UpdateItemFunction<TValue> = (index: number) => (value: TValue) => void;
export type DeleteItemFunction = (index: number) => () => void;

type HookReturnType<TValue> = {
  createItem: CreateItemFunction<TValue>;
  updateItem: UpdateItemFunction<TValue>;
  deleteItem: DeleteItemFunction;
};

type ArrayItem<TArray> = TArray extends Array<infer TItem> ? TItem : never;

/**
 * @example
 * const List = ({ value, onChange }) => {
 *   const { createItem, updateItem, deleteItem } = useArrayMutation(
 *     value,
 *     onChange
 *   );
 *
 *   return (
 *     <Fragment>
 *       {value.map((item, index) => (
 *         <Person key={index} value={item} onChange={updateItem(index)} />
 *         <button onClick={deleteItem(index)}>Delete</button>
 *       ))}
 *       <button onClick={createItem({ name: '', age: 0 })}>Add</button>
 *     </Fragment>
 *   );
 * };
 */
const useArrayMutation = <TArrayState extends ArrayState>(
  array: TArrayState,
  onChange: (array: TArrayState) => void
): HookReturnType<ArrayItem<TArrayState>> => {
  const newArray = useMemo(() => [...array], [array]) as typeof array;
  return useMemo(
    () => ({
      updateItem: (index: number) => (value: typeof array[0]) => {
        newArray[index] = value;
        onChange(newArray);
      },
      deleteItem: (index: number) => () => {
        newArray.splice(index, 1);
        onChange(newArray);
      },
      createItem: (value: typeof array[0]) => () => {
        newArray.push(value);
        onChange(newArray);
      },
    }),
    [onChange, newArray]
  );
};

export default useArrayMutation;
