import React from 'react';

import { SurveyQuestionUpdatableAttributes } from 'models';

import { __ } from 'helpers/i18n';

import { Checkbox, Icon, Text, Tooltip } from 'components';

type Props = {
  enabled: boolean;
  onUpdate: (params: { content: SurveyQuestionUpdatableAttributes }) => void;
};

const TextSentiment = ({ enabled, onUpdate }: Props) => {
  return (
    <>
      <Text preset="14bs6">{__('AI-assisted analysis:')}</Text>
      <div className="flex items-center gap-2 mt-2">
        <Checkbox
          isChecked={enabled}
          size="small"
          onChange={value =>
            onUpdate({ content: { textSentimentEnabled: value } })
          }
        />
        <Text>{__('Activate sentiment analysis')}</Text>
        <Tooltip
          content={__(
            'Elevo AI supports you by categorizing each answer to a question as positive, neutral or negative. You can also see the average "sentiment" of responses to that question.'
          )}
          style={{ maxWidth: '400px' }}
          triggerClassName="flex items-center"
        >
          <Icon name="info" />
        </Tooltip>
      </div>
    </>
  );
};

export default TextSentiment;
