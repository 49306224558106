import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';

import { SurveyPollingPeriodStats } from 'models';

import tailwindTheme from 'config/tailwindTheme';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  FetchContainer,
  FlatPieChart,
  Icon,
  MaterialIconName,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import NotEnoughParticipantsEmptyState from '../../components/NotEnoughParticipantEmptyState';
import NoParticipantEmptyState from './NoParticipantEmptyState';

type Props = {
  campaignId: string;
  isAnonymous: boolean;
  periodId: string;
  userFilters: ActiveFilters | '';
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    surveyPollingPeriodStats: SurveyPollingPeriodStats;
  };

const NumberWithLabel = ({
  count,
  label,
  iconName,
  testClassName,
}: {
  count: number | string;
  label: string;
  iconName: MaterialIconName;
  testClassName: string;
}) => (
  <div className="flex flex-col gap-2 items-center">
    <Text preset="24bs2" testClassName={testClassName}>
      {count}
    </Text>
    <Text preset="14s6" color="light" className="whitespace-nowrap">
      <Icon name={iconName} className="mr-3" />
      {label}
    </Text>
  </div>
);

const StatsBox = ({
  isFetching,
  hasError,
  surveyPollingPeriodStats,
  isAnonymous,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const {
          sharedPercent,
          inProgressPercent,
          notStartedPercent,
          participantCount,
          respondentCount,
        } = surveyPollingPeriodStats;

        if (participantCount === 0) return <NoParticipantEmptyState />;
        if (participantCount < 3 && isAnonymous)
          return <NotEnoughParticipantsEmptyState />;

        const flatPieChartData = [
          {
            label: __('Shared'),
            value: sharedPercent,
            color: tailwindTheme.colors.indigo[800],
          },
          {
            label: __('In progress'),
            value: inProgressPercent,
            color: tailwindTheme.colors.indigo[400],
          },
          {
            label: __('Not started'),
            value: notStartedPercent,
            color: tailwindTheme.colors.indigo[300],
          },
        ];

        return (
          <Box>
            <div className="flex">
              <div className="flex-1 mr-16">
                <Text preset="18bs5" className="mb-2 block">
                  {__('Completion')}
                </Text>
                <FlatPieChart data={flatPieChartData} />
              </div>
              <div className="flex flex-1 items-center justify-around border-0 border-l border-solid border-ui-separator-on-background pl-16">
                <NumberWithLabel
                  count={participantCount}
                  label={__('Participants')}
                  iconName="groups"
                  testClassName="test-survey-overview-participants-count"
                />
                <NumberWithLabel
                  count={respondentCount}
                  label={n__(
                    'Singular|Shared',
                    'Plural|Shared',
                    respondentCount
                  )}
                  iconName="assignment_turned_in"
                  testClassName="test-survey-overview-respondents-count"
                />
              </div>
            </div>
          </Box>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ campaignId, periodId, userFilters }: Props) =>
    get(`survey/campaigns/${campaignId}/periods/${periodId}/stats`, {
      userFilters,
    }),
  hydrate: {
    surveyPollingPeriodStats: {},
  },
  cacheKey: ({ campaignId, periodId, userFilters }: Props) =>
    compositeKey({ campaignId, periodId, userFilters }),
})(StatsBox) as React.ComponentType<Props>;
