import React, { createContext, useMemo } from 'react';

import { FundingSource } from 'models';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';

import { FormBudgetEnvelope } from '../types';
import EnvelopeBreakdown from './EnvelopeBreakdown';
import SingleBreakdown from './SingleBreakdown';
import useDummyEnvelope from './helpers/useDummyEnvelope';

export const FundingSourcesContext = createContext(
  {} as {
    availableFundingSources: Array<FundingSource>;
  }
);

type Props = {
  budgetByEnvelope: boolean;
  budgetEnvelopes: Array<FormBudgetEnvelope>;
  onChange: (value: Array<FormBudgetEnvelope>) => void;
  canUpdateFullBudget: boolean;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    fundingSources?: Array<FundingSource>;
  };

const FundingSourcesBlock = ({
  budgetByEnvelope,
  budgetEnvelopes,
  onChange,
  canUpdateFullBudget,
  fundingSources,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  const budgetItems = budgetEnvelopes.flatMap(envelope => envelope.budgetItems);

  const availableFundingSources = useMemo(
    () =>
      fundingSources
        ? fundingSources.filter(
            source =>
              !budgetItems.find(item => item.fundingSource?.id === source.id)
          )
        : [],
    [fundingSources, budgetItems]
  );

  const isStateReady = useDummyEnvelope({
    budgetByEnvelope,
    budgetEnvelopes,
    onChange,
  });

  if (!isStateReady) return null;

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="none"
      render={() => (
        <FundingSourcesContext.Provider
          value={{
            availableFundingSources,
          }}
        >
          {budgetByEnvelope ? (
            <EnvelopeBreakdown
              budgetEnvelopes={budgetEnvelopes}
              onChange={onChange}
              canUpdateFullBudget={canUpdateFullBudget}
            />
          ) : (
            <SingleBreakdown
              budgetEnvelopes={budgetEnvelopes}
              onChange={onChange}
            />
          )}
        </FundingSourcesContext.Provider>
      )}
    />
  );
};

export default newDataLoader({
  fetch: () => get(`training/funding_sources`),
  hydrate: {
    fundingSources: {},
  },
  cacheKey: () => 'funding_sources',
})(FundingSourcesBlock) as React.ComponentType<Props>;
