import React, { useState } from 'react';

import { Tag } from 'models';

import useUrlQueryParams from 'helpers/hooks/useUrlQueryParams';
import { __ } from 'helpers/i18n';

import {
  ContentContainer,
  Label,
  Level,
  LevelLeft,
  PageHeader,
  PageTitle,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import TagPicker from 'scenes/components/TagPicker';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import PlannedActionsWithStats from './PlannedActionsWithStats';

const Index = () => {
  const [filteredTagLabels, setFilteredTagLabels] = useState<Array<Tag>>([]);
  const [shouldRefetchTags, setShouldRefetchTags] = useState(0);

  const { urlQueryParams } = useUrlQueryParams();

  const [userFilters, setUserFilters] = useState<ActiveFilters | ''>(
    // @ts-ignore TSFIXME: queryParams doesn't exist in PaginationSearchParams
    urlQueryParams().queryParams?.userFilters || ''
  );

  return (
    <>
      <PageTitle title={__('Actions plans')} />
      <PageHeader title={__('Actions plans')} />

      <ContentContainer>
        <Text>
          {__(
            'Actions are only visible to administrators and People Review responsibles. They are not displayed to employees.'
          )}
        </Text>

        <Level className="my-4">
          <LevelLeft className="space-x-2">
            <Label>{__('Type')}</Label>
            <TagPicker
              key={shouldRefetchTags}
              context="planned_action"
              selectedTags={filteredTagLabels}
              onTagsChange={setFilteredTagLabels}
              className="w-64"
              placeholder={__('Select an action type')}
              preventCreation
            />

            <UserMultiFilters
              userFilters={userFilters}
              onChange={({ userFilters }) => setUserFilters(userFilters)}
            />
          </LevelLeft>
        </Level>
        <PlannedActionsWithStats
          tagLabels={filteredTagLabels.map(tag => tag.label)}
          refetchTags={() => setShouldRefetchTags(shouldRefetchTags + 1)}
          userFilters={userFilters}
        />
      </ContentContainer>
    </>
  );
};

export default Index;
