import React from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models/index';
import type { ReactNode } from 'react';

import { __ } from 'helpers/i18n/index';
import { getSelectedPeers } from 'helpers/models/evaluation';

import { MenuItem, Toggleable } from 'components/index';

import ValidatePeersModal from './ValidatePeersModal';

type Props = {
  userReview: ThreeSixtyUserReview;
  reviewCycle: ThreeSixtyReviewCycle;
  renderAction: (onClick: () => void) => ReactNode;
  onAfterAction?: () => Promise<void>;
};

export { ValidatePeersModal };
export { default as NominatePeersModal } from './NominatePeersModal';

const ManagePeersAction = ({
  userReview,
  reviewCycle,
  renderAction,
  onAfterAction,
}: Props) => {
  return (
    <Toggleable
      render={(isToggled, toggle) => (
        <>
          {renderAction(toggle)}
          {isToggled && (
            <ValidatePeersModal
              user={userReview.user}
              isActive
              onClose={toggle}
              reviewCycle={reviewCycle}
              userReview={userReview}
              onAfterValidate={onAfterAction}
              peers={getSelectedPeers(userReview.evaluations)}
            />
          )}
        </>
      )}
    />
  );
};

ManagePeersAction.defaultProps = {
  renderAction: onClick => (
    <MenuItem onClick={onClick}>{__('Manage peers')}</MenuItem>
  ),
};

export default ManagePeersAction;
