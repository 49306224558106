import React from 'react';

import { Text } from 'components';

type SuggestionsAccordionTitleAreaProps = {
  title: string;
  count: number;
};

const SuggestionsAccordionAreaTitle = ({
  title,
  count,
}: SuggestionsAccordionTitleAreaProps) => (
  <Text preset="14bs6">
    {title} <span className="text-text-soften ml-1">({count})</span>
  </Text>
);

export default SuggestionsAccordionAreaTitle;
