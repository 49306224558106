import React from 'react';
import { compose } from 'redux';

import type { SkillsArea } from 'models';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import MatrixPaginatedList from '../../components/MatrixPaginatedList';
import Header from '../../components/SkillsAreaHeader';

type AfterMatchParamsProps = {
  match: { id: string };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    area: SkillsArea;
  };

function AreaMatrixList({ match, area, isFetching }: AfterDataLoaderProps) {
  return (
    <>
      <Header areaId={match.id} />
      <ContentContainer>
        {!isFetching && (
          <MatrixPaginatedList
            areaId={area.id}
            paginationType="url"
            emptyStateProps={{
              iconName: 'table_chart',
              title: __(
                'No skills matrix has been associated to this skill yet.'
              ),
            }}
          />
        )}
      </ContentContainer>
    </>
  );
}

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`/skills/areas/${match.id}`),
    hydrate: { area: { expectations: {} } },
  })
)(AreaMatrixList);
