import React from 'react';

import { FetchContainer, Text, Title } from 'components';

import HistorySectionItemComponent, {
  HistorySectionItem,
} from './HistorySectionItem';
import HistoryItemPlaceholder from './HistorySectionItemPlaceholder';

export const HISTORY_ITEM_LIMIT = 5;
const LoadingStateRenderer = () => (
  <>
    <HistoryItemPlaceholder />
    <HistoryItemPlaceholder />
  </>
);

type Props = {
  icon: React.ReactNode;
  title: string;
  historySectionItems: Array<HistorySectionItem>;
  emptyState: string;
  seeMorePath?: string;
  seeMoreLabel?: string;
  isFetching: boolean;
  hasError: boolean;
};

export default function HistorySection({
  icon,
  title,
  historySectionItems,
  emptyState,
  seeMorePath,
  seeMoreLabel,
  isFetching,
  hasError,
}: Props) {
  const displaySeeMoreAction =
    historySectionItems?.length > HISTORY_ITEM_LIMIT &&
    seeMorePath &&
    seeMoreLabel;

  return (
    <div className="history-section">
      <div className="history-section-header">
        <div className="icon-wrapper">{icon}</div>
        <Title size={6} style={{ fontSize: 16, marginLeft: 8 }}>
          {title}
        </Title>
      </div>

      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        renderFetching={LoadingStateRenderer}
        render={() => {
          if (historySectionItems.length === 0) {
            return (
              <Text color="info" transformation="italic">
                {emptyState}
              </Text>
            );
          }

          return (
            <>
              {historySectionItems
                .slice(0, HISTORY_ITEM_LIMIT)
                .map(historySectionItem => (
                  <HistorySectionItemComponent
                    key={historySectionItem.id}
                    historySectionItem={historySectionItem}
                  />
                ))}

              {displaySeeMoreAction && (
                <div style={{ textAlign: 'center', marginTop: 8 }}>
                  <Text linkTo={seeMorePath}>{seeMoreLabel}</Text>
                </div>
              )}
            </>
          );
        }}
      />
    </div>
  );
}
