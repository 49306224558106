import React from 'react';

import compositeKey from 'helpers/compositeKey';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Level } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import PaginatedList from './PaginatedList';
import PlannedActionsStats from './PlannedActionsStats';

type Props = {
  userFilters: ActiveFilters;
  tagLabels: Array<string>;
  refetchTags: () => Promise<void>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    plannedActionsStatsData: PlannedActionsStatsData;
  };

type PlannedActionsStatsData = {
  soonOverdueCount: number;
  overdueCount: number;
  totalCount: number;
};

const PlannedActionsWithStats = ({
  plannedActionsStatsData,
  isFetching,
  hasError,
  refetchData,
  refetchTags,
  userFilters,
  tagLabels,
}: AfterDataLoaderProps) => (
  <>
    <Level>
      <PlannedActionsStats
        plannedActionsStatsData={plannedActionsStatsData}
        isFetching={isFetching}
        hasError={hasError}
      />
    </Level>

    <PaginatedList
      tagLabels={tagLabels}
      parentRefetchData={() => {
        refetchTags();
        return refetchData();
      }}
      additionalQueryParams={{ userFilters }}
    />
  </>
);

export default newDataLoader({
  fetch: ({ tagLabels, userFilters }: Props) =>
    get('planned/people_review_actions/stats', {
      userFilters,
      'tagLabels[]': tagLabels,
    }),
  hydrate: {
    plannedActionsStatsData: {},
  },
  cacheKey: ({ tagLabels, userFilters }: Props) =>
    compositeKey({ tagLabels, userFilters }),
})(PlannedActionsWithStats);
