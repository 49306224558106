import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { __ } from 'helpers/i18n';
import {
  pathToManagerTrainingDashboardRequests,
  pathToManagerTrainingDashboardSessions,
} from 'helpers/paths';

import { ContentContainer, PageHeader, PageTitle } from 'components';

import Requests from './Requests';
import Sessions from './Sessions';

const ManagerDashboard = () => {
  const { periodSlug } = useParams();
  if (!periodSlug) return null;

  return (
    <>
      <PageTitle title={[__('Trainings'), __('Team follow-up')]} />
      <PageHeader
        title={__('Team follow-up')}
        tabItems={[
          {
            to: pathToManagerTrainingDashboardRequests(periodSlug),
            label: __('Requests'),
          },
          {
            to: pathToManagerTrainingDashboardSessions(periodSlug),
            label: __('Sessions'),
          },
        ]}
      />
      <ContentContainer>
        <Routes>
          <Route
            path="requests"
            element={<Requests periodSlug={periodSlug} />}
          />
          <Route
            path="sessions"
            element={<Sessions periodSlug={periodSlug} />}
          />
          <Route
            path="*"
            element={
              <Navigate
                to={pathToManagerTrainingDashboardRequests(periodSlug)}
              />
            }
          />
        </Routes>
      </ContentContainer>
    </>
  );
};

export default ManagerDashboard;
