import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import queryString from 'helpers/queryString';

import { parsePaginationParams } from 'lib/dataLoader/pagination/paginationParams';

export default function useUrlQueryParams() {
  const location = useLocation();
  const navigate = useNavigate();

  const urlQueryParams = useCallback(
    () =>
      parsePaginationParams(
        // @ts-expect-error: TSFIXME
        queryString.parse(location.search)
      ),
    [location.search]
  );

  const replaceHistory = useCallback(
    (params: Record<string, any>) => {
      const newSearch = queryString.stringify(params);
      navigate(`${location.pathname}?${newSearch}`, { replace: true });
    },
    [navigate, location.pathname]
  );

  return {
    urlQueryParams,
    replaceHistory,
  };
}
