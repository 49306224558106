import React from 'react';

import type { ThreeSixtyUserReview } from 'models';

import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  BreadcrumbAnchor,
  ContentContainer,
  FetchContainer,
  PageHeader,
  PageTitle,
} from 'components';

import CardPlaceholder from '../components/CardPlaceholder';
import FeedbackEmptyState from './FeedbackEmptyState';
import FeedbackRequestsList from './FeedbackRequestsList';

type Props = {};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    userReviews: Array<ThreeSixtyUserReview>;
  };

const FeedbackRequests = ({
  userReviews,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  return (
    <>
      <PageTitle title={__('My Feedback Requests')} />
      <BreadcrumbAnchor name="user_review_list" />
      <PageHeader title={__('My feedback requests')} />

      <ContentContainer>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          renderFetching={() => (
            <>
              <CardPlaceholder variant="team_2" />
              <CardPlaceholder variant="team_2" />
            </>
          )}
          render={() => {
            if (userReviews.length === 0) return <FeedbackEmptyState />;

            return <FeedbackRequestsList userReviews={userReviews} />;
          }}
        />
      </ContentContainer>
    </>
  );
};

export default newDataLoader({
  fetch: () => get('feedback_requests'),
  hydrate: {
    userReviews: {
      abilities: {},
      reviewCycle: {},
      reviewer: {},
      user: {},
      evaluations: {
        reviewer: {},
      },
      meeting: {
        participants: {},
      },
    },
  },
})(FeedbackRequests) as React.ComponentType<Props>;
