import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PaginatedCollection, PeopleReviewCycle, User } from 'models';

import { withActiveUser } from 'helpers/withSessionProps';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';

import CardPlaceholder from 'scenes/reviews/components/CardPlaceholder';

import PeopleReviewCycleListItem from './PeopleReviewCycleListItem';
import PeopleUserReviewList from './PeopleUserReviewList';

type AfterConnectProps = DataLoaderProvidedProps & {
  activeUser: User;
  peopleReviewCycleCollection: PaginatedCollection<PeopleReviewCycle>;
};

const PeopleReviewCycleList = ({
  isFetching,
  hasError,
  peopleReviewCycleCollection,
}: AfterConnectProps) => (
  <FetchContainer
    isFetching={isFetching}
    hasError={hasError}
    renderFetching={() => (
      <>
        <CardPlaceholder variant="team_2" />
        <CardPlaceholder variant="team_2" />
      </>
    )}
    render={() => (
      <>
        {peopleReviewCycleCollection.items.map(
          (peopleReviewCycle: PeopleReviewCycle, index: number) => {
            // queue the user review loading queries to avoid potential ddos issues
            const initialQueryMsDelay = 200 * index;

            return (
              <PeopleReviewCycleListItem
                key={peopleReviewCycle.id}
                peopleReviewCycle={peopleReviewCycle}
              >
                <PeopleUserReviewList
                  peopleReviewCycle={peopleReviewCycle}
                  initialQueryMsDelay={initialQueryMsDelay}
                />
              </PeopleReviewCycleListItem>
            );
          }
        )}
      </>
    )}
  />
);

export default withActiveUser<React.ComponentType<{}>>(
  newDataLoader({
    fetch: ({ activeUser }) =>
      get('people_review_cycles', {
        reviewer_id: activeUser.id,
        // TODO: Paginate the people review cycles
        count_per_page: 50,
      }),
    hydrate: {
      peopleReviewCycleCollection: {
        items: {},
      },
    },
    cacheKey: () => 'my-people-review-cycles',
  })(PeopleReviewCycleList)
);
