import { uniqueId } from 'lodash';
import React from 'react';

import { ObjectiveVersion as ObjectiveVersionType } from 'models';

import { __ } from 'helpers/i18n';

import StrictlySanitizedHtml from 'components/StrictlySanitizedHtml';

import AvatarDescription from './components/AvatarDescription';
import ComputedChanges from './components/ComputedChanges';

type Props = {
  version: ObjectiveVersionType;
};

const ObjectiveCreated = ({ version }: Props) => (
  <div className="mb-4">
    <AvatarDescription
      version={version}
      text={__('%1 created the objective', version.user.fullName)}
    />
  </div>
);

const ObjectiveDestroyed = ({ version }) => (
  <div className="mb-4">
    <AvatarDescription
      version={version}
      text={__('%1 destroyed the objective', version.user.fullName)}
    />
  </div>
);

const ObjectiveUpdated = ({ version }: Props) => {
  const skippedAttributes = [
    'updatedAt',
    'position',
    'status',
    'duplicatedFromId',
  ];
  const attributes = Object.keys(version.objectChanges);
  const computeChangeText = (attribute: string) => {
    switch (attribute) {
      case 'title':
        return __('the title');
      case 'description':
        return __('the description');
      case 'completion':
        return __('the completion');
      case 'weight':
        return __('the weight');
      case 'dueDate':
        return __('the due date');
      default:
        window.logException(
          new Error(
            `Unknown attribute translation in objective versions: ${attribute}`
          )
        );
        return attribute;
    }
  };

  return (
    <>
      {attributes.map(attribute => {
        if (skippedAttributes.includes(attribute)) return null;
        if (attribute === 'published') {
          const isPublished = version.objectChanges[attribute][1];
          return (
            <div className="mb-4" key={uniqueId(attribute)}>
              <AvatarDescription
                version={version}
                text={
                  isPublished
                    ? __('%1 published the objective', version.user.fullName)
                    : __('%1 unpublished the objective', version.user.fullName)
                }
              />
            </div>
          );
        }

        const [beforeChange, afterChange] = version.objectChanges[attribute];

        return (
          <div className="mb-4" key={uniqueId(attribute)}>
            <AvatarDescription
              version={version}
              text={
                <StrictlySanitizedHtml
                  componentType="div"
                  html={__(
                    '%1 changed <b>%2</b>',
                    version.user.fullName,
                    computeChangeText(attribute)
                  )}
                />
              }
            />
            <ComputedChanges
              beforeChange={beforeChange}
              afterChange={afterChange}
            />
          </div>
        );
      })}
    </>
  );
};

const ObjectiveVersion = ({ version }: Props) => {
  switch (version.event) {
    case 'create':
      return <ObjectiveCreated version={version} />;
    case 'update':
      return <ObjectiveUpdated version={version} />;
    case 'destroy':
      return <ObjectiveDestroyed version={version} />;
    default:
      return null;
  }
};

export default ObjectiveVersion;
