import React from 'react';

import type { TrainingPlanBudgetStats } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';
import { pathToTrainingBudget } from 'helpers/paths';

import { hasShape } from 'types/predicates/WithShape';

import { Link, Text } from 'components';

import BudgetVisualizer from 'scenes/trainings/PlanManagement/components/BudgetVisualizer';

type Props = {
  /**
   * @deprecated After FF budgetEnvelopes deletion
   */
  openDetailsModal: () => void;
  periodSlug: string;
  isValidatedPlan: boolean;
  stats: TrainingPlanBudgetStats;
};

const BudgetStatsCard = ({
  periodSlug,
  isValidatedPlan,
  stats,
  openDetailsModal,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const budgetEnvelopesEnabled = featureFlags.includes('budgetEnvelopes');

  const link = (
    <Link
      additionalClassName="budget-card-link"
      onClick={budgetEnvelopesEnabled ? undefined : openDetailsModal}
      to={budgetEnvelopesEnabled ? pathToTrainingBudget(periodSlug) : undefined}
      testClassName="test-budget-details-link"
    >
      {__('Details')}
    </Link>
  );

  const validatedAmountCents = stats.validatedAmountCents;
  const forecastAmountCents = hasShape(stats, { forecastAmountCents: 0 })
    ? stats.forecastAmountCents
    : undefined;

  return (
    <div className="flex flex-col test-budget-stats-card">
      <div className="flex justify-between">
        <Text preset="16bs5.5">
          {isValidatedPlan ? __('Budget spent') : __('Budget')}
        </Text>
        {link}
      </div>

      {stats.provisionedAmountCents > 0 ? (
        <BudgetVisualizer
          className="mt-3"
          testClassName="test-total-budget-visualizer"
          provisionedAmountCents={stats.provisionedAmountCents}
          forecastAmountCents={forecastAmountCents}
          validatedAmountCents={validatedAmountCents}
          currency={stats.currency}
        />
      ) : (
        <div className="flex flex-col mt-1">
          <div>
            <Text>{__('Validated funding:')}</Text>
            <Text
              preset="14bs6"
              className="ml-1"
              testClassName="test-validated-amount"
            >
              {validatedAmountCents
                ? formatMoney(validatedAmountCents, stats.currency)
                : '-'}
            </Text>
          </div>
          {forecastAmountCents !== undefined && (
            <div>
              <Text>{__('Forecast funding:')}</Text>
              <Text
                preset="14bs6"
                className="ml-2"
                testClassName="test-forecast-amount"
              >
                {forecastAmountCents > 0
                  ? formatMoney(forecastAmountCents, stats.currency)
                  : '-'}
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BudgetStatsCard;
