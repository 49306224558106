/*
The Label component of bulma, see https://bulma.io/documentation/form/general
It is used to wrap inputs, selects, checkbox, radio or textarea in forms
It itself is wrapped in a Field which keeps the spacing between the different fields.

Typical example:
<div class="field">
  <label class="label">Email</label>
  <div class="control has-icons-left has-icons-right">
    <input class="input is-danger" type="email" placeholder="Email input" value="hello@">
    <span class="icon is-small is-left">
      <i class="fas fa-envelope"></i>
    </span>
    <span class="icon is-small is-right">
      <i class="fas fa-exclamation-triangle"></i>
    </span>
  </div>
  <p class="help is-danger">This email is invalid</p>
</div>
*/
import React from 'react';

import type { BulmaSize } from 'components/types/bulma';
import type { TextSize } from 'components/types/text';

import { bulmaSizeClassName } from 'helpers/bulma';
import classNames from 'helpers/classNames';

import Text, { type Props as TextProps } from 'components/text/Text';

type Props = Omit<TextProps, 'size'> & {
  textSize?: TextSize;
  className?: string;
  labelClassName?: string;
  size: BulmaSize;
};

function Label({ size, style, textSize, labelClassName, ...textProps }: Props) {
  const className = classNames(
    'label',
    labelClassName,
    bulmaSizeClassName(size)
  );

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label style={style} className={className}>
      <Text {...textProps} size={textSize} />
    </label>
  );
}

Label.defaultProps = {
  size: 'normal', // This is the default and applies no specific class
};

export default Label;
