import React, { CSSProperties, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';
import { useCanGoBack } from 'helpers/navigation';

import { DeprecatedContainerElevo, Icon, Link, Text } from 'components';

export type Props = {
  target?: string | undefined | null;
  fallbackTarget?: string | undefined | null;
  children?: ReactNode;
  style?: CSSProperties;
  render?: (
    to?: string,
    onClick?: () => void,
    isDisabled?: boolean
  ) => ReactNode;
  className?: string;
};

export function BackButton({
  target,
  fallbackTarget,
  children,
  render,
  className,
}: Props) {
  const canGoBack = useCanGoBack();
  const navigate = useNavigate();
  let url: string | undefined = undefined;
  let useHistory = false;
  if (target) {
    url = target;
  } else if (canGoBack) {
    useHistory = true;
  } else if (fallbackTarget) {
    url = fallbackTarget;
  }

  const onClick = useHistory ? () => navigate(-1) : undefined;
  const disabled = !useHistory && !url;

  const defaultRenderer = (to, onClick, disabled) => (
    <Link
      to={to}
      additionalClassName={classNames(
        'back-button',
        'test-back-button',
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <Text size={7}>
        <Icon style={{ marginRight: 8, marginBottom: 1 }} name="arrow_back" />
        <span>{children || __('Back')}</span>
      </Text>
    </Link>
  );

  return <>{(render || defaultRenderer)(url, onClick, disabled)}</>;
}

type TargetProvider<T> = (props: T) => string | undefined | null;

const withBackButton =
  <TProps,>(targetOrTargetProvider?: string | TargetProvider<TProps>) =>
  (WrappedComponent: React.ComponentType<any>) => {
    return class extends React.Component<TProps> {
      render() {
        let target: string | undefined | null;

        if (
          typeof targetOrTargetProvider === 'string' ||
          typeof targetOrTargetProvider === 'undefined'
        ) {
          target = targetOrTargetProvider;
        } else {
          target = targetOrTargetProvider(this.props);
        }

        return (
          <DeprecatedContainerElevo>
            <BackButton target={target} />
            <WrappedComponent {...this.props} />
          </DeprecatedContainerElevo>
        );
      }
    };
  };

export default withBackButton;
