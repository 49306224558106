import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { QueryParams, WithPaginationProps } from 'lib/pagination/types';

import queryString from 'helpers/queryString';

import parsePaginatedQueryParams from 'lib/pagination/parsePaginatedQueryParams';

type BaseQueryParams = {
  search: string;
  filter?: { [key: string]: boolean | { [key: string]: boolean } };
  userFilters?: { [key: string]: { label: string; value: string } } | '';
  sort?: { [key: string]: 'asc' | 'desc' };
};

type BaseAdditionalQueryParams = Record<string, unknown>;

type Props = WithPaginationProps;

export type PaginatedQueryParams = {
  page: number;
  countPerPage: number;
  queryParams: QueryParams;
};

const defaultParams: PaginatedQueryParams = {
  page: 1,
  countPerPage: 10,
  queryParams: {
    filter: { all: true },
    userFilters: {},
    search: '',
  },
};

const buildDefaultParams = (
  filter?: Partial<BaseQueryParams> | undefined,
  additionalQueryParams?: BaseAdditionalQueryParams | null | undefined,
  countPerPage?: number,
  withUrlChange?: boolean
): PaginatedQueryParams => {
  const queryParamsFromUrl = withUrlChange
    ? parsePaginatedQueryParams(queryString.parse(window.location.search))
    : null;

  return {
    page: queryParamsFromUrl?.page || defaultParams.page,
    countPerPage:
      countPerPage ||
      queryParamsFromUrl?.countPerPage ||
      defaultParams.countPerPage,
    ...queryParamsFromUrl,
    queryParams: {
      ...defaultParams.queryParams,
      filter: filter || defaultParams.queryParams.filter,
      userFilters:
        queryParamsFromUrl?.queryParams?.userFilters ||
        defaultParams.queryParams.userFilters,
      ...queryParamsFromUrl?.queryParams,
      ...additionalQueryParams,
      sort:
        queryParamsFromUrl?.queryParams?.sort || additionalQueryParams?.sort,
    } as QueryParams,
  };
};

/**
 * A custom hook to manage paginated queries with optional URL synchronization.
 *
 * @param:
 * {Object} [props.defaultFilter] - Default filter to be applied to the query.
 * {Record<string, unknown>} [props.additionalQueryParams] - Additional query parameters to be included.
 * {boolean} [props.withUrlChange=true] - Whether to synchronize the query parameters with the URL.
 * {number} [props.countPerPage] - Number of items per page.
 *
 * @returns:
 * - `setQueryParams`: Function to update the query parameters.
 * - `setPreviousPageParams`: Function to go to the previous page.
 * - `setNextPageParams`: Function to go to the next page.
 * - `getNextPageUrl`: Function to get the URL for the next page.
 * - `getPreviousPageUrl`: Function to get the URL for the previous page.
 * - `page`: Current page number.
 * - `countPerPage`: Number of items per page.
 * - `queryParams`: Current query parameters.
 *
 * @example:
 * const {
 *   setQueryParams,
 *   setPreviousPageParams,
 *   setNextPageParams,
 *   getNextPageUrl,
 *   getPreviousPageUrl,
 *   page,
 *   countPerPage,
 *   queryParams,
 * } = usePaginatedQuery({
 *   defaultFilter: { active: true },
 *   additionalQueryParams: { userFilters: { value: '', label: ''}},
 *   countPerPage: 5,
 * });
 */
const usePaginatedQuery = ({
  defaultFilter,
  additionalQueryParams,
  withUrlChange = true,
  countPerPage,
}: Props) => {
  const navigate = useNavigate();

  // Memoize params to avoid infinite re-rendering
  const params = useMemo(
    () =>
      buildDefaultParams(
        defaultFilter,
        additionalQueryParams,
        countPerPage,
        withUrlChange
      ),
    [defaultFilter, additionalQueryParams, countPerPage, withUrlChange]
  );

  const [currentParams, setParams] = useState(params);

  const buildFullUrl = (newParams: PaginatedQueryParams): string =>
    `${window.location.pathname}?${queryString.stringify(newParams)}`;

  // This allows to update currentParams when params from props change
  useEffect(() => {
    if (JSON.stringify(currentParams) !== JSON.stringify(params)) {
      setParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // This updates URL when `currentParams` changes
  useEffect(() => {
    const {
      queryParams: { userFilters, ...otherQueryParams },
      ...otherParams
    } = currentParams;
    // We want to replace the empty userFilters with an empty string to keep it in the URL (empty objects are removed from the query string).
    // When there are default filters, we only want to use them if no filters are in the URL but not when they are empty.
    const formattedParams = {
      ...otherParams,
      queryParams: {
        ...otherQueryParams,
        userFilters: isEmpty(userFilters) ? '' : userFilters,
      },
    };

    if (withUrlChange) {
      const newUrl = `${window.location.pathname}?${queryString.stringify(
        formattedParams
      )}`;
      // Replace URL without navigation
      window.history.replaceState(null, '', newUrl);
    }
  }, [withUrlChange, currentParams, navigate]);

  const setQueryParams = (newQueryParams: Partial<QueryParams>) => {
    setParams(prevState => ({
      ...prevState,
      queryParams: { ...prevState.queryParams, ...newQueryParams },
      page: 1,
    }));
  };

  const setNextPageParams = () => {
    setParams(prevState => ({
      ...prevState,
      page: prevState.page + 1,
    }));
  };

  const setPreviousPageParams = () => {
    setParams(prevState => ({
      ...prevState,
      page: prevState.page > 1 ? prevState.page - 1 : 1,
    }));
  };

  const getPreviousPageUrl = () => {
    return buildFullUrl({
      ...params,
      page: params.page > 1 ? params.page - 1 : 1,
    });
  };

  const getNextPageUrl = () => {
    return buildFullUrl({
      ...params,
      page: params.page + 1,
    });
  };

  const onSortChange = (sortName: string) => {
    const currentSortValue =
      currentParams.queryParams.sort &&
      currentParams.queryParams.sort[sortName];
    const defaultSortParam = additionalQueryParams?.sort;
    const defaultIsDesc =
      defaultSortParam && defaultSortParam[sortName] === 'desc';
    let newSortValue = {};

    switch (currentSortValue) {
      case null:
      case undefined:
        newSortValue = { [sortName]: 'asc' };
        break;
      case 'asc':
        newSortValue = { [sortName]: 'desc' };
        break;
      default:
        // In the deprecated pagination, the default sort value was passed as a prop outside the state to the component.
        // This doesn't work in the new one, so this resets the sort value to the default one (if any).
        // if default sort is desc, then the only other possible sort value is asc
        newSortValue = defaultIsDesc
          ? { [sortName]: 'asc' }
          : defaultSortParam || {};
        break;
    }

    setQueryParams({ sort: newSortValue });
  };

  return {
    setQueryParams,
    setPreviousPageParams,
    setNextPageParams,
    getNextPageUrl,
    getPreviousPageUrl,
    setParams,
    onSortChange,
    ...currentParams,
  };
};

export default usePaginatedQuery;
