import { useState } from 'react';

import { FormBudgetEnvelope } from '../../types';

const useEnvelopeModalState = () => {
  const [envelopeBeingEdited, setEnvelopeBeingEdited] =
    useState<FormBudgetEnvelope | null>(null);
  const [creatingEnvelope, setCreatingEnvelope] = useState(false);

  const openCreateModal = () => {
    setCreatingEnvelope(true);
    setEnvelopeBeingEdited(null);
  };

  const openEditModal = (envelope: FormBudgetEnvelope) => {
    setCreatingEnvelope(false);
    setEnvelopeBeingEdited(envelope);
  };

  const closeModal = () => {
    setCreatingEnvelope(false);
    setEnvelopeBeingEdited(null);
  };

  return {
    openCreateModal,
    openEditModal,
    closeModal,
    isModalOpen: creatingEnvelope || !!envelopeBeingEdited,
    envelopeBeingEdited,
  };
};

export default useEnvelopeModalState;
