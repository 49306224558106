import React from 'react';

import type { AttributesOnlyOrganization, User } from 'models';

import can from 'helpers/can';
import { useAppDispatch, useOrganizationPlan, useSession } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  pathToAdminRights,
  pathToEntities,
  pathToIntegrations,
  pathToNotificationsSettings,
  pathToObjectivesSettings,
  pathToReviewsSettings,
  pathToThreeSixtySettings,
  pathToTrainingSettings,
} from 'helpers/paths';

import { post } from 'redux/actions/api';

import { Button, Icon, Text } from 'components';

import NavItem from './NavItem';
import NavSection from './NavSection';

type Props = {
  organization: AttributesOnlyOrganization;
  activeUser: User;
  hideSettingsPage: () => void;
};

export default function NavSettings({
  organization,
  activeUser,
  hideSettingsPage,
}: Props) {
  const plan = useOrganizationPlan();
  const { isImpersonated } = useSession();
  const { isOrganizationAdmin } = activeUser;

  const dispatch = useAppDispatch();
  const adminRightsEnabled = organization.featureFlags.includes('adminRights');
  const billingPageEnabled = organization.featureFlags.includes('billingPage');

  return (
    <>
      <div className="flex items-center mb-4 px-2">
        <Button
          className="border-0 bg-transparent text-nav-icon hover:text-nav-icon"
          onClick={hideSettingsPage}
          hasIconOnly
        >
          <Icon size="small" className="pr-3" name="arrow_back" />
          <Text className="font-semibold !text-nav-names-default" size={6.5}>
            {__('Back')}
          </Text>
        </Button>
      </div>
      <NavSection title={__('Workspace')} className="px-2">
        {adminRightsEnabled && isOrganizationAdmin && (
          <NavItem
            iconName="admin_panel_settings"
            title={__('Administrators')}
            path={pathToAdminRights()}
          />
        )}
        {plan.multiLevelAdminEnabled &&
          isImpersonated &&
          isOrganizationAdmin && (
            <NavItem
              iconName="workspaces"
              title={__('Entities')}
              path={pathToEntities()}
            />
          )}
        {isImpersonated && isOrganizationAdmin && (
          <NavItem
            iconName="integration_instructions"
            title={__('Integrations')}
            path={pathToIntegrations()}
          />
        )}
        {can({
          perform: 'manage_notifications_settings',
          on: organization,
        }) && (
          <NavItem
            iconName="mail"
            title={__('Notifications')}
            path={pathToNotificationsSettings()}
          />
        )}
        {billingPageEnabled &&
          can({
            perform: 'create_billing_portal_session',
            on: organization,
          }) && (
            <NavItem
              iconName="open_in_new"
              title={__('Billing')}
              onClick={async () => {
                const data = await dispatch(post('billing/portal'));
                return window.open(data.response.body.url, '_blank');
              }}
            />
          )}
      </NavSection>
      <NavSection title={__('Product')} className="px-2">
        <NavItem
          iconName="speed"
          title={__('Reviews')}
          path={pathToReviewsSettings()}
        />
        <NavItem
          iconName="360"
          title={__('360 Feedback')}
          path={pathToThreeSixtySettings()}
        />
        <NavItem
          iconName="track_changes"
          title={__('Objectives')}
          path={pathToObjectivesSettings()}
        />
        <NavItem
          iconName="school"
          title={__('Training')}
          path={pathToTrainingSettings()}
        />
      </NavSection>
    </>
  );
}
