import React from 'react';

import { TrainingCourse } from 'models/TrainingCourse';

import { FormErrors } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';

import { Field, FieldError, Label, NumberInput, Radio, Text } from 'components';

import { CourseInputChangeHandler } from './GeneralInfoBlock';

type Props = {
  trainingCourse: Partial<TrainingCourse>;
  handleInputChange: CourseInputChangeHandler;
  errors: FormErrors;
};

const CertificationBlock = ({
  trainingCourse,
  handleInputChange,
  errors,
}: Props) => {
  const [certifying, setCertifying] = React.useState<boolean | null>(
    trainingCourse.certifying || false
  );

  return (
    <>
      <Text preset="16bs5.5">{__('Certification')}</Text>
      <Field className="mt-4">
        <Label>{__('The training leads to certification?')}</Label>
        <div className="flex gap-1">
          <Radio
            label={__('Yes')}
            isChecked={certifying === true}
            onChange={() => {
              setCertifying(true);
              handleInputChange({
                value: true,
                name: 'certifying',
              });
            }}
          />
          <Radio
            label={__('No')}
            isChecked={certifying === false}
            onChange={() => {
              setCertifying(false);
              handleInputChange({
                value: false,
                name: 'certifying',
              });
            }}
          />
        </div>
      </Field>
      <FieldError>{errors.certification}</FieldError>
      <Field>
        <Label>{__('Define the validity period of the training course')}</Label>
        <div className="flex gap-4 max-w-[15%] items-center">
          <NumberInput
            value={
              trainingCourse.validityDurationMonths === null
                ? undefined
                : trainingCourse.validityDurationMonths
            }
            placeholder={__('Months')}
            onChange={months => {
              handleInputChange({
                value: months,
                name: 'validityDurationMonths',
              });
            }}
          />
          <Text>{__('months')}</Text>
        </div>
        <FieldError>{errors.validityDurationMonths}</FieldError>
      </Field>
    </>
  );
};
export default CertificationBlock;
