import React, { ReactNode, useCallback, useEffect } from 'react';

import type { Document } from 'components/behaviors/FilesUploader';
import type { FileScan } from 'models';
import type { ReduxStore } from 'redux/reducers';

import { useAppSelector } from 'helpers/hooks';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { humanFileSize } from 'helpers/i18n/fileSize';
import invariant from 'helpers/invariant';

import { get, post } from 'redux/actions/api';

import { FileInfo, Poller } from 'components';

type UploadingState =
  | 'uploading'
  | 'failed'
  | 'scan:awaiting'
  | 'scan:processing'
  | 'scan:rejected'
  | 'finished';

type Props = {
  document: Document;
  removeDocument: () => void;
};

const DocumentListItem = ({ document, removeDocument }: Props) => {
  const dispatch = useAppDispatch();

  const fileScan = useAppSelector((state: ReduxStore) =>
    Object.values(
      (state.data.fileScan?.records || {}) as unknown as Record<
        string,
        FileScan
      >
    ).find(file => file.fileSignedId === document.signedId)
  );

  const state = (
    {
      'waiting/': 'uploading',
      'uploading/': 'uploading',
      'error/': 'failed',
      'finished/': 'scan:awaiting',
      'finished/pending': 'scan:processing',
      'finished/unsafe': 'scan:rejected',
      'finished/safe': 'finished',
    } as {
      [key: string]: UploadingState;
    }
  )[`${document.state}/${fileScan?.status || ''}`];
  invariant(state, 'Unknown attached document state');

  const hasError = ['failed', 'scan:rejected'].includes(state);

  const hasProgressBar = [
    'uploading',
    'scan:awaiting',
    'scan:processing',
  ].includes(state);

  const getFailedInfo = (reason?: ReactNode) => {
    if (reason) {
      return `${__('Upload failed')} • ${reason}`;
    } else {
      return `${__('Upload failed')}`;
    }
  };

  const getFileInfo = () => {
    if (state === 'failed') {
      if (document.file.size > 5 * Math.pow(2, 20)) {
        return getFailedInfo(__('File is too large (> 5 MB)'));
      } else {
        return getFailedInfo();
      }
    } else if (state === 'scan:rejected') {
      return getFailedInfo(__('File potentially harmful'));
    } else return humanFileSize(document.file.size);
  };

  const getProgressInfo = () => {
    if (state === 'uploading') {
      return Math.floor(document.progress || 0) + '%';
    } else if (['scan:awaiting', 'scan:processing'].includes(state)) {
      return __('Anti-virus checking');
    }
  };

  const getProgress = () => {
    if (hasProgressBar) {
      return state === 'uploading' ? document.progress || 0 : 'indeterminate';
    } else return undefined;
  };

  const createFileScan = useCallback(() => {
    dispatch(post(`file_scans`, { signedId: document.signedId }));
  }, [dispatch, document]);

  const getFileScan = () =>
    fileScan && dispatch(get(`file_scans/${fileScan.id}/status`));

  useEffect(() => {
    state === 'scan:awaiting' && createFileScan();
  }, [createFileScan, state]);

  return (
    <>
      {state === 'scan:processing' && (
        <Poller poll={() => getFileScan()} everyMs={3000} />
      )}
      <FileInfo
        hasError={hasError}
        filename={document.file.name}
        fileInfo={getFileInfo()}
        progressInfo={getProgressInfo()}
        progress={getProgress()}
        onDelete={removeDocument}
      />
    </>
  );
};

export default DocumentListItem;
