import { __ } from 'helpers/i18n';

const sessionStatusLabels = () => ({
  in_preparation: __('In preparation'),
  completed: __('TrainingSessionStatus|Completed'),
  prepared: __('TrainingSessionStatus|Prepared'),
  canceled: __('TrainingSessionStatus|Canceled'),
  rescheduled: __('TrainingSessionStatus|Rescheduled'),
});

export default sessionStatusLabels;
