import React, { useState } from 'react';

import queryString from 'helpers/queryString';

import parsePaginatedQueryParams from 'lib/pagination/parsePaginatedQueryParams';

import { Box, FeatureFlagged } from 'components';

import TrainingPeriodPicker, {
  FAKE_ALL_PERIODS_SLUG,
} from 'scenes/components/TrainingPeriodPicker';

import SessionTable from './SessionTable';

type Props = {
  userId: string;
  userFullName: string;
};

const TrainingSessions = ({ userId, userFullName }: Props) => {
  const { queryParams } = parsePaginatedQueryParams(
    queryString.parse(window.location.search)
  );
  const [selectedPeriodSlug, setSelectedPeriodSlug] = useState(
    queryParams?.periodSlug || FAKE_ALL_PERIODS_SLUG
  );

  return (
    <FeatureFlagged flag="revampTrainingProfileAndReview">
      <div className="flex justify-between mb-4">
        <div className="w-1/3">
          <TrainingPeriodPicker
            renderingStyle={'select'}
            currentPeriodSlug={selectedPeriodSlug}
            onChange={period => setSelectedPeriodSlug(period.slug)}
            allPeriodsOptionAvailable
          />
        </div>
      </div>
      <Box>
        <SessionTable
          userId={userId}
          userFullName={userFullName}
          additionalQueryParams={{
            sort: { startDate: 'desc' },
            periodSlug: selectedPeriodSlug,
          }}
        />
      </Box>
    </FeatureFlagged>
  );
};

export default TrainingSessions;
