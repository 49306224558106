import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PeopleUserReview } from 'models';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Column,
  Columns,
  ErrorBoundary,
  FetchContainer,
  PageTitle,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import Content from './components/Content';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    review: PeopleUserReview;
  };

function PeopleUserReviewComponent({
  review,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) {
  return (
    <div className="people-user-review">
      {review && (
        <PageTitle
          title={[review.user.fullName, review.peopleReviewCycleName]}
        />
      )}
      <FetchContainer
        hasError={hasError}
        isFetching={isFetching}
        render={() => (
          <div className="review-content !h-screen">
            <Topbar review={review} refetchReview={refetchData} />
            <Columns>
              <Column className="review-sidebar" hideOn="mobile">
                <ErrorBoundary>
                  <Sidebar review={review} />
                </ErrorBoundary>
              </Column>
              <Column className="form-content-wrapper">
                <ErrorBoundary>
                  <Content review={review} refetchReview={refetchData} />
                </ErrorBoundary>
              </Column>
            </Columns>
          </div>
        )}
      />
    </div>
  );
}

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`people_user_reviews/${match.id}`),
    hydrate: {
      review: {
        abilities: {},
        user: {
          abilities: {},
        },
        reviewedDimensionBlocks: {
          dimension: {},
          ratingOptions: {},
          rating: {},
        },
        finalCommentBlock: {},
        successorBlock: {
          peopleReviewSuccessors: {
            user: { currentCareerLevel: { skillsLevel: {} } },
            peopleGroups: {},
          },
        },
      },
    },
  })
)(PeopleUserReviewComponent);
