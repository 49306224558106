import React from 'react';

import useActions from '../helpers/useActions';
import SessionList from './SessionList';

type Props = { periodSlug: string };

const Requests = ({ periodSlug }: Props) => {
  const { renderActions, userFilters } = useActions(periodSlug);

  return (
    <>
      {renderActions()}

      <SessionList
        currentPeriodSlug={periodSlug}
        defaultFilter={{ all: true }}
        additionalQueryParams={{ userFilters, sort: { startDate: 'desc' } }}
      />
    </>
  );
};

export default Requests;
