import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

type Props = {
  onAllPeriods: boolean;
};

const NoResultState = ({ onAllPeriods }: Props) => {
  return (
    <EmptyStateWithIcon
      className="mb-5"
      inBox={false}
      iconName="school"
      title={
        onAllPeriods
          ? __('There is no trainings matching your search.')
          : __('There is no trainings matching your search for this period.')
      }
    />
  );
};

export default NoResultState;
