import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { compose } from 'redux';

import type { NavigationItem } from 'components/navigation/Tabs/NavigationList';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { ReviewCycle } from 'models';

import can from 'helpers/can';
import {
  useCurrentOrganization,
  useOrganizationPlan,
  useSession,
} from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { anyTemplateWithSkillsInCycle } from 'helpers/models/reviewCycle';
import {
  pathToReviewCycleAnalytics,
  pathToReviewCycleParticipants,
  pathToReviewCycleResults,
  pathToReviewCycleSkills,
  pathToReviewCycleTemplates,
} from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  ContentContainer,
  DesignSystem,
  FetchContainer,
  Icon,
  PageTitle,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import Skills from 'scenes/components/reviewCycle/analytics/Skills';

import Analytics from './Analytics';
import Header from './Header';
import LaunchingCycle from './LaunchingCycle';
import Participants from './Participants';
import PendingParticipantAddition from './PendingParticipantAddition';
import Results from './Results';
import ResultViewFeaturePromptModal from './Results/ResultViewFeaturePromptModal';
import Templates from './Templates';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataloaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    reviewCycle: ReviewCycle;
  };

function NewReviewCycleManage({
  reviewCycle,
  isFetching,
  hasError,
}: AfterDataloaderProps) {
  const [resultModal, setResultModal] = React.useState(false);
  const session = useSession();
  const organization = useCurrentOrganization();
  const organizationPlan = useOrganizationPlan();

  const shouldShowResultsAsLocked = !organizationPlan.resultViewEnabled;

  const skillsEnabled =
    organization.featureFlags.includes(
      'mockDataForSkillsAndCareersAnalytics'
    ) || organization.featureFlags.includes('skillsAndCareersAnalytics');

  return (
    <DesignSystem version={2}>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          const shouldShowResultsTab = can({
            perform: 'show_results_tab',
            on: reviewCycle,
          });
          const shouldShowSkillsTab =
            skillsEnabled &&
            can({ perform: 'show_skills_tab', on: reviewCycle });

          if (reviewCycle.status === 'launching') {
            return (
              <>
                <PageTitle title={[reviewCycle.name, __('Admin')]} />
                <LaunchingCycle reviewCycle={reviewCycle} />
              </>
            );
          }

          const pendingParticipantAddition =
            reviewCycle.pendingParticipantAddition;
          if (!!pendingParticipantAddition) {
            return (
              <>
                <PageTitle title={[reviewCycle.name, __('Admin')]} />
                <PendingParticipantAddition
                  participantAddition={pendingParticipantAddition}
                  reviewCycleId={reviewCycle.id}
                />
              </>
            );
          }

          const tabItems: Array<NavigationItem> = [
            {
              label: __('Participants'),
              to: pathToReviewCycleParticipants(reviewCycle.id),
              testName: 'test-participants-link',
            },
            {
              label: __('Progress'),
              to: pathToReviewCycleAnalytics(reviewCycle.id),
              testName: 'test-analytics-link',
            },
          ];

          if (shouldShowResultsTab) {
            let resultsTabItem: NavigationItem = {
              label: __('Results'),
              testName: 'test-results-link',
              rightContent: null,
              to: pathToReviewCycleResults(reviewCycle.id),
            };

            if (shouldShowResultsAsLocked) {
              resultsTabItem = {
                ...resultsTabItem,
                rightContent: <Icon size="small" name="lock" />,
                onClick: () => {
                  setResultModal(!resultModal);
                },
              };
            }

            tabItems.push(resultsTabItem);
          }

          if (
            shouldShowSkillsTab &&
            anyTemplateWithSkillsInCycle(reviewCycle)
          ) {
            tabItems.push({
              label: __('Skills'),
              to: pathToReviewCycleSkills(reviewCycle.id),
            });
          }

          if (session.isImpersonated) {
            tabItems.push({
              label: __('Templates'),
              to: `${pathToReviewCycleTemplates(reviewCycle.id)}`,
            });
          }

          return (
            <div className="review-cycle-manage">
              <Header reviewCycle={reviewCycle} tabItems={tabItems} />

              {!!shouldShowResultsAsLocked && (
                <ResultViewFeaturePromptModal
                  isActive={resultModal}
                  onClose={() => setResultModal(!resultModal)}
                />
              )}

              <ContentContainer>
                <Routes>
                  <Route index element={<Navigate to="participants" />} />
                  <Route
                    path="participants"
                    element={<Participants reviewCycle={reviewCycle} />}
                  />
                  <Route
                    path="analytics"
                    element={<Analytics reviewCycle={reviewCycle} />}
                  />
                  {shouldShowResultsTab && (
                    <Route
                      path="results"
                      element={<Results reviewCycle={reviewCycle} />}
                    />
                  )}
                  {shouldShowSkillsTab && (
                    <Route
                      path="skills"
                      element={<Skills reviewCycle={reviewCycle} />}
                    />
                  )}
                  {session.isImpersonated && (
                    <Route
                      path="templates/*"
                      element={
                        <Templates
                          reviewCycle={reviewCycle}
                          userIsImpersonated={session.isImpersonated}
                        />
                      }
                    />
                  )}
                </Routes>
              </ContentContainer>
            </div>
          );
        }}
      />
    </DesignSystem>
  );
}

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }) => get(`review_cycles/${match.id}`),
    hydrate: {
      reviewCycle: {
        pendingParticipantAddition: {},
        abilities: {},
        defaultTemplate: {},
        templates: {},
        lastPendingExportAction: {},
        responsible: {},
        tags: {},
      },
    },
    cacheKey: ({ match }: AfterMatchParamsProps) => match.id,
  })
)(NewReviewCycleManage);
