import React from 'react';

import { TrainingCourse, TrainingFormat } from 'models/TrainingCourse';
import { TrainingType } from 'models/TrainingSession';

import { FormErrors, FormInputChangeHandler } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';
import {
  formatsTranslation,
  typesTranslation,
} from 'helpers/models/trainingCourse';

import {
  Field,
  FieldError,
  Input,
  Label,
  NumberInput,
  Select,
  Text,
} from 'components';
import FloatInput from 'components/formElements/advancedElements/FloatInput';
import MoneyInput from 'components/formElements/advancedElements/MoneyInput';

type Props = {
  trainingCourse: Partial<TrainingCourse>;
  handleInputChange: FormInputChangeHandler<TrainingCourse>;
  errors: FormErrors;
};

const FormatCostBlock = ({
  trainingCourse,
  handleInputChange,
  errors,
}: Props) => {
  const typesOptions = (
    Object.keys(typesTranslation()) as Array<TrainingType>
  ).map(key => ({
    label: typesTranslation()[key],
    value: key,
  }));

  const formatsOptions = (
    Object.keys(formatsTranslation()) as Array<TrainingFormat>
  ).map(key => ({
    label: formatsTranslation()[key],
    value: key,
  }));

  const [currency, setCurrency] = React.useState<string>(
    trainingCourse?.totalCostPerSessionCurrency || ''
  );

  return (
    <>
      <Text preset="16bs5.5">{__('Format and Cost')}</Text>
      <div className="flex mt-4 gap-8">
        <div className="flex-grow max-w-[50%]">
          <Field>
            <Label>{__('Training type')}</Label>
            <Select
              value={
                typesOptions.find(
                  option => option.value === trainingCourse?.trainingCourseType
                ) || null
              }
              options={typesOptions}
              onChange={type => {
                handleInputChange({
                  value: type?.value || '',
                  name: 'trainingCourseType',
                });
              }}
              placeholder={__('Select training course type')}
            />
            <FieldError>{errors.trainingCourseType}</FieldError>
          </Field>
          <div className="flex gap-4">
            <Field className="basis-0 min-w-[50%] flex-grow">
              <Label>{__('Format')}</Label>
              <Select
                value={
                  formatsOptions.find(
                    option => option.value === trainingCourse?.trainingFormat
                  ) || null
                }
                options={formatsOptions}
                onChange={format => {
                  handleInputChange({
                    value: format?.value as TrainingFormat,
                    name: 'trainingFormat',
                  });
                }}
                placeholder={__('Select format')}
              />
              <FieldError>{errors.trainingFormat}</FieldError>
            </Field>
            <Field className="max-w-[50%] flex-grow">
              <Label>{__('Number of days')}</Label>
              <FloatInput
                value={
                  trainingCourse.numberOfDays
                    ? Number(trainingCourse.numberOfDays)
                    : null
                }
                placeholder={__('Enter the number of days')}
                onInputChange={(days: number | null) => {
                  handleInputChange({ value: days, name: 'numberOfDays' });
                }}
              />
              <FieldError>{errors.numberOfDays}</FieldError>
            </Field>
          </div>
          <Field>
            <Label>{__('Cost per session')}</Label>
            <MoneyInput
              key="costPerSessionCents"
              amount={trainingCourse.totalCostPerSessionCents}
              placeholder={__('Enter an amount')}
              onAmountChange={totalCostPerSessionCents => {
                if (
                  totalCostPerSessionCents ===
                  trainingCourse.totalCostPerSessionCents
                ) {
                  return;
                }
                handleInputChange({
                  value: totalCostPerSessionCents,
                  name: 'totalCostPerSessionCents',
                });
              }}
              currency={currency}
              onCurrencyChange={currency => {
                setCurrency(currency);
                handleInputChange({
                  value: currency,
                  name: 'totalCostPerSessionCurrency',
                });
              }}
            />
            <FieldError>{errors.totalCostPerSession}</FieldError>
          </Field>
        </div>
        <div className="max-w-[50%] flex-grow">
          <Field>
            <Label>{__('Training course location')}</Label>
            <Input
              value={trainingCourse?.location || ''}
              placeholder={__(
                'Enter the address where the training will take place'
              )}
              onChange={(location: string) => {
                handleInputChange({ value: location, name: 'location' });
              }}
            />
            <FieldError>{errors.location}</FieldError>
          </Field>

          <div className="flex gap-4">
            <Field className="max-w-[50%] flex-grow">
              <Label>{__('Total number of hours')}</Label>
              <FloatInput
                value={
                  trainingCourse.durationInHours
                    ? Number(trainingCourse.durationInHours)
                    : null
                }
                placeholder={__('Enter the total number of hours')}
                onInputChange={(hours: number | null) => {
                  handleInputChange({
                    value: hours,
                    name: 'durationInHours',
                  });
                }}
              />
              <FieldError>{errors.durationInHours}</FieldError>
            </Field>
            <Field className="max-w-[50%] flex-grow">
              <Label>{__('Available seats')}</Label>
              <div className="flex gap-2 items-center">
                <Text>{__('Min')}</Text>
                <NumberInput
                  value={
                    trainingCourse.minAvailableSeats === null
                      ? undefined
                      : trainingCourse.minAvailableSeats
                  }
                  placeholder={__('Min')}
                  onChange={seats => {
                    handleInputChange({
                      value: seats,
                      name: 'minAvailableSeats',
                    });
                  }}
                />
                <Text>{__('Max')}</Text>
                <NumberInput
                  value={
                    trainingCourse.maxAvailableSeats === null
                      ? undefined
                      : trainingCourse.maxAvailableSeats
                  }
                  placeholder={__('Max')}
                  onChange={seats => {
                    handleInputChange({
                      value: seats,
                      name: 'maxAvailableSeats',
                    });
                  }}
                />
              </div>
              <FieldError>{errors.minAvailableSeats}</FieldError>
            </Field>
          </div>
          <Field>
            <Label>{__('Cost per participant')}</Label>
            <MoneyInput
              key="costPerParticipantCents"
              amount={trainingCourse.costPerParticipantCents}
              placeholder={__('Enter an amount')}
              onAmountChange={costPerParticipantCents => {
                if (
                  costPerParticipantCents ===
                  trainingCourse.costPerParticipantCents
                ) {
                  return;
                }
                handleInputChange({
                  value: costPerParticipantCents,
                  name: 'costPerParticipantCents',
                });
              }}
              currency={currency}
              onCurrencyChange={currency => {
                setCurrency(currency);
                handleInputChange({
                  value: currency,
                  name: 'costPerParticipantCurrency',
                });
              }}
            />
            <FieldError>{errors.costPerParticipant}</FieldError>
          </Field>
        </div>
      </div>
    </>
  );
};
export default FormatCostBlock;
