import React, { useContext, useState } from 'react';

import type { TrainingSession } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { HamburgerMenu, MenuItem, MenuList, Tooltip } from 'components';

import { DataContext } from '..';
import DeleteSessionModal from '../../components/DeleteSessionModal';
import MoveToForecastModal from '../../components/MoveToForecastModal';
import ValidateSessionModal from '../../components/ValidateSessionModal';

type Props = {
  session: TrainingSession;
  refetchSessions: () => void;
  isValidatedPlan: boolean;
};

const RowActions = ({ session, refetchSessions, isValidatedPlan }: Props) => {
  const [isMoveToForecastModalVisible, setIsMoveToForecastModalVisible] =
    useState(false);
  const [isDeleteSessionModalVisible, setIsDeleteSessionModalVisible] =
    useState(false);
  const [
    isMoveToValidatedPlanModalVisible,
    setIsMoveToValidatedPlanModalVisible,
  ] = useState(false);

  const { setShouldRefetchStats } = useContext(DataContext);

  return (
    <>
      <HamburgerMenu
        disabled={!can({ perform: 'destroy', on: session })}
        disabledMessage={__(
          "You must be the creator to access the session's actions"
        )}
      >
        <MenuList>
          {isValidatedPlan ? (
            <Tooltip
              enabled={session.status === 'completed'}
              content={__(
                'A session cannot be moved to the forecast plan if it is completed.'
              )}
            >
              <MenuItem
                onClick={() => setIsMoveToForecastModalVisible(true)}
                disabled={session.status === 'completed'}
              >
                {__('Move to the forecast plan')}
              </MenuItem>
            </Tooltip>
          ) : (
            <MenuItem
              onClick={() => setIsMoveToValidatedPlanModalVisible(true)}
            >
              {__('Move to the validated plan')}
            </MenuItem>
          )}
          <MenuItem
            isDanger
            onClick={() => setIsDeleteSessionModalVisible(true)}
          >
            {__('Delete session')}
          </MenuItem>
        </MenuList>
      </HamburgerMenu>
      {isMoveToForecastModalVisible && (
        <MoveToForecastModal
          sessionId={session.id}
          onClose={() => setIsMoveToForecastModalVisible(false)}
          onAfterUpdate={() => {
            refetchSessions();
            setShouldRefetchStats(true);
          }}
        />
      )}
      {isDeleteSessionModalVisible && (
        <DeleteSessionModal
          sessionId={session.id}
          onClose={() => setIsDeleteSessionModalVisible(false)}
          onAfterDelete={() => {
            refetchSessions();
            setShouldRefetchStats(true);
          }}
        />
      )}
      {isMoveToValidatedPlanModalVisible && (
        <ValidateSessionModal
          session={session}
          onClose={() => setIsMoveToValidatedPlanModalVisible(false)}
          onAfterUpdate={() => {
            refetchSessions();
            setShouldRefetchStats(true);
          }}
        />
      )}
    </>
  );
};

export default RowActions;
