import React, { useState } from 'react';

import type { Tag as TagType } from 'models';

import { __ } from 'helpers/i18n';

import { ConfirmationModal } from 'components';

import TagPicker from 'scenes/components/TagPicker';

type Props = {
  tags: Array<TagType>;
  updateTags: (tags: Array<TagType>) => Promise<void>;
  tagContext: 'review_cycle' | 'three_sixty_review_cycle';
  onClose: () => void;
};

const UpdateTagsModal = ({ tags, updateTags, onClose, tagContext }: Props) => {
  const [selectedTags, setSelectedTags] = useState(tags);

  return (
    <ConfirmationModal
      isActive
      onConfirm={async () => {
        await updateTags(selectedTags);
        onClose();
      }}
      onCancel={onClose}
      confirmLabel={__('Add')}
      title={__('Add tags to this campaign')}
      refreshContentOnOpening
      isLarge
      overflowVisible
    >
      <p>{__('Choose one or many tags to add to this campaign')}</p>
      <div className="my-4">
        <TagPicker
          context={tagContext}
          selectedTags={selectedTags}
          onTagsChange={setSelectedTags}
        />
      </div>
    </ConfirmationModal>
  );
};

export default UpdateTagsModal;
