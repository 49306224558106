import React, { useContext } from 'react';

import { useCurrentOrganization, useOrganizationPlan } from 'helpers/hooks';

import { Column, Columns, FeatureFlagged } from 'components';

import { DataContext } from '..';
import EditableCustomFieldValues from './EditableCustomFieldValues';
import EditableField from './EditableField';
import LastSyncStateCard from './LastSyncStateCard';

const EditionInputs = () => {
  const { user, attributes } = useContext(DataContext);
  const { ssoEnabled, usernameEnabled } = useCurrentOrganization();
  const { multiLevelAdminEnabled } = useOrganizationPlan();

  return (
    <>
      <Columns className="field-section">
        <Column>
          <EditableField field="firstName" />
          <EditableField field="email" />
          <EditableField field="jobTitle" />
          <EditableField field="workStartDate" />
          <EditableField field="team" />
          <EditableField field="department" />
          <FeatureFlagged flag="genderInProfile">
            <EditableField field="gender" />
          </FeatureFlagged>
          <EditableField field="locale" />

          {!user.external && ssoEnabled && attributes.useSso !== undefined && (
            <EditableField field="useSso" />
          )}
        </Column>

        <Column>
          <EditableField field="lastName" />
          {usernameEnabled && <EditableField field="username" />}
          <EditableField field="manager" />
          <EditableField field="level" />
          <EditableField field="registrationNumber" />

          {multiLevelAdminEnabled && <EditableField field="entityId" />}

          <EditableField field="region" />
          <EditableField field="service" />
          <FeatureFlagged flag="fullTimeEquivalenceInProfile">
            <EditableField field="workingTimeRatio" />
          </FeatureFlagged>
        </Column>
      </Columns>

      {(attributes.customFieldValues || []).length !== 0 && (
        <EditableCustomFieldValues />
      )}

      {!!user.lastUserSync && <LastSyncStateCard />}
    </>
  );
};

export default EditionInputs;
