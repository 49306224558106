import React, { useState } from 'react';

import { SkillsDomain } from 'models/skills';

import DomainHeader from './DomainHeader';

type Props = {
  children: React.ReactNode;
  domain: SkillsDomain;
  renderLastDropdownButton: () => React.ReactNode | null;
};

const DomainSectionWrapper = ({
  children,
  domain,
  renderLastDropdownButton,
}: Props) => {
  const [active, setActive] = useState(true);

  return (
    <>
      <DomainHeader
        domain={domain}
        active={active}
        onClick={() => setActive(!active)}
      />
      {active && children}
      {renderLastDropdownButton()}
    </>
  );
};

export default DomainSectionWrapper;
