import React from 'react';

import { __ } from 'helpers/i18n';

import { Box, EmptyStateWithIcon } from 'components';

type Props = {
  revieweeFullName: string;
};

const EmptyState = ({ revieweeFullName }: Props) => {
  return (
    <Box className="mt-4">
      <EmptyStateWithIcon
        inBox={false}
        iconName="edit_note"
        title={__(
          'No training requests have been created for %1 yet',
          revieweeFullName
        )}
      />
    </Box>
  );
};

export default EmptyState;
