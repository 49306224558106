import React from 'react';
import { Navigate } from 'react-router-dom';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { TrainingPeriod } from 'models';

import { useOrganizationPlan } from 'helpers/hooks';
import {
  pathToTrainingPeriodCreator,
  pathToTrainingPeriodRequests,
  pathToTrainingPeriodRequestsLandingPage,
} from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';

type Props = {};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    currentTrainingPeriod: TrainingPeriod;
  };

const TrainingPeriodRedirector = ({
  currentTrainingPeriod,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const { trainingModuleEnabled } = useOrganizationPlan();
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        if (!trainingModuleEnabled) {
          return <Navigate to={pathToTrainingPeriodRequestsLandingPage()} />;
        }

        if (currentTrainingPeriod) {
          return (
            <Navigate
              to={pathToTrainingPeriodRequests(currentTrainingPeriod.slug)}
            />
          );
        }

        return <Navigate to={pathToTrainingPeriodCreator()} />;
      }}
    />
  );
};

export default newDataLoader({
  fetch: () => get('training/periods/current'),
  hydrate: {
    currentTrainingPeriod: {},
  },
})(TrainingPeriodRedirector) as React.ComponentType<Props>;
