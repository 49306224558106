import React, { type ReactNode } from 'react';

import type { Props as BackButtonProps } from 'components/HOCs/withBackButton';
import type { NavigationItem } from 'components/navigation/Tabs/NavigationList';

import classNames from 'helpers/classNames';

import { BackButton, Tabs, Text } from 'components';

type Props = {
  title?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  withBackButton?: boolean;
  backButtonProps?: BackButtonProps;
  statusInfo?: ReactNode;
  tabItems?: NavigationItem[];
  transparentBackground?: boolean;
  children?: ReactNode;
};

const MobilePageHeader = ({
  title,
  description,
  actions,
  withBackButton,
  backButtonProps,
  statusInfo,
  tabItems,
  transparentBackground,
  children,
}: Props) => {
  const tabItemsWithPreset = tabItems?.map(item => ({
    ...item,
    labelPreset: '16bs5.5' as const,
  }));

  return (
    <div
      className={classNames('mobile-page-header', {
        'page-header--transparent-background': transparentBackground,
        'pb-0': !!tabItemsWithPreset,
      })}
    >
      {(withBackButton || statusInfo) && (
        <div
          className={classNames(
            'mobile-page-header__back-button-and-status-container',
            {
              'justify-end': !withBackButton && statusInfo,
            }
          )}
        >
          {withBackButton && (
            <div>
              <BackButton {...backButtonProps} className="text-text-light" />
            </div>
          )}
          {statusInfo && statusInfo}
        </div>
      )}

      <div className="mobile-page-header__title-and-actions-container">
        {!!children ? (
          children
        ) : (
          <Text
            className="mobile-page-header__title-and-actions-container__title"
            preset="24bs2"
            testClassName="test-page-header__title-and-actions-container__title"
          >
            {title}
          </Text>
        )}
        {!!actions && (
          <div className="mobile-page-header__title-and-actions-container__actions">
            {actions}
          </div>
        )}
      </div>
      {description && (
        <div className="mobile-page-header__description">
          {typeof description === 'string' ? (
            <Text preset="13s7">{description}</Text>
          ) : (
            description
          )}
        </div>
      )}

      {!!tabItemsWithPreset && tabItemsWithPreset.length > 1 && (
        <Tabs tabsPosition="left" items={tabItemsWithPreset} />
      )}
    </div>
  );
};

export default MobilePageHeader;
