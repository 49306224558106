import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import { compose } from 'redux';

import { TrainingPeriodBudget } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { pathToTrainingBudget } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer, FetchContainer, PageHeader } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import Form from './Form';

type AfterMatchParamsProps = {
  match: {
    periodSlug: string;
  };
};

type AfterConnectProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    trainingPeriod?: TrainingPeriodBudget;
  };

const BudgetEdit = ({
  match: { periodSlug },
  trainingPeriod,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  return (
    <Fragment>
      <PageHeader
        title={
          trainingPeriod ? (
            __('Budget: %1', trainingPeriod?.name)
          ) : (
            <Skeleton width={200} />
          )
        }
        withBackButton
        backButtonProps={{
          target: pathToTrainingBudget(periodSlug),
          children: __('Back to training plan'),
        }}
      />
      <ContentContainer>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => {
            if (!trainingPeriod) return;

            return <Form trainingPeriod={trainingPeriod} />;
          }}
        />
      </ContentContainer>
    </Fragment>
  );
};

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`training/periods/${match.periodSlug}/budget`),
    hydrate: {
      trainingPeriod: {
        budgetEnvelopes: {
          responsibles: {},
          budgetItems: {
            fundingSource: {},
          },
        },
        budgetItems: {
          fundingSource: {},
        },
        exchangeRates: {},
      },
    },
    cacheKey: ({ match }: AfterMatchParamsProps) =>
      compositeKey({
        periodSlug: match.periodSlug,
        view: 'BudgetEdit',
      }),
  })
)(BudgetEdit);
