import * as React from 'react';

import { __, n__ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';

import {
  Button,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  SearchBar,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserMultiFilters from 'scenes/components/UserMultiFilters';

type Props = {
  search: string;
  participantCount: number;
  totalRecordCount: number;
  onRemoveEveryoneClick: () => void;
  onAddEveryoneClick: (
    search: string,
    userFilters: ActiveFilters | undefined
  ) => Promise<void>;
  userFilters?: ActiveFilters | undefined;
  onQueryParamsChange?: (queryParams: { [key: string]: any }) => void;
};

export default function Header({
  search,
  userFilters,
  onQueryParamsChange,
  participantCount,
  totalRecordCount,
  onRemoveEveryoneClick,
  onAddEveryoneClick,
}: Props) {
  return (
    <>
      <Level>
        <LevelLeft>
          <Text preset="14s6">
            {n__(
              '%1 employee selected',
              '%1 employees selected',
              participantCount
            )}
          </Text>
        </LevelLeft>
      </Level>
      <SearchBar
        value={search}
        placeholder={__('Search by name or email')}
        onChange={search =>
          onQueryParamsChange && onQueryParamsChange({ search })
        }
        style={{ marginTop: 12, marginBottom: 12 }}
      />
      <Level style={{ marginBottom: 12 }}>
        <LevelLeft>
          <UserMultiFilters
            onChange={onQueryParamsChange}
            userFilters={userFilters}
          />
        </LevelLeft>

        <LevelRight className="tr-modal-header-buttons">
          <Button
            testClassName="test-add-everyone-button"
            color="secondary"
            onClick={() => onAddEveryoneClick(search, userFilters)}
            style={{ marginRight: 16 }}
          >
            <Icon
              style={{ marginBottom: 1, marginRight: 7 }}
              name="add_circle"
            />

            {totalRecordCount
              ? n__(
                  'Add %1 result',
                  'Add %1 results',
                  assertDefined(
                    totalRecordCount,
                    'totalRecordCount must be defined'
                  )
                )
              : __('Add everyone')}
          </Button>
          <Button
            testClassName="test-remove-everyone-button"
            color="secondary"
            onClick={onRemoveEveryoneClick}
          >
            <Icon
              style={{ marginBottom: 1, marginRight: 7 }}
              name="do_not_disturb_on"
            />
            {__('Remove everyone')}
          </Button>
        </LevelRight>
      </Level>
    </>
  );
}
