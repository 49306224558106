import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { CSSProperties } from 'react';

import { User } from 'models';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Avatar, Text, Tooltip } from 'components';

import { Marks } from './DiscreteSlider.types';

type Props = {
  labels: Array<string>;
  selectedLabel: string | null;
  onChange?: (value: { value: number | number[]; label: any }) => unknown;
  hideLabels?: boolean;
  isDisabled?: boolean;
  className?: string;
  testClassName?: string;
  style?: CSSProperties;
  usersBySelectedLabel?: { [key: string]: User[] };
  renderTooltipContent?: (user: User) => string;
  hasWhiteRail?: boolean;
};

const DiscreteSlider = ({
  labels,
  selectedLabel,
  onChange,
  isDisabled,
  hideLabels = false,
  className,
  testClassName,
  style,
  usersBySelectedLabel,
  renderTooltipContent,
  hasWhiteRail,
}: Props) => {
  let marks: Marks = {};
  labels.forEach((label, index) => {
    marks[index] = label;
  });

  let value: number | null = null;
  for (let key in marks) {
    if (marks[key] === selectedLabel) {
      value = Number(key);
    }
  }

  const baseAvatarOffset = 10;
  const avatarSize = 20;
  const renderAvatarsForLevel = (label: string) => {
    invariant(usersBySelectedLabel, 'usersBySelectedLabel must be defined');

    return usersBySelectedLabel[label].map((user, userIndex) => (
      <Tooltip
        content={
          !!renderTooltipContent
            ? renderTooltipContent(user)
            : __(
                '%1 has evaluated the skill as "%2"',
                <b>{user.fullName}</b>,
                label
              )
        }
        key={user.id}
      >
        <Avatar
          url={user.avatarUrl}
          size="tiny"
          className="opacity-40"
          style={{ marginLeft: userIndex * baseAvatarOffset * -1 }}
        />
      </Tooltip>
    ));
  };

  // The avatars next to the handle are not rendered with renderAvatars because there are in different stacking context and the avatars were either above the handle or behind the slider.
  // They are also shifted to the left to be visible when there is the handle
  const renderAvatarsNextToHandle = (handleValue: number) => {
    if (!usersBySelectedLabel || !usersBySelectedLabel[labels[handleValue]])
      return null;

    return (
      <div
        className="flex fixed w-min"
        style={{
          top: -avatarSize / 4,
          left: `calc(${(100 / (labels.length - 1)) * handleValue}% - ${
            (usersBySelectedLabel[labels[handleValue]].length + 1) *
            baseAvatarOffset
          }px)`,
        }}
      >
        {renderAvatarsForLevel(labels[handleValue])}
      </div>
    );
  };

  const renderAvatars = () => (
    <div className="discrete-slider-avatar-container">
      {!!usersBySelectedLabel &&
        Object.keys(usersBySelectedLabel).map(label => {
          if (label === 'none') return null;

          const indexOfLabel = labels.indexOf(label);
          if (value === indexOfLabel) return null;

          return (
            <button
              key={label}
              className="flex fixed w-min cursor-pointer bg-transparent border-none"
              style={{
                left: `calc(${
                  (100 / (labels.length - 1)) * indexOfLabel
                }% - ${baseAvatarOffset}px - ${avatarSize / 4}px)`,
              }}
              onClick={() =>
                onChange && onChange({ value: indexOfLabel, label })
              }
            >
              {renderAvatarsForLevel(label)}
            </button>
          );
        })}
    </div>
  );

  return (
    <div
      className={classNames(
        'discrete-slider test-discrete-slider',
        className,
        testClassName,
        {
          'mb-4': !isDisabled,
          'has-white-rail': !!hasWhiteRail,
        }
      )}
      style={style}
    >
      <Slider
        dots
        dotStyle={dotValue => {
          const invisibleCss = {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            backgroundImage: 'none',
          };

          if (!!value && dotValue < value) {
            return invisibleCss;
          }
          if (!!usersBySelectedLabel) {
            const indexesOflabelsWithAnswers = Object.keys(
              usersBySelectedLabel
            ).map(label => labels.indexOf(label));

            if (indexesOflabelsWithAnswers.includes(dotValue)) {
              return invisibleCss;
            }
          }
          return {};
        }}
        min={0}
        max={Math.max(0, labels.length - 1)}
        marks={hideLabels ? undefined : marks}
        // @ts-expect-error: The lib doesn't officially support having a null but it does what we want nonetheless
        value={value}
        onChange={value =>
          onChange &&
          onChange({ value: value, label: marks[[value].flat()[0]] })
        }
        disabled={isDisabled}
        handleRender={(node, handleProps) => (
          <>
            {renderAvatarsNextToHandle(handleProps.value)}
            {node}
            {handleProps.dragging && (
              <Text
                preset="11s8"
                color="soften"
                className="discrete-slider-legend"
                style={{
                  left: `${(100 / (labels.length - 1)) * handleProps.value}%`,
                }}
              >
                {marks[handleProps.value]}
              </Text>
            )}
          </>
        )}
      />
      {renderAvatars()}
    </div>
  );
};

export default DiscreteSlider;
