import * as React from 'react';

import { __, n__ } from 'helpers/i18n';

import {
  Button,
  Flex,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  Title4,
} from 'components';

import clap from './clap';

type Props = {
  onClose: () => void;
  trainee: { firstName: string } | null;
  trainingRequestsCount: number;
};

export default function SuccessContent({
  onClose,
  trainee,
  trainingRequestsCount,
}: Props) {
  return (
    <>
      <ModalCardHead onClose={onClose} />
      <ModalCardBody style={{ paddingBottom: 16 }}>
        <Flex
          style={{ marginBottom: 16 }}
          direction="column"
          verticalAlign
          horizontalAlign
        >
          <img
            alt=""
            style={{ height: 168, width: 168, borderRadius: '50%' }}
            src={`data:image/svg+xml;base64,${window.btoa(clap)}`}
          />
          <Flex direction="column" style={{ textAlign: 'center' }}>
            <Title4 style={{ marginTop: 32, marginBottom: 16 }}>
              {n__('Request sent!', 'Requests sent!', trainingRequestsCount)}
            </Title4>
            <p>
              {n__(
                'Your request has been sent to the person in charge of training requests.',
                'Your requests have been sent to the person in charge of training requests.',
                trainingRequestsCount
              )}
            </p>
            <p>
              {n__(
                '%2 has been informed by email that you have created a training request.',
                'The concerned employees have been informed by email that you have created training requests.',
                trainingRequestsCount,
                trainee?.firstName || ''
              )}
            </p>
          </Flex>
        </Flex>{' '}
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </>
  );
}
