import React from 'react';

import { SurveyQuestionUpdatableAttributes } from 'models';

import { FormErrors } from 'helpers/api';
import { __ } from 'helpers/i18n';

import { Text } from 'components';

import RatingScaleInput from './RatingScaleInput';

type Props = {
  minValue: number;
  maxValue: number;
  rightLabel: string | undefined;
  leftLabel: string | undefined;
  onUpdate: (params: { content: SurveyQuestionUpdatableAttributes }) => void;
  errors: FormErrors;
};

const getMaxOptions = () => {
  const values = Array.from({ length: 8 }, (_, index) => index + 3);
  return values.map(value => ({
    label: value.toLocaleString(),
    value,
    attribute: 'maxValue',
  }));
};

const ScaleQuestionDetails = ({
  minValue,
  maxValue,
  leftLabel,
  rightLabel,
  onUpdate,
  errors,
}: Props) => {
  return (
    <>
      <Text preset="14bs6">{__('Rating scale:')}</Text>
      <div className="mt-2 -mb-4">
        <RatingScaleInput
          label={__('From')}
          selectAttribute={{ attribute: 'minValue', value: minValue }}
          options={[
            { label: '0', value: 0 },
            { label: '1', value: 1 },
          ]}
          inputAttribute={{ attribute: 'scaleLeftLabel', value: leftLabel }}
          onUpdate={onUpdate}
          errors={errors}
          testClassName="test-survey-edition-from"
        />
        <RatingScaleInput
          label={__('To')}
          selectAttribute={{ attribute: 'maxValue', value: maxValue }}
          options={getMaxOptions()}
          inputAttribute={{ attribute: 'scaleRightLabel', value: rightLabel }}
          onUpdate={onUpdate}
          errors={errors}
          testClassName="test-survey-edition-to"
        />
      </div>
    </>
  );
};

export default ScaleQuestionDetails;
