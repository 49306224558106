import React from 'react';

import {
  SurveyAnswerSentimentCategory,
  SurveyTextQuestion,
  TextScore,
  TextSpecificStats,
} from 'models';

import tailwindTheme from 'config/tailwindTheme';

import { __ } from 'helpers/i18n';

import { FlatPieChart, Text } from 'components';

import AILogo from 'scenes/components/AILogo';

import QuestionScore from '../../components/QuestionScore';

type Props = {
  stats: TextSpecificStats;
  score: TextScore;
  sentimentCategory: SurveyAnswerSentimentCategory;
  question: SurveyTextQuestion;
};

const TextStats = ({ stats, score, sentimentCategory, question }: Props) => {
  if (!stats) {
    return null;
  }
  return (
    <TextSentimentStats
      stats={stats}
      score={score}
      sentimentCategory={sentimentCategory}
      question={question}
    />
  );
};

type PropsWithSentiment = {
  stats: NonNullable<TextSpecificStats>;
  score: TextScore;
  sentimentCategory: SurveyAnswerSentimentCategory;
  question: SurveyTextQuestion;
};

const TextSentimentStats = ({
  stats,
  score,
  sentimentCategory,
  question,
}: PropsWithSentiment) => {
  const categoriesData = [
    {
      label: __('Positive'),
      value: stats.positivePercent,
      color: tailwindTheme.colors['green-success'],
    },
    {
      label: __('Neutral'),
      value: stats.neutralPercent,
      color: tailwindTheme.colors['orange-warning'],
    },
    {
      label: __('Negative'),
      value: stats.negativePercent,
      color: tailwindTheme.colors['red-fuckup'],
    },
    {
      label: __('No sentiment expressed'),
      value: stats.nonePercent,
      color: tailwindTheme.colors['ui-grey'],
    },
  ];

  return (
    <div className="grow">
      <div className="flex justify-between">
        <Text preset="18bs5">{__('Text question - Sentiment analysis')}</Text>
        <AILogo />
      </div>
      <div className="flex mt-4 justify-end">
        <QuestionScore
          score={score}
          sentimentCategory={sentimentCategory}
          question={question}
        />

        <div className="ml-16 grow">
          <FlatPieChart data={categoriesData} />
        </div>
      </div>
    </div>
  );
};

export default TextStats;
