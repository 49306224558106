import React, { ReactNode } from 'react';

import type { ThreeSixtyReviewCycle, ThreeSixtyUserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';

import { HamburgerMenu, MenuList, Tooltip } from 'components';

import {
  ManagePeersAction,
  ReleaseAction,
  RemindPeersFeedbackAction,
  RemindPeersNominationAction,
  RemindSelfAssessmentAction,
  ReopenPeersFeedbackAction,
  UnreleaseAction,
} from 'scenes/components/userReview/360/actions';
import { UpdateResponsibleAction } from 'scenes/components/userReview/actions';
import { RemoveParticipantAction } from 'scenes/components/userReview/common/actions';

type Props = {
  userReview: ThreeSixtyUserReview;
  reviewCycle: ThreeSixtyReviewCycle;
  refetchData: () => Promise<void>;
};

export default function ManageReviewActionMenu({
  userReview,
  reviewCycle,
  refetchData,
}: Props) {
  const availableActions: ReactNode[] = [];
  if (can({ perform: 'remind_peers_nomination', on: userReview })) {
    availableActions.push(
      <RemindPeersNominationAction
        userReview={userReview}
        key="remindPeersNominationAction"
      />
    );
  }
  if (can({ perform: 'remind_self_assessment', on: userReview })) {
    availableActions.push(
      <RemindSelfAssessmentAction
        userReview={userReview}
        key="remindSelfAssessmentAction"
      />
    );
  }
  if (can({ perform: 'remind_peers_feedback', on: userReview })) {
    availableActions.push(
      <RemindPeersFeedbackAction
        userReview={userReview}
        key="remindPeersFeedbackAction"
      />
    );
  }
  if (can({ perform: 'unrelease', on: userReview })) {
    availableActions.push(
      <UnreleaseAction
        userReview={userReview}
        key="unreleaseAction"
        onAfterAction={refetchData}
      />
    );
  }
  if (
    can({ perform: 'manage_peers', on: userReview }) ||
    can({ perform: 'change_peers_after_validation', on: userReview }) ||
    can({ perform: 'validate_peers', on: userReview })
  ) {
    availableActions.push(
      <ManagePeersAction
        key="managePeersAction"
        userReview={userReview}
        reviewCycle={reviewCycle}
        onAfterAction={refetchData}
      />
    );
  }
  if (can({ perform: 'update_responsible', on: userReview })) {
    availableActions.push(
      <UpdateResponsibleAction
        userReview={userReview}
        reviewCycle={reviewCycle}
        onAfterAction={refetchData}
        key="updateResponsibleAction"
      />
    );
  }
  if (
    can({ perform: 'show_action_to_release', on: userReview }) ||
    can({
      perform: 'show_secondary_action_in_review_to_release',
      on: userReview,
    })
  ) {
    availableActions.push(
      <ReleaseAction
        userReview={userReview}
        key="releaseAction"
        onAfterAction={refetchData}
      />
    );
  }
  if (can({ perform: 'show_reopen_peers_feedback_action', on: userReview })) {
    const canReopen = can({
      perform: 'reopen_peers_feedback',
      on: userReview,
    });
    const disabledReason =
      !canReopen && userReview.hasAnonymizedResults
        ? __(
            'To guarantee anonymity, feedback cannot be reopened for anonymous campaigns'
          )
        : null;
    availableActions.push(
      <Tooltip enabled={!!disabledReason} content={disabledReason}>
        <ReopenPeersFeedbackAction
          userReview={userReview}
          disabled={!!disabledReason}
          key="reopenPeersFeedbackAction"
        />
      </Tooltip>
    );
  }
  if (can({ perform: 'remove_participant', on: userReview })) {
    availableActions.push(
      <RemoveParticipantAction
        key="removeParticipantAction"
        userReview={userReview}
        reviewCycle={reviewCycle}
      />
    );
  }

  return (
    <>
      <HamburgerMenu
        align="right"
        disabled={availableActions.length === 0}
        disabledMessage={__(
          'You must be responsible for the review to have access to the actions'
        )}
      >
        <MenuList>{availableActions}</MenuList>
      </HamburgerMenu>
    </>
  );
}
