import React from 'react';

import type { Props } from '.';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';

import {
  SimbelTrainingRequest,
  SimbelTrainingRequestStatus,
} from 'models/simbel/SimbelTrainingRequest';

import { __ } from 'helpers/i18n';
import { pathToSimbelRedirection } from 'helpers/simbel';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Date,
  FetchContainer,
  Link,
  SimpleBoxTable,
  SoftErrorNotification,
  SupportLink,
  TrainingRequestEmptyState,
} from 'components';

import PoweredBySimbel from 'scenes/components/simbel/PoweredBySimbel';

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    trainingRequests: SimbelTrainingRequest[];
  };

const linkToSimbelTrainingRequest = (
  trainingRequest: SimbelTrainingRequest
) => {
  return pathToSimbelRedirection({
    intent: 'training_request_details',
    trainingRequestId: trainingRequest.id,
  });
};

const formattedStatus = (status: SimbelTrainingRequestStatus) => {
  const mapping = {
    waiting_manager_input: __('Awaiting manager input'),
    waiting_hr_validation: __('Awaiting validation'),
    refused: __('Refused'),
    canceled: __('Canceled'),
    validated: __('Validated'),
    pending_provider: __('Validated'), // Weird but from their doc
    on_hold: __('Validated'), // Idem
    pending_employee: __('Availability to be confirmed'),
    registered: __('Registered'),
    in_progress: __('In progress'),
    completed: __('Completed'),
  };
  return mapping[status] || status;
};

const ConnectedState = ({
  trainingRequests,
  isFetching,
  hasError,
  emptyStateTitle,
  footer,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <>
          <SimpleBoxTable
            emptyState={<TrainingRequestEmptyState title={emptyStateTitle} />}
            footer={footer}
            columns={[
              {
                header: __('Title'),
                cell: trainingRequest => trainingRequest.title,
              },
              {
                header: __('Training date'),
                cell: trainingRequest => (
                  <Date value={trainingRequest.startDate} />
                ),
              },
              {
                header: __('Status'),
                cell: trainingRequest =>
                  formattedStatus(trainingRequest.status),
              },
              {
                header: __('Creation date'),
                cell: trainingRequest => (
                  <Date value={trainingRequest.createdAt} />
                ),
              },
              {
                header: '',
                cell: trainingRequest => (
                  <Link
                    openInNewTab
                    to={linkToSimbelTrainingRequest(trainingRequest)}
                  >
                    {__('Details')}
                  </Link>
                ),
                isNarrow: true,
              },
            ]}
            rows={trainingRequests}
            keyFn={trainingRequest => trainingRequest.id}
          />
          <div className="mt-2">
            <PoweredBySimbel />
          </div>
        </>
      )}
      renderError={() => (
        <SoftErrorNotification
          message={__(
            "The training requests couldn't be fetched from Simbel. Please refresh the page or %1 if the problem persists.",
            <SupportLink>{__('contact us')}</SupportLink>
          )}
        />
      )}
    />
  );
};

export default newDataLoader({
  fetch: ({ userId, userReviewId }: Props) =>
    userReviewId
      ? get(`simbel/user_reviews/${userReviewId}/training_requests`)
      : get(`simbel/users/${userId}/training_requests`),
  hydrate: { trainingRequests: {} },
})(ConnectedState);
