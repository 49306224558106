import React from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import type { ReviewCycle, UserReview } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToReviewCycleParticipants } from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { AppDispatch, removeParticipant } from 'redux/actions';
import { getRemoveParticipantSideEffects } from 'redux/actions/resources/sideEffects';

import { MenuItem } from 'components/navigation/Menus';

const removeParticipantAction = (
  reviewCycle: ReviewCycle,
  userReview: UserReview,
  navigate: NavigateFunction,
  location: Location
) => {
  return async (dispatch: AppDispatch) => {
    const { user } = userReview;

    await confirmAsync(
      __('Remove %1 from cycle', user.fullName),
      __(
        'Removing %1 from this review cycle will also delete the following items.',
        <b>{user.fullName}</b>
      ),
      {
        onConfirm: async () => {
          if (
            (await dispatch(removeParticipant(reviewCycle, userReview))) &&
            location.pathname !== pathToReviewCycleParticipants(reviewCycle.id)
          ) {
            navigate(pathToReviewCycleParticipants(reviewCycle.id));
          }
        },
        confirmLabel: __('Remove %1 from cycle', user.fullName),
        sideEffects: getRemoveParticipantSideEffects(userReview),
      }
    );
  };
};

type Props = {
  reviewCycle: ReviewCycle;
  userReview: UserReview;
};

const RemoveParticipantAction = ({ reviewCycle, userReview }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const removeParticipant = () =>
    dispatch(
      removeParticipantAction(reviewCycle, userReview, navigate, location)
    );

  return (
    <MenuItem isDanger onClick={removeParticipant}>
      {__('Remove from campaign')}
    </MenuItem>
  );
};

export default RemoveParticipantAction;
