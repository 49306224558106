import { sortBy } from 'lodash';
import React from 'react';

import { CompensationBandContent } from 'models/Compensation';

import { __ } from 'helpers/i18n';

import { Box, BoxList, BoxListItem, Column, Columns, Text } from 'components';

import BandLevelItem from './BandLevelItem';

type Props = {
  compensationBand: CompensationBandContent;
};

const BandContent = ({ compensationBand }: Props) => {
  return (
    <Box>
      <Text preset="18bs5">{compensationBand.name}</Text>
      <BoxList className="mt-2">
        <BoxListItem>
          <Columns isGapLess>
            <Column size={3}>
              <Text preset="14bs6" color="light">
                {__('Level')}
              </Text>
            </Column>
            <Column size={3}>
              <Text preset="14bs6" color="light">
                {__('Compensation Type')}
              </Text>
            </Column>
            <Column size={3}>
              <Text preset="14bs6" color="light">
                {__('Minimum Compensation')}
              </Text>
            </Column>
            <Column size={3}>
              <Text preset="14bs6" color="light">
                {__('Maximum Compensation')}
              </Text>
            </Column>
          </Columns>
        </BoxListItem>

        {sortBy(compensationBand.levels, 'position').map(level => (
          <BandLevelItem key={level.id} level={level} />
        ))}
      </BoxList>
    </Box>
  );
};

export default BandContent;
