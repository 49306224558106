import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { pathToHome } from 'helpers/paths';

import SimbelTrainingRoutes from 'scenes/components/simbel/SimbelTrainingRoutes';

import CatalogRoutes from './Catalog/Routes';
import CatalogManagementRoutes from './CatalogManagement/Routes';
import ManagerDashboard from './ManagerDashboard';
import PlanManagementRoutes from './PlanManagement/Routes';

const ManagerDashboardRoutes = () => {
  const organization = useCurrentOrganization();

  const canShowTrainingCatalog = can({
    perform: 'show_training_catalog',
    on: organization,
  });

  const canShowTrainingCatalogManagement = can({
    perform: 'show_training_catalog_management_menu',
    on: organization,
  });

  const canShowManagerTrainingDashboard = can({
    perform: 'show_manager_training_dashboard',
    on: organization,
  });

  const canShowTrainingPlanMenu = can({
    perform: 'show_training_plan_menu',
    on: organization,
  });

  return (
    <Routes>
      {canShowTrainingCatalogManagement && (
        <Route index path="/*" element={<CatalogManagementRoutes />} />
      )}

      {canShowTrainingCatalog && (
        <Route path="catalog/*" element={<CatalogRoutes />} />
      )}

      {canShowManagerTrainingDashboard && (
        <Route
          path=":periodSlug/manager-dashboard/*"
          element={<ManagerDashboard />}
        />
      )}

      {canShowTrainingPlanMenu && (
        <Route path="plan/*" element={<PlanManagementRoutes />} />
      )}

      <Route path="*" element={<Navigate to={pathToHome()} />} />
    </Routes>
  );
};

const TrainingRoutes = () => {
  const organization = useCurrentOrganization();

  if (organization.isSimbelIntegrationActive) {
    return <SimbelTrainingRoutes />;
  } else {
    return <ManagerDashboardRoutes />;
  }
};

export default TrainingRoutes;
