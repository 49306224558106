import React from 'react';

import { useObjectMutation } from 'helpers/hooks';

import { FormBudgetEnvelope } from '../../types';
import BudgetItemsList from '../shared/BudgetItemsList';

type Props = {
  budgetEnvelope: FormBudgetEnvelope;
  onChange: (envelope: FormBudgetEnvelope) => void;
  showAddButton: boolean;
};

const FlatBudgetEnvelope = ({
  budgetEnvelope,
  onChange,
  showAddButton,
}: Props) => {
  const updateAttribute = useObjectMutation(budgetEnvelope, onChange);

  return (
    <BudgetItemsList
      budgetItems={budgetEnvelope.budgetItems}
      onChange={updateAttribute('budgetItems')}
      showAddButton={showAddButton}
    />
  );
};

export default FlatBudgetEnvelope;
