import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { QuickFilter } from 'components/Filters/types';

type Props = {
  filteredSource?: 'users' | 'survey_users';
};

const useAvailableQuickFilters = ({ filteredSource = 'users' }: Props) => {
  const activeUser = useActiveUser();

  const availableQuickFilters: Array<QuickFilter> = [
    {
      label: __('My reports'),
      slug: 'reports',
      segmentValue: activeUser.id,
      enabled: filteredSource === 'users' && activeUser.isManager,
      onSelect: setActiveFilters =>
        setActiveFilters(prevFilters => ({
          ...prevFilters,
          reports: { label: activeUser.fullName, value: activeUser.id },
        })),
    },
    {
      label: __('My direct reports'),
      slug: 'direct_reports',
      segmentValue: activeUser.id,
      enabled: activeUser.isManager,
      onSelect: setActiveFilters =>
        setActiveFilters(prevFilters => ({
          ...prevFilters,
          direct_reports: { label: activeUser.fullName, value: activeUser.id },
        })),
    },
  ];

  return availableQuickFilters;
};

export default useAvailableQuickFilters;
