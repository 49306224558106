import React from 'react';

import { useArrayMutation } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Text } from 'components';

import { FormBudgetEnvelope } from '../../types';
import FlatBudgetEnvelope from './FlatBudgetEnvelope';

type Props = {
  budgetEnvelopes: Array<FormBudgetEnvelope>;
  onChange: (value: Array<FormBudgetEnvelope>) => void;
};

const SingleBreakdown = ({ budgetEnvelopes, onChange }: Props) => {
  const { updateItem: updateEnvelope } = useArrayMutation(
    budgetEnvelopes,
    onChange
  );

  return (
    <>
      <Text preset="18bs5" className="block mb-4">
        {__('Define your funding sources')}
      </Text>
      <Text color="info" weight="normal" className="block mb-4">
        {__(
          'It will allow you to add different funding sources for a training session.'
        )}
      </Text>
      {budgetEnvelopes.map((envelope, index) => (
        <FlatBudgetEnvelope
          key={index}
          budgetEnvelope={envelope}
          onChange={updateEnvelope(index)}
          showAddButton={index === budgetEnvelopes.length - 1}
        />
      ))}
    </>
  );
};

export default SingleBreakdown;
