import { omit } from 'lodash';
import React, { createContext, useState } from 'react';

import { User } from 'models';

import { handleFormErrors } from 'helpers/api';
import { dateToDefaultDateStringFormat } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { successNotice } from 'redux/actions';
import { put } from 'redux/actions/api';

import { PullRight, Text, UnsavedModificationsGuard } from 'components';

import CancelAndSaveButtons from './CancelAndSaveButtons';
import EditionInputs from './EditionInputs';
import useUserToEditableAttributes, {
  Attributes,
  type Errors,
  UpdatableKeys,
} from './hooks/useUserToEditableAttributes';

type Props = { user: User; refetchUser: () => Promise<any> };

type DataContextType = {
  user: User;
  refetchUser: () => Promise<any>;
  errors: Errors;
  attributes: Attributes;
  onAttributeChange: (field: UpdatableKeys, value: any) => void;
  isFieldSynced: (field: string) => boolean;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

const EditableFields = ({ user, refetchUser }: Props) => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState({} as Errors);

  const {
    isDirty,
    attributes,
    onAttributeChange,
    onAttributesReset,
    setIsDirty,
  } = useUserToEditableAttributes(user);

  const updateUser = () =>
    dispatch(
      put(`users/${user.id}`, {
        user: {
          ...omit(attributes, 'manager', 'team'),
          managerId: attributes.manager?.id || null,
          teamId: attributes.team?.id || null,
          workStartDate: dateToDefaultDateStringFormat(
            attributes.workStartDate
          ),
          useSso: attributes.useSso ? null : attributes.useSso,
          username: attributes.username === '' ? null : attributes.username,
        },
      })
    );

  const onCancel = () => {
    onAttributesReset();
    setErrors({});
  };

  const onSubmit = async () => {
    await handleFormErrors(
      async () => {
        await updateUser();
        await dispatch(successNotice(__('%1 has been updated', user.fullName)));
        setIsDirty(false);
        setErrors({});
      },
      errors => setErrors(errors as Errors)
    );
  };

  const isFieldSynced = (field: string) => user.syncedFields.includes(field);

  return (
    <>
      <DataContext.Provider
        value={{
          user,
          refetchUser,
          errors,
          attributes,
          onAttributeChange,
          isFieldSynced,
        }}
      >
        <EditionInputs />
      </DataContext.Provider>
      {user.manager?.id !== attributes.manager?.id && (
        <Text color="danger">
          {__(
            "This action will change the manager for %1. Manager role for all %1's active reviews will be updated immediately.",
            user.firstName
          )}
        </Text>
      )}

      {isDirty && (
        <PullRight>
          <CancelAndSaveButtons onSave={onSubmit} onCancel={onCancel} />
        </PullRight>
      )}
      <UnsavedModificationsGuard isDirty={isDirty} onSubmit={onSubmit} />
    </>
  );
};

export default EditableFields;
