import { __ } from 'helpers/i18n';

type Props = { withAllStatuses: boolean };

const sessionTableFilters = (
  { withAllStatuses }: Props = { withAllStatuses: true }
) => {
  const defaultFilters = [
    { param: 'completed', label: __('TrainingSessionStatus|Plural|Completed') },
    {
      param: 'rescheduled',
      label: __('TrainingSessionStatus|Plural|Rescheduled'),
    },
    { param: 'canceled', label: __('TrainingSessionStatus|Plural|Canceled') },
    { param: 'all', label: __('TrainingSessionStatus|Plural|All') },
  ];
  if (withAllStatuses) {
    return [
      {
        param: 'in_preparation',
        label: __('TrainingSessionStatus|Plural|In preparation'),
      },
      { param: 'prepared', label: __('TrainingSessionStatus|Plural|Prepared') },
      ...defaultFilters,
    ];
  } else {
    return [
      { param: 'to_come', label: __('TrainingSessionStatus|Plural|To come') },
      ...defaultFilters,
    ];
  }
};

export default sessionTableFilters;
