import React from 'react';

import { __ } from 'helpers/i18n';

import { Box, EmptyStateWithIcon } from 'components';

type Props = {
  revieweeFullName: string;
};

const EmptyState = ({ revieweeFullName }: Props) => {
  return (
    <Box className="mt-4">
      <EmptyStateWithIcon
        inBox={false}
        iconName="school"
        title={__('%1 did not follow any trainings.', revieweeFullName)}
      />
    </Box>
  );
};

export default EmptyState;
