import React, { useState } from 'react';

import type { ManagementBoxProps } from './';

import { __ } from 'helpers/i18n';

import CareerLevelManagementModal from 'scenes/components/skills/CareerLevelManagementModal';

import CareerLevelManagementBoxContent from './components/CareerLevelManagementBoxContent';

type Props = ManagementBoxProps;

const CurrentCareerLevelManagementBox = ({
  careerLevel,
  isActiveUser,
  userFullName,
  canUpdate,
  performUpdate,
}: Props) => {
  const [modalIsActive, setModalIsActive] = useState(false);

  return (
    <>
      <CareerLevelManagementBoxContent
        title={__('Current matrix and level')}
        emptyCareerLevelMessage={
          isActiveUser
            ? __('You are not associated with a skills matrix.')
            : __('%1 is not associated with a skills matrix.', userFullName)
        }
        careerLevel={careerLevel}
        canUpdate={canUpdate}
        onLevelTitleClick={() => setModalIsActive(true)}
      />
      {canUpdate && (
        <CareerLevelManagementModal
          isActive={modalIsActive}
          onClose={() => setModalIsActive(false)}
          title={__('Update the current level of %1', userFullName)}
          description={
            <>
              <p>
                {__(
                  'You are about to update the current level of %1 on the skills matrix.',
                  userFullName
                )}
              </p>
              <p>
                {__(
                  'Make sure to make the necessary adjustments before launching a campaign.'
                )}
              </p>
            </>
          }
          levelSelectionLabel={__('Select the current level')}
          submitLabel={__('Update the current level')}
          onLevelSelect={async (levelId: string | null) => {
            await performUpdate({ levelType: 'current', levelId });
            setModalIsActive(false);
          }}
          currentSkillsLevel={careerLevel?.skillsLevel}
        />
      )}
    </>
  );
};

export default CurrentCareerLevelManagementBox;
