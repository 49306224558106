import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import type { Locale, Session, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';
import type { ReduxStore } from 'redux/reducers';

import 'styles/index.css';
import 'styles/tailwind.css';

import { __ } from 'helpers/i18n';
import { setLocale } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import queryString from 'helpers/queryString';
import confirmAsync from 'helpers/react/confirmAsync';
import { trackPage } from 'helpers/tracking';

import { registerOnCredentialsChange } from 'lib/api/credentials';
import { errorNotice, successNotice } from 'redux/actions/application';
import { setUserIdentityForExternalServices } from 'redux/actions/resources';
import { signInFromLocalStorage } from 'redux/actions/resources/user';
import { getCurrentSession } from 'redux/reducers';

type Props = {
  render: (isInitialized: boolean, session?: Session | null) => ReactNode;
};

type AfterConnectProps = {
  onInitialize: () => Promise<any>;
  session: Session | undefined | null;
  onLocaleChange: (newLocale: Locale) => void;
  onUserChange: (session: Session) => void;
  onCredentialsChange: () => void;
} & Props;

type State = {
  isInitialized: boolean;
};

class App extends React.Component<AfterConnectProps, State> {
  state = {
    isInitialized: false,
  };

  async componentDidMount() {
    try {
      registerOnCredentialsChange(this.props.onCredentialsChange);

      await this.props.onInitialize();
    } finally {
      this.setState({ isInitialized: true });
    }
  }

  componentDidUpdate(previousProps: AfterConnectProps) {
    const { session, onLocaleChange, onUserChange } = this.props;

    let previousUser: User | null = null;
    let currentUser: User | null = null;

    if (session) {
      currentUser = session.user;
    }

    if (previousProps.session) {
      previousUser = previousProps.session.user;
    }

    const previousLocale = previousUser ? previousUser.locale : null;
    if (
      currentUser &&
      currentUser.locale &&
      currentUser.locale !== previousLocale
    ) {
      onLocaleChange(currentUser.locale);
    }

    const previousUserId = previousUser ? previousUser.id : null;
    if (currentUser && currentUser.id !== previousUserId) {
      invariant(session, 'Session must be defined if a user is set');
      onUserChange(session);
    }
  }

  render() {
    const { session, render } = this.props;
    const { isInitialized } = this.state;

    return render(isInitialized, session);
  }
}

function mapStateToProps(state: ReduxStore) {
  return {
    session: getCurrentSession(state),
  };
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    onInitialize: async () => {
      try {
        await dispatch(signInFromLocalStorage());
      } catch (e: any) {
        if (e.response && e.response.status === 500) {
          window.location.href = '/500';
        } else {
          throw e;
        }
      } finally {
        trackPage();
      }

      const query = queryString.parse(window.location.search);
      if (!!query.error) {
        dispatch(errorNotice(query.error as string));
      } else if (!!query.success) {
        dispatch(successNotice(query.success as string));
      }
    },
    onLocaleChange: newLocale => setLocale(newLocale),
    onUserChange: newSession => setUserIdentityForExternalServices(newSession),
    onCredentialsChange: async () => {
      await confirmAsync(
        __('Logged-out'),
        __(
          'It seems that you have logged out or changed your user in another tab'
        ),
        {
          hideCancel: true,
          nonEscapable: true,
        }
      );

      window.location.reload();
    },
  };
}

// @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
export default connect(
  mapStateToProps,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(App) as React.ComponentType<Props>;
