/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import type { MouseEvent, ReactNode } from 'react';

import classNames from 'helpers/classNames';

type Props = {
  onClick: ((e: MouseEvent) => void | Promise<void>) | undefined | null;
  onMouseDown?: () => void | Promise<void>;
  disabled?: boolean;
  className?: string | null;
  style?: React.CSSProperties;
  onKeyDown?: React.KeyboardEventHandler<HTMLAnchorElement>;
  tabIndex?: number;
  children: ReactNode | undefined | null;
};

export default function LinkWithPromise({
  onClick,
  onMouseDown,
  disabled,
  className,
  style,
  onKeyDown,
  tabIndex,
  children,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  // Should fix "Can't perform a React state update on an unmounted component" React error
  useEffect(() => () => setIsLoading(false), []);

  const handleMouseDown = async (_e: MouseEvent<HTMLAnchorElement>) => {
    if (!onMouseDown) return;

    setIsLoading(true);
    try {
      await onMouseDown();
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async (e: MouseEvent<HTMLAnchorElement>) => {
    if (!onClick) return;

    setIsLoading(true);
    try {
      await onClick(e);
    } finally {
      setIsLoading(false);
    }
  };

  const linkClasseNames = classNames(className, {
    disabled: isLoading || disabled,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <a
      className={linkClasseNames}
      // @ts-ignore TSFIXME: disabled is not a valid prop to <a>, it is working because we have custom CSS
      disabled={isLoading || disabled}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      style={style}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
    >
      {children}
    </a>
  );
}
