import React, { ReactNode, useEffect, useState } from 'react';

import { useIsMobile } from 'helpers/hooks';
import {
  makeDocumentNonScrollable,
  makeDocumentScrollable,
} from 'helpers/scrollable';

import {
  Column,
  Columns,
  ErrorBoundary,
  MobileShowPanelButton,
  MobileSlidingSidebar,
  SlidingSidebar,
} from 'components';

type Props = {
  sidebar: ReactNode;
  content: ReactNode;
  topbar: ReactNode;
  userPanel?: ReactNode;
  userAvatarUrl?: string;
};

const ReviewLayout: React.FC<Props> = ({
  sidebar,
  content,
  topbar,
  userPanel,
  userAvatarUrl,
}) => {
  useEffect(() => {
    makeDocumentNonScrollable();
    return () => {
      makeDocumentScrollable();
    };
  }, []);

  const [showMobileSlidingSidebar, setShowMobileSlidingSidebar] =
    useState<boolean>(false);
  const toggleMobileSlidingSidebar = () => {
    setShowMobileSlidingSidebar(!showMobileSlidingSidebar);
  };

  const isMobile = useIsMobile();

  return (
    <div className="review-content !h-screen">
      <Columns className="h-full">
        <Column className="p-0 flex flex-col h-full">
          {topbar}
          <div className="form-content-wrapper flex-1 relative overflow-y-auto">
            {isMobile && userPanel && (
              <>
                <div className="flex justify-end mb-2">
                  <MobileShowPanelButton
                    toggleSidebar={toggleMobileSlidingSidebar}
                    showMobileSlidingSidebar={showMobileSlidingSidebar}
                  />
                </div>
                <MobileSlidingSidebar
                  showMobileSlidingSidebar={showMobileSlidingSidebar}
                  toggleSidebar={toggleMobileSlidingSidebar}
                >
                  {userPanel}
                </MobileSlidingSidebar>
              </>
            )}
            <ErrorBoundary>{content}</ErrorBoundary>
          </div>
        </Column>
        <Column className="review-sidebar border-y-0 border-r-0 border-l border-solid border-text-lightest/60">
          <ErrorBoundary>{sidebar}</ErrorBoundary>
          {!isMobile && userPanel && (
            <SlidingSidebar avatarUrl={userAvatarUrl}>
              {userPanel}
            </SlidingSidebar>
          )}
        </Column>
      </Columns>
    </div>
  );
};

export default ReviewLayout;
