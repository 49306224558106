import React from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader, PageTitle } from 'components/index';

import MyList from './MyList';

const ChatRooms = () => (
  <>
    <PageTitle title={[__('Surveys'), __('My conversations')]} />
    <PageHeader title={__('My conversations')} />

    <ContentContainer>
      <MyList additionalQueryParams={{ sort: { lastMessageAt: 'desc' } }} />
    </ContentContainer>
  </>
);

export default ChatRooms;
