import React, { type CSSProperties } from 'react';

import classNames from 'helpers/classNames';

import { Flex } from 'components';

import AvatarImage from './Image';

export type AvatarSize =
  | 'tiny'
  | 'smaller'
  | 'small'
  | 'small-lg'
  | 'normal'
  | 'large';

type Props = {
  url: string | undefined | null;
  children?: React.ReactNode;
  decoration?: React.ReactNode;
  size: AvatarSize;
  style?: CSSProperties;
  className?: string;
  avatarImageClassName?: string;
  avatarChildrenClassName?: string;
  testClassName?: string;
  isSquared?: boolean;
  isDisabled?: boolean;
  canUpload?: boolean;
  onUpload?: (base64Image: string) => Promise<void>;
  onDelete?: () => Promise<void>;
};

function Avatar({
  url,
  children,
  decoration,
  size,
  style,
  className,
  avatarImageClassName,
  avatarChildrenClassName,
  testClassName,
  isSquared,
  isDisabled,
  canUpload,
  onUpload,
  onDelete,
}: Props) {
  return (
    <Flex
      className={classNames(
        'avatar',
        `is-${size}`,
        {
          'with-decoration': !!decoration,
          'is-squared': isSquared,
          'is-disabled': isDisabled,
        },
        className,
        testClassName
      )}
      style={style}
      verticalAlign
    >
      <AvatarImage
        url={url}
        canUpload={canUpload}
        onUpload={onUpload}
        onDelete={onDelete}
        className={avatarImageClassName}
      />

      {!!decoration && <Flex className="avatar-decoration">{decoration}</Flex>}

      {!!children && (
        <Flex
          className={classNames('avatar-children', avatarChildrenClassName)}
          direction="column"
          verticalAlign
        >
          {children}
        </Flex>
      )}
    </Flex>
  );
}

Avatar.defaultProps = {
  size: 'small',
};

export { default as AvatarTitle } from './Title';
export { default as AvatarSubTitle } from './SubTitle';
export default Avatar;
