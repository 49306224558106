import React from 'react';
import { connect } from 'react-redux';

import type { ReviewedDimensionBlock } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { post } from 'redux/actions/api';

import QuestionBlock from 'scenes/components/review/QuestionBlock';

import FeedbackZone from '../FeedbackZone';

type Props = {
  reviewId: string;
  reviewedDimensionBlock: ReviewedDimensionBlock;
  canFillContent: boolean;
  isClosed: boolean;
};

type AfterConnectProps = Props & {
  persistAction: (
    ratingValue?: string | null,
    comment?: string | null
  ) => Promise<void>;
};

function ReviewedDimensionBlockComponent({
  reviewedDimensionBlock,
  persistAction,
  canFillContent,
  isClosed,
}: AfterConnectProps) {
  const { title, description, dimension, rating, ratingOptions } =
    reviewedDimensionBlock;

  return (
    <QuestionBlock
      testClassName="test-question-block"
      title={title}
      description={description}
      tag={dimension.label}
      withRichText
    >
      <FeedbackZone
        ratingValue={rating?.ratingValue}
        ratingOptions={ratingOptions}
        comment={rating?.comment}
        readOnly={isClosed || !canFillContent}
        persistAction={persistAction}
      />
    </QuestionBlock>
  );
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { reviewId, reviewedDimensionBlock }: Props
) {
  const dimensionId = reviewedDimensionBlock.dimension.id;

  return {
    persistAction: async (
      ratingValue?: string | null,
      comment?: string | null
    ) =>
      dispatch(
        post('people_review_ratings', {
          reviewId,
          dimensionId,
          rating: { ratingValue, comment },
        })
      ),
  };
}

export default connect(
  null,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(ReviewedDimensionBlockComponent);
