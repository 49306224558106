import React, { createContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useCurrentOrganization } from 'helpers/hooks';

import ReviewCycleIndex from './Index';
import ReviewCycleManage from './Manage';
import Edit from './Setup/Edit';
import New from './Setup/New';

type DataContextType = {
  withUserMultiFilters: boolean;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

const ReviewCyclesRoutes = () => {
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  return (
    <DataContext.Provider value={{ withUserMultiFilters }}>
      <Routes>
        <Route index element={<ReviewCycleIndex />} />
        <Route path="new" element={<New />} />
        <Route path=":id/edit" element={<Edit />} />
        <Route path=":id/*" element={<ReviewCycleManage />} />
      </Routes>
    </DataContext.Provider>
  );
};

export default ReviewCyclesRoutes;
