import { memoize } from 'lodash';
import React from 'react';

import type {
  BlockContent,
  Evaluation,
  ObjectivesModuleBlockContent,
  OneOnOneUserReview,
} from 'models';
import type { ReactNode } from 'react';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { StrictlySanitizedHtml, Text } from 'components';

import { successMessage as reviewReleasedSuccessMessage } from 'scenes/review/Review/ReleaseReviewModal';

import SubmitFormModal from '../components/SubmitFormModal';

type Props = {
  isActive: boolean;
  onShare: () => Promise<void>;
  onClose: () => void;
  evaluation: Evaluation;
};

// COMMON METHODS

function evaluationSharedSuccessMessage(evaluation: Evaluation) {
  const review = evaluation && evaluation.userReview;
  const responsibleName =
    review && review.responsible && review.responsible.firstName;

  return __('Your comments have been shared with %1.', responsibleName || '');
}

function successMessage(evaluation: Evaluation) {
  const { userReview } = evaluation;

  if (userReview && userReview.isReleased) {
    // @ts-ignore: FIXME: OneOnOneUserReview expected but got UserReview
    return reviewReleasedSuccessMessage(userReview);
  }

  return evaluationSharedSuccessMessage(evaluation);
}

function description(evaluation: Evaluation) {
  const { userReview } = evaluation;
  const responsible = userReview && userReview.responsible;

  return (
    <>
      <Text>{__('Once shared, your comments will be locked.')}</Text>
      {responsible && (
        <Text>
          {' '}
          {__(
            '%1 will be able to re-open your evaluation if needed.',
            responsible.fullName
          )}
        </Text>
      )}
    </>
  );
}

// CUSTOM PROPS, USED IN CASE OF ADDITIONAL REVIEWER

const additionalReviewerEvaluationStatus = memoize(
  (userReview: OneOnOneUserReview) =>
    userReview.evaluations.find(
      evaluation => evaluation.reviewerRole === 'additional_reviewer'
    )?.status
);

const draftObjectivesWarning = () => {
  return (
    <StrictlySanitizedHtml
      html={__(
        '<b>Attention!</b> You are about to share your feedback <b>and there are still unpublished objectives. This will be published with the review,</b> if you do not wish to publish them, please delete them.'
      )}
    />
  );
};

const additionnalReviewerWarning = (additionalReviewerName: string) => {
  return (
    <>
      <Text weight="bold">
        {__('%1 has not shared their comments.', additionalReviewerName)}
      </Text>
      <Text>
        {__(
          "If you share your comments, %1 won't have access to this review anymore and won't be able to share their comments with the reviewee.",
          <b>{additionalReviewerName}</b>
        )}
      </Text>
    </>
  );
};

function additionalReviewerDescription(userReview: OneOnOneUserReview) {
  const responsible = userReview && userReview.responsible;

  return (
    <>
      <Text>{__('Once shared, your comments will be locked.')}</Text>
      {responsible && (
        <Text>
          {' '}
          {__(
            "%1 will be able to re-open the reviewers' review if needed.",
            responsible.fullName
          )}
        </Text>
      )}
    </>
  );
}

function additionalReviewerSubtitle(
  evaluation: Evaluation,
  userReview: OneOnOneUserReview
) {
  const responsible = userReview && userReview.responsible;

  if (
    additionalReviewerEvaluationStatus(userReview) === 'shared' &&
    evaluation.reviewer.id === userReview.responsible?.id
  ) {
    return __(
      'You are about to share your comments and those of %1.',
      userReview.additionalReviewer?.fullName
    );
  }

  if (evaluation.reviewerRole === 'additional_reviewer') {
    return __(
      'You are about to share your comments to %1.',
      responsible && responsible.fullName
    );
  }

  return null;
}

function additionalReviewerTitle(userReview: OneOnOneUserReview) {
  if (additionalReviewerEvaluationStatus(userReview) === 'shared') {
    return __('Share comments of reviewers');
  }

  return null;
}

type CustomProps = {
  warningContents?: Array<ReactNode>;
  title?: string;
  subtitle?: string;
  description?: ReactNode;
  withSignButton?: boolean;
};

const computeCustomProps = (
  evaluation: Evaluation,
  withDraftObjectives: boolean,
  currentUserId
) => {
  const { userReview } = evaluation;

  if (!userReview || userReview.interactionType !== 'one_on_one') {
    return null;
  }

  let props: CustomProps = {};

  if (
    userReview?.signatureModuleEnabled &&
    currentUserId !== userReview?.user.id &&
    userReview.isReleased
  )
    props.withSignButton = true;

  if (!userReview.additionalReviewer && !withDraftObjectives) return props;

  const warningContents = () => {
    const contents: ReactNode[] = [];
    if (
      !!userReview.additionalReviewer &&
      userReview.responsible?.id === evaluation.reviewer.id &&
      additionalReviewerEvaluationStatus(userReview) !== 'shared'
    ) {
      contents.push(
        additionnalReviewerWarning(userReview.additionalReviewer.fullName)
      );
    }
    if (withDraftObjectives) {
      contents.push(draftObjectivesWarning());
    }
    return contents;
  };

  props.warningContents = warningContents();

  const customTitle = additionalReviewerTitle(userReview);
  const customDescription = additionalReviewerDescription(userReview);
  const customSubtitle = additionalReviewerSubtitle(evaluation, userReview);

  if (customTitle) props.title = customTitle;
  if (customSubtitle) props.subtitle = customSubtitle;
  if (customDescription) {
    props.description = <Text align="centered">{customDescription}</Text>;
  }

  return props;
};

export default function ShareEvaluationModal({
  isActive,
  onShare,
  onClose,
  evaluation,
}: Props) {
  const activeUser = useActiveUser();
  const { formContent } = evaluation;

  const customProps = () => {
    if (!formContent) {
      return computeCustomProps(evaluation, false, activeUser.id);
    } else {
      const objectiveBlocks = formContent.blocks.filter(
        (block: BlockContent) => block.blockType === 'objectives_module'
      ) as ObjectivesModuleBlockContent[];

      const draftObjectives = objectiveBlocks
        .map((block: ObjectivesModuleBlockContent) =>
          block.feedbackableItems.filter(item => !item.item.published)
        )
        .flat();

      const draftObjectivesToBePublished =
        evaluation.reviewer.id === evaluation.userReview?.reviewer?.id &&
        draftObjectives.length > 0;

      return computeCustomProps(
        evaluation,
        draftObjectivesToBePublished,
        activeUser.id
      );
    }
  };

  return (
    <SubmitFormModal
      isActive={isActive}
      onShare={onShare}
      onClose={onClose}
      confirmLabel={__('Share my comments')}
      successTitle={__('Done!')}
      successDescription={
        <Text align="centered">{successMessage(evaluation)}</Text>
      }
      title={__('Share your comments')}
      description={<Text align="centered">{description(evaluation)}</Text>}
      {...customProps()}
    />
  );
}
