import React, { useState } from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del } from 'redux/actions/api';

import {
  Button,
  Dropdown,
  DropdownDivider,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  StrictlySanitizedHtml,
} from 'components';

import BulkUpdateParticipantFundingItems from './BulkUpdateParticipantFundingItems';

type Props = {
  sessionId: string;
  periodSlug: string;
  selectedParticipantIds: Set<string>;
  resetSelectedParticipantIds: () => void;
  refetchParticipants: () => Promise<void>;
  defaultCurrency: string;
};

const BulkActions = ({
  sessionId,
  periodSlug,
  selectedParticipantIds,
  resetSelectedParticipantIds,
  refetchParticipants,
  defaultCurrency,
}: Props) => {
  const [bulkUpdateModalIsActive, setBulkUpdateModalIsActive] = useState(false);
  const dispatch = useAppDispatch();
  const bulkDeleteFundingItems = () =>
    confirmAsync(
      n__(
        'Delete the funding of %1 participant',
        'Delete the funding of %1 participants',
        selectedParticipantIds.size
      ),
      <p>
        {n__(
          'This action will delete the funding of the %1 selected participant.',
          'This action will delete the funding of the %1 selected participants.',
          selectedParticipantIds.size
        )}
        <br />
        <StrictlySanitizedHtml
          html={__(
            'The <b>related costs and funding sources will be deleted</b>, having an impact on the budget. '
          )}
        />
        <br />
        <br />
        {n__(
          'Are you sure you want to delete the funding of the %1 selected participant?',
          'Are you sure you want to delete the funding of the %1 selected participants?',
          selectedParticipantIds.size
        )}
      </p>,
      {
        confirmLabel: __('Delete funding'),
        isDanger: true,
        onConfirm: async () => {
          await dispatch(
            del(
              `training/sessions/${sessionId}/participants/bulk_destroy_funding_items`,
              { participantIds: Array.from(selectedParticipantIds) }
            )
          );
          resetSelectedParticipantIds();
          return refetchParticipants();
        },
      }
    );

  return (
    <>
      <Dropdown align="left">
        <DropdownTrigger>
          <Button color="secondary" className="ml-0">
            {__('Actions')}
            <Icon name="keyboard_arrow_down" />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownMenuItem onClick={() => setBulkUpdateModalIsActive(true)}>
            {__('Change funding')}
          </DropdownMenuItem>
          <DropdownDivider />
          <DropdownMenuItem onClick={bulkDeleteFundingItems} isDanger>
            {__('Delete funding')}
          </DropdownMenuItem>
        </DropdownMenu>
      </Dropdown>
      {bulkUpdateModalIsActive && (
        <BulkUpdateParticipantFundingItems
          sessionId={sessionId}
          defaultCurrency={defaultCurrency}
          periodSlug={periodSlug}
          participantIds={Array.from(selectedParticipantIds)}
          onClose={() => setBulkUpdateModalIsActive(false)}
          onAfterUpdate={() => {
            resetSelectedParticipantIds();
            return refetchParticipants();
          }}
        />
      )}
    </>
  );
};

export default BulkActions;
