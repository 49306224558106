import { useCallback, useMemo } from 'react';

type ObjectState = Record<string, unknown>;

export type UpdateAttributeFunction<TObjectState extends ObjectState> = <
  TObjectAttribute extends keyof TObjectState
>(
  field: TObjectAttribute
) => (value: TObjectState[TObjectAttribute]) => void;

type HookReturnType<TObjectState extends ObjectState> =
  UpdateAttributeFunction<TObjectState>;

/**
 * @example
 * const Person = ({ value, onChange }) => {
 *   const updateAttribute = useObjectMutation(value, onChange);
 *   return (
 *     <input value={value.name} onChange={updateAttribute('name')} />
 *     <input value={value.age} onChange={updateAttribute('age')} />
 *   );
 * };
 */
const useObjectMutation = <TObjectState extends ObjectState>(
  obj: TObjectState,
  onChange: (obj: TObjectState) => void
): HookReturnType<TObjectState> => {
  const newObj = useMemo(() => ({ ...obj }), [obj]);
  return useCallback(
    <FormField extends keyof TObjectState>(field: FormField) =>
      (value: TObjectState[FormField]) => {
        newObj[field] = value;
        onChange(newObj);
      },
    [newObj, onChange]
  );
};

export default useObjectMutation;
