import React, { useState } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { Occupation, User } from 'models';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { EmptyStateWithIcon } from 'components';
import { DesignSystem, FetchContainer } from 'components';

import OccupationHistory from './OccupationHistory';

type Props = {
  user: User;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    occupations: Array<Occupation>;
  };

const ProfessionalBackground = ({
  occupations,
  hasError,
  isFetching,
}: AfterDataLoaderProps) => {
  const [blurSalary, setBlurSalary] = useState(true);

  const showBlurButton =
    !!occupations &&
    occupations.some(occupation => !!occupation.remunerations?.length);

  return (
    <DesignSystem version={2}>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() =>
          occupations.length === 0 ? (
            <EmptyStateWithIcon
              inBox={false}
              iconName="history_edu"
              title={__('No professional background specified')}
              className="mt-4"
            />
          ) : (
            <OccupationHistory
              occupations={occupations}
              showBlurButton={showBlurButton}
              blurSalary={blurSalary}
              setBlurSalary={setBlurSalary}
            />
          )
        }
      />
    </DesignSystem>
  );
};

export default newDataLoader({
  fetch: ({ user }: Props) => get(`users/${user.id}/occupations`),
  hydrate: {
    occupations: { remunerations: {} },
  },
})(ProfessionalBackground) as React.ComponentType<Props>;
