import React from 'react';
import { compose } from 'redux';

import { SurveyCampaignPreview } from 'models';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { withMatchParams } from 'components/HOCs/withMatchParams';

import SurveyContent from '../Survey/SurveyContent';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    campaign: SurveyCampaignPreview;
  };

const CampaignPreview = ({
  isFetching,
  hasError,
  campaign,
}: AfterDataLoaderProps) => {
  return (
    <SurveyContent
      isFetching={isFetching}
      hasError={hasError}
      campaign={campaign}
    />
  );
};

export default compose(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`survey/campaigns/${match.id}/preview`),
    hydrate: {
      campaign: {
        form: { steps: { question: {}, instruction: {} } },
      },
    },
  })
)(CampaignPreview) as React.ComponentType<{}>;
