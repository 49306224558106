import { cloneDeep } from 'lodash';
import React, { ReactNode, useContext, useState } from 'react';

import { useObjectMutation } from 'helpers/hooks';
import { __, p__ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import {
  Button,
  Divider,
  Input,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';
import CurrencySelect from 'components/formElements/advancedElements/CurrencySelect';

import UserPicker from 'scenes/components/UserPicker';

import { BudgetFormContext } from '../../Form';
import { FormBudgetEnvelope } from '../../types';
import { generateNewEnvelope } from '../helpers/generateNewEnvelope';
import BudgetItemsList from '../shared/BudgetItemsList';

type ModalFieldProps = {
  label: string;
  description?: string;
  children: ReactNode;
};

const ModalField = ({ label, description, children }: ModalFieldProps) => (
  <div>
    <Text preset="14s6">
      <Label>{label}</Label>
    </Text>
    {description && (
      <Text color="light" preset="14s6" className="block my-0 mt-2">
        {description}
      </Text>
    )}
    <div className="mt-4">{children}</div>
  </div>
);

type Props = {
  envelope: FormBudgetEnvelope | null;
  onConfirm: (envelope: FormBudgetEnvelope) => void;
  onCancel: () => void;
};

const EnvelopeModal = ({
  envelope: envelopeProp,
  onConfirm,
  onCancel,
}: Props) => {
  const { trainingPeriod, sumBudgetItems } = useContext(BudgetFormContext);
  const [envelope, setEnvelope] = useState(
    envelopeProp ? cloneDeep(envelopeProp) : generateNewEnvelope(trainingPeriod)
  );
  const updateAttribute = useObjectMutation(envelope, setEnvelope);

  const totalAmountCents = sumBudgetItems(
    envelope.budgetItems,
    envelope.mainCurrency
  );

  const formValid = !!envelope.name;

  return (
    <ModalCard
      isActive={true}
      refreshContentOnOpening={true}
      onClose={onCancel}
      isLarge
    >
      <ModalCardHead>
        <ModalCardTitle>
          {envelopeProp
            ? __('Update a budget envelope')
            : p__('EnvelopeModalTitle', 'Add a budget envelope')}
        </ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody className="flex flex-col gap-4">
        <ModalField label={__('Define envelope name')}>
          <Input value={envelope.name} onChange={updateAttribute('name')} />
        </ModalField>
        <ModalField
          label={__('Envelope responsible')}
          description={__(
            'Envelope responsibles can view and update the budget envelope.'
          )}
        >
          <UserPicker
            isMulti
            value={envelope.responsibles}
            onChange={responsibles => {
              if (Array.isArray(responsibles))
                updateAttribute('responsibles')(responsibles);
            }}
            fetchParams={{
              with_budget_show_permission: true,
            }}
          />
        </ModalField>
        <ModalField
          label={__('Currency')}
          description={__(
            'Currency in which the envelope budget will be calculated.'
          )}
        >
          <CurrencySelect
            selectedCurrency={envelope.mainCurrency}
            onCurrencyChange={updateAttribute('mainCurrency')}
          />
        </ModalField>
        <ModalField label={__('Define your funding sources')}>
          <BudgetItemsList
            budgetItems={envelope.budgetItems}
            onChange={updateAttribute('budgetItems')}
          />
        </ModalField>
        <Divider className="my-0" />
        <ModalField
          label={__('Envelope budget')}
          description={__(
            'The budget for the envelope is calculated on the basis of funding sources'
          )}
        >
          <Text preset="16bs5.5">
            {formatMoney(totalAmountCents, envelope.mainCurrency)}
          </Text>
        </ModalField>
      </ModalCardBody>

      <ModalCardFooter>
        <Button color="secondary" onClick={onCancel}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={() => onConfirm(envelope)}
          disabled={!formValid}
        >
          {envelopeProp
            ? __('Update the budget envelope')
            : p__('EnvelopeModalButton', 'Add a budget envelope')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default EnvelopeModal;
