import moment from 'moment';

import { __, getCurrentLocale, n__ } from 'helpers/i18n';

/**
 * @returns "2024-08-21"
 */
export const dateToDefaultDateStringFormat = (
  date: Date | null | undefined
): string | null | undefined =>
  !!date ? moment(date).format().slice(0, 10) : null;

/**
 * @returns "2024-08-21T09:00:00+02:00"
 */
export const dateToDefaultDateTimeStringFormat = (
  date: Date | null
): string | null => (!!date ? moment(date).format() : null);

export const dateFromDefaultDateString = (date: string | null) =>
  !date ? null : new Date(date);

export const dateWithoutTimeFromDateString = (date: string) =>
  moment(date).startOf('day').toDate();

const formatDate = (
  date: Date | null,
  format: string | null,
  defaultLabel?: string | null
) => (!!date ? moment(date).format(format || 'Y-M-D') : defaultLabel || '');

const toDate = (date: string | Date | null | undefined) => {
  return !date ? null : typeof date == 'string' ? new Date(date) : date;
};

export const formatDateToLocaleMonthAndYear = (date: Date) =>
  date.toLocaleString(getCurrentLocale(), {
    day: undefined,
    month: 'long',
    year: 'numeric',
  });

// label from startDate to endDate
export const startToEndDateLabel = (
  startDate: string | Date | null | undefined,
  endDate: string | Date | null | undefined,
  options?: {
    defaultLabel?: string; // label to use if no dates. Dash is used by default,
    format?: string; // date format you want to use,
    fromToLabelsAlwaysVisible?: boolean;
  }
) => {
  const defaultOptions = {
    defaultLabel: '-',
    format: 'D/M/Y',
    fromToLabelsAlwaysVisible: false,
  } as const;
  const { defaultLabel, format, fromToLabelsAlwaysVisible } = {
    ...defaultOptions,
    ...options,
  } as const;

  startDate = toDate(startDate);
  endDate = toDate(endDate);

  if (fromToLabelsAlwaysVisible) {
    return __(
      'From %1 to %2',
      formatDate(startDate, format, defaultLabel),
      formatDate(endDate, format, defaultLabel)
    );
  }

  if (!startDate && !endDate) return defaultLabel;

  if (!endDate && !!startDate) {
    return formatDate(startDate, format);
  }

  return __(
    '%1 to %2',
    formatDate(startDate, format),
    formatDate(endDate, format)
  );
};

/**
 * @param start_date in Date format
 * @param end_date in Date format
 * @returns "start_date to end_date" in with only the month and year displayed. Ex: "January 2020 to February 2021"
 */
export const startToEndDateInMonths = (
  startDate: Date,
  endDate: Date | undefined | null
) => {
  if (!endDate) {
    return __('From %1', formatDateToLocaleMonthAndYear(startDate));
  }

  return __(
    'from_to_in_months',
    formatDateToLocaleMonthAndYear(startDate),
    formatDateToLocaleMonthAndYear(endDate)
  );
};

export const guessTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || '';
};

export const computeTimesForFiltering = (
  time: Date,
  rawDate: string | null | undefined,
  rawReferenceDate: string
): {
  referenceTime: number;
  currentTime: number;
} => {
  const timeAsDate = new Date(time);
  const referenceDate = new Date(rawReferenceDate);

  const date = rawDate
    ? moment(rawDate)
        .set({ h: timeAsDate.getHours(), m: timeAsDate.getMinutes() })
        .toDate()
    : timeAsDate;

  return {
    referenceTime: referenceDate.getTime(),
    currentTime: date.getTime(),
  };
};

/**
 * @param date ex: "2024-02-26"
 */
export const isToday = (date: string) => {
  const today = new Date().toISOString().split('T')[0];
  return date === today;
};

export const timeBetweenDates = (startDate: Date, endDate: Date) => {
  let yearDiff = moment(endDate).diff(moment(startDate), 'years');
  let monthDiff =
    Math.ceil(moment(endDate).diff(moment(startDate), 'months', true)) -
    12 * yearDiff;

  if (monthDiff === 12) {
    yearDiff++;
    monthDiff = 0;
  }

  if (yearDiff === 0) {
    return n__('%1 month', '%1 months', monthDiff);
  }
  if (yearDiff === 1) {
    return n__('1 year and %1 month', '1 year and %1 months', monthDiff);
  }
  return n__(
    '%2 years and %1 month',
    '%2 years and %1 months',
    monthDiff,
    yearDiff
  );
};

export const timeSinceDate = (date: Date) => timeBetweenDates(date, new Date());
