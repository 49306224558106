import React, { ReactElement } from 'react';

import type { GroupedMenuItems } from 'components';
import type { ReviewCycle, UserReview } from 'models';

import can from 'helpers/can';
import { pathToHome, pathToNewReviewResults } from 'helpers/paths';

import {
  ManagePeersAction,
  ReleaseAction,
  RemindPeersFeedbackAction,
  RemindPeersNominationAction,
  RemindSelfAssessmentAction,
  ReopenPeersFeedbackAction,
  UnreleaseAction,
} from 'scenes/components/userReview/360/actions';
import { UpdateResponsibleAction } from 'scenes/components/userReview/actions';
import { RemoveParticipantAction } from 'scenes/components/userReview/common/actions';
import {
  ChangeReviewTemplateAction,
  SendReminder,
  SendSignatureReminder,
  ShareEvaluation,
  UnshareEvaluation,
  UpdateAdditionalReviewerAction,
} from 'scenes/components/userReview/oneOnOne/actions';

const redirectUser = (userReview: UserReview) => {
  const shouldRedirectToUserReview = can({
    perform: 'redirect_to_user_reviews_after_responsible_update',
    on: userReview,
  });

  const goToUrl = shouldRedirectToUserReview
    ? pathToNewReviewResults(userReview.id)
    : pathToHome();

  window.location.replace(goToUrl);
};

type Props = {
  userReview: UserReview;
  reviewCycle: ReviewCycle;
  refreshReviewContent: () => Promise<void>;
};

const getAvailableActions = ({
  userReview,
  reviewCycle,
  refreshReviewContent,
}: Props): GroupedMenuItems => {
  const itemGroups: {
    remind: ReactElement[];
    unshare: ReactElement[];
    other: ReactElement[];
    remove: ReactElement[];
  } = {
    remind: [],
    unshare: [],
    other: [],
    remove: [],
  };

  if (
    userReview.interactionType === '360' &&
    reviewCycle.interactionType === '360'
  ) {
    /* Remind */
    if (can({ perform: 'remind_peers_nomination', on: userReview })) {
      itemGroups.remind.push(
        <RemindPeersNominationAction
          userReview={userReview}
          key="remindPeersNominationAction"
        />
      );
    }
    if (can({ perform: 'remind_self_assessment', on: userReview })) {
      itemGroups.remind.push(
        <RemindSelfAssessmentAction
          userReview={userReview}
          key="remindSelfAssessmentAction"
        />
      );
    }
    if (can({ perform: 'remind_peers_feedback', on: userReview })) {
      itemGroups.remind.push(
        <RemindPeersFeedbackAction
          userReview={userReview}
          key="remindPeersFeedbackAction"
        />
      );
    }
    /* Unshare */
    /* Other */
    if (can({ perform: 'unrelease', on: userReview })) {
      itemGroups.other.push(
        <UnreleaseAction
          key="unreleaseAction"
          userReview={userReview}
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (
      can({ perform: 'nominate_peers', on: userReview }) ||
      can({ perform: 'change_peers_after_validation', on: userReview }) ||
      can({ perform: 'manage_peers', on: userReview }) ||
      can({ perform: 'validate_peers', on: userReview })
    ) {
      itemGroups.other.push(
        <ManagePeersAction
          key="managePeersAction"
          userReview={userReview}
          reviewCycle={reviewCycle}
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (can({ perform: 'update_responsible', on: userReview })) {
      itemGroups.other.push(
        <UpdateResponsibleAction
          userReview={userReview}
          reviewCycle={reviewCycle}
          onAfterAction={refreshReviewContent}
          key="updateResponsibleAction"
        />
      );
    }
    if (
      can({
        perform: 'show_secondary_action_in_review_to_release',
        on: userReview,
      })
    ) {
      itemGroups.other.push(
        <ReleaseAction
          onAfterAction={refreshReviewContent}
          userReview={userReview}
          key="releaseAction"
        />
      );
    }
    if (can({ perform: 'reopen_peers_feedback', on: userReview })) {
      itemGroups.other.push(
        <ReopenPeersFeedbackAction
          key="reopenPeersFeedback"
          userReview={userReview}
          onAfterAction={refreshReviewContent}
        />
      );
    }
    /* Remove */
    if (can({ perform: 'remove_participant', on: userReview })) {
      itemGroups.remove.push(
        <RemoveParticipantAction
          key="removeParticipantAction"
          userReview={userReview}
          reviewCycle={reviewCycle}
        />
      );
    }
  } else if (
    userReview.interactionType === 'one_on_one' &&
    reviewCycle.interactionType === 'one_on_one'
  ) {
    /* Remind */
    if (can({ perform: 'remind_self_assessment', on: userReview })) {
      itemGroups.remind.push(
        <SendReminder
          userReview={userReview}
          target="reviewee"
          key="sendReminderToReviewee"
        />
      );
    }
    if (can({ perform: 'remind_reviewer_evaluation', on: userReview })) {
      itemGroups.remind.push(
        <SendReminder
          userReview={userReview}
          target="reviewer"
          key="sendReminderToReviewer"
        />
      );
    }
    if (can({ perform: 'remind_reviewee_signature', on: userReview })) {
      itemGroups.remind.push(
        <SendSignatureReminder
          userReview={userReview}
          target="reviewee"
          key="sendSignatureReminderToReviewee"
        />
      );
    }
    if (can({ perform: 'remind_reviewer_signature', on: userReview })) {
      itemGroups.remind.push(
        <SendSignatureReminder
          userReview={userReview}
          target="reviewer"
          key="sendSignatureReminderToReviewer"
        />
      );
    }
    /* Unshare */
    if (can({ perform: 'unshare_self_assessment', on: userReview })) {
      itemGroups.unshare.push(
        <UnshareEvaluation
          userReview={userReview}
          target="reviewee"
          key="unshareRevieweeEvaluation"
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (can({ perform: 'unshare_main_reviewer_evaluation', on: userReview })) {
      itemGroups.unshare.push(
        <UnshareEvaluation
          userReview={userReview}
          target="reviewer"
          key="unshareReviewerEvaluation"
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (
      can({ perform: 'unshare_additional_reviewer_evaluation', on: userReview })
    ) {
      itemGroups.unshare.push(
        <UnshareEvaluation
          userReview={userReview}
          target="additional_reviewer"
          key="unshareAdditionalReviewerEvaluation"
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (can({ perform: 'unshare_reviewers_evaluations', on: userReview })) {
      itemGroups.unshare.push(
        <UnshareEvaluation
          userReview={userReview}
          target="reviewers"
          key="unshareReviewersEvaluation"
          onAfterAction={refreshReviewContent}
        />
      );
    }
    /* Other */
    if (can({ perform: 'update_additional_reviewer', on: userReview })) {
      itemGroups.other.push(
        <UpdateAdditionalReviewerAction
          userReview={userReview}
          key="updateAdditionalReviewerAction"
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (can({ perform: 'share_main_reviewer_evaluation', on: userReview })) {
      itemGroups.other.push(
        <ShareEvaluation
          userReview={userReview}
          target="reviewer"
          key="shareReviewerEvaluation"
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (can({ perform: 'share_reviewers_evaluations', on: userReview })) {
      itemGroups.other.push(
        <ShareEvaluation
          userReview={userReview}
          target="reviewers"
          key="shareReviewersEvaluation"
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (can({ perform: 'share_self_assessment', on: userReview })) {
      itemGroups.other.push(
        <ShareEvaluation
          userReview={userReview}
          target="reviewee"
          key="shareRevieweeEvaluation"
          onAfterAction={refreshReviewContent}
        />
      );
    }
    if (can({ perform: 'update_responsible', on: userReview })) {
      itemGroups.other.push(
        <UpdateResponsibleAction
          userReview={userReview}
          reviewCycle={reviewCycle}
          key="updateResponsibleAction"
          onAfterAction={() => redirectUser(userReview)}
        />
      );
    }
    if (can({ perform: 'change_review_template', on: userReview })) {
      itemGroups.other.push(
        <ChangeReviewTemplateAction
          key="changeReviewTemplateAction"
          userReview={userReview}
          onAfterAction={refreshReviewContent}
        />
      );
    }
    /* Remove */
    if (can({ perform: 'remove_participant', on: userReview })) {
      itemGroups.remove.push(
        <RemoveParticipantAction
          key="removeParticipantAction"
          userReview={userReview}
          reviewCycle={reviewCycle}
        />
      );
    }
  }

  return itemGroups;
};

export default getAvailableActions;
