import { sortBy } from 'lodash';
import React from 'react';

import type { PeopleGroup } from 'models';

import { __ } from 'helpers/i18n';

import { Tag, Text } from 'components';

type Props = {
  peopleGroups: PeopleGroup[];
};

const PeopleGroupTagList = ({ peopleGroups }: Props) => {
  if (peopleGroups.length === 0) {
    return <Text color="grey">{__('Is not assigned to a group')}</Text>;
  }

  return (
    <>
      {sortBy(peopleGroups, pg => pg.position).map(peopleGroup => (
        <Tag
          key={peopleGroup.id}
          className="people-group-tag normal-case my-1 mr-1"
          testClassName="test-people-group-tag"
        >
          <div className="is-text-overflow-ellipsis">{peopleGroup.title}</div>
        </Tag>
      ))}
    </>
  );
};

export default PeopleGroupTagList;
