import React, { ComponentProps } from 'react';
import Skeleton from 'react-loading-skeleton';
import { compose } from 'redux';

import { TrainingCourse } from 'models/TrainingCourse';

import { __ } from 'helpers/i18n';
import { pathToTrainingsCatalog } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  ContentContainer,
  FetchContainer,
  PageHeader,
  PageTitle,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import TrainingRequestModaleButton from '../components/TrainingRequestModalButton';
import CourseContent from './CourseContent';
import CourseMetadata from './CourseMetadata';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    trainingCourse: TrainingCourse;
  };

const Course = ({
  trainingCourse,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const action = (
    <TrainingRequestModaleButton trainingCourse={trainingCourse} />
  );

  const backButton: Partial<ComponentProps<typeof PageHeader>> = {
    withBackButton: true,
    backButtonProps: {
      fallbackTarget: pathToTrainingsCatalog(),
      children: __('Back to training catalog'),
    },
  };

  return (
    <div>
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="alone"
        renderFetching={() => (
          <PageHeader title={<Skeleton width={200} />} {...backButton} />
        )}
        render={() => (
          <>
            <PageTitle title={[__('Trainings'), trainingCourse.name]} />
            <PageHeader
              title={trainingCourse.name}
              actions={action}
              {...backButton}
            />
            <ContentContainer>
              <div className="max-w-[1128px] m-auto flex gap-4 flex-wrap flex-row-reverse items-start">
                <Box className="w-[240px] flex-grow-[1] m-0" size="small">
                  <CourseMetadata {...trainingCourse} />
                </Box>
                <Box className="w-[360px] flex-grow-[3] m-0" size="small">
                  <CourseContent {...trainingCourse} />
                </Box>
              </div>
            </ContentContainer>
          </>
        )}
      />
    </div>
  );
};

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`training/courses/${match.id}`),
    hydrate: {
      trainingCourse: {
        organism: {},
        tags: {},
      },
    },
  })
)(Course);
