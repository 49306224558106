import React from 'react';
import { compose } from 'redux';

import {
  DraftSurveyCampaign,
  SurveyCampaignStepName as StepName,
  SurveyCampaignUpdateParams,
} from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';
import { pathToSurveyCampaigns } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, put } from 'redux/actions/api';

import { FetchContainer, PageHeader, PageTitle } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import Stepper from 'scenes/components/Stepper';

import getSetupStepInfoList from '../helpers/steps';
import CurrentStep from './CurrentStep';
import DestroyButton from './DestroyButton';

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    surveyCampaign: DraftSurveyCampaign;
  };

const EditCampaign = ({
  isFetching,
  hasError,
  surveyCampaign,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const updateCampaign = (
    params: SurveyCampaignUpdateParams,
    options?: { withDefaultErrorHandling: boolean }
  ) => dispatch(put(`survey/campaigns/${surveyCampaign.id}`, params, options));

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <>
            <PageHeader
              title={surveyCampaign.name}
              withBackButton
              backButtonProps={{
                target: pathToSurveyCampaigns(),
                children: __('Back to survey campaigns'),
              }}
              actions={
                can({ perform: 'destroy', on: surveyCampaign }) && (
                  <DestroyButton
                    surveyCampaignId={surveyCampaign.id}
                    surveyCampaignName={surveyCampaign.name}
                  />
                )
              }
            ></PageHeader>
            <PageTitle title={surveyCampaign.name} />

            <Stepper<StepName>
              currentStepIndexFromElement={surveyCampaign.setupStepIndex}
              stepInfoList={getSetupStepInfoList(surveyCampaign)}
              renderChildren={(currentStepInfo, _goToStep, goToNextStep) => (
                <CurrentStep
                  surveyCampaign={surveyCampaign}
                  currentStepInfo={currentStepInfo}
                  onUpdate={updateCampaign}
                  goToNextStep={goToNextStep}
                />
              )}
            />
          </>
        );
      }}
    />
  );
};

export default compose(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`survey/campaigns/${match.id}`),
    hydrate: {
      surveyCampaign: {
        abilities: {},
      },
    },
  })
)(EditCampaign) as React.ComponentType<{}>;
