import moment from 'moment';
import React from 'react';

import { User } from 'models';

import { __ } from 'helpers/i18n';

import { Notification, Text } from 'components';

type Props = { user: User };

const Notifications = ({ user }: Props) => {
  return (
    <>
      {user.isAnonymized && (
        <Notification kind="dark">
          <Text>
            {__(
              'This employee has been anonymized by %1 on %2, %3',
              user.anonymizedBy?.fullName,
              moment(user.anonymizedAt).format('ll'),
              moment(user.anonymizedAt).format('LT')
            )}
          </Text>
        </Notification>
      )}
      {user.previousEmail && (
        <Notification kind="warning">
          {__(
            "We've sent an email to confirm the new address: %1. We will continue to use the old address (%2) until the new one is confirmed.",
            <b>{user.email}</b>,
            <b>{user.previousEmail}</b>
          )}
        </Notification>
      )}
      {!user.email &&
        user.invitationStatus === 'not_invited' &&
        !user.useSSO && (
          <Notification kind="warning">
            {__(
              'This user needs an access code in order to access the platform'
            )}
          </Notification>
        )}
    </>
  );
};

export default Notifications;
