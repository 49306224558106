import React from 'react';
import { compose } from 'redux';

import {
  AllBudgetEnvelopesStats,
  BudgetEnvelopesStats,
} from 'models/BudgetEnvelopeStats';

import compositeKey from 'helpers/compositeKey';

import { newDataLoader } from 'lib/dataLoader';
import { DataLoaderProvidedProps } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer } from 'components';
import AccordionStructure from 'components/Accordion/AccordionStructure';

import BudgetVisualizer from '../../components/BudgetVisualizer';
import BudgetEnvelopeItem from './BudgetEnvelopeItem';

type Props = {
  periodSlug: string;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    budgetEnvelopesStats: AllBudgetEnvelopesStats;
  };

const BudgetEnvelopes = ({
  budgetEnvelopesStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <AccordionStructure
          design="elevated"
          items={budgetEnvelopesStats.map(
            (envelopeStats: BudgetEnvelopesStats) => ({
              key: envelopeStats.envelopeId,
              title: <BudgetEnvelopeItem envelopeStats={envelopeStats} />,
              content: envelopeStats.fundingSourcesStats.map(foundingSource => (
                <BudgetVisualizer
                  key={foundingSource.id}
                  title={foundingSource.title}
                  className="pl-[var(--accordion-pad-left)] py-3 pr-4"
                  isInline
                  provisionedAmountCents={foundingSource.provisionedAmountCents}
                  validatedAmountCents={foundingSource.validatedAmountCents}
                  forecastAmountCents={foundingSource.forecastAmountCents}
                  currency={foundingSource.currency}
                  hideLabels
                />
              )),
            })
          )}
        />
      )}
    />
  );
};

export default compose<React.ComponentType<Props>>(
  newDataLoader({
    fetch: ({ periodSlug }: Props) =>
      get(
        `training/periods/${periodSlug}/plans/forecast/budget_envelopes_stats`
      ),
    hydrate: {
      budgetEnvelopesStats: {
        fundingSourcesStats: [],
      },
    },
    cacheKey: ({ periodSlug }: Props) =>
      compositeKey({
        type: 'budgetEnvelopesStats',
        periodSlug,
      }),
  })
)(BudgetEnvelopes);
