import React from 'react';

import { AutoSave, FieldError, RadioScale } from 'components';

type Props = {
  minValue: number;
  maxValue: number;
  leftLabel: string;
  rightLabel: string;
  value: number | null;
  persistChanges: (score: number | null) => Promise<void>;
  inputName: string;
  isDisabled?: boolean;
  errorMessage: string;
};

const ScaleQuestionContent = ({
  value,
  maxValue,
  minValue,
  leftLabel,
  rightLabel,
  persistChanges,
  inputName,
  isDisabled,
  errorMessage,
}: Props) => {
  const possibleValues: number[] = [];
  for (let i = minValue; i <= maxValue; i++) {
    possibleValues.push(i);
  }
  const [selectedValue, setSelectedValue] = React.useState<number | null>(
    value
  );

  return (
    <AutoSave
      fieldUid={inputName}
      onChange={(value: number | null) => setSelectedValue(value)}
      doPersist={() => persistChanges(selectedValue)}
      unprocessableEntityHandled
      render={autoSavingOnChange => (
        <>
          <RadioScale<number>
            name={inputName}
            selectedValue={selectedValue}
            values={possibleValues}
            className="mt-2 md:mt-8"
            legends={[leftLabel, rightLabel]}
            onChange={value => {
              autoSavingOnChange(value);
            }}
            isDisabled={isDisabled}
          />
          <FieldError>{errorMessage}</FieldError>
        </>
      )}
    />
  );
};

export default ScaleQuestionContent;
