import React, { useCallback, useEffect, useState } from 'react';

import type { TrainingSession } from 'models';

import { guessTimeZone } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { get, post } from 'redux/actions/api';

import { FetchContainer, Icon } from 'components';
import { ConfirmationModal } from 'components/modals';

import ParticipantsChooser from 'scenes/components/ParticipantsChooser';

import AutoInvitationHelper from '../../components/AutoInvitationHelper';

type Props = {
  session: TrainingSession;
  onClose: () => void;
  onAfterAddition?: () => void;
};

const AddParticipantsModal = ({ session, onClose, onAfterAddition }: Props) => {
  const dispatch = useAppDispatch();
  const [isClearingPlannedParticipants, setIsClearingPlannedParticipants] =
    useState(true);
  const refetchSession = () => dispatch(get(`training/sessions/${session.id}`));

  const plannedParticipantIds = session.plannedParticipantIds || [];

  const addParticipants = async () => {
    await dispatch(
      post(`training/sessions/${session.id}/add_participants`, {
        userIds: plannedParticipantIds,
        timezone: guessTimeZone(),
      })
    );
    // We need to refetch the session to recompute its abilities
    refetchSession();

    onAfterAddition && onAfterAddition();
    onClose();
  };

  const clearPlannedParticipants = useCallback(async () => {
    await dispatch(
      post(`training/sessions/${session.id}/planned_participants/clear`)
    );
    setIsClearingPlannedParticipants(false);
  }, [dispatch, session.id]);

  useEffect(() => {
    clearPlannedParticipants();
  }, [clearPlannedParticipants]);

  return (
    <ConfirmationModal
      isActive
      confirmDisabled={plannedParticipantIds.length === 0}
      onConfirm={addParticipants}
      onCancel={onClose}
      cancelLabel={__('Cancel')}
      confirmLabel={
        session.autoInviteNewParticipants ? __('Add and invite') : __('Add')
      }
      title={__('Add participants to the training session')}
      inlineButtonIcon={<Icon name="add" />}
      refreshContentOnOpening
      isLarge
    >
      <FetchContainer
        isFetching={isClearingPlannedParticipants}
        hasError={false}
        render={() => (
          <>
            <p>
              <b>
                {__(
                  'Select the participants you want to add to the training session "%1".',
                  session.name || __('Untitled session')
                )}
              </b>
            </p>
            <p>{__('Employees and managers will not be notified.')}</p>
            <div className="my-4">
              <ParticipantsChooser
                withUserMultiFilters
                showBulkActions
                fetchUrl={`training/sessions/${session.id}/participant_eligibilities`}
                updateUrl={`training/sessions/${session.id}/planned_participants`}
                participantsCount={plannedParticipantIds.length}
                countPerPage={4}
                showTotalRecordCount
                withFilters
              />
            </div>
            {session.autoInviteNewParticipants &&
              plannedParticipantIds.length > 0 && (
                <AutoInvitationHelper
                  userCount={plannedParticipantIds.length}
                />
              )}
          </>
        )}
      />
    </ConfirmationModal>
  );
};

export default AddParticipantsModal;
