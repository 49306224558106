import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Campaign from './Campaign';
import EditCampaign from './Campaign/Edit';
import NewCampaign from './Campaign/New';
import CampaignPreview from './Campaign/Preview';
import Campaigns from './Campaigns';
import ChatRooms from './ChatRooms';
import Survey from './Survey';
import ToComplete from './ToComplete';

const SurveyRoutes = () => {
  return (
    <Routes>
      <Route index path="to-complete" element={<ToComplete />} />
      <Route path="conversations" element={<ChatRooms />} />
      <Route path=":id" element={<Survey />} />
      <Route path="campaigns" element={<Campaigns />} />
      <Route path="campaigns/new" element={<NewCampaign />} />
      <Route path="campaigns/:id/edit" element={<EditCampaign />} />
      <Route path="campaigns/:id/preview" element={<CampaignPreview />} />
      <Route path="campaigns/:id/*" element={<Campaign />} />
    </Routes>
  );
};

export default SurveyRoutes;
