import React from 'react';

import {
  AllForecastFundingSourceStats,
  AllValidatedFundingSourceStats,
  ForecastFundingSourceStats,
  TrainingPlanBudgetStats,
  ValidatedFundingSourceStats,
} from 'models';

import { __ } from 'helpers/i18n';

import { hasShape } from 'types/predicates/WithShape';

import { Divider, Text } from 'components';

import BudgetVisualizer from '../../../../components/BudgetVisualizer';
import FundingSourceWithBudgetStats from './FundingSourceWithBudgetStats';
import FundingSourceWithoutBudgetStats from './FundingSourceWithoutBudgetStats';

type Props = {
  stats: TrainingPlanBudgetStats;
  fundingSourcesStats:
    | AllValidatedFundingSourceStats
    | AllForecastFundingSourceStats;
};

const Stats = ({ stats, fundingSourcesStats }: Props) => {
  const validatedAmountCents = stats.validatedAmountCents;
  const forecastAmountCents = hasShape(stats, { forecastAmountCents: 0 })
    ? stats.forecastAmountCents
    : undefined;

  return (
    <div>
      {stats.provisionedAmountCents > 0 && (
        <>
          <Text preset="14bs6">{__('Total budget')}</Text>
          <BudgetVisualizer
            className="mt-1"
            testClassName="test-total-budget-visualizer"
            validatedAmountCents={validatedAmountCents}
            forecastAmountCents={forecastAmountCents}
            provisionedAmountCents={stats.provisionedAmountCents}
            currency={stats.currency}
          />

          <Divider />
        </>
      )}

      {fundingSourcesStats.map(
        (
          sourceStats: ValidatedFundingSourceStats | ForecastFundingSourceStats
        ) => {
          const provisionedAmountCents =
            sourceStats.budgetItem.provisionedAmountCents;

          return (
            <div
              className="mb-6 test-funding-source-stats"
              key={sourceStats.id}
            >
              {!!provisionedAmountCents ? (
                <FundingSourceWithBudgetStats sourceStats={sourceStats} />
              ) : (
                <FundingSourceWithoutBudgetStats sourceStats={sourceStats} />
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default Stats;
