import React, { useState } from 'react';

import type { Integration } from 'models';

import can from 'helpers/can';
import { useAppDispatch, useSession } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del, put } from 'redux/actions/api';

import { HamburgerMenu, Icon, MenuItem, MenuList } from 'components';

import IntegrationModal from '../IntegrationModal';
import HowToLaunchManualSyncInformationModal from './HowToLaunchManualSyncInformationModal';

type Props = {
  integration: Integration;
  refetchIntegrations: () => Promise<void>;
};

const Actions = ({ integration, refetchIntegrations }: Props) => {
  const { isImpersonated } = useSession();
  const [isEditionModalActive, setIsEditionModalActive] = useState(false);
  const [infoModalParams, setInfoModalParams] = useState<
    null | 'prepare' | 'launch'
  >(null);

  const dispatch = useAppDispatch();
  const updateIntegration = (integration: Partial<Integration>) => {
    const sanitizedSettings = {};
    const { settings, ...otherIntegrationAttributes } = integration;
    if (!!settings) {
      Object.keys(settings).forEach(key => {
        if (
          typeof settings[key] === 'string' &&
          !settings[key].includes('••')
        ) {
          sanitizedSettings[key] = settings[key];
        }
      });
    }
    const shouldUpdateSettings = Object.keys(sanitizedSettings).length > 0;

    return dispatch(
      put(`integrations/${integration.id}`, {
        integration: {
          ...otherIntegrationAttributes,
          ...(shouldUpdateSettings ? { settings: sanitizedSettings } : {}),
        },
      })
    );
  };

  const destroyIntegration = () =>
    confirmAsync(
      __('Delete this %1 integration', integration.integrationType),
      __(
        'This action is irreversible. Are you sure you want to delete this integration?'
      ),
      {
        inlineButtonIcon: <Icon name="delete" className="mr-2" />,
        confirmLabel: __('Delete'),
        isDanger: true,
        onConfirm: async () => {
          await dispatch(del(`integrations/${integration.id}`));
          refetchIntegrations();
        },
      }
    );

  return (
    <>
      <HamburgerMenu align="right">
        <MenuList>
          <MenuItem onClick={() => setIsEditionModalActive(true)} key="edit">
            {__('Edit')}
          </MenuItem>
          {isImpersonated && (
            <MenuItem
              onClick={() => setInfoModalParams('prepare')}
              key="launchSyncTest"
            >
              {__('Launch a synchronization test')}
            </MenuItem>
          )}
          {isImpersonated && (
            <MenuItem
              onClick={() => setInfoModalParams('launch')}
              key="launchManualSync"
            >
              {__('Launch a manual synchronization')}
            </MenuItem>
          )}
          {can({ perform: 'destroy', on: integration }) && (
            <MenuItem
              onClick={destroyIntegration}
              key="destroyManualSync"
              isDanger
            >
              {__('Delete this integration')}
            </MenuItem>
          )}
        </MenuList>
      </HamburgerMenu>
      {!!infoModalParams && (
        <HowToLaunchManualSyncInformationModal
          action={infoModalParams}
          onClose={() => setInfoModalParams(null)}
        />
      )}

      {!!isEditionModalActive && (
        <IntegrationModal
          onConfirm={updateIntegration}
          integration={integration}
          onClose={() => setIsEditionModalActive(false)}
        />
      )}
    </>
  );
};

export default Actions;
