import React from 'react';

import type { ObjectivePeriod } from 'models';

import { __ } from 'helpers/i18n';

import { Can, HorizontalEmptyStateWithSvg, Toggleable } from 'components';

import emptyStateSvg from 'scenes/objectives/team/components/TeamSvg';

import AddTeamModal from '../components/AddTeamButton/AddTeamModal';

type Props = {
  objectivePeriod: ObjectivePeriod;
};

const TeamListEmptyState = ({ objectivePeriod }: Props) => {
  return (
    <Can
      perform="manage_team_objectives"
      on="organization"
      alternativeChildren={
        <HorizontalEmptyStateWithSvg
          svg={emptyStateSvg}
          description={__('This period does not have any team yet')}
        />
      }
    >
      <Toggleable
        render={(isToggled, toggle) => (
          <>
            {!isToggled && (
              <HorizontalEmptyStateWithSvg
                svg={emptyStateSvg}
                description={__('This period does not have any team yet')}
                action={{
                  text: __('Add a team'),
                  onTrigger: toggle,
                  testableName: 'test-add-team-button',
                }}
              />
            )}
            {isToggled && (
              <AddTeamModal
                onClose={toggle}
                objectivePeriod={objectivePeriod}
              />
            )}
          </>
        )}
      />
    </Can>
  );
};

export default TeamListEmptyState;
