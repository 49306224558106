import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { ReduxStore } from 'redux/reducers';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { getRequestStatus } from 'lib/dataLoader/reducer';

import { DeprecatedContainerElevo, PageTitle, Text } from 'components';

import DirectReports, { DIRECT_REPORTS_REQUEST_ID } from './DirectReports';
import IndirectReports from './IndirectReports';

type Props = {};

type AfterConnectProps = Props & {
  isFetching: boolean;
  hasError: boolean;
};

const MyReports = ({ isFetching, hasError }: AfterConnectProps) => {
  const activeUser = useActiveUser();
  if (hasError) {
    return null;
  }

  return (
    <DeprecatedContainerElevo className="py-12 mt-5">
      <PageTitle title={__('My team')} />

      <div className="mb-4">
        <Text preset="16s5.5" className="subtitle mt-10 md:mt-0">
          {__('My team')}
        </Text>
      </div>

      <DirectReports activeUser={activeUser} />

      {!isFetching && (
        <div style={{ paddingTop: '40px' }}>
          <IndirectReports activeUser={activeUser} />
        </div>
      )}
    </DeprecatedContainerElevo>
  );
};

export default compose(
  connect((state: ReduxStore) => ({
    ...getRequestStatus(state, DIRECT_REPORTS_REQUEST_ID),
  }))
)(MyReports);
