import React, { useState } from 'react';

import type { AuditReport } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { zendeskURL } from 'helpers/zendesk';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  Button,
  ContentContainer,
  EmptyStateWithIcon as EmptyStateComponent,
  FetchContainer,
  Icon,
  Link,
  PageHeader,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import AuditReportListItem from './AuditReportListItem';
import AuditReportsTableHeader from './AuditReportsTableHeader';
import NewAuditModal from './NewAuditModal';

type AfterDataLoaderProps = DataLoaderProvidedProps & {
  auditReports: Array<AuditReport>;
};

const AuditsHeader = ({ onModalClose }) => {
  const [newAuditModalIsActive, setNewAuditModalIsActive] = useState(false);
  const activeUser = useActiveUser();

  const documentationURL = zendeskURL({
    fr: '18197574062353-S-assurer-de-la-conformité-des-entretiens-grâce-aux-Rapports-d-Audit',
    en: '18197574062353-Ensuring-review-conformity-using-Audit-Reports',
  });

  return (
    <>
      <PageHeader
        title={__('Audit reports')}
        actions={
          activeUser.isOrganizationAdmin && (
            <Button
              color="primary"
              onClick={() => setNewAuditModalIsActive(true)}
            >
              <Icon name="add" />
              <span>{__('Create')}</span>
            </Button>
          )
        }
      />
      <ContentContainer>
        <Text>
          {__(
            'The audit tool allows you to ensure that you are in compliance with your employee reviews. For more information, please visit our %1.',
            <Link
              additionalClassName="text-[var(--primary)]"
              to={documentationURL}
              openInNewTab
            >
              {__('help center')}
            </Link>
          )}
        </Text>
      </ContentContainer>
      {newAuditModalIsActive && (
        <NewAuditModal
          onClose={() => {
            onModalClose();
            setNewAuditModalIsActive(false);
          }}
        />
      )}
    </>
  );
};

const Audits = ({
  auditReports = [],
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const activeUser = useActiveUser();
  const AuditReportsTableContent = () => {
    if (!isFetching && (!auditReports || auditReports.length === 0))
      return activeUser.isOrganizationAdmin ? (
        <EmptyStateComponent
          className="mt-5"
          title={__('Ensure compliance by creating an audit report')}
          description={
            <StrictlySanitizedHtml
              html={__(
                'Click on <b>Create</b> in the top right-hand corner to create your first audit report'
              )}
            />
          }
          iconName="assignment_turned_in"
        />
      ) : (
        <EmptyStateComponent
          className="mt-5"
          title={__('Ensure compliance with the audit report')}
          description={
            <Text>
              {__('Ask one of your global admins to create an audit report')}
            </Text>
          }
          iconName="assignment_turned_in"
        />
      );
    return (
      <BoxList>
        <AuditReportsTableHeader />
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <div>
              {!!auditReports &&
                auditReports.map(auditReport => (
                  <AuditReportListItem
                    key={auditReport.id}
                    auditReport={auditReport}
                  />
                ))}
            </div>
          )}
        />
      </BoxList>
    );
  };

  return (
    <>
      <AuditsHeader onModalClose={refetchData} />
      <div className="m-4 md:m-6">
        <Box>
          <AuditReportsTableContent />
        </Box>
      </div>
    </>
  );
};

export default newDataLoader({
  fetch: () => get('audit_reports'),
  hydrate: { auditReports: { tags: {} } },
})(Audits);
