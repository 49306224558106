import React from 'react';

import type {
  FeedbackableItem,
  SkillsCareerLevelBlock,
  SkillsCareerLevelExpectationBlock,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { Box, Text, Title } from 'components';

import FeedbackZone from '../components/FeedbackZone';
import RequiredQuestionWarning from '../components/FeedbackZone/RequiredQuestionWarning';

type Props = {
  feedbackableItem: FeedbackableItem<
    SkillsCareerLevelExpectationBlock | SkillsCareerLevelBlock
  >;
  isPreview: boolean;
  persistAction: (
    evaluationId: string | null,
    feedbackableId: string,
    feedbackableType: string,
    text: string,
    rating?: string | null | undefined
  ) => (dispatch: AppDispatch) => Promise<void>;
};

export default function ExpectationBlockContent({
  feedbackableItem,
  isPreview,
  persistAction,
}: Props) {
  const openedAnswer = feedbackableItem.answers.find(
    answer => answer.status === 'opened'
  );
  const displayBox =
    !isPreview ||
    (isPreview &&
      openedAnswer &&
      (openedAnswer.ratingEnabled || openedAnswer.textEnabled));
  const title = feedbackableItem.item?.areaTitle;
  const content = feedbackableItem.item?.expectationContent;
  const ratingMandatory = openedAnswer && openedAnswer.ratingMandatory;

  return (
    <>
      {displayBox && (
        <Box
          className="block-content-wrapper"
          testClassName="test-skills-expectations-block"
        >
          {(title || content) && (
            <div className="mb-4">
              {title && (
                <div className="flex justify-between">
                  <Title
                    style={{ marginBottom: 16 }}
                    size={5}
                    testClassName="test-block-title"
                  >
                    {title}
                  </Title>
                  {ratingMandatory && <RequiredQuestionWarning />}
                </div>
              )}

              {content && (
                <Text preserveLinebreaks testClassName="test-block-description">
                  {content}
                </Text>
              )}
            </div>
          )}
          <FeedbackZone
            feedbackableItem={feedbackableItem}
            persistAction={persistAction}
          />
        </Box>
      )}
    </>
  );
}
