import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const RequireUnauth = ({ loggedIn }) => {
  const location = useLocation();

  if (!loggedIn) {
    return (
      <Navigate to="/auth/notsignedin" state={{ from: location }} replace />
    );
  }

  // If logged in, render child routes
  return <Outlet />;
};

export default RequireUnauth;
