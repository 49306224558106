import type { JsonApiStrippedResponse } from './JsonApiResponse';

import {
  Relationships,
  Resource,
} from 'helpers/data/types/NormalizedJsonApiResponse';

export const DELETE_ALL_RESOURCES_FROM_KEY = 'DELETE_ALL_RESOURCES_FROM_KEY';
export const RECEIVED_RESOURCES = 'RECEIVED_RESOURCES';

const REQUEST_STARTED = 'REQUEST_STARTED';
const REQUEST_SUCCEEDED = 'REQUEST_SUCCEEDED';
const REQUEST_FAILED = 'REQUEST_FAILED';

type RequestStartedAction = {
  type: typeof REQUEST_STARTED;
  requestId: string;
};

type RequestSucceededAction = {
  type: typeof REQUEST_SUCCEEDED;
  requestId: string;
  value?: JsonApiStrippedResponse;
};

type RequestFailedAction = {
  type: typeof REQUEST_FAILED;
  requestId: string;
};

type DeleteAllResourcesFromKey = {
  type: typeof DELETE_ALL_RESOURCES_FROM_KEY;
  keyId: string;
};

type ReceivedResourcesAction = {
  type: typeof RECEIVED_RESOURCES;
  resources: { [resourceType: string]: { [id: string]: Resource } };
  relationships: { [resourceType: string]: Relationships };
};

export type DataLoaderAction =
  | RequestStartedAction
  | RequestSucceededAction
  | RequestFailedAction
  | DeleteAllResourcesFromKey
  | ReceivedResourcesAction;
