import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { __ } from 'helpers/i18n';
import { pathToSimbelRedirection } from 'helpers/simbel';

import { Flex, Link, Loading, Overlay, Text } from 'components';

function SimbelRedirector() {
  const url = pathToSimbelRedirection({
    intent: 'training_plan',
  });
  const [isRedirecting, setIsRedirecting] = React.useState(true);
  useEffect(() => {
    setTimeout(() => {
      window.open(url, '_blank');
      setIsRedirecting(false);
    }, 1500);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Overlay>
      <Flex style={{ marginBottom: 16 }} direction="column" verticalAlign>
        <Text preset="16bs5.5">
          {__('We are redirecting to your training management platform')}
        </Text>
        <Text preset="13s7">
          {__(
            'You can make a training request, check the catalog or manage your training sessions on your training management platform.'
          )}
        </Text>

        {isRedirecting ? (
          <Loading delay={0} />
        ) : (
          <Link style={{ marginTop: 16 }} to={url} openInNewTab>
            <Text preset="13s7">
              {__('Click here if you are not redirected automatically')}
            </Text>
          </Link>
        )}
      </Flex>
    </Overlay>
  );
}

const TrainingRoutes = () => {
  return (
    <Routes>
      <Route index path="*" element={<SimbelRedirector />} />
    </Routes>
  );
};

export default TrainingRoutes;
