import React from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { ContentContainer, RawHtml, Text } from 'components';

import SkillsHeader from 'scenes/skills/components/SkillsHeader';

import OldPaginatedList from './OldPaginatedList';
import PaginatedList from './PaginatedList';

const EmployeesOverview = () => {
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  return (
    <>
      <SkillsHeader />
      <ContentContainer>
        <Text className="mb-4">
          <RawHtml
            html={__(
              "Manage your team's development on the Employees page: quickly identify employees without a skills matrix and <b>associate them with the appropriate matrix.</b>"
            )}
          />
        </Text>
        {withUserMultiFilters ? (
          <PaginatedList />
        ) : (
          <OldPaginatedList paginationType="url" />
        )}
      </ContentContainer>
    </>
  );
};

export default EmployeesOverview;
