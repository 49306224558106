import { sortBy } from 'lodash';
import React, { useState } from 'react';

import { CompensationBandLevel } from 'models/Compensation';

import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import {
  BoxListItem,
  BoxSeparator,
  Column,
  Columns,
  Icon,
  Link,
  Text,
} from 'components';
import RichTextEditor from 'components/formElements/advancedElements/RichTextEditor';

type NullableMoneyProps = {
  cents: number | null;
  currency: string | null;
};

const NullableMoney = ({ cents, currency }: NullableMoneyProps) => {
  if (cents === null || !currency) {
    return null;
  }

  return (
    <Text>
      {formatMoney(cents, currency, {
        hideEmptyDecimal: true,
      })}
    </Text>
  );
};

type Props = {
  level: CompensationBandLevel;
};

const BandLevelItem = ({ level }: Props) => {
  const sortedRemunerations = sortBy(
    level.remunerations,
    remuneration => remuneration.remunerationType.position
  );

  const [showMore, setShowMore] = useState(false);

  const visibleRemunerations = showMore
    ? sortedRemunerations
    : [sortedRemunerations[0]];

  return (
    <BoxListItem>
      <Columns isGapLess className="mb-0">
        <Column size={3} isVerticallyCentered>
          <div className="flex">
            <Text preset="14bs6">{level.name}</Text>
          </div>
        </Column>
        <Column size={3}>
          <div className="flex flex-col">
            {visibleRemunerations.map(remuneration => {
              return (
                <div className="my-1" key={remuneration.id}>
                  <Text>{remuneration.remunerationType.name}</Text>
                </div>
              );
            })}
          </div>
        </Column>
        <Column size={3}>
          <div className="flex flex-col">
            {visibleRemunerations.map(remuneration => {
              return (
                <div className="my-1" key={remuneration.id}>
                  <NullableMoney
                    cents={remuneration.minimumCents}
                    currency={remuneration.minimumCurrency}
                  />
                </div>
              );
            })}
          </div>
        </Column>
        <Column size={3}>
          <div className="flex flex-col">
            {visibleRemunerations.map(remuneration => {
              return (
                <div className="my-1" key={remuneration.id}>
                  <NullableMoney
                    cents={remuneration.maximumCents}
                    currency={remuneration.maximumCurrency}
                  />
                </div>
              );
            })}
          </div>
        </Column>
      </Columns>

      {!!level.otherBenefits && showMore && (
        <>
          <Columns isGapLess className="mb-0">
            <Column size={3} />
            <Column size={9}>
              <BoxSeparator className="bg-ui-body-bg" />
            </Column>
          </Columns>

          <Columns isGapLess className="mb-0">
            <Column size={3} />
            <Column size={3}>
              <div className="my-1">
                <Text>{__('Other benefits')}</Text>
              </div>
            </Column>
            <Column size={6}>
              <RichTextEditor
                value={level.otherBenefits}
                className="my-1 py-0"
                disabled
                display
              />
            </Column>
          </Columns>
        </>
      )}

      <Columns isGapLess className="mb-0">
        <Column size={3} />
        <Column size={9}>
          <Link
            onClick={() => setShowMore(showMore => !showMore)}
            className="text-text-light"
          >
            <Text preset="14s6">
              {showMore ? __('Show less') : __('Show more')}
            </Text>
            <Icon
              className="pl-2"
              name={showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            />
          </Link>
        </Column>
      </Columns>
    </BoxListItem>
  );
};

export default BandLevelItem;
