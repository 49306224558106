import React from 'react';

import { __ } from 'helpers/i18n';

import IntegrationSettingField from './IntegrationSettingField';

type Props = {
  settings: {
    apiKey: string;
    fullDomain: string;
  };
  onChange: (key: string, value: string) => void;
};

export function LuccaIntegrationSettings({ settings, onChange }: Props) {
  return (
    <>
      <IntegrationSettingField
        name="apiKey"
        label={__('API Key:')}
        value={settings.apiKey}
        cleanOnFocus
        onUpdate={value => onChange('apiKey', value)}
      />
      <IntegrationSettingField
        name="fullDomain"
        label={__('Full domain:')}
        value={settings.fullDomain}
        placeholder={__('subdomain.ilucca.net')}
        onUpdate={value => onChange('fullDomain', value)}
      />
    </>
  );
}

export const LuccaIntegrationDefaultSettings = {
  apiKey: '',
  fullDomain: '',
};
