import type { AppDispatch } from '../types';
import type { GenericFetchParams } from 'lib/api/genericFetch';
import type { Attributes, Query } from 'lib/api/types';
import type { APIActionError } from 'lib/api/types';
import type { NavigateFunction } from 'react-router-dom';

import * as commonPhrases from 'helpers/commonPhrases';

import * as api from 'lib/api';
import { withGenericErrorHandling } from 'lib/api/errorHandler';

import { htmlErrorNotice, htmlSuccessNotice } from '../application';
import { storeResourcesFromResponse } from './serialization';

export type Options = {
  withDefaultErrorHandling?: boolean;
  customErrorHandler?: (
    error: APIActionError<any>,
    dispatch: AppDispatch
  ) => unknown;
  redirectTo?: string;
  navigate?: NavigateFunction;
  errorMessage?: string | null;
  successMessage?: string | null;
};

type GenericFetch = (
  params: GenericFetchParams,
  options?: Options
) => (dispatch: AppDispatch) => Promise<any>;
const genericFetch: GenericFetch = (params, options) => async dispatch => {
  const defaultOptions: Options = {
    withDefaultErrorHandling: true,
    customErrorHandler: undefined,
    errorMessage: null,
    successMessage: null,
  };

  const {
    withDefaultErrorHandling,
    customErrorHandler,
    errorMessage,
    successMessage,
    redirectTo,
    navigate,
  } = {
    ...defaultOptions,
    ...options,
  };

  const withErrorHandler = customErrorHandler
    ? apiCall => dispatch =>
        apiCall.then(
          response => response,
          error => customErrorHandler(error, dispatch)
        )
    : withGenericErrorHandling;

  try {
    const response = await withErrorHandler(api.genericFetch(params))(dispatch);

    storeResourcesFromResponse(response, dispatch);

    if (redirectTo && navigate) {
      navigate(redirectTo);
    }

    if (successMessage) {
      dispatch(htmlSuccessNotice(successMessage));
    }

    return { response };
  } catch (e: any) {
    if (!e.isHandled && withDefaultErrorHandling !== false) {
      const finalErrorMessage = errorMessage
        ? `<b>${errorMessage}</b><br>` + commonPhrases.somethingWentWrong()
        : commonPhrases.somethingWentWrong();
      dispatch(htmlErrorNotice(finalErrorMessage));
    }

    throw e;
  }
};

export const get = (url: string, query?: Query, options?: Options) =>
  genericFetch(
    { method: 'GET', url, query },
    {
      withDefaultErrorHandling: false,
      ...options,
    }
  );

export const post = (url: string, attributes?: Attributes, options?: Options) =>
  genericFetch({ method: 'POST', url, attributes }, options);
export const put = (url: string, attributes?: Attributes, options?: Options) =>
  genericFetch({ method: 'PUT', url, attributes }, options);
export const del = (url: string, attributes?: Attributes, options?: Options) =>
  genericFetch({ method: 'DELETE', url, attributes }, options);

export const postFile = (
  url: string,
  file: File,
  attributes?: Attributes,
  options?: Options
) => genericFetch({ method: 'POST', url, attributes, file }, options);
