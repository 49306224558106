import React from 'react';

import type { OneOnOneReviewTemplate } from 'models';

import { useOrganizationPlan } from 'helpers/hooks';

import { BoxSeparator } from 'components';

import EnableSelfEvaluation from './components/EnableSelfEvaluation';
import ObjectiveSettings from './components/ObjectiveSettings';
import SignatureModule from './components/SignatureModule';
import SkillsCareerLevelModule from './components/SkillsCareerLevelModule';
import TemplateName from './components/TemplateName';
import TrainingHistoryModule from './components/TrainingHistoryModule';

type Props = {
  template: OneOnOneReviewTemplate;
  onFieldChange: (value: Partial<OneOnOneReviewTemplate>) => any;
  settingsDisabled: boolean;
};

function OneOnOneTemplateSettings({
  template,
  onFieldChange,
  settingsDisabled,
}: Props) {
  const { trainingModuleEnabled, skillsAndCareersEnabled } =
    useOrganizationPlan();

  return (
    <>
      <TemplateName
        name={template.actualName}
        onChange={name => onFieldChange({ name })}
        templateId={template.id}
      />

      <BoxSeparator style={{ margin: '8px 0' }} />

      <ObjectiveSettings
        objectivesReviewEnabled={template.objectivesReviewEnabled}
        objectivesDefinitionEnabled={template.objectivesDefinitionEnabled}
        disabled={settingsDisabled}
        onChange={onFieldChange}
        templateId={template.id}
      />
      {trainingModuleEnabled && (
        <>
          <BoxSeparator style={{ margin: '8px 0' }} />

          <TrainingHistoryModule
            trainingHistoryEnabled={template.trainingHistoryEnabled}
            isDisabled={settingsDisabled}
            onChange={trainingHistoryEnabled =>
              onFieldChange({ trainingHistoryEnabled })
            }
            templateId={template.id}
          />
        </>
      )}

      <BoxSeparator style={{ margin: '8px 0' }} />

      {skillsAndCareersEnabled && (
        <>
          <SkillsCareerLevelModule
            skillsCareerLevelEnabled={template.skillsCareerLevelEnabled}
            disabled={settingsDisabled}
            onChange={skillsCareerLevelEnabled =>
              onFieldChange({ skillsCareerLevelEnabled })
            }
            templateId={template.id}
          />

          <BoxSeparator style={{ margin: '8px 0' }} />
        </>
      )}

      <SignatureModule
        signatureModuleEnabled={template.signatureModuleEnabled}
        disabled={settingsDisabled}
        onChange={signatureModuleEnabled =>
          onFieldChange({ signatureModuleEnabled })
        }
        templateId={template.id}
      />

      <BoxSeparator style={{ margin: '8px 0' }} />

      <EnableSelfEvaluation
        disabled={settingsDisabled}
        hasSelfEvaluationEnabled={template.hasSelfEvaluationEnabled}
        showRevieweeEvaluationToReviewers={
          template.showRevieweeEvaluationToReviewers
        }
        onChange={onFieldChange}
        interactionType="one_on_one"
        templateId={template.id}
      />
    </>
  );
}

export default OneOnOneTemplateSettings;
