import React, { useState } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { SkillsLevel, SkillsMatrix } from 'models';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer, Field, Label, Select } from 'components';

type Props = {
  levelSelectionLabel: string;
  selectedLevel: SkillsLevel | null;
  setSelectedLevel: (skillsLevel: SkillsLevel | undefined) => void;
  disabled?: boolean;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    skillsMatrixCollection: Array<SkillsMatrix>;
  };

const toOption = (item: SkillsMatrix | SkillsLevel) => ({
  value: item.id,
  label: item.title,
});

const LevelPicker = ({
  selectedLevel,
  levelSelectionLabel,
  setSelectedLevel,
  skillsMatrixCollection,
  isFetching,
  hasError,
  disabled = false,
}: AfterDataLoaderProps) => {
  const [selectedMatrix, setSelectedMatrix] = useState<
    SkillsMatrix | undefined
  >(undefined);
  const [loaded, setLoaded] = useState(false);

  if (!isFetching && !!skillsMatrixCollection && !loaded) {
    setLoaded(true);

    setSelectedMatrix(
      !!selectedLevel
        ? skillsMatrixCollection.find(
            matrix =>
              !!matrix.levels.find(level => level.id === selectedLevel.id)
          )
        : undefined
    );
  }

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <>
            <Field>
              <Label labelClassName="mt-4 mb-2">
                {__('Select the skills matrix')}
              </Label>

              <Select
                value={!!selectedMatrix ? toOption(selectedMatrix) : undefined}
                placeholder={__('Select a skills matrix')}
                options={skillsMatrixCollection.map(matrix => toOption(matrix))}
                onChange={option => {
                  setSelectedMatrix(
                    skillsMatrixCollection.find(
                      matrix => matrix.id === option?.value
                    )
                  );
                  setSelectedLevel(undefined);
                }}
                isClearable
                isDisabled={disabled}
              />
            </Field>

            <Field>
              <Label labelClassName="mt-4 mb-2">{levelSelectionLabel}</Label>
              <Select
                isDisabled={!selectedMatrix || disabled}
                value={!!selectedLevel ? toOption(selectedLevel) : undefined}
                placeholder={__('Select a skills level')}
                isClearable
                options={(selectedMatrix?.levels || []).map(level =>
                  toOption(level)
                )}
                onChange={option =>
                  setSelectedLevel(
                    selectedMatrix?.levels?.find(
                      level => level.id === option?.value
                    )
                  )
                }
              />
            </Field>
          </>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: () => get('/skills/matrices'),
  hydrate: {
    skillsMatrixCollection: {
      levels: {},
    },
  },
})(LevelPicker);
