import React from 'react';

import type { Answer, ReviewerTag } from 'models';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Avatar, Text, Title } from 'components';

type Props = {
  answer: Answer;
};

const displayTag = (tag: ReviewerTag) => {
  switch (tag) {
    case 'reviewee':
      return __('self-evaluation');
    case 'manager':
      return __('manager');
    case 'additional_reviewer':
      return __('additional reviewer');
    case 'reviewer':
      return __('reviewer');
    case 'peer':
    case null:
      return __('peer');
    default:
      return '';
  }
};

export default function AnswerComponent({ answer }: Props) {
  const {
    text,
    rating,
    ratingEnabled,
    textEnabled,
    author,
    authorTag,
    authorRole,
    isAnswered,
    translatedRating,
  } = answer;

  const className = classNames('answer', 'is-closed', 'test-closed-answer', {
    'is-reviewee-answer': authorRole === 'reviewee',
    'is-reviewer-answer': authorRole !== 'reviewee',
    'test-reviewee-answer': authorRole === 'reviewee',
    'test-reviewer-answer': authorRole !== 'reviewee',
  });

  return (
    <div className={className}>
      <Avatar
        url={author.avatarUrl}
        size="normal"
        style={{ alignItems: 'start' }}
      />

      <div className="answer-content">
        <div className="reviewer-description">
          <span className="reviewer-name">{author.fullName}</span>
          <span className="separator">&bull;</span>
          <Text size={6} className="reviewer-role">
            {displayTag(authorTag)}
          </Text>
        </div>

        {isAnswered ? (
          <>
            {ratingEnabled && !!rating && (
              <Title weight="bold" size={6} style={{ marginBottom: 0 }}>
                {translatedRating || rating}
              </Title>
            )}

            {textEnabled && !!text && <Text weight="normal">{text}</Text>}
          </>
        ) : (
          <Text transformation="italic" className="placeholder">
            {__(
              '%1 has not provided a comment to this question',
              author.fullName
            )}
          </Text>
        )}
      </div>
    </div>
  );
}
