import React from 'react';
import { compose } from 'redux';

import { CollectionInfo } from 'models/Collection';
import { CompensationBand } from 'models/Compensation/Band';

import compositeKey from 'helpers/compositeKey';
import { __, n__, p__ } from 'helpers/i18n';
import { pathToCompensationBand } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { PaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyStateWithIcon,
  FetchContainer,
  SimpleTable,
  Text,
} from 'components';

import StatusTag from 'scenes/components/StatusTag';

type CompensationBandCollection = CollectionInfo & {
  items: Array<CompensationBand>;
};

type Props = {};

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    compensationBandCollection: CompensationBandCollection;
  };

const BandsList = ({
  page,
  countPerPage,
  queryParams: { search, filter, sort },
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
  onSortChange,
  isFetching,
  hasError,
  compensationBandCollection,
}: AfterDataLoaderProps) => {
  return (
    <Box>
      <DatatableWrapper
        collectionInfo={compensationBandCollection}
        isFetching={isFetching}
        hasError={hasError}
        search={search}
        filter={filter}
        filters={[
          { param: 'published', label: p__('CompensationBands', 'Published') },
          {
            param: 'unpublished',
            label: p__('CompensationBands', 'Not published'),
          },
          { param: 'archived', label: p__('CompensationBands', 'Archived') },
          { param: 'all', label: p__('CompensationBands', 'All') },
        ]}
        page={page}
        countPerPage={countPerPage}
        getNextPage={setNextPageParams}
        getPreviousPage={setPreviousPageParams}
        onQueryParamsChange={setQueryParams}
        withSearch
        totalCountRenderer={count => n__('%1 band', '%1 bands', count || 0)}
        renderNoRecord={() => (
          <EmptyStateWithIcon
            iconName="equalizer"
            title={__('No compensation band')}
            description={__(
              'Please contact Elevo support (support@elevo.io) to import them.'
            )}
          />
        )}
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => {
            return (
              <SimpleTable
                rows={compensationBandCollection.items}
                keyFn={band => band.id}
                columns={[
                  {
                    header: __('Name'),
                    cell: band => (
                      <Text
                        preset="14s6"
                        linkTo={pathToCompensationBand(band.id)}
                      >
                        {band.name}
                      </Text>
                    ),
                    activeSort: sort && sort['name'],
                    onSort: () => onSortChange && onSortChange('name'),
                  },
                  {
                    header: __('Status'),
                    cell: band => <StatusTag status={band.status} />,
                  },
                ]}
              />
            );
          }}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, sort },
    }: AfterPaginateProps) =>
      get('compensation/bands', { page, countPerPage, search, filter, sort }),
    hydrate: {
      compensationBandCollection: {
        items: {},
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, sort },
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, sort }),
  })
)(BandsList);
