import classNames from 'classnames';
import React, { Key, ReactNode } from 'react';

import { Icon, Text } from 'components';
import Accordion from 'components/Accordion';
import AccordionItem from 'components/Accordion/AccordionItem';

const BLOCK_CLASS = 'accordion-structure';

type Props = {
  items: Array<{
    key: Key;
    title: ReactNode;
    content: ReactNode;
  }>;
  defaultState?: 'open' | 'closed';
  design?: 'elevated' | 'flat';
};

/**
 * Generic accordion structure with a design
 */
const AccordionStructure = ({
  items,
  defaultState,
  design = 'flat',
}: Props) => {
  const defaultValue =
    defaultState === 'open' ? items.map(item => `${item.key}`) : [];

  return (
    <Accordion
      type="multiple"
      defaultValue={defaultValue}
      className={classNames(BLOCK_CLASS, `${BLOCK_CLASS}--${design}`)}
    >
      {items.map(item => (
        <AccordionItem
          key={item.key}
          value={`${item.key}`}
          className={`${BLOCK_CLASS}__item`}
          title={
            <div className={`${BLOCK_CLASS}__header`}>
              <Icon
                name="chevron_right"
                className={classNames(
                  `${BLOCK_CLASS}__caret`,
                  'elevo-accordion-caret'
                )}
              />
              <div className="w-full">{item.title}</div>
            </div>
          }
          content={
            <div className={`${BLOCK_CLASS}__content`}>
              <Text preset="14s6">{item.content}</Text>
            </div>
          }
        />
      ))}
    </Accordion>
  );
};

export default AccordionStructure;
