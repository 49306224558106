import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import TemplateEdit from './Edit';
import OldTemplatePreview from './OldPreview';
import TemplatePreview from './Preview';

const TemplateRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="edit" />} />
      <Route index path="edit" element={<TemplateEdit />} />
      <Route path="preview" element={<OldTemplatePreview />} />
      <Route path="preview/v2/:role" element={<TemplatePreview />} />
    </Routes>
  );
};

export default TemplateRoutes;
