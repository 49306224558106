import classNames from 'classnames';
import React from 'react';

import type { Answer, User } from 'models';

import { Avatar, DiscreteSliderResults } from 'components';

import groupUsersByAnswerRating from '../../helpers/groupUsersByAnswerRating';

type Props = {
  ratingOptions: string[];
  closedAnswers: Answer[];
  marginTopSlider?: number;
  marginBottomSlider?: number;
  hasWhiteRail?: boolean;
  testClassName?: string;
};

const RatingSliderResultsWithNoneRating = ({
  ratingOptions,
  closedAnswers,
  marginTopSlider = 20,
  marginBottomSlider = 20,
  hasWhiteRail = false,
  testClassName,
}: Props) => {
  const usersBySelectedLabel = groupUsersByAnswerRating(closedAnswers);
  const sliderRailHeight = 8;
  const avatarHeight = 20;

  return (
    <>
      <DiscreteSliderResults
        labels={ratingOptions}
        usersBySelectedLabel={usersBySelectedLabel}
        className="col-span-10 pb-0"
        testClassName={testClassName}
        style={{
          marginTop: marginTopSlider,
          marginBottom: marginBottomSlider + sliderRailHeight,
        }}
        hasWhiteRail={hasWhiteRail}
        hideLabels
      />
      <div
        className="col-span-2 pl-2 h-min border-0 border-solid border-l border-nav-border-default flex justify-center"
        style={{
          paddingTop: marginTopSlider + sliderRailHeight / 2 - avatarHeight / 2,
          paddingBottom:
            marginBottomSlider + sliderRailHeight / 2 - avatarHeight / 2,
        }}
      >
        {!!usersBySelectedLabel['none'] &&
        usersBySelectedLabel['none'].length > 0 ? (
          <div className="flex">
            {usersBySelectedLabel['none'].map((user: User, index: number) => (
              <Avatar
                key={user.id}
                url={user.avatarUrl}
                size="tiny"
                className={classNames({ 'ml-[-8px]': index !== 0 })}
              />
            ))}
          </div>
        ) : (
          <span style={{ height: avatarHeight }} />
        )}
      </div>
    </>
  );
};

export default RatingSliderResultsWithNoneRating;
