import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { TrainingPeriod } from 'models/TrainingPeriod';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToTrainingBudget, pathToTrainingBudgetEdit } from 'helpers/paths';

import { Button, ContentContainer } from 'components';

import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import PageHeader from '../../components/PageHeader';
import usePeriodFromStore from '../../hooks/usePeriodFromStore';
import BudgetDetailsBody from './BudgetDetailsBody';

const BudgetDetails = () => {
  const { periodSlug } = useParams();
  const navigate = useNavigate();
  invariant(periodSlug, 'Training period must be defined.');
  const period = usePeriodFromStore(periodSlug);

  const buttonColor =
    period?.hasBudgetItems === false ? 'primary' : 'secondary';

  const canEditBudget =
    !!period &&
    can({
      perform: 'update_budget',
      on: period,
    });

  return (
    <>
      <PageHeader periodSlug={periodSlug} />
      <ContentContainer>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <TrainingPeriodPicker
              renderingStyle="popover"
              currentPeriodSlug={periodSlug}
              onChange={(period: TrainingPeriod) => {
                navigate(pathToTrainingBudget(period.slug));
              }}
            />
            {canEditBudget && (
              <Button
                color={buttonColor}
                to={pathToTrainingBudgetEdit(periodSlug)}
              >
                {__('Edit budget')}
              </Button>
            )}
          </div>

          {
            /* The invariant is on the periodSlug and period is populate through a hook
              So if the component BudgetDetailsBody is renderer before the hook finish,
              that clearly happen when you reload the page for example, we have an error.
              So the safeguard */ !!period && (
              <BudgetDetailsBody period={period} />
            )
          }
        </div>
      </ContentContainer>
    </>
  );
};

export default BudgetDetails;
