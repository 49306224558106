import { useEffect, useMemo } from 'react';

import { useArrayMutation } from 'helpers/hooks';
import { nonDestroyed } from 'helpers/hooks/mutation/useRemoteDestroy';

import { BudgetForm, FormExchangeRate } from '../types';

/**
 * Creates exchange rates automatically when a new currency is being used
 * by an envelope or an item.
 */
const useAutoExchangeRate = (
  mainCurrency: string,
  form: BudgetForm,
  onChange: (exchangeRates: Array<FormExchangeRate>) => void
) => {
  const budgetEnvelopesCurrencies = useMemo(
    () =>
      Array.from(
        new Set(
          form.budgetEnvelopes
            .filter(nonDestroyed)
            .map(envelope => envelope.mainCurrency)
            .filter(currency => currency)
        )
      ),
    [form.budgetEnvelopes]
  );

  const budgetItemsCurrencies = useMemo(
    () =>
      Array.from(
        new Set(
          form.budgetEnvelopes
            .filter(nonDestroyed)
            .flatMap(envelope => envelope.budgetItems)
            .filter(nonDestroyed)
            .map(item => item.provisionedAmountCurrency)
            .filter(currency => currency)
        )
      ),
    [form.budgetEnvelopes]
  );

  const usedCurrencies = useMemo(
    () =>
      Array.from(
        new Set(budgetItemsCurrencies.concat(budgetEnvelopesCurrencies))
      ),
    [budgetItemsCurrencies, budgetEnvelopesCurrencies]
  );

  const { createItem: createExchangeRate } = useArrayMutation(
    form.exchangeRates,
    onChange
  );

  const definedCurrencies = useMemo(
    () =>
      form.exchangeRates
        .filter(nonDestroyed)
        .map(rate => rate.baseCurrency)
        .concat([mainCurrency]),
    [form.exchangeRates, mainCurrency]
  );

  useEffect(() => {
    const missingCurrencies = usedCurrencies.filter(
      usedCurrency => !definedCurrencies.includes(usedCurrency)
    );
    missingCurrencies.forEach(missingCurrency => {
      createExchangeRate({
        id: '',
        baseCurrency: missingCurrency,
        targetToBaseRate: '1',
        targetCurrency: mainCurrency,
      })();
    });
  }, [usedCurrencies, createExchangeRate, definedCurrencies, mainCurrency]);

  return { usedCurrencies, definedCurrencies };
};

export default useAutoExchangeRate;
