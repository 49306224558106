import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const NoSessionState = () => {
  return (
    <EmptyStateWithIcon
      title={__('There is no session in this plan yet')}
      description={__(
        'As soon as a training session is added to this plan, it will be displayed in this screen.'
      )}
      iconName={'school'}
      inBox={false}
    />
  );
};

export default NoSessionState;
