import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { TrainingRequest, TrainingSession } from 'models';

import { guessTimeZone } from 'helpers/date';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';
import { pathToTrainingSessionDetails } from 'helpers/navigation';

import { hydrateFromStore } from 'lib/dataLoader';
import { post } from 'redux/actions/api';

import {
  Button,
  Centered,
  Helper,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import AutoInvitationHelper from '../../components/AutoInvitationHelper';
import SessionSummary from '../../components/SessionSummary';
import AlreadyInTheSessionWarning from './AlreadyInTheSessionWarning';
import RequestList from './RequestList';
import SessionPicker from './SessionPicker';

type Props = {
  requestIds: Array<string>;
  onClose: () => void;
  createNewSessionWithSelectedRequests: () => Promise<void>;
};

const AddParticipantsToSessionModal = ({
  createNewSessionWithSelectedRequests,
  requestIds,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const addParticipants = (
    sessionId: string,
    trainingRequestIds: Array<string>
  ) =>
    dispatch(
      post(`training/sessions/${sessionId}/add_participants`, {
        trainingRequestIds,
        timezone: guessTimeZone(),
      })
    );

  const [selectedTrainingSession, setSelectedTrainingSession] =
    useState<TrainingSession | null>(null);
  const [showNoSessionWarning, setShowNoSessionWarning] = useState(false);

  const trainingRequests = useAppSelector(state =>
    requestIds.reduce(
      (requests: Array<TrainingRequest>, currentRequestId: string) => {
        const currentRequest = hydrateFromStore(
          state.data,
          { resourceType: 'trainingRequest', id: currentRequestId },
          { trainingRequest: { trainee: { manager: {} } } }
        );
        // We only want to keep one request per user
        if (
          !requests.map(e => e.trainee.id).includes(currentRequest.trainee.id)
        )
          requests.push(currentRequest);
        return requests;
      },
      []
    )
  );

  const handleSubmit = async () => {
    if (showNoSessionWarning) {
      await createNewSessionWithSelectedRequests();
    } else {
      const session = assert(
        selectedTrainingSession,
        'A training session must be selected.'
      );
      try {
        await addParticipants(
          session.id,
          trainingRequests.map(tr => tr.id)
        );
        navigate(pathToTrainingSessionDetails(session.id));
      } catch (e: unknown) {
        // The errors are already handled by the API action
      }
    }
  };

  const userIds = new Set(trainingRequests.map(request => request.trainee.id));

  const userIdsAlreadyInTheSession = selectedTrainingSession?.participantUserIds
    ? Array.from(
        new Set(selectedTrainingSession?.participantUserIds).intersection(
          userIds
        )
      )
    : [];

  const showUsersAlreadyInTheSession = userIdsAlreadyInTheSession.length > 0;
  const showAutoInvitationHelper =
    selectedTrainingSession?.autoInviteNewParticipants;

  return (
    <ModalCard
      isActive
      isLarge
      refreshContentOnOpening
      onClose={onClose}
      className="add-training-requests-to-session-modal"
    >
      <ModalCardHead>
        <ModalCardTitle>{__('Add to a training session')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Text
          preset="14bs6"
          style={{ display: 'block', marginBottom: 8, marginTop: 16 }}
        >
          {__('Select an existing training session')}
        </Text>
        {showNoSessionWarning ? (
          <Helper style={{ marginBottom: 0 }}>
            {__(
              'There is not training session in your platform. You can create a new one from the selected requests by clicking on the button below.'
            )}
          </Helper>
        ) : (
          <SessionPicker
            onChange={session => setSelectedTrainingSession(session)}
            selectedTrainingSession={selectedTrainingSession}
            setShowNoSessionWarning={setShowNoSessionWarning}
          />
        )}
        {selectedTrainingSession && (
          <SessionSummary
            session={selectedTrainingSession}
            className="my-2"
            sessionNameDisplayed={false}
          />
        )}
        <Text style={{ marginTop: 16, display: 'block' }}>
          {n__(
            'The following participant will be added to the training session',
            'The following %1 participants will be added to the training session',
            trainingRequests.length
          )}
        </Text>
        <RequestList trainingRequests={trainingRequests} />
        {(showUsersAlreadyInTheSession || showAutoInvitationHelper) && (
          <div className="flex flex-col gap-y-4 mt-4">
            {showUsersAlreadyInTheSession && (
              <AlreadyInTheSessionWarning
                userIds={userIdsAlreadyInTheSession}
              />
            )}
            {showAutoInvitationHelper && (
              <AutoInvitationHelper userCount={trainingRequests.length} />
            )}
          </div>
        )}
      </ModalCardBody>
      <ModalCardFooter>
        <Centered>
          <div style={{ marginTop: 16 }}>
            <Button onClick={onClose} color="secondary">
              {__('Cancel')}
            </Button>
            <Button
              disabled={!selectedTrainingSession && !showNoSessionWarning}
              onClick={handleSubmit}
              color="primary"
            >
              <Icon style={{ marginRight: 8 }} name="add" />
              {showNoSessionWarning
                ? __('Create a new training session')
                : selectedTrainingSession?.autoInviteNewParticipants
                ? __('Add and invite to the training session')
                : __('Add to the training session')}
            </Button>
          </div>
        </Centered>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default AddParticipantsToSessionModal;
