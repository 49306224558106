import type { User, UserReview, ViewerRole } from 'models';

const userIsReviewer = (user: User, userReview: UserReview): boolean =>
  user.id === userReview.reviewerId;

export const userIsReviewee = (user: User, userReview: UserReview): boolean =>
  user.id === userReview.userId;

export const viewerRole = (
  user: User,
  userReview: UserReview
): ViewerRole | null | undefined => {
  if (userIsReviewer(user, userReview)) {
    return 'reviewer';
  } else if (userIsReviewee(user, userReview)) {
    return 'reviewee';
  } else if (user.isOrganizationAdmin) {
    return 'admin';
  }

  return null;
};
