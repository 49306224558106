import React from 'react';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { CheckboxSwitch, Text, Title5 } from 'components';
import { Level, LevelLeft, LevelRight } from 'components/layout';
import WithSavingStatusRecorder from 'components/withSavingStatus/Recorder';

type Props = {
  hasAnonymizedResults: boolean;
  onChange: (newValue: boolean) => Promise<void>;
  disabled: boolean;
  templateId: string;
};

const AnonymousResults = ({
  hasAnonymizedResults,
  onChange,
  disabled,
  templateId,
}: Props) => {
  return (
    <>
      <Level>
        <LevelLeft>
          <Title5>{__('Anonymous feedback')}</Title5>
        </LevelLeft>
        <LevelRight>
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              templateId: templateId,
              field: 'has_anonymized_results',
            })}
            onChange={(value: boolean) => onChange(value)}
            render={autoSavingOnchange => (
              <CheckboxSwitch
                disabled={disabled}
                value={hasAnonymizedResults}
                onChange={newValue => autoSavingOnchange(newValue)}
                size="small"
              />
            )}
          />
        </LevelRight>
      </Level>

      <div style={{ marginTop: 8 }}>
        <Text color="grey">
          {__(
            'Allows you to hide the name of peers on the Results screen. A minimum of 3 peers is required to ensure anonymity'
          )}
        </Text>
      </div>
    </>
  );
};

export default AnonymousResults;
