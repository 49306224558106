import React from 'react';

import ConnectedState from './ConnectedState';
import PreviewState from './PreviewState';

export type Props = {
  emptyStateTitle: string;
  footer: React.ReactNode;
  isPreview: boolean;
  userId: string;
  userReviewId: string | undefined;
};

const TrainingRequestList = ({
  isPreview,
  emptyStateTitle,
  footer,
  userId,
  userReviewId,
}: Props) => {
  if (isPreview) {
    return <PreviewState title={emptyStateTitle} />;
  }

  return (
    <ConnectedState
      emptyStateTitle={emptyStateTitle}
      footer={footer}
      userId={userId}
      userReviewId={userReviewId}
    />
  );
};

export default TrainingRequestList;
