import React from 'react';
import { connect } from 'react-redux';

import type { InteractionType } from 'models';
import type {
  ReviewBlock,
  ReviewBlockUpdateParams,
} from 'models/ReviewStructure';
import type { CSSProperties, ReactNode } from 'react';
import type { AppDispatch } from 'redux/actions/types';

import { put } from 'redux/actions/api';

import DropdownQuestionBlock from './DropdownQuestionBlock';
import InstructionsBlock from './InstructionsBlock';
import LegacyQuestionBlock from './LegacyQuestionBlock';
import MultipleScaleQuestionBlock from './MultipleScaleQuestionBlock';
import NextObjectivesModuleBlock from './NextObjectivesModuleBlock';
import ReviewedObjectivesModuleBlock from './ReviewedObjectivesModuleBlock';
import SignatureBlock from './SignatureBlock';
import SkillsCareerLevelModuleBlock from './SkillsCareerLevelModuleBlock';
import TextQuestionBlock from './TextQuestionBlock';
import TitleBlock from './TitleBlock';
import TrainingHistoryBlock from './TrainingHistoryBlock';

type Props = {
  block: ReviewBlock;
  style?: CSSProperties;
  onDelete?: () => Promise<any>;
  justEnteredInList: boolean;
  hasSelfEvaluationEnabled: boolean;
  interactionType: InteractionType;
  updatePosition: (blockId: string, newPosition: number) => Promise<any>;
  onDuplicate: () => Promise<any>;
};

type AfterConnectProps = Props & {
  updateBlock: (params: ReviewBlockUpdateParams) => Promise<any>;
  moveUp: () => Promise<any>;
  moveDown: () => Promise<any>;
};

class ReviewBlockComponent extends React.Component<AfterConnectProps> {
  render() {
    const {
      block,
      onDelete,
      style,
      updateBlock,
      justEnteredInList,
      hasSelfEvaluationEnabled,
      interactionType,
      moveUp,
      moveDown,
      onDuplicate,
    } = this.props;

    let content: ReactNode = null;
    const blockType = block.blockType;

    switch (blockType) {
      case 'title':
        content = (
          <TitleBlock
            block={block}
            onChange={updateBlock}
            justEnteredInList={justEnteredInList}
            onDelete={onDelete}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
          />
        );
        break;
      case 'instructions':
        content = (
          <InstructionsBlock
            block={block}
            onChange={updateBlock}
            justEnteredInList={justEnteredInList}
            onDelete={onDelete}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
            onDuplicate={onDuplicate}
          />
        );
        break;
      case 'reviewedObjectivesModule':
        content = (
          <ReviewedObjectivesModuleBlock
            block={block}
            onChange={updateBlock}
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            onDelete={onDelete}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
          />
        );
        break;
      case 'question':
        content = (
          <LegacyQuestionBlock
            block={block}
            onDelete={onDelete}
            onChange={updateBlock}
            justEnteredInList={justEnteredInList}
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            interactionType={interactionType}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
            onDuplicate={onDuplicate}
          />
        );
        break;
      case 'nextObjectivesModule':
        content = (
          <NextObjectivesModuleBlock
            block={block}
            onChange={updateBlock}
            onDelete={onDelete}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
          />
        );
        break;
      case 'signature':
        content = (
          <SignatureBlock
            block={block}
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            onChange={updateBlock}
          />
        );
        break;
      case 'multipleScaleQuestion':
        content = (
          <MultipleScaleQuestionBlock
            block={block}
            interactionType={interactionType}
            justEnteredInList={justEnteredInList}
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            onChange={updateBlock}
            onDelete={onDelete}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
            onDuplicate={onDuplicate}
          />
        );
        break;
      case 'textQuestion':
        content = (
          <TextQuestionBlock
            block={block}
            interactionType={interactionType}
            justEnteredInList={justEnteredInList}
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            onChange={updateBlock}
            onDelete={onDelete}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
            onDuplicate={onDuplicate}
          />
        );
        break;
      case 'trainingRequestHistory': // Only use training_history EA-8158
      case 'trainingHistory':
        content = (
          <TrainingHistoryBlock
            block={block}
            onChange={updateBlock}
            onDelete={onDelete}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
          />
        );
        break;
      case 'skillsCareerLevel':
        content = (
          <SkillsCareerLevelModuleBlock
            block={block}
            interactionType={interactionType}
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            onChange={updateBlock}
            onDelete={onDelete}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
          />
        );
        break;
      case 'dropdownQuestion':
        content = (
          <DropdownQuestionBlock
            block={block}
            onDelete={onDelete}
            onChange={updateBlock}
            justEnteredInList={justEnteredInList}
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            interactionType={interactionType}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
            onDuplicate={onDuplicate}
          />
        );
        break;
      default:
        throw new Error(`${blockType} is not an element of type BlockType`);
    }

    return (
      <div style={style} className="review-block">
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { block, updatePosition }: Props
) => ({
  updateBlock: (params: ReviewBlockUpdateParams) =>
    dispatch(
      put(`review_blocks/${block.id}`, {
        reviewBlock: params,
      })
    ),
  moveUp: () => updatePosition(block.id, block.position - 1),
  moveDown: () => updatePosition(block.id, block.position + 1),
});

export default connect(
  null,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(ReviewBlockComponent) as React.ComponentType<Props>;
