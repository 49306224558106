import React from 'react';
import { useParams } from 'react-router-dom';

import { ContentContainer } from 'components';

import MatrixHeader from '../../components/SkillsMatrixHeader';
import EmployeesPaginatedList from './EmployeesPaginatedList';

export default function MatrixEmployeesList() {
  const { id: matrixId } = useParams();
  if (!matrixId) return null;

  return (
    <>
      <MatrixHeader matrixId={matrixId} />
      <ContentContainer>
        <EmployeesPaginatedList matrixId={matrixId} paginationType="url" />
      </ContentContainer>
    </>
  );
}
