import React from 'react';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Level,
  LevelLeft,
  LevelRight,
  Text,
  Title5,
  WithSavingStatusRecorder,
} from 'components';

type Props = {
  skillsCareerLevelEnabled: boolean;
  onChange: (value: boolean) => any;
  disabled: boolean;
  templateId: string;
};

export default function SkillsCareerLevelModule({
  skillsCareerLevelEnabled,
  onChange,
  disabled,
  templateId,
}: Props) {
  return (
    <>
      <Level>
        <LevelLeft>
          <Title5>{__('Skills')}</Title5>
        </LevelLeft>
        <LevelRight>
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              templateId: templateId,
              field: 'skills_career_level_block',
            })}
            onChange={onChange}
            render={autoSavingOnChange => (
              <CheckboxSwitch
                disabled={disabled}
                value={skillsCareerLevelEnabled}
                onChange={autoSavingOnChange}
                size="small"
              />
            )}
          />
        </LevelRight>
      </Level>
      <div style={{ marginTop: 8 }}>
        <Text color="grey">
          {__(
            "It allows the evaluation of the collaborator's skills in their position."
          )}
        </Text>
      </div>
    </>
  );
}
