import { pick } from 'lodash';

import { TrainingPeriodBudget, User } from 'models';

import { generateNewEnvelope } from '../FundingSources/helpers/generateNewEnvelope';
import {
  BudgetForm,
  FormBudgetEnvelope,
  FormBudgetItem,
  FormExchangeRate,
} from '../types';

export const formatPeriodToForm = (trainingPeriod: TrainingPeriodBudget) => {
  const form: BudgetForm = {
    budgetByEnvelope: trainingPeriod.budgetByEnvelope,
    exchangeRates: trainingPeriod.exchangeRates,
    budgetEnvelopes: [],
  };

  if (trainingPeriod.budgetByEnvelope) {
    form.budgetEnvelopes.push(...trainingPeriod.budgetEnvelopes);
  } else {
    const dummyEnvelope = generateNewEnvelope(trainingPeriod, []);
    dummyEnvelope.budgetItems.push(...trainingPeriod.budgetItems);
    form.budgetEnvelopes.push(dummyEnvelope);
  }

  return form;
};

export const formatFormToParams = (form: BudgetForm) => {
  if (form.budgetByEnvelope) {
    return {
      budgetByEnvelope: true,
      budgetEnvelopesAttributes: form.budgetEnvelopes.map(formatBudgetEnvelope),
      exchangeRatesAttributes: form.exchangeRates.map(formExchangeRate),
    };
  } else {
    return {
      budgetByEnvelope: false,
      budgetItemsAttributes: form.budgetEnvelopes
        .flatMap(envelope => envelope.budgetItems)
        .filter(item => item.fundingSource)
        .map(formatBudgetItem),
      exchangeRatesAttributes: form.exchangeRates.map(formExchangeRate),
    };
  }
};

const formatBudgetEnvelope = (budgetEnvelope: FormBudgetEnvelope) => ({
  ...pick(budgetEnvelope, ['id', 'name', 'mainCurrency', '_destroy']),
  responsiblesAttributes: budgetEnvelope.responsibles.map(formatResponsible),
  budgetItemsAttributes: budgetEnvelope.budgetItems
    .filter(item => item.fundingSource)
    .map(formatBudgetItem),
});

const formatBudgetItem = (budgetItem: FormBudgetItem) => ({
  ...pick(budgetItem, [
    'id',
    'provisionedAmountCents',
    'provisionedAmountCurrency',
    '_destroy',
  ]),
  fundingSourceAttributes: formatFundingSource(budgetItem.fundingSource),
});

const formatFundingSource = (
  fundingSource: FormBudgetItem['fundingSource']
) => {
  if (!fundingSource) return undefined;
  return pick(fundingSource, ['id', 'title']);
};

const formExchangeRate = (exchangeRate: FormExchangeRate) => ({
  ...pick(exchangeRate, [
    'id',
    'baseCurrency',
    'targetCurrency',
    'targetToBaseRate',
    '_destroy',
  ]),
});

const formatResponsible = (responsible: User) => pick(responsible, ['id']);
