import React, { ReactNode } from 'react';

import tailwindTheme from 'config/tailwindTheme';

import { Avatar, Tooltip } from 'components';

type Props = {
  count: number;
  tooltipContent?: ReactNode;
  children?: ReactNode;
  avatarImageClassName?: string;
};

export default function PlusXLeftAvatar({
  count,
  tooltipContent,
  children,
  avatarImageClassName,
}: Props) {
  const label = `+${count}`;
  const params = new URLSearchParams();
  params.append('name', label);
  params.append('length', label.length.toString());
  params.append('background', tailwindTheme.colors['ui-grey'].replace('#', ''));
  params.append('color', tailwindTheme.colors['text-main'].replace('#', ''));
  const url = `https://eu.ui-avatars.com/api/?${params.toString()}`;
  const proxiedUrl = `https://xc.elevo.fr/?u=${btoa(url)}`;
  return (
    <Tooltip content={tooltipContent}>
      <Avatar url={proxiedUrl} avatarImageClassName={avatarImageClassName}>
        {children}
      </Avatar>
    </Tooltip>
  );
}
