import React from 'react';
import { compose } from 'redux';

import type { SkillsArea, SkillsExpectation } from 'models';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer, FetchContainer } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import useParentAndChildrenHandlingWithErrors from '../../helpers/useParentAndChildrenHandlingWithErrors';
import AreaVisualizer from './AreaVisualizer';
import Header from './Header';

type AfterMatchParamsProps = {
  match: { id: string };
};

type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    area: SkillsArea;
  };

export type AreaErrorsType = {
  area?: {
    title?: Array<string>;
  };
  expectations?: {
    [id: string]: {
      title?: Array<string>;
      content?: Array<string>;
    };
  };
};

function AreaEditor({
  area,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) {
  const handlingProps = useParentAndChildrenHandlingWithErrors<
    SkillsArea,
    SkillsExpectation
  >({
    refetchData,
    parent: area,
    parentProps: {
      name: 'area',
      endpoint: 'skills/areas',
      errorField: 'area',
    },
    childProps: {
      name: 'expectation',
      endpoint: 'skills/expectations',
      errorField: 'expectations',
    },
  });

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => (
        <>
          {!!area && (
            <>
              <Header {...handlingProps.headerProps} />
              <ContentContainer>
                <AreaVisualizer
                  refetchData={refetchData}
                  {...handlingProps.editorProps}
                />
              </ContentContainer>
            </>
          )}
        </>
      )}
    />
  );
}

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`/skills/areas/${match.id}`),
    hydrate: { area: { expectations: {}, domain: {} } },
  })
)(AreaEditor);
