import { getCurrentLocale } from './i18n';

/**
 * @param amountCents integer
 * @param currency is the ISO code of a currency like 'EUR' or 'USD'
 * @param options.currencyDisplay is the way to display the currency, it can be 'symbol' (EUR) or 'code'
 * @param options.hideEmptyDecimal display 20€ instead of 20,00€
 */
export const formatMoney = (
  amountCents: number,
  currency: string,
  options?: {
    currencyDisplay?: Intl.NumberFormatOptions['currencyDisplay'];
    hideEmptyDecimal?: boolean;
  }
): string => {
  const amount = amountCents / 100.0;
  const minimumFractionDigits =
    options?.hideEmptyDecimal && amount % 1 === 0 ? 0 : 2;
  return amount.toLocaleString(getCurrentLocale(), {
    style: 'currency',
    currency,
    currencyDisplay: options?.currencyDisplay,
    minimumFractionDigits,
  });
};
