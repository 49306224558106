import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

type Props = {
  userFullName: string;
  onAllPeriods: boolean;
};

const NoRecordState = ({ userFullName, onAllPeriods }: Props) => {
  return (
    <EmptyStateWithIcon
      inBox={false}
      iconName="school"
      title={
        onAllPeriods
          ? __('%1 did not follow any trainings.', userFullName)
          : __('%1 did not follow any trainings on this period.', userFullName)
      }
    />
  );
};

export default NoRecordState;
