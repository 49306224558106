import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import classNames from 'helpers/classNames';
import { isActiveUrl } from 'helpers/navigation';

import Badge from 'components/Badge';
import Icon from 'components/Icon';
import materialIconMapping from 'components/Icon/materialIconMapping';
import { Link } from 'components/links';
import { Text } from 'components/text';

import './navItem.css';

type Props = {
  withArrow?: boolean;
  truncateTitle?: boolean;
  count?: number;
  title: string;
  path?: string;
  className?: string;
  titleClassName?: string;
  testClassName?: string;
  iconClassName?: string;
  additionalClassName?: string;
  iconName?: keyof typeof materialIconMapping;
  onClick?: () => void;
  matchFullPath?: boolean;
  withNotification?: boolean;
};

export default function NavItem({
  title,
  path,
  count,
  iconName,
  className,
  withArrow,
  truncateTitle,
  titleClassName,
  testClassName,
  additionalClassName,
  iconClassName,
  onClick,
  matchFullPath,
  withNotification,
}: Props) {
  const LinkComponent = path ? NavLink : Link;
  const { search, pathname } = useLocation();

  return (
    <LinkComponent
      className={classNames(
        'elevo-nav-item',
        className,
        testClassName,
        'group flex items-center w-full px-3 py-2.5 rounded-md hover:bg-nav-item-hover-bg',
        { 'focus:bg-nav-item-focus-bg': !withArrow },
        additionalClassName
      )}
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      to={path}
      tabIndex={0}
      onClick={onClick}
      {...(LinkComponent === Link
        ? {
            isActive: () => isActiveUrl(pathname, search, path, matchFullPath),
            activeClassName: 'active',
          }
        : {})}
    >
      {iconName && (
        <div className="relative">
          <Icon
            className={classNames(
              'nav-item-icon mr-3 text-nav-icon text-xl leading-5',
              iconClassName
            )}
            name={iconName}
          />
          {withNotification && (
            <span className="absolute top-0 right-0 block h-1.5 w-1.5 mr-2.5 rounded-full bg-nav-item-active-icon ring-2 ring-nav-bg" />
          )}
        </div>
      )}

      <Text
        className={classNames(
          'nav-item-title text-sm flex-1 !font-semibold text-left pr-2 text-nav-text',
          titleClassName,
          { truncate: truncateTitle }
        )}
      >
        {title}
      </Text>

      {!!count && (
        <Badge
          className="nav-item-badge text-nav-text bg-nav-badge-bg group-hover:bg-nav-item-hover-badge-bg group-focus:bg-nav-item-focus-badge-bg"
          count={count}
        />
      )}

      {withArrow && (
        <Icon
          className="ml-2 rounded-full text-nav-icon text-xl leading-5 hover:bg-nav-nav_group_caret-hover-bg elevo-accordion-caret"
          name="arrow_right"
        />
      )}
    </LinkComponent>
  );
}
