import React from 'react';

import { __ } from 'helpers/i18n';

import IntegrationSettingField from './IntegrationSettingField';

type Props = {
  settings: {
    clientId: string;
    clientSecret: string;
  };
  onChange: (key: string, value: string) => void;
};

export function ADPIntegrationSettings({ settings, onChange }: Props) {
  return (
    <>
      <IntegrationSettingField
        name="clientId"
        label={__('Client ID:')}
        value={settings.clientId}
        onUpdate={(value: string) => onChange('clientId', value)}
      />
      <IntegrationSettingField
        name="clientSecret"
        label={__('Client secret:')}
        value={settings.clientSecret}
        cleanOnFocus
        onUpdate={(value: string) => onChange('clientSecret', value)}
        placeholder={__('client secret')}
      />
    </>
  );
}

export const ADPIntegrationDefaultSettings = {
  clientId: '',
  clientSecret: '',
};
