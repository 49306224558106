import React from 'react';

import type { HomeMessage, NavActionsCount, Organization, User } from 'models';
import type { ComponentType } from 'react';

import {
  useActiveUser,
  useAppDispatch,
  useAppSelector,
  useSeasonalTheme,
} from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { hydrateFromStore } from 'lib/dataLoader';
import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, put } from 'redux/actions/api';

import { CheckboxSwitch, DesignSystem, Text, Tooltip } from 'components';

import HomeBanner from './HomeBanner';
import HomeLogo from './HomeLogo';
import HomeMainGrid from './components/HomeMainGrid';
import SeasonalComponent from './components/SeasonalComponent';

type Props = {};

type AfterConnectProps = {
  organization: Organization;
  activeUser: User;
  navActionsCount: NavActionsCount;
  homeMessage: HomeMessage;
} & Props &
  DataLoaderProvidedProps;

const Homepage = ({
  homeMessage,
  isFetching,
  hasError,
  refetchData,
}: AfterConnectProps) => {
  const activeUser = useActiveUser();
  const { displaySeasonalTheme, seasonalTheme } = useSeasonalTheme();
  const dispatch = useAppDispatch();

  const navActionsCount = useAppSelector(state =>
    hydrateFromStore(
      state.data,
      {
        resourceType: 'navActionsCount',
        id: 'nav_actions_count',
      },
      { navActionsCount: {} }
    )
  );

  const toggleSesonalTheme = (newValue: boolean) => {
    dispatch(
      put(`users/${activeUser.id}`, {
        user: {
          seasonalThemeEnabled: newValue,
        },
      })
    );
  };

  const customGreetings = () => {
    if (displaySeasonalTheme && seasonalTheme === 'new_year') {
      return __('Happy new year %1 🎉', activeUser.firstName);
    }
  };

  return (
    <DesignSystem version={2}>
      <div className="homepage mt-14 md:mt-0 relative">
        {seasonalTheme && (
          <>
            <div className="fixed top-56 right-6 z-10">
              <Tooltip
                content={
                  <Text>
                    {activeUser.seasonalThemeEnabled
                      ? __('Disable the seasonal theme')
                      : __('Enable the seasonal theme')}
                  </Text>
                }
              >
                <CheckboxSwitch
                  value={activeUser.seasonalThemeEnabled}
                  onChange={newValue => toggleSesonalTheme(newValue)}
                  size="small"
                />
              </Tooltip>
            </div>
            {displaySeasonalTheme && (
              <SeasonalComponent period={seasonalTheme} />
            )}
          </>
        )}
        <HomeBanner />
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <HomeLogo />
        </div>
        <div className="mx-auto max-w-7xl mt-8 px-4 sm:px-6 lg:px-8">
          <HomeMainGrid
            user={activeUser}
            actionsCount={navActionsCount}
            homeMessage={homeMessage}
            isFetchingHomeMessage={isFetching}
            hasError={hasError}
            refetchHomeMessage={refetchData}
            customGreetings={customGreetings()}
          />
        </div>
      </div>
    </DesignSystem>
  );
};

export default newDataLoader({
  fetch: () => get('/home_messages/current'),
  hydrate: {
    homeMessage: {},
  },
})(Homepage) as ComponentType<Props>;
