import React from 'react';

import { FormMessage } from 'models';

import { __ } from 'helpers/i18n';

import { Button, Icon, Text } from 'components';

import Message from './Message';

type Props = {
  messages: Array<FormMessage>;
  style?: React.CSSProperties;
};

export default function ReviewMessages({ messages, style }: Props) {
  return (
    <>
      {messages.map((message, index) => {
        switch (message.id) {
          case 'evaluation_not_completed':
            return (
              <Message
                key={index}
                style={style}
                message={message.content}
                cta={
                  <Button to={message.link} color="primary" size="small">
                    <Icon style={{ marginRight: 8 }} name="edit" />{' '}
                    <Text size={7}>{__('Complete my evaluation')}</Text>
                  </Button>
                }
              />
            );
          default:
            return <Message key={0} message={message.content} style={style} />;
        }
      })}
    </>
  );
}
