import React from 'react';

import { __ } from 'helpers/i18n';

import { ContentContainer, PageHeader, PageTitle } from 'components';

import PeopleReviewCycleListWithPagination from './PeopleReviewCycleListWithPagination';

const Index = () => {
  return (
    <div className="people-review-cycle-list">
      <PageTitle title={__('People review campaigns')} />
      <PageHeader title={__('People review campaigns')} />

      <ContentContainer>
        <PeopleReviewCycleListWithPagination
          defaultFilter={{ ongoing: true }}
        />
      </ContentContainer>
    </div>
  );
};

export default Index;
