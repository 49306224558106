import React from 'react';

import compositeKey from 'helpers/compositeKey';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Level,
  LevelLeft,
  LevelRight,
  Text,
  WithSavingStatusRecorder,
} from 'components';

type Props = {
  trainingHistoryEnabled: boolean;
  onChange: (newValue: boolean) => Promise<void>;
  isDisabled: boolean;
  templateId: string;
};

const TrainingHistoryModule = ({
  trainingHistoryEnabled,
  isDisabled,
  templateId,
  onChange,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const isRevampTrainingRequestHistoryEnabled = featureFlags.includes(
    'revampTrainingProfileAndReview'
  );

  return (
    <>
      <Level className="baseline" style={{ paddingTop: 8 }}>
        <LevelLeft style={{ maxWidth: '82%' }}>
          <Text preset="18bs5">
            {isRevampTrainingRequestHistoryEnabled
              ? __('History of training requests and sessions')
              : __('History of training requests')}
          </Text>
        </LevelLeft>
        <LevelRight testClassName="test-template-training-request-history-setting">
          <WithSavingStatusRecorder
            fieldUid={compositeKey({
              templateId: templateId,
              field: 'training_history_enabled',
            })}
            onChange={onChange}
            render={autoSavingOnchange => (
              <CheckboxSwitch
                disabled={isDisabled}
                value={trainingHistoryEnabled}
                onChange={autoSavingOnchange}
                size="small"
              />
            )}
          />
        </LevelRight>
      </Level>
      <div style={{ marginTop: 8 }}>
        <Text color="grey">
          {isRevampTrainingRequestHistoryEnabled
            ? __(
                'Allows displaying completed and upcoming training sessions as well as created training requests'
              )
            : __(
                'Allows you to display all the training requests made for the reviewee'
              )}
        </Text>
      </div>
    </>
  );
};

export default TrainingHistoryModule;
