import classNames from 'classnames';
import React from 'react';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

type Props = {
  ratingOptions: string[];
  className?: string;
};

const RatingSliderHeader = ({ ratingOptions, className }: Props) => (
  <>
    <div
      className={classNames(
        'flex flex-row justify-between col-span-10 gap-2 mr-2',
        className
      )}
    >
      {ratingOptions.map(ratingOption => (
        <Text
          key={ratingOption}
          preset="11s8"
          color="soften"
          className="text-center flex-1"
        >
          {ratingOption}
        </Text>
      ))}
    </div>
    <Text
      preset="11s8"
      color="soften"
      className={classNames('col-span-2 pl-2 text-center', className)}
    >
      {__("I don't know / can't evaluate")}
    </Text>
  </>
);

export default RatingSliderHeader;
