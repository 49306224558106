import React, { useContext } from 'react';

import { useArrayMutation } from 'helpers/hooks';
import { nonDestroyed } from 'helpers/hooks/mutation/useRemoteDestroy';
import { __, p__ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import {
  Button,
  EmptyStateWithIcon,
  Icon,
  Link,
  SimpleTable,
  Text,
  Tooltip,
} from 'components';
import { HeaderText } from 'components/SimpleTable/SimpleTable';

import AvatarList from 'scenes/components/AvatarList';

import { BudgetFormContext } from '../../Form';
import { FormBudgetEnvelope } from '../../types';
import useEnvelopeModalState from '../helpers/useEnvelopeModalState';
import EnvelopeMenu from './EnvelopeMenu';
import EnvelopeModal from './EnvelopeModal';

type EmptyStateProps = {
  onButtonClick: () => void;
};

const EmptyState = ({ onButtonClick }: EmptyStateProps) => (
  <EmptyStateWithIcon
    iconName="savings"
    inBox={false}
    title={__('Start allocating your budget')}
    description={
      <div className="flex flex-col items-center">
        <span>{__('Add your first budget envelope by clicking below')}</span>
        <Button color="secondary" className="mt-4" onClick={onButtonClick}>
          {p__('EnvelopeEmptyState', 'Add a budget envelope')}
        </Button>
      </div>
    }
  />
);

type Props = {
  budgetEnvelopes: Array<FormBudgetEnvelope>;
  onChange: (value: Array<FormBudgetEnvelope>) => void;
  canUpdateFullBudget: boolean;
};

const EnvelopeBreakdown = ({
  budgetEnvelopes,
  onChange,
  canUpdateFullBudget,
}: Props) => {
  const {
    createItem: createEnvelope,
    updateItem: updateEnvelope,
    deleteItem: deleteEnvelope,
  } = useArrayMutation(budgetEnvelopes, onChange);

  const { sumBudgetItems } = useContext(BudgetFormContext);

  const {
    openCreateModal,
    openEditModal,
    closeModal,
    isModalOpen,
    envelopeBeingEdited,
  } = useEnvelopeModalState();

  const modal = isModalOpen && (
    <EnvelopeModal
      envelope={envelopeBeingEdited}
      onConfirm={envelope => {
        if (envelopeBeingEdited) {
          const index = budgetEnvelopes.indexOf(envelopeBeingEdited);
          updateEnvelope(index)(envelope);
        } else {
          createEnvelope(envelope)();
        }
        closeModal();
      }}
      onCancel={closeModal}
    />
  );

  if (budgetEnvelopes.filter(nonDestroyed).length === 0) {
    return (
      <>
        <EmptyState onButtonClick={openCreateModal} />
        {modal}
      </>
    );
  }

  return (
    <>
      <SimpleTable
        keyFn={(envelope, index) => envelope.id || `i${index}`}
        rows={budgetEnvelopes}
        displayRow={envelope => nonDestroyed(envelope)}
        columns={[
          {
            header: __('Budget envelopes'),
            cell: envelope => (
              <Link onClick={() => openEditModal(envelope)}>
                {envelope.name}
              </Link>
            ),
          },
          {
            header: (
              <HeaderText>
                <Tooltip
                  isLight
                  placement="right"
                  className="max-w-md"
                  content={
                    <>
                      {__(
                        'Responsibles have access and can manage the budget envelope.'
                      )}
                      <br />
                      {__(
                        'Global admins and training admins have access to all budgets.'
                      )}
                    </>
                  }
                >
                  {__('Responsibles')}
                  <Icon className="mb-1 ml-2" name="info" color="info" />
                </Tooltip>
              </HeaderText>
            ),
            headerKey: 'responsibles',
            cell: envelope => (
              <AvatarList
                users={envelope.responsibles}
                totalUserCount={envelope.responsibles.length}
                maxInlineUsers={5}
                renderNoUser={() => (
                  <Text color="light" transformation="italic">
                    {__('No responsibles')}
                  </Text>
                )}
              />
            ),
          },
          {
            header: __('Allocated budget'),
            cell: envelope => {
              const envelopeTotal = sumBudgetItems(
                envelope.budgetItems,
                envelope.mainCurrency
              );
              return (
                <>
                  {envelope.budgetItems.length > 0 ? (
                    formatMoney(envelopeTotal, envelope.mainCurrency)
                  ) : (
                    <Text color="light" transformation="italic">
                      {__('No funding sources')}
                    </Text>
                  )}
                </>
              );
            },
          },
          {
            header: null,
            headerKey: 'actions',
            cell: (envelope, index) => {
              return canUpdateFullBudget ? (
                <EnvelopeMenu
                  envelope={envelope}
                  onChange={updateEnvelope(index)}
                  onDelete={deleteEnvelope(index)}
                  onEditClick={() => openEditModal(envelope)}
                />
              ) : null;
            },
            isNarrow: true,
          },
        ]}
      />
      {canUpdateFullBudget && (
        <Button color="secondary" className="mt-2" onClick={openCreateModal}>
          {p__('EnvelopeAddToList', 'Add a budget envelope')}
        </Button>
      )}
      {modal}
    </>
  );
};

export default EnvelopeBreakdown;
