import React, { useState } from 'react';

import type { TrainingSession } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Button, EmptyStateWithIcon } from 'components';

import AddParticipantsModal from '../../components/AddParticipantsModal';

type Props = {
  session: TrainingSession;
  refetchParticipants: () => void;
};

const NoRecordState = ({ session, refetchParticipants }: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const [isAddParticipantsModalActive, setIsAddParticipantsModalActive] =
    useState(false);

  return (
    <>
      <div className="flex flex-row-reverse">
        <Button
          onClick={() => setIsAddParticipantsModalActive(true)}
          color="secondary"
        >
          {__('Add participants')}
        </Button>
      </div>
      <EmptyStateWithIcon
        inBox={false}
        iconName="school"
        title={
          featureFlags.includes('addParticipantsToSession')
            ? __('Add participants to the training session')
            : __('This training session does not have any participant')
        }
        description={
          featureFlags.includes('addParticipantsToSession')
            ? __(
                'To add participants to this session, you can click on the "Add participants" button or select training requests.'
              )
            : undefined
        }
      />
      {isAddParticipantsModalActive && (
        <AddParticipantsModal
          session={session}
          onClose={() => setIsAddParticipantsModalActive(false)}
          onAfterAddition={refetchParticipants}
        />
      )}
    </>
  );
};

export default NoRecordState;
