import moment from 'moment';
import React from 'react';

import type { HistorySectionItem } from '../components/HistorySectionItem';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PeopleUserReview, User } from 'models';
import type { ComponentType } from 'react';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToPeopleUserReview } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Icon } from 'components';

import HistorySection, {
  HISTORY_ITEM_LIMIT,
} from '../components/HistorySection';

const mapPeopleUserReviewToHistoryItem = (
  peopleUserReview: PeopleUserReview
): HistorySectionItem => {
  invariant(
    peopleUserReview.peopleReviewCycle,
    'peopleReviewCycle must be defined'
  );

  const title = peopleUserReview.peopleReviewCycle.name;
  const subTitle = peopleUserReview.peopleGroups
    ? peopleUserReview.peopleGroups
        .map(peopleGroup => peopleGroup.title)
        .join(' / ')
    : '';

  return {
    id: peopleUserReview.id,
    title,
    subTitle,
    date: moment(peopleUserReview.createdAt).format('MMMM YYYY'),
    linkTo: can({ perform: 'show_content', on: peopleUserReview })
      ? pathToPeopleUserReview(peopleUserReview.id)
      : undefined,
  };
};

type Props = {
  user: User;
  skipIds: Array<string>;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    peopleUserReviews: Array<PeopleUserReview>;
  };

function PeopleReviewHistory({
  skipIds,
  peopleUserReviews,
  isFetching,
  hasError,
}: AfterConnectProps) {
  const historySectionItems = peopleUserReviews
    ?.filter(peopleUserReview => !skipIds.includes(peopleUserReview.id))
    .map(peopleUserReview =>
      mapPeopleUserReviewToHistoryItem(peopleUserReview)
    );

  return (
    <HistorySection
      icon={<Icon name="history" />}
      title={__('People Reviews')}
      historySectionItems={historySectionItems}
      emptyState={__('No people review has been done')}
      isFetching={isFetching}
      hasError={hasError}
    />
  );
}

// As we dont want to display the current people user review in the people reviews history,
//  we fetch limit + 2 and remove it from the list
export default newDataLoader({
  fetch: ({ user }) =>
    get(`users/${user.id}/people_reviews`, {
      limit: HISTORY_ITEM_LIMIT + 2,
    }),
  hydrate: {
    peopleUserReviews: {
      abilities: {},
      peopleReviewCycle: {},
      peopleGroups: {},
    },
  },
})(PeopleReviewHistory) as ComponentType<Props>;
