import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import ResizeObserver from 'resize-observer-polyfill';

import {
  getStage,
  isDevelopment,
  isProduction,
  isStaging,
  isTest,
} from 'config/environment';
import { initializeBrowserUpdate } from 'config/services/browserUpdate';

import { assertDefined } from 'helpers/invariant';
import {
  UnsupportedBrowserMessage,
  UnsupportedBrowserSettingsMessage,
  isUnsupportedBrowser,
  isUnsupportedBrowserSettings,
} from 'helpers/legacyBrowsers';
import xhrActivityDetectorPatch from 'helpers/xhrActivityDetectorPatch';

import Root from './config/Root';
import { guessLocaleFromNavigator } from './helpers/i18n';

require('focusin').polyfill();

// Polyfill ResizeObserver for old browsers (not included in core-js)
if (typeof window.ResizeObserver === 'undefined') {
  window.ResizeObserver = ResizeObserver;
}

// Do this first. If everything else fails because of an outdated browser,
// at least they'll have a banner about it.
if (!isDevelopment() && !isTest()) {
  initializeBrowserUpdate();
}

let version: string | undefined;
try {
  version = require('./version');
} catch (e) {
  version = 'unknown-frontend';
}

guessLocaleFromNavigator();

// Polyfill Intl for unsupported browsers (Safari, IE)
if (!window.Intl) {
  require('intl');
}

// Fallback for TypeScript Primitive
type Primitive = boolean | null | number | string | symbol | typeof undefined;

// @ts-expect-error: TSFIXME
window.logException = function (
  ex,
  data: {
    extra?: {
      [x: string]: unknown;
    };
    tags?: {
      [x: string]: Primitive;
    };
  }
): void {
  if (isProduction() || isStaging()) {
    if (!ex.isHandled && Sentry) {
      Sentry.withScope(scope => {
        if (data) {
          if (data['extra']) {
            Object.entries(data['extra']).forEach(([key, value]) => {
              scope.setExtra(key, value);
            });
          }
          if (data['tags']) {
            scope.setTags(data['tags']);
          }
        }
        Sentry.captureException(ex);
      });
    }
  }
  /*eslint no-console:0*/
  window.console && console.error && console.error(ex);
};

if (isProduction() || isStaging()) {
  Sentry.init({
    dsn: 'https://af6650b50e3709da4c2bd90a69f62dde@o4509054778015744.ingest.de.sentry.io/4509055822594128',
    integrations: [new Integrations.BrowserTracing()],
    release: version,
    environment: getStage(),
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr') {
        if (
          hint?.xhr?.getAllResponseHeaders()?.indexOf('x-request-id') >= 0 &&
          typeof breadcrumb.data !== 'undefined'
        ) {
          breadcrumb.data['x-request-id'] =
            hint?.xhr?.getResponseHeader('x-request-id');
        }
      }
      return breadcrumb;
    },
    denyUrls: [
      /translate.goog/i,
      // Chrome urls (extensions, themes, ...)
      /^chrome:\/\//i,
      /^chrome-extension/i,
    ],
    beforeSend(event, hint) {
      const error = hint?.originalException;
      // @ts-expect-error: error can be a string
      if (error?.message?.match(/Failed to fetch/i)) {
        const chromeExtensionFound = event.breadcrumbs
          ?.filter(breadcrumb => breadcrumb.category === 'fetch')
          ?.find(
            breadcrumb =>
              breadcrumb.message?.match(/^chrome-extension/i) ||
              breadcrumb.data?.url.match(/^chrome-extension/i)
          );
        if (chromeExtensionFound)
          event.fingerprint = ['error-chrome-extension'];
      }
      return event;
    },
  });

  if (process.env.REACT_APP_DATADOG_RUM_ENABLE === 'true') {
    const applicationId = assertDefined(
      process.env.REACT_APP_DATADOG_APPLICATION_ID,
      'REACT_APP_DATADOG_APPLICATION_ID should be defined'
    );
    const clientToken = assertDefined(
      process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      'REACT_APP_DATADOG_CLIENT_TOKEN should be defined'
    );
    const sampleRate = parseInt(
      process.env.REACT_APP_DATADOG_SAMPLE_RATE || '1'
    );
    const sessionReplaySampleRate = parseInt(
      process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE || '0'
    );

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.eu',
      service: 'elevo-frontend',
      env: getStage(),

      // Specify a version number to identify the deployed version of your application in Datadog
      version: version,
      sampleRate,
      sessionReplaySampleRate,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
}

// Adds a `window.hasPendingXHR()` in dev and test environment
// Useful for Selenium to roughly tell when a page has loaded
if (isTest() || isDevelopment()) {
  xhrActivityDetectorPatch({ debug: false });
}

const rootEl = document.getElementById('root');
if (!rootEl) throw new Error('Could not find root element in index.html');

if (isUnsupportedBrowser()) {
  ReactDOM.render(<UnsupportedBrowserMessage />, rootEl);
} else if (isUnsupportedBrowserSettings()) {
  ReactDOM.render(<UnsupportedBrowserSettingsMessage />, rootEl);
} else {
  ReactDOM.render(<Root />, rootEl);
}
