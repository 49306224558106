import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { ReviewTemplate } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer, Notification } from 'components';

import DropdownQuestionBlockResults from './blocks/DropdownQuestionBlockResults';
import LegacyQuestionBlockResults from './blocks/LegacyQuestionBlockResults';
import MultipleScaleQuestionBlockResults from './blocks/MultipleScaleQuestionBlockResults';
import TextQuestionBlockResults from './blocks/TextQuestionBlockResults';
import TitleBlockResults from './blocks/TitleBlockResults';

type Props = {
  templateId: string;
  reviewCycleId: string;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    template: ReviewTemplate;
  };

function TemplateResults({
  reviewCycleId,
  template,
  isFetching,
  hasError,
}: AfterConnectProps) {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const { reviewStructure } = template;
        const { reviewBlocks } = reviewStructure;
        const supportedBlockTypes = [
          'title',
          'question',
          'textQuestion',
          'dropdownQuestion',
          'multipleScaleQuestion',
        ];

        const displayedBlocks = reviewBlocks.filter(block =>
          supportedBlockTypes.includes(block.blockType)
        );
        const formHasAnyQuestionBlock = displayedBlocks.some(block =>
          [
            'question',
            'textQuestion',
            'dropdownQuestion',
            'multipleScaleQuestion',
          ].includes(block.blockType)
        );

        if (!formHasAnyQuestionBlock) {
          return (
            <Notification kind="info">
              <p>
                {__(
                  'The current template does not have any question. You can check statistics on objectives in the Progress view.'
                )}
              </p>
            </Notification>
          );
        }

        return (
          <div className="user-review-content test-user-review-content">
            {displayedBlocks.map((block, i) => {
              const isFirstBlock = i === 0;

              switch (block.blockType) {
                case 'title':
                  return (
                    <TitleBlockResults
                      block={block}
                      withoutSectionSeparator={isFirstBlock}
                      key={block.id}
                    />
                  );
                case 'question':
                  return (
                    <LegacyQuestionBlockResults
                      block={block}
                      reviewCycleId={reviewCycleId}
                      key={block.id}
                    />
                  );
                case 'textQuestion':
                  return (
                    <TextQuestionBlockResults
                      block={block}
                      reviewCycleId={reviewCycleId}
                      key={block.id}
                    />
                  );
                case 'dropdownQuestion':
                  return (
                    <DropdownQuestionBlockResults
                      block={block}
                      reviewCycleId={reviewCycleId}
                      key={block.id}
                    />
                  );
                case 'multipleScaleQuestion':
                  return (
                    <MultipleScaleQuestionBlockResults
                      block={block}
                      reviewCycleId={reviewCycleId}
                      key={block.id}
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
        );
      }}
    />
  );
}

export default newDataLoader({
  fetch: ({ templateId }: Props) => get(`review_templates/${templateId}`),
  hydrate: {
    template: {
      reviewStructure: {
        reviewBlocks: {
          revieweeFeedbackOptions: {},
          reviewerFeedbackOptions: {},
          revieweeRatingOptions: {},
          reviewerRatingOptions: {},
          ratingOptions: {},
          ratingCriteria: {},
        },
      },
    },
  },
  cacheKey: ({ templateId }: Props) => compositeKey({ templateId }),
})(TemplateResults) as React.ComponentType<Props>;
