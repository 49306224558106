import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { __ } from 'helpers/i18n';

import PageTitle from 'components/PageTitle';

import Band from './Band';
import Bands from './Bands';

const CompensationRoutes = () => {
  return (
    <>
      <PageTitle title={__('Compensation')} />

      <Routes>
        <Route path="bands" element={<Bands />} />
        <Route path="bands/:id" element={<Band />} />
      </Routes>
    </>
  );
};

export default CompensationRoutes;
