import React, { useState } from 'react';

import { ExchangeRate } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { del, put } from 'redux/actions/api';

import {
  Button,
  Icon,
  Select,
  SimpleFloatInput,
  Text,
  WithSavingStatusRecorder,
  stringToOption,
} from 'components';

import { refetchBudgetStats } from './BudgetSummary';

type Props = {
  periodSlug: string;
  availableCurrencies: string[];
  exchangeRate: ExchangeRate;
  locked: boolean;
  refetchData: () => void;
};

const ExchangeRateItem = ({
  periodSlug,
  availableCurrencies,
  exchangeRate: exchangeRateFromProps,
  locked,
  refetchData,
}: Props) => {
  const dispatch = useAppDispatch();
  const [exchangeRate, setExchangeRate] = useState<ExchangeRate>(
    exchangeRateFromProps
  );

  const deleteExchangeRate = () =>
    dispatch(del(`exchange_rates/${exchangeRate.id}`));

  const updateExchangeRate = (exchangeRate: Partial<ExchangeRate>) =>
    dispatch(
      put(`exchange_rates/${exchangeRateFromProps.id}`, { ...exchangeRate })
    );

  const refetchStats = () => dispatch(refetchBudgetStats(periodSlug));

  return (
    <div className="flex justify-between items-center my-4 test-training-exchange-rate-item">
      <WithSavingStatusRecorder
        onChange={async (baseCurrency: string) => {
          setExchangeRate({ ...exchangeRate, baseCurrency });
          await updateExchangeRate({ baseCurrency });
        }}
        render={autoSavingOnChange => (
          <Select
            value={stringToOption(exchangeRate.baseCurrency)}
            options={availableCurrencies.map(stringToOption)}
            onChange={option => {
              invariant(
                option && !Array.isArray(option),
                'Option must exist and cannot be an array'
              );

              autoSavingOnChange(option.value);
            }}
            isMulti={false}
            isClearable={false}
            inModal
            className="w-1/3"
            isDisabled={locked}
          />
        )}
      />

      <div className="flex items-center">
        <Text color="light" className="mr-2">
          {__('1 %1 is equal to: ', exchangeRate.targetCurrency)}
        </Text>

        <WithSavingStatusRecorder
          onChange={async (targetToBaseRate: string) => {
            setExchangeRate({ ...exchangeRate, targetToBaseRate });
            await updateExchangeRate({ targetToBaseRate });
            await refetchStats();
          }}
          render={autoSavingOnChange => (
            <SimpleFloatInput
              value={exchangeRate.targetToBaseRate}
              onValueChange={autoSavingOnChange}
              label={exchangeRate.baseCurrency}
            />
          )}
        />
      </div>

      <Button
        onClick={async () => {
          await deleteExchangeRate();
          refetchData();
        }}
        className="border-none bg-transparent"
        disabled={locked}
      >
        <Icon name="close" />
      </Button>
    </div>
  );
};

export default ExchangeRateItem;
