import { useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation, useNavigationType } from 'react-router-dom';

export * from './paths';

export const useCanGoBack = () => {
  const [historyChanged, setHistoryChanged] = useState(false);
  const hasSetRef = useRef(false);
  const navigationType = useNavigationType();

  useEffect(() => {
    if (!hasSetRef.current && navigationType !== 'POP') {
      setHistoryChanged(true);
      hasSetRef.current = true;
    }
  }, [navigationType]);

  return historyChanged;
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const isActiveUrl = (
  refPathname: string,
  refSearch: string,
  path: string | null | undefined,
  matchFullPath: boolean = false
) => {
  if (!path) {
    return false;
  }

  const iUrl = new URL(
    window.location.protocol + '//' + window.location.host + path
  );

  let match = !matchFullPath
    ? iUrl.pathname === refPathname
    : refPathname.startsWith(iUrl.pathname);

  if (!match) {
    return false;
  }

  const searchParams = new URLSearchParams(refSearch);
  return iUrl.searchParams.get('type') === searchParams.get('type');
};

const PATHS_WITHOUT_NAVBAR = [
  '/o/:organizationShortName/evaluations/:id',
  '/o/:organizationShortName/reviews/:reviewId',
  '/o/:organizationShortName/admin/templates/:id/preview/v2/:role',
];

export const isPageWithoutNavbar = (currentPathname: string) => {
  return PATHS_WITHOUT_NAVBAR.some(path => {
    const match = matchPath(path, currentPathname);
    if (match?.params?.reviewId) {
      // regex to only remove navbar on a reviews show (with id)
      // Only return true if reviewId is a valid number
      return /^\d+$/.test(match.params.reviewId);
    }
    return !!match;
  });
};
