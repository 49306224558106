import classNames from 'classnames';
import React, { CSSProperties } from 'react';

import type { ReactNode } from 'react';

import { hasShape, stringType } from 'types/predicates/WithShape';

import { Icon, Notification, StrictlySanitizedHtml, Text } from 'components';

type BaseProps = {
  style?: CSSProperties;
  className?: string;
};

type Props =
  | (BaseProps & {
      text: string;
    })
  | (BaseProps & {
      children: ReactNode;
    });

const BlueInfoBox = (props: Props) => {
  const { className, style } = props;

  return (
    <Notification
      className={classNames('bg-blue-200', className)}
      style={style}
    >
      <Text className="mr-2 text-blue-500 self-start">
        <Icon name="info" className="flex" />
      </Text>
      <Text preset="13s7">
        {hasShape(props, { text: stringType }) ? (
          <StrictlySanitizedHtml html={props.text} />
        ) : (
          props.children
        )}
      </Text>
    </Notification>
  );
};

export default BlueInfoBox;
