import React, { ComponentType } from 'react';
import { compose } from 'redux';

import type { OrganizationSyncWithStats } from 'models';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { DesignSystem, FetchContainer, PageTitle } from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import OrganizationSyncDetails from './OrganizationSyncDetails';

type Props = {};

type AfterMatchParamsProps = Props & {
  match: {
    id: string;
  };
};
type AfterDataLoaderProps = AfterMatchParamsProps &
  DataLoaderProvidedProps & {
    organizationSync: OrganizationSyncWithStats;
  };

const SyncDetails = ({
  organizationSync,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <DesignSystem version={2}>
      <PageTitle title={[__('Synchronization'), __('Admin')]} />
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <OrganizationSyncDetails organizationSync={organizationSync} />
        )}
      />
    </DesignSystem>
  );
};

export default compose<ComponentType<Props>>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`organization_syncs/${match.id}`),
    hydrate: {
      organizationSync: {
        userSyncsWithErrors: {},
        userSyncsWithWarnings: {},
        lastPendingExportAction: {},
      },
    },
  })
)(SyncDetails);
