import React from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToTrainingBudgetEdit } from 'helpers/paths';

import { Button, Flex, Text } from 'components';

import SavingsIcon from './svg/SavingsIcon';

type Props = {
  /**
   * @deprecated After FF budgetEnvelopes deletion
   */
  onClick: () => void;
  periodSlug: string;
};

const BudgetCreationCard = ({ onClick, periodSlug }: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const budgetEnvelopesEnabled = featureFlags.includes('budgetEnvelopes');

  return (
    <Flex className="py-1">
      <SavingsIcon />
      <Text
        size={6}
        color="light"
        align="left"
        style={{ paddingLeft: 8, paddingRight: 8, flexGrow: 1 }}
      >
        {__('Define your budget and funding sources')}
      </Text>

      <Button
        testClassName="test-define-budget-button"
        color="secondary"
        onClick={budgetEnvelopesEnabled ? undefined : onClick}
        to={
          budgetEnvelopesEnabled
            ? pathToTrainingBudgetEdit(periodSlug)
            : undefined
        }
      >
        {__('Define')}
      </Button>
    </Flex>
  );
};

export default BudgetCreationCard;
