import React, { useState } from 'react';

import type { SkillsMatrix } from 'models';

import { useOrganizationSettings } from 'helpers/hooks';

import AreaCreationFromMatrixModal from './AreaCreationFromMatrixModal';
import AreaImportModal from './AreaImportModal';
import DomainImportModal from './DomainImportModal';
import DropdownButton from './DropdownButton';

export type AdditionType = 'import' | 'create' | 'import_domain' | null;

type ModalParams = null | {
  position: number;
  type: AdditionType;
};

export type ModalProps = {
  matrix: SkillsMatrix;
  modalParams: ModalParams;
  setModalParams: (params: ModalParams) => void;
};

export default function useAreaAction(matrix: SkillsMatrix) {
  const [modalParams, setModalParams] = useState<ModalParams>(null);

  const { skillsMaxAreasInMatrix } = useOrganizationSettings();

  const disableAreaAction =
    !!matrix.areaMappings &&
    matrix.areaMappings.length >= skillsMaxAreasInMatrix;

  return {
    renderDropdownButton: (params: { position: number }) => (
      <DropdownButton
        disabled={disableAreaAction}
        onClick={(type: AdditionType) => {
          setModalParams({ position: params.position, type });
        }}
      />
    ),
    renderModal: () => (
      <>
        {modalParams?.type === 'create' && (
          <AreaCreationFromMatrixModal
            matrix={matrix}
            modalParams={modalParams}
            setModalParams={setModalParams}
          />
        )}
        {modalParams?.type === 'import' && (
          <AreaImportModal
            matrix={matrix}
            modalParams={modalParams}
            setModalParams={setModalParams}
          />
        )}
        {modalParams?.type === 'import_domain' && (
          <DomainImportModal
            matrix={matrix}
            modalParams={modalParams}
            setModalParams={setModalParams}
          />
        )}
      </>
    ),
  };
}
