import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { InteractionType } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';
import 'helpers/navigation';
import { pathToReviewCycleEdit, pathToReviewCycles } from 'helpers/navigation';
import { useQuery } from 'helpers/navigation';
import { trackAction } from 'helpers/tracking';

import { post } from 'redux/actions/api';

import { Button, DesignSystem, PageHeader, PageTitle } from 'components';

import Stepper from 'scenes/components/Stepper';

import getSetupStepInfoList from '../helpers/steps';
import Step1Name from './Step1Name';

const New = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const type = query.get('type') as InteractionType;
  const navigate = useNavigate();

  const createReviewCycleAndRedirect = async params => {
    const response = await dispatch(post('review_cycles', params));
    const reviewCycleId = response.response.body.data.id;
    trackAction('Review cycle draft created', {
      reviewCycleId: reviewCycleId,
    });
    navigate(pathToReviewCycleEdit(reviewCycleId));
  };

  return (
    <DesignSystem version={2}>
      <div className="review-cycle-setup">
        <PageHeader
          title={__('New campaign')}
          actions={
            <Button color="danger" isOutlined disabled>
              {__('Delete campaign')}
            </Button>
          }
          withBackButton
          backButtonProps={{
            target: type ? pathToReviewCycles(type) : null,
            children: __('Back to campaigns'),
          }}
        />
        <PageTitle title={[__('New campaign'), __('Admin')]} />

        <Stepper
          currentStepIndexFromElement={0}
          stepInfoList={getSetupStepInfoList(null)}
          renderChildren={() => (
            <Step1Name
              cycleType={type}
              createReviewCycleAndRedirect={createReviewCycleAndRedirect}
            />
          )}
        />
      </div>
    </DesignSystem>
  );
};

export default New;
