import React from 'react';

import type { TrainingSession } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { del } from 'redux/actions/api';

import {
  Button,
  FetchContainer,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  StrictlySanitizedHtml,
  Text,
} from 'components';

type Props = {
  session: TrainingSession;
  onClose: () => void;
  onAfterDelete: () => void;
  isFetching?: boolean;
  hasError?: boolean;
};

const Modal = ({
  isFetching,
  hasError,
  session,
  onClose,
  onAfterDelete,
}: Props) => {
  const dispatch = useAppDispatch();
  const deleteSession = async () => {
    await dispatch(del(`training/sessions/${session.id}`));
    onAfterDelete();
    onClose();
  };
  const someInvitationsCancelable =
    !!session?.cancelableInvitationCount &&
    session.cancelableInvitationCount > 0;

  const removeRequestsWarning = (
    <StrictlySanitizedHtml
      html={__(
        'The training request(s) linked to this session will be visible in the "Training requests" tab but <b>the related costs and funding sources will be deleted,</b> having an impact on the budget.'
      )}
    />
  );

  return (
    <ModalCard isActive refreshContentOnOpening onClose={onClose}>
      <ModalCardHead>
        <ModalCardTitle>{__('Confirm deletion')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody className="pb-4">
        <FetchContainer
          isFetching={!!isFetching}
          hasError={!!hasError}
          render={() => {
            return (
              <>
                <p>
                  {__('This action will remove the training session')}
                  {session.name ? ` ${session.name}.` : '.'}
                </p>
                {someInvitationsCancelable ? (
                  <ul className="list-disc pl-6">
                    <li>{removeRequestsWarning}</li>
                    <li>
                      {n__(
                        'An email will be sent to the participant to cancel the calendar invitation.',
                        'An email will be sent to the participants to cancel the calendar invitation.',
                        session.cancelableInvitationCount || 0
                      )}
                    </li>
                  </ul>
                ) : (
                  <p className="py-6 px-0">{removeRequestsWarning}</p>
                )}
                {__('Are you sure you want to remove this training session?')}
                <div className="mt-5">
                  <Text color="danger">
                    <Icon className="mr-2" name="warning" />
                    {__('This action cannot be undone')}
                  </Text>
                </div>
              </>
            );
          }}
        />
      </ModalCardBody>
      <ModalCardFooter>
        <Button
          onClick={deleteSession}
          color="danger"
          disabled={isFetching || hasError}
        >
          {someInvitationsCancelable && <Icon className="mr-2" name="send" />}
          {__('Delete session')}
        </Button>
        <Button onClick={onClose} color="secondary">
          {__('Cancel')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default Modal;
