import classNames from 'classnames';
import React, { ReactElement } from 'react';

import type { EvaluationStatus, User } from 'models';

import { clipArray } from 'helpers/array';
import { n__ } from 'helpers/i18n';

import { Avatar, PlusXLeftAvatar, Tooltip } from 'components';

import {
  AvatarWithStatus,
  AvatarWithStatusIndicator,
} from 'scenes/components/AvatarWithStatus';

import UserAvatar from './UserAvatar';

type Props = {
  users: Array<User>;
  statuses?: Array<EvaluationStatus>;
  maxInlineUsers: number;
  renderNoUser: () => ReactElement;
  testClassName?: string;
  totalUserCount?: number;
};

const AvatarList = ({
  users,
  statuses,
  maxInlineUsers,
  renderNoUser,
  testClassName,
  totalUserCount,
}: Props) => {
  if (users.length === 0) return renderNoUser();

  const maxTooltipUsers = 9;
  const [inlineUsers, remainingInlineUsers] = clipArray(users, maxInlineUsers);
  const [tooltipUsers, remainingUsers] = clipArray(
    remainingInlineUsers,
    maxTooltipUsers
  );
  const avatarContainerClass = 'inline-block';
  const avatarImageClass = 'border-[2px] border-ui-bg border-solid box-content';
  const flexItemClass = 'min-w-0';
  const remainingUserInlineCount = !!totalUserCount
    ? totalUserCount - maxInlineUsers
    : 0;
  const remainingUserInTooltipCount = !!totalUserCount
    ? remainingUserInlineCount - maxTooltipUsers
    : remainingUsers.length;

  return (
    <div className="flex max-w-full">
      {inlineUsers.map((user, i) => {
        const tooltipContent = statuses ? (
          <AvatarWithStatus
            user={user}
            status={statuses[i]}
            avatarImageClassName={avatarImageClass}
          />
        ) : (
          <UserAvatar
            user={user}
            withJobTitle
            avatarImageClassName={avatarImageClass}
          />
        );
        const tooltipTarget = statuses ? (
          <AvatarWithStatusIndicator
            status={statuses[i]}
            user={user}
            avatarImageClassName={avatarImageClass}
          />
        ) : (
          <Avatar
            url={user.avatarUrl}
            avatarImageClassName={avatarImageClass}
          />
        );
        return (
          <span
            key={user.id + i}
            className={classNames(
              avatarContainerClass,
              flexItemClass,
              testClassName
            )}
          >
            <Tooltip content={tooltipContent}>{tooltipTarget}</Tooltip>
          </span>
        );
      })}
      {tooltipUsers.length > 0 && (
        <span className={avatarContainerClass}>
          <PlusXLeftAvatar
            count={remainingUserInlineCount}
            avatarImageClassName={avatarImageClass}
            tooltipContent={
              <>
                {tooltipUsers.map((user, i) => (
                  <div
                    style={{ marginTop: i === 0 ? undefined : 8 }}
                    key={user.id + i}
                  >
                    {statuses ? (
                      <AvatarWithStatus
                        user={user}
                        status={statuses[maxInlineUsers + i]}
                      />
                    ) : (
                      <UserAvatar user={user} withJobTitle />
                    )}
                  </div>
                ))}
                {remainingUserInTooltipCount > 0 && (
                  <div style={{ marginTop: 8 }}>
                    <PlusXLeftAvatar count={remainingUserInTooltipCount}>
                      {n__(
                        '%1 more employee',
                        '%1 more employees',
                        remainingUserInTooltipCount
                      )}
                    </PlusXLeftAvatar>
                  </div>
                )}
              </>
            }
          />
        </span>
      )}
    </div>
  );
};

export default AvatarList;
