import moment from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import classNames from 'helpers/classNames';
import { __, getCurrentLocale } from 'helpers/i18n';

import { ClickAwayListener } from 'components';
import CalendarIconSvg from 'components/Icon/calendarIconSvg';

import {
  type StylingProps as InputStylingProps,
  inputClassName,
} from '../bulmaElements/Input';

type TimeProps = {
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeCaption?: string;
  timeIntervals?: number;
};

export type SharedWithTimePickerProps = {
  value: Date | null | undefined;
  onChange: (value: Date | null | undefined) => any;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  openToDate?: Date;
  withPortal?: boolean;
  onBlur?: () => any;
  filterTime?: (value: Date) => boolean;
  name?: string;
  popperPlacement?: string;
  showIcon?: boolean;
} & InputStylingProps;

type Props = SharedWithTimePickerProps & {
  minDate?: Date;
  maxDate?: Date;
  isFullWidth?: boolean;
} & TimeProps &
  InputStylingProps;

function DatePicker({
  value,
  minDate,
  maxDate,
  isFullWidth,
  onBlur,
  filterTime,
  autoFocus,
  placeholder,
  onChange,
  withPortal,
  showTimeSelect,
  showTimeSelectOnly,
  timeCaption,
  timeIntervals,
  disabled,
  openToDate,
  name,
  popperPlacement,
  ...otherProps
}: Props) {
  const currentLocale = getCurrentLocale() || moment.locale();

  // See date-fns formats:  P is Long localized date and p is Long localized time
  const dateFormat = showTimeSelectOnly ? 'p' : 'P';

  // Workaround for https://github.com/Hacker0x01/react-datepicker/issues/2544
  const onCalendarOpen = () => {
    if (showTimeSelect && showTimeSelectOnly && withPortal) {
      const defaultDate = new Date(2020, 12, 31, 10, 0, 0); // We are only interested by the time of the date here
      const consideredDate = value || openToDate || defaultDate;
      const selectedTime = moment(consideredDate).format('LT');
      const availableTimesNodes = Array.from(
        document.querySelectorAll('.react-datepicker__time-list-item')
      );
      const selectedTimeNode = availableTimesNodes.find(timeNode =>
        timeNode.textContent?.includes(selectedTime)
      );

      if (selectedTimeNode) {
        selectedTimeNode.scrollIntoView({ block: 'center' });
      }
    }
  };

  return (
    <div
      className={classNames(
        'datepicker-wrapper',
        isFullWidth && 'is-full-width',
        'test-datepicker-wrapper'
      )}
    >
      <ClickAwayListener
        onClickAway={() => {
          onBlur && onBlur();
        }}
      >
        <ReactDatePicker
          name={name || 'elevo_date'}
          className={inputClassName(otherProps)}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          disabled={disabled}
          placeholderText={placeholder || __('Select date')}
          minDate={minDate}
          maxDate={maxDate || moment('2999/01/01').toDate()}
          selected={value}
          onChange={newValue => {
            if (value && showTimeSelectOnly) {
              const mNewValue = moment(newValue);
              const mValue = moment(value);

              newValue = mValue
                .set({
                  hour: mNewValue.get('hour'),
                  minute: mNewValue.get('minute'),
                })
                .toDate();
            }
            onChange(newValue);
          }}
          filterTime={filterTime}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          dateFormat={dateFormat}
          timeCaption={timeCaption}
          timeFormat={'p'}
          timeIntervals={timeIntervals}
          portalId={withPortal ? 'body' : undefined}
          openToDate={openToDate}
          onCalendarOpen={onCalendarOpen}
          locale={currentLocale}
          autoComplete="off"
          popperPlacement={popperPlacement}
          showIcon={!showTimeSelectOnly}
          icon={
            <div className="z-50 text-text-soften">
              <CalendarIconSvg />
            </div>
          }
          {...otherProps}
        />
      </ClickAwayListener>
    </div>
  );
}

DatePicker.defaultProps = {
  showTimeSelect: false,
  showTimeSelectOnly: false,
};

export default DatePicker;
