import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Index from './Index';
import Template from './Template';

const TemplateRoutes = () => {
  return (
    <Routes>
      <Route index element={<Index />} />
      <Route path=":id/*" element={<Template />} />
    </Routes>
  );
};

export default TemplateRoutes;
