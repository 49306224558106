import React, { useState } from 'react';
import { Navigate, Route, useNavigate, useParams } from 'react-router-dom';
import { compose } from 'redux';

import type {
  AutoAddMode,
  InteractionType,
  ResponsibleRole,
  ReviewCycle,
} from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  pathToReviewCycle,
  pathToReviewCycleParticipants,
  pathToReviewCycles,
} from 'helpers/navigation';
import { TrackView, trackAction } from 'helpers/tracking';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { del, get, post, put } from 'redux/actions/api';

import {
  Button,
  ConfirmationModal,
  DesignSystem,
  FetchContainer,
  Icon,
  PageHeader,
  PageTitle,
} from 'components';
import { withMatchParams } from 'components/HOCs/withMatchParams';

import Stepper from 'scenes/components/Stepper';

import getSetupStepInfoList from '../helpers/steps';
import CurrentStep from './steps/CurrentStep';

export type UpdatableReviewCycleFields = {
  name: string | undefined | null;
  setupStepIndex: number;
  interactionType: InteractionType | undefined | null;
  defaultTemplateId: string;
  overrideNominatePeersInstruction: string | undefined | null;
  responsibleId: string | undefined | null;
  responsibleRole: ResponsibleRole | undefined | null;
  visibleForSuperiors: boolean | undefined | null;
  visibleForReviewee: boolean | undefined | null;
  dateReviewRevieweeEnd: string | undefined | null;
  dateReviewReviewerEnd: string | undefined | null;
  autoAddParticipantsEnabled: boolean | undefined | null;
  addParticipantsBasedOnWorkStartDateAfterDays: number | undefined | null;
  revieweeDeadlineAfterDays: number | undefined | null;
  reviewerDeadlineAfterDays: number | undefined | null;
  addDirectReportsAsPeersByDefault: boolean;
  autoAddMode: AutoAddMode;
  tags: Array<string>;
  tagLabels: Array<string>;
  reviewCategorizationEnabled: boolean;
};

export type UpdateReviewCycle = (
  reviewCycle: Partial<UpdatableReviewCycleFields>
) => Promise<void>;

type AfterMatchParamsProps = {
  match: {
    id: string;
  };
};

type AfterDataLoaderProps = DataLoaderProvidedProps & {
  reviewCycle: ReviewCycle;
};

type AfterConnectProps = {
  updateReviewCycle: UpdateReviewCycle;
  deleteReviewCycle: () => Promise<void>;
  launchReviewCycle: () => Promise<void>;
} & AfterDataLoaderProps;

const ReviewCycleSetup: React.FC<AfterConnectProps> = ({
  isFetching,
  hasError,
  reviewCycle,
}) => {
  const navigate = useNavigate();

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const dispatch = useAppDispatch();
  const { id: reviewCycleId } = useParams();

  if (!reviewCycleId) return null;

  const openDeletionModal = () => setDeleteModalOpened(true);
  const closeDeletionModal = () => setDeleteModalOpened(false);

  if (reviewCycle?.status !== 'draft') {
    <Route element={<Navigate to={pathToReviewCycle(reviewCycle?.id)} />} />;
  }

  const updateReviewCycle = (
    reviewCycle: Partial<UpdatableReviewCycleFields>
  ) => dispatch(put(`review_cycles/${reviewCycleId}`, { reviewCycle }));

  const deleteReviewCycle = async () => {
    await dispatch(
      del(
        `review_cycles/${reviewCycleId}`,
        {},
        {
          successMessage: __('Campaign deleted'),
          errorMessage: __('Failed to delete campaign.'),
        }
      )
    );
    navigate(pathToReviewCycles(reviewCycle.interactionType));
  };

  const launchReviewCycle = async () => {
    await dispatch(
      post(`review_cycles/${reviewCycleId}/launch`, undefined, {
        errorMessage: __('Failed to start review campaign.'),
      })
    );

    trackAction('Review cycle launched', {
      type: reviewCycle.interactionType,
      numberOfParticipants: reviewCycle.userReviewsCount,
      name: reviewCycle.name,
    });

    navigate(pathToReviewCycleParticipants(reviewCycleId));
  };

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <DesignSystem version={2}>
          <div className="review-cycle-setup">
            <PageHeader
              title={reviewCycle.name}
              actions={
                <Button
                  color="danger"
                  onClick={openDeletionModal}
                  size="normal"
                >
                  <Icon style={{ marginRight: 8 }} name="delete" />
                  {__('Delete')}
                </Button>
              }
              withBackButton
              backButtonProps={{
                target: pathToReviewCycles(reviewCycle.interactionType),
                children: __('Back to campaigns'),
              }}
            />
            <PageTitle title={[reviewCycle.name, __('Admin')]} />

            <Stepper
              stepInfoList={getSetupStepInfoList(reviewCycle)}
              currentStepIndexFromElement={reviewCycle.setupStepIndex}
              updateElementStepIndex={newIndex =>
                updateReviewCycle({ setupStepIndex: newIndex })
              }
              renderChildren={(currentStepInfo, goToStep, goToNextStep) => (
                <>
                  <PageTitle title={[reviewCycle.name, __('Admin')]} />
                  <TrackView
                    event="Review cycle setup viewed"
                    properties={{
                      reviewCycleId: reviewCycle.id,
                    }}
                  />

                  <CurrentStep
                    currentStepInfo={currentStepInfo}
                    reviewCycle={reviewCycle}
                    goToNextStep={goToNextStep}
                    goToStep={goToStep}
                    updateReviewCycle={updateReviewCycle}
                    launchReviewCycle={launchReviewCycle}
                  />

                  <ConfirmationModal
                    isDanger
                    isActive={deleteModalOpened}
                    onCancel={closeDeletionModal}
                    onConfirm={deleteReviewCycle}
                    cancelLabel={__("Don't delete")}
                    confirmLabel={__('Delete campaign')}
                    title={__('Delete campaign')}
                    refreshContentOnOpening={false}
                  >
                    <p>{__('Caution: This action is not reversible.')}</p>
                  </ConfirmationModal>
                </>
              )}
            />
          </div>
        </DesignSystem>
      )}
    />
  );
};

export default compose<React.ComponentType>(
  withMatchParams,
  newDataLoader({
    fetch: ({ match }: AfterMatchParamsProps) =>
      get(`review_cycles/${match.id}`),
    hydrate: {
      reviewCycle: {
        defaultTemplate: {},
        templates: {},
        responsible: {},
        autoAddRules: { userSegments: {} },
        tags: {},
        abilities: {},
      },
    },
    cacheKey: ({ match }) => match.id,
  })
)(ReviewCycleSetup);
